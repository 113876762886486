import { gql } from "@apollo/client";

export const MUTATION_UPDATE_USER_TO_CUSTOM_FORM_FIELD = gql`
  mutation MyMutation(
    $customFormFieldId: uuid
    $doctorId: uuid
    $userId: uuid
    $value: String
    $id: uuid
  ) {
    update_userToCustomFormField(_set: {customFormFieldId: $customFormFieldId, doctorId: $doctorId, userId: $userId, value: $value}, where: {id: {_eq: $id}}) {
      returning {
        createdAt
        customFormFieldId
        doctorId
        id
        userId
        value
      }
    }
  }
`;

export const MUTATION_INSERT_USER_TO_CUSTOM_FORM_FIELD = gql`
  mutation MUTATION_INSERT_USER_TO_CUSTOM_FORM_FIELD(
    $customFormFieldId: uuid
    $doctorId: uuid
    $userId: uuid
    $value: String
    $key: String
  ) {
    insert_userToCustomFormField_one(object: {customFormFieldId: $customFormFieldId, doctorId: $doctorId, userId: $userId, value: $value, key: $key}) {
      created_at
      customFormFieldId
      doctorId
      id
      userId
      value
    }
  }
`;


