import React, { useEffect, useState } from "react";
import * as S from "./BoxInfoTimeFilter.style";
import { useTranslation } from "react-i18next";

import Popup from "reactjs-popup";

// pipes
import * as P from "../../../../../utils/pipes";

// components
import ZaiaToggle from "../../../../zaiaComponents/zaiaToggle";

function BoxInfoTimeFilter({
  icon,
  title,
  color,
  date,
  colorTitle,
  transparentColor,
  setTime,
  time,
  children
}) {
  const nowTime = new Date(Date.now());
  const [t] = useTranslation("global");
  const [timesList, setTimesList] = useState();

  return (
    <S.BoxInfoPatientDiv
      color={color}
      colorTitle={colorTitle}
      transparentColor={transparentColor}
    >
      <div className="boxInfoPatient__header">
        <div className="boxInfoPatient__header--left">
          <div className="boxInfoPatient__title">
            <div>
              <div className={`icon ${icon}`}></div>
            </div>
            <h4>{title}</h4>
          </div>
        </div>
        <div className="boxInfoPatient__header--center">
          <div className="boxInfoPatient__time">
            <Tabs color={color} setTimesList={setTimesList} nowTime={nowTime} setTime={setTime}/>
          </div>
        </div>
        <div className="boxInfoPatient__header--right">
          <Popup
            trigger={
              <div className="boxInfoPatient__date">
                <div className="boxInfoPatient__text-date">
                  { time?.timeOp === 'week' ? t("patients.boxInfoPatient.from") + ' ' : null}
                  <strong>{time?.name}</strong>
                </div>
                <i className="icon zaia-icono-flechas-arriba-abajo"></i>
              </div>
            }
            keepTooltipInside
            nested
          >
            <DatesContainer color={color} timesList={timesList} setTime={setTime} time={time}/>
          </Popup>
        </div>
      </div>
      {children}
    </S.BoxInfoPatientDiv>
  );
}

const Tabs = ({color, setTimesList, nowTime, setTime}) => {
  const [t] = useTranslation("global");
  const [activeTab, setActiveTab] = useState(3);

  useEffect(() => {
    // timesForDay().then(
    //   times => {
    //     setTime(times[0]);
    //     setTimesList(times);
    //   }
    // )
    timesForMonth().then(
      timesForMonth => {
        setTime(timesForMonth[0]); 
        setTimesList(timesForMonth);
      }
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[setTime, setTimesList]);

  const tabs = [
    {id: 1, name: t("patients.boxInfoPatient.tabs.day")}, // day 1
    {id: 2, name: t("patients.boxInfoPatient.tabs.week")}, // week 2
    {id: 3, name: t("patients.boxInfoPatient.tabs.month")} // year 3
  ]

  const changeDates = (e) => {
    if(e !== activeTab){
      setActiveTab(e);
      if(e === 1) timesForDay().then(
        timesDay => {
          setTime(timesDay[0]);
          setTimesList(timesDay);
        }
      )
      if(e === 2) timesForWeek().then(
        timesWeek => {
          setTime(timesWeek[0]);
          setTimesList(timesWeek);
        }
      )
      if(e === 3) timesForMonth().then(
        timesForMonth => {
          setTime(timesForMonth[0]); 
          setTimesList(timesForMonth);
        }
      )
    }
  }

  const timesForDay = async () => {
    let timesDay = [];
    for(let i=0; i<8; i++) {
      let time = nowTime.setDate(nowTime.getDate() - (i === 0 ? 0 : 1));
      timesDay.push(
        {
          startDate: new Date(time).setHours(0,0,0,0),
          finishDate: new Date(time).setHours(23,59,59,999),
          name: P.longDate(new Date(time), t),
          timeOp: 'day'
        }
      )
    }
    return Promise.all(timesDay);
  }

  const timesForWeek = async () => {
    let timesWeek = [];
    let nowDay = nowTime.getDay();
    let dateStartWeek = new Date(nowTime);
    let dateFinishWeek = new Date(nowTime);
    for(let i=0; i<8; i++) {
      dateStartWeek.setDate(dateStartWeek.getDate() - (i === 0 ? (nowDay - 1) : 7));
      dateFinishWeek.setDate(dateFinishWeek.getDate() - (i === 0 ? 0 : i === 1 && nowDay !== 0 ? nowDay : 7));
      timesWeek.push(
        {
          startDate: new Date(dateStartWeek).setHours(0,0,0,0),
          finishDate: new Date(dateFinishWeek).setHours(23,59,59,999),
          name: P.shortDate(new Date(dateStartWeek), t, false) + " - " +  P.shortDate(new Date(dateFinishWeek), t, false),
          timeOp: 'week'
        }
      )
    }
    return Promise.all(timesWeek);
  }
  
  const timesForMonth = async () => {
    let timesMonth = [];
    let dateStartMonth = new Date(nowTime)
    let dateFinishMonth = new Date(nowTime);
    for(let i=0; i<11; i++) {
      let numberMonth = dateFinishMonth.getMonth();
      let numberDaysMonth = new Date(dateFinishMonth.getFullYear(), numberMonth + 1, 0).getDate();
      dateFinishMonth.setDate(numberDaysMonth);
      dateStartMonth.setDate(1);
      timesMonth.push(
        {
          startDate: new Date(dateStartMonth).setHours(0,0,0,0),
          finishDate: new Date(dateFinishMonth).setHours(23,59,59,999),
          name: P.nameMonth(numberMonth, 'L', t),
          timeOp: 'month'
        }
      )
      dateFinishMonth.setDate(dateFinishMonth.getDate() - numberDaysMonth);
      dateStartMonth.setDate(dateStartMonth.getDate() - 1);
    }
    return Promise.all(timesMonth);
  }

  return (
    <ZaiaToggle
      color={color}
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={changeDates}
    />
  );
};

const DatesContainer = ({color, timesList, setTime, time}) => {
  return (
    <S.DateOptions color={color}>
      {
        timesList?.map(timeItem => {
          return (
            <div 
              key={timeItem.startDate} 
              className="select-date" 
              onClick={() => setTime(timeItem)}
              style={{backgroundColor: timeItem === time ? color : "", color: timeItem === time ? "var(--white)" : "" }}
              ref={element => { 
                if (element) element.style.setProperty('color', timeItem === time ? "var(--white)" : "", 'important'); 
              }}
            >{timeItem.name}</div> 
          )
        })
      }
    </S.DateOptions>
  )
}

export default BoxInfoTimeFilter;
