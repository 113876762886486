import { useStateValue } from "../contextAPI/StateProvider";

export default function useActivateModal() {
  const [, dispatch] = useStateValue();

  const activeModal = (modal) => {
    const newPopUp = {
      isActive: true,
      modal: modal
    }
    dispatch({
      type: "SET_POP_UP",
      popUp: newPopUp,
    });        
  }

  const desactiveModal = () => {
    const newPopUp = {
      isActive: false,
      modal: () => <></>
    }
    dispatch({
      type: "SET_POP_UP",
      popUp: newPopUp,
    });
  }

  return { 
    activeModal,
    desactiveModal 
  };
}