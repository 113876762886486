import styled from "styled-components";

export const CustomCheckBoxDiv = styled.div`
  display: flex;
  align-items: center;
  .checkbox {
    font-size: smaller;
    color: var(--subtitle-color);
    display: flex;
    align-items: center;
  }
  .checkbox > input[type="checkbox"] {
    cursor: pointer;
    margin-right: 5px;
  }
`;
