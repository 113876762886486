import styled from "styled-components";

export const ProgressBarDiv = styled.div`
  height: 10px;
  width: 95%;
  background-color: #e3e3e3;
  border-radius: 20px;
  margin: 15px;
  position: relative;
  div {
    background: var(--red-color);
    box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
    border-radius: 20px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
  }
`;
