import styled from "styled-components";

export const CmpBoxesContainer = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  h5 {
    font-family: "Open Sans Bold";
  }
  .boxes-container {
    /* max-height: 120px;
    overflow: auto; */
    margin: 8px 0 8px;
    display: flex;
    flex-wrap: wrap;
  }
  .middle-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Open Sans";
    h5 {
      font-family: "Nunito";
    }
    &-right {
      color: var(--blue-color);
      font-size: 14px;
      font-family: "Open Sans Semibold";
      display: flex;
      cursor: pointer;
      align-items: center;
      &-container {
        margin-left: 15px;
        width: 120px;
      }
    }
  }
  .big-info-card{
    padding: 20px;
    border-radius: 10px;    
    box-shadow: 0px 3px 6px var(--box-shadow);
    h4 {
      font-size: 15px;
      font-family: "Open Sans Semibold";
      color: var(--blue-color);
    }
    ul {
      margin-top: 10px;
      padding-left: 18px;
    }
  }
`;

export const Box = styled.div`
  border-radius: 10px;
  background-color: ${({active}) => active ? 'var(--blue-dark-color)' : 'var(--no-notification-background)'};
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 110px;
  height: 85px;
  font-family: "Open Sans Bold";
  color: ${({active}) => active ? 'var(--white)' : 'var(--blue-color)'};
  text-align: center;
  margin: 0 8px 8px 8px;
  padding: 5px 13px;
  box-sizing: border-box;
  /* cursor: pointer; */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  cursor: pointer;
  .risk-group-box-text {
    font-size: 13px;
    margin: 5px 0 28px;
    margin: auto;
  }

`
