  import styled, { keyframes } from "styled-components";

  const dotElasticBefore = () => keyframes`
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  `;

const dotElasticAfter = () => keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
`;

const dotElastic = () => keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const ZaiaLoadingPoints = styled.div`
  padding: 2px 12px;
  
  .dot-elastic {
    position: relative;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotElastic()} 1s infinite linear;
  }

  .dot-elastic::before, .dot-elastic::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-elastic::before {
    left: -11px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotElasticBefore()} 1s infinite linear;
  }

  .dot-elastic::after {
    left: 11px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotElasticAfter()} 1s infinite linear;
  }
  
`