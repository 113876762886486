import React, { useContext, useMemo } from "react";
import * as S from "./OutcomesTable.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../../../utils/pipes";

// Hooks
import useDoctorData from "../../../../../../../../../hooks/useDoctorData";

// GraphQL
import { useMutation } from "@apollo/client";
import {
  MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD,
  MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD,
  MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD,
} from "../../../../../../../../../graphql/mutations/DoctorToUserProcessBoard";
import Popup from "reactjs-popup";
import ZaiaReaction from "../../../../../../../../zaiaComponents/zaiaReaction";
import { ThemeContext } from "styled-components";
import useActivateModal from "../../../../../../../../../hooks/useActivateModal";
import NewEventModal from "../newEventModal";
import InvitePatientButton from "../../../../../../../patients/components/invitePatientButton";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;

function OutcomesTable({
  patientID,
  processID,
  processToCustomFormID,
  customForm,
  customFormLoading,
  userToProcessData,
  highlightsFilter,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const theme = useContext(ThemeContext);
  const { activeModal, desactiveModal } = useActivateModal();

  const [insertDoctorToUserProcessBoard] = useMutation(
    MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [deleteDoctorToUserProcessBoard] = useMutation(
    MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [updateDoctorToUserProcessBoard] = useMutation(
    MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD
  );

  const insertBoardData = async (customFormId) => {
    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data,
      } = await insertDoctorToUserProcessBoard({
        variables: {
          customFormId: customFormId,
          typeView: "new",
          doctorId: doctorData.id,
          userToProcessId: userToProcessData.userToProcess[0].id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateBoardData = async (doctorToUserProcessBoardId, state) => {
    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data,
      } = await updateDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
          typeView: state ? "new" : "history",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBoardData = async (doctorToUserProcessBoardId) => {
    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data,
      } = await deleteDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const custoFormOrder = useMemo(() => {
    const formGroupsFilter = highlightsFilter
      ? customForm?.customForm_by_pk.formGroups.filter(
          (formGroup) => formGroup.DoctorToUserProcessBoards[0]
        )
      : customForm?.customForm_by_pk.formGroups;

    return formGroupsFilter?.map((formGroup) => {
      // const optionsWithoutDate = formGroup.customFormFields.filter( item => item.typeFieldValue !== "dateInput");

      const optionsWithPayload = formGroup.customFormFields.map((option) => {
        let optionTemp = JSON.parse(JSON.stringify(option));
        if (
          optionTemp &&
          optionTemp.customFormTypeField?.fieldOptions?.length > 0 &&
          optionTemp.userToCustomFormFields?.length > 0
        ) {
          let payloadItem = optionTemp.customFormTypeField.fieldOptions.find(
            (field) => field.key === optionTemp.userToCustomFormFields[0].key
          )?.payload;
          let payloadFormated;
          if (payloadItem) {
            payloadFormated = JSON.parse(payloadItem);
            try {
              optionTemp.payload =
                "msg" in JSON.parse(payloadItem) ? payloadFormated.msg : null;
            } catch (err) {
              optionTemp.payload = null;
            }
          } else {
            optionTemp.payload = null;
          }
        } else {
          optionTemp.payload = null;
        }

        return optionTemp;
      });

      return [
        formGroup,
        optionsWithPayload,
        formGroup?.customFormFields[0]?.userToCustomFormFields[0] &&
        formGroup?.customFormFields[0]?.userToCustomFormFields[0].doctor
          ? `${formGroup?.customFormFields[0]?.userToCustomFormFields[0]?.doctor.name} ${formGroup?.customFormFields[0]?.userToCustomFormFields[0]?.doctor.lastname}`
          : "-",
        formGroup?.customFormFields[0]?.userToCustomFormFields[0]
          ? P.formatNumberDate(
              formGroup?.customFormFields[0]?.userToCustomFormFields[0]
                ?.created_at,
              true
            )
          : "–",
        formGroup?.userToProcesses[0]
          ? formGroup.userToProcesses[0]?.descript.split("-")[0] ===
            "undefined "
            ? "-"
            : formGroup.userToProcesses[0]?.descript.split("-")[0]
          : "-",
      ];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customForm, highlightsFilter]);

  const modalPatient = (formFieldID) => {
    return (
      <>
        <NewEventModal
          patientID={patientID}
          processID={processID}
          processToCustomFormID={processToCustomFormID}
          customFormFieldID={formFieldID}
        />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    );
  };

  // const activeNewModal = (customFormFieldID) => {
  //   console.log("helado", customFormFieldID);
  //   setCustomFormFieldID(customFormFieldID)
  //   activeModal(() => modalPatient())
  // }

  return (
    <S.OutcomesTable>
      <table className="illness-control-table">
        <thead>
          <tr>
            <th>{t("outcomes.tableOutcomes.eventType")}</th>
            <th>{t("outcomes.tableOutcomes.details")}</th>
            <th>{t("outcomes.tableOutcomes.doctorInCharge")}</th>
            <th>{t("outcomes.tableOutcomes.date")}</th>
            <th>{t("outcomes.tableOutcomes.comment")}</th>
          </tr>
        </thead>
        <tbody>
          {custoFormOrder?.map((formGroup, index) => {
            // console.log("gaga", );
            // console.log("gaga", formGroup[0].customFormName);

            return (
              <tr key={index}>
                {formGroup.map((formGroup, index) => {
                  if (index === 1) {
                    return (
                      <td key={index}>
                        {formGroup.length <= 0
                          ? "–"
                          : formGroup.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div>
                                    <p>
                                      {P.dataTranslation(
                                        item.fieldName,
                                        language
                                      )}
                                      {item.descript ? (
                                        <Popup
                                          trigger={
                                            <i className="template-header-item-icon icon zaia-alert"></i>
                                          }
                                          on={["hover", "focus"]}
                                          closeOnDocumentClick
                                          keepTooltipInside
                                          position={["right center"]}
                                          nested
                                        >
                                          <S.PopUpPayload>
                                            <p>
                                              {P.dataTranslation(
                                                item.descript,
                                                language
                                              )}
                                            </p>
                                          </S.PopUpPayload>
                                        </Popup>
                                      ) : null}
                                    </p>
                                    {item.userToCustomFormFields[0]
                                      ? item.typeFieldValue === "dateInput"
                                        ? P.formatNumberDate(
                                            item.userToCustomFormFields[0]
                                              ?.value,
                                            true
                                          )
                                        : item.userToCustomFormFields[0]?.value
                                      : "-"}
                                    {item.payload ? (
                                      <Popup
                                        trigger={
                                          <i className="template-header-item-icon icon zaia-alert"></i>
                                        }
                                        on={["hover", "focus"]}
                                        closeOnDocumentClick
                                        keepTooltipInside
                                        position={["right"]}
                                        nested
                                      >
                                        <S.PopUpPayload>
                                          <p>{item.payload}</p>
                                        </S.PopUpPayload>
                                      </Popup>
                                    ) : null}
                                  </div>
                                  <br />
                                </div>
                              );
                            })}
                      </td>
                    );
                  }
                  if (index === 0) {
                    return (
                      <td
                        key={index}
                        style={{
                          color: "",
                          fontFamily: "Nunito ExtraBold",
                          verticalAlign: "middle",
                        }}
                      >
                        {formGroup.DoctorToUserProcessBoards[0] ? (
                          <i
                            className="icon-save-form-field icon zaia-star-on"
                            onClick={() =>
                              deleteBoardData(
                                formGroup.DoctorToUserProcessBoards[0].id
                              )
                            }
                          ></i>
                        ) : (
                          <i
                            className="icon-save-form-field icon zaia-star-off"
                            onClick={() => insertBoardData(formGroup.id)}
                          ></i>
                        )}
                        {
                          <i
                            className="icon-save-form-field icon zaia-icono-blog-web"
                            onClick={() =>
                              // activeNewModal(formGroup.id)
                              activeModal(() => modalPatient(formGroup.id))
                            }
                          ></i>
                        }
                        {formGroup.DoctorToUserProcessBoards[0] ? (
                          <i
                            className="icon-save-form-field icon zaia-timeline"
                            style={{
                              color:
                                formGroup.DoctorToUserProcessBoards[0]
                                  .typeView === "history"
                                  ? theme.inputSelected
                                  : theme.onBackgroundSoft,
                            }}
                            onClick={() =>
                              formGroup.DoctorToUserProcessBoards[0]
                                .typeView === "history"
                                ? updateBoardData(
                                    formGroup.DoctorToUserProcessBoards[0].id,
                                    true
                                  )
                                : updateBoardData(
                                    formGroup.DoctorToUserProcessBoards[0].id,
                                    false
                                  )
                            }
                          />
                        ) : null}
                        {P.dataTranslation(formGroup.customFormName, language)}
                        {formGroup.descript ? (
                          <Popup
                            trigger={
                              <i className="template-header-item-icon icon zaia-alert"></i>
                            }
                            on={["hover", "focus"]}
                            closeOnDocumentClick
                            keepTooltipInside
                            position={["right"]}
                            nested
                          >
                            <S.PopUpPayload>
                              <p>
                                {P.dataTranslation(
                                  formGroup.descript,
                                  language
                                )}
                              </p>
                            </S.PopUpPayload>
                          </Popup>
                        ) : null}
                      </td>
                    );
                  }
                  return <td key={index}>{formGroup}</td>;
                })}
                {/* <td>
                    <i className="notes-last-change zaia-icono-chat-relleno"></i>
                  </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      {customFormLoading ? (
        <div className="illness-control-reaction">
          <ZaiaReaction widthImg="170px" zaiaImg={logoZaiaLoading} />
        </div>
      ) : customForm === undefined ? (
        <div className="illness-control-reaction">
          <ZaiaReaction
            zaiaImg={logoZaiaEmpty}
            widthImg="170px"
            text={t("outcomes.tableOutcomes.empty")}
            sizeText="18px"
            widthText="200px"
          />
        </div>
      ) : null}
    </S.OutcomesTable>
  );
}

export default OutcomesTable;
