import styled from "styled-components";

export const SidebarPatients = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: ${({theme}) => theme.variantBackground};
  .sidebar-patient {
    width: 300px;
    background-color: ${({theme}) => theme.background};

    &__top {
      height: ${({heightTop}) => heightTop};
    }

    &__list {
      height: ${({heightTop}) => 'calc(100% - ' + heightTop + ')'};
      padding: 0 10px;
      /* .patients__algorithmsInput {
        background-color: var(--background-header-input);
        border: none;
        padding: 6px;
        width: 95%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin-left: 5px;
        margin-top: 10px;
        input {
          border: none;
          background-color: var(--background-header-input);
          width: 300px;
          margin-left: 2px;
          &:focus {
            outline: none;
          }
        }
      } */
    }
  }

  
`;

export const SidebarPatientsButton = styled.button`
  position: relative;
  margin: auto;
  border: none;
  background: none;
  outline: none;
  .sidebarPatientsButton__icon {
    padding: 5px 2px 5px 1px;
    background-color: ${({theme}) => theme.background};
    border-radius: 0 10px 10px 0;
    font-size: 13px;
    color: ${({theme}) => theme.onBackground};
  }
`
