import React, { useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";
import ReCaptcha from "react-google-recaptcha";

import * as S from "./ResetPassword.style";

import Header from "../header";
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import ZaiaInputForm from "../../zaiaComponents/zaiaInputForm";
import CoverPage from "../coverPage";
import EmailSent from "../emailSent";
import ErrorMessage from "../errorMessage";

// GraphQL
import { useMutation } from "@apollo/client";
import { MUTATION_RESET_PASS } from "../../../graphql/mutations/Auth";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
const env = process.env.REACT_APP_ENV;

function ResetPassword() {
  const [t] = useTranslation("global");
  const [{ colorTheme },] = useStateValue();
  const [emailSentActive, setEmailSentActive] = useState(false);
  const [emailSent, setEmailSent] = useState("");
  const [resetPass] = useMutation(MUTATION_RESET_PASS);
  const [ activeErrorForm, setActiveErrorForm ] = useState(false);
  const [errorCaptcha, setErrorCaptcha] = useState(false);  
  const resetPassCaptchaRef = useRef(null);

  const handleSubmit = async (values, { resetForm }) => {
    if (env === 'test' && env === 'development') {
      const captchaToken = await resetPassCaptchaRef.current.executeAsync();
      if(captchaToken) {
        resetPassFunction(values, resetForm, captchaToken);
      } else {
        setErrorCaptcha(true);
        activateError();
        resetPassCaptchaRef.current.reset();
      }
      resetForm();
    } else {
      resetPassFunction(values, resetForm);
    }
  };

  const resetPassFunction = async (values, resetForm, captchaToken) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const data = resetPass({
        variables: {
          email: values.email,
          captchaToken: captchaToken
        },
      });
      setEmailSentActive(true)
      setEmailSent(values.email)
      console.log('mensaje exitoooo', data);
    } catch (err) {
      setErrorCaptcha(false);
      if(captchaToken)  resetPassCaptchaRef.current.reset();
    }
  }

  const activateError = () => {
    setActiveErrorForm(true);
    setTimeout(function() {
      setActiveErrorForm(false);      
    }, 5000);
  }

  if (emailSentActive) 
    return (
      <EmailSent
        email={emailSent}
        setEmailSent={setEmailSentActive}
        message1={t("emailPassSent.link")}
        message2={t("emailPassSent.checkLink")}
      />
    )

  return (
    <S.ResetPasswordDiv>
      <CoverPage/>
      <div className="resetPassword__right">
        <Header route="/" />
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("register.validation.email"))
              .required(t("register.validation.required")),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className="reset-pass-form">
              <>
                <S.ResetPasswordFormDiv>
                  <div className="reset-pass-container">
                    <ErrorMessage
                      errorMessage={errorCaptcha}
                      activeError={activeErrorForm}
                    />
                    <h2>{t("resetPassword.title")}</h2>
                    <p>{t("resetPassword.subtitle")}</p>
                    <div className="resetPassword__email">
                      <h3 className="title-register">{t("home.email")}</h3>
                      <ZaiaInputForm
                        placeholder={t("home.emailPlace")}
                        name="email"
                        type="email"
                        icon="icono-email"
                      />
                      {
                        env !== 'test' && env !== 'development' &&
                        <ReCaptcha
                          ref={resetPassCaptchaRef}
                          sitekey={reCaptchaKey}
                          size="invisible"
                          theme={colorTheme}
                        />
                      }
                      <ZaiaButton
                        type="submit"
                        marginTop="170"
                        shadow={true}
                        title={
                          props.isSubmitting
                            ? `${t("globally.loading")}...`
                            : `${t("globally.send")}`
                        }
                      />
                    </div>
                  </div>
                </S.ResetPasswordFormDiv>
              </>
            </form>
          )}
        </Formik>
      </div>
    </S.ResetPasswordDiv>
  );
}

export default ResetPassword;
