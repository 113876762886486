import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as S from "./Patients.style";
import { useStateValue } from "../../../contextAPI/StateProvider";
import { useParams } from "react-router-dom";

// Components
import SidebarPatients from "../shared/sidebarPatients";
import DashboardAllPatients from "./components/dashboardAllPatients";
import InvitePatientButton from "./components/invitePatientButton";
import InvitePatientForm from "./components/invitePatientForm";
import SidebarTopPatients from "./components/sidebarTopPatients";
import DashboardPatient from "./components/dashboardPatient";

// hooks
import useActivateModal from "../../../hooks/useActivateModal";
import usePatientVerification from "../../../hooks/usePatientVerification";

function Patients() {
  const [t] = useTranslation("global");
  const history = useHistory();
  const { patientID } = useParams();
  const { activeModal, desactiveModal } = useActivateModal();
  const [{ selectedPatient }] = useStateValue();
  const panelActive = window.location.href.split('?')[1]?.split('=')[0] === "panelActive" && window.location.href.split('?')[1].split('=')[1] ? window.location.href.split('?')[1].split('=')[1] : null;

  const { patientValid, loadingValidation }  = usePatientVerification(patientID);

  useEffect(() => {
    if(patientValid === false && !loadingValidation) history.push('/patients');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientValid, loadingValidation])

  const modalPatient = () => {
    return (
      <>
        <InvitePatientForm />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  } 

  return (
    <S.Patients selectedPatient={selectedPatient}>
      <div className="patients-container">
        {
          selectedPatient ? null : 
          <div className="patients-container__sidebar-patients">
            <SidebarPatients buttonHide={true} defaultHide={panelActive !== null && !(String(panelActive).toLowerCase() === "true")} heightTop="178px" urlRedirection={'/patients'}>
              <SidebarTopPatients title={t("patients.patientList.select")} />
            </SidebarPatients>
          </div>
        }
        <div className="patients-container__info-content">
          { patientID ?  
            <DashboardPatient patientID={patientID} /> :
            <DashboardAllPatients />
          }
        </div>
      </div>
      <div onClick={() => activeModal(() => modalPatient())}>
        <InvitePatientButton showModal={false} />
      </div>
    </S.Patients>
  );
}

export default Patients;
