import styled from "styled-components";

export const FeelingsBoxInfo = styled.div`

  .boxInfoPatient__info {
    width: 100% ;
    display: flex;
    
    &-left {
      display: flex;
      width: calc(100% - 200px);
      overflow-x: auto;
      
      &-first {
        width: 40%;
        padding-right: 10px;
        min-width: 314px;
        display: flex;
      }

      &-second {
        width: 60%;
        padding: 0 10px 0 10px;
      }
    }

    &-right {
      width: 200px;
      padding-left: 10px;
      display: flex;
      min-width: 200px;
    }

  }
`;