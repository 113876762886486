export const Routes = {
  HOME: {
    name: "Home",
    route: "/",
  },
  LOGIN: {
    name: "Inicio de sesión",
    route: "/login",
  },
  REGISTER: {
    name: "Registro",
    route: "/register",
  },
  RESET_PASSWORD: {
    name: "Restablecer contraseña",
    route: "/reset-password",
  },
  DASHBOARD: {
    name: "Inicio",
    route: "/dashboard",
  },
  PACIENTS: {
    name: "Patientes",
    route: "/patients",
  },
  ALGORITHMS: {
    name: "Algoritmos",
    route: "/algorithms",
  },
  OUTCOMES: {
    name: "Resultados",
    route: "/outcomes",
  },
  CHAT: {
    name: "Chat",
    route: "/chat",
  },
};
