import { gql } from "@apollo/client";

export const SUSCRIPTION_GET_VITAL_SIGNS = gql`
  subscription SUSCRIPTION_GET_VITAL_SIGNS(
    $vitalSigns: [userVitalSignHistory_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    userVitalSignHistory(where: {_or: $vitalSigns, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUSCRIPTION_GET_VITAL_SIGNS_BY_USER = gql`
  subscription SUSCRIPTION_GET_VITAL_SIGNS(
    $vitalSigns: [userVitalSignHistory_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
  ) {
    userVitalSignHistory(where: {_or: $vitalSigns, created_at: {_gte: $initialDate, _lte: $finalDate}, userId: {_eq: $userId}}) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`
export const SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER = gql`
  subscription SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS(
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
  ) {
    userVitalSignHistory(where: {created_at: {_gte: $initialDate, _lte: $finalDate}, userId: {_eq: $userId}, isTaken: {_eq: false}, comment: {_is_null: false}}) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      comment
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`
export const SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS = gql`
  subscription SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS(
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    userVitalSignHistory(where: {created_at: {_gte: $initialDate, _lte: $finalDate}, isTaken: {_eq: false}, comment: {_is_null: false}}) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      comment
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`