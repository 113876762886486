import React, { useState, useContext } from "react";
import * as S from "./InvitePatientForm.style";
import { Formik } from "formik";
import * as Yup from "yup";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { ThemeContext } from 'styled-components';

// GraphQL
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";
import { MUTATION_ENRROLL_PATIENCE } from "../../../../../graphql/mutations/Patience";
import { MUTATION_SIGN_UP } from "../../../../../graphql/mutations/User";
// import { doctorVar } from '../../../../../graphql/cache';

// pipes
import * as P from "../../../../../utils/pipes";

// components
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";

// Hooks
import useActivateModal from "../../../../../hooks/useActivateModal"

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-ok.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;

function InvitePatientForm() {

  const [typeForm, setTypeForm] = useState("invitation");
  const { t, i18n: { language } } = useTranslation("global");
  const [formInfo, setFormInfo] = useState(null);
  const [response, setResponse] = useState();
  const [errorResponse, setErrorResponse] = useState();
  const {desactiveModal} = useActivateModal()
  const [createAuditPatient, setCreateAuditPatient] = useState(null);

  const [enrrollPatience, { loading: enrrollLoading }] = useMutation(MUTATION_ENRROLL_PATIENCE);
  const [registerUser, { loading: userRegisteredLoading }] = useMutation(MUTATION_SIGN_UP);
  
  const handleSubmit =  (values, { resetForm }) => {
  if(typeForm === "invitation") {
    enrrollPatient(values, resetForm);
  } else {
    registerPatient(values, resetForm);
    }
  };

  const registerPatient = async ( values, resetForm ) => {
    let enrrollMessage;
    try {
      const {
        data: {
          signup: { message }
        }
      } = await registerUser({
        variables: {
          lastname: values.lastname,
          name: values.name,
          document: values.document.toString(),
          email: !createAuditPatient ? values.email : `${values.document}@zaia.com`,
        },
      });
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(message), language);
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryItAgain")}`;
      }
      setResponse(enrrollMessage);
      enrrollPatient(values);
    } catch (err) {
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(err.message), language);
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryItAgain")}`;
      }
      setErrorResponse(enrrollMessage);
      console.log("errorrrrrr", err);
      setTypeForm("error");
    }
    setFormInfo(null); 
    resetForm();
  }

  const enrrollPatient = async ( values, resetForm ) => {
    // setFormInfo(values);
    let enrrollMessage;
    try {
      const {
        data: {
          enrrollPatient: { message }
        }
      } = await enrrollPatience({
        variables: {
          descript: values.descript,
          processId: Number(values.processId),
          stageId: 'enroll',
          document: values.document.toString(),
          email: !createAuditPatient ? values.email : `${values.document}@zaia.com`,
          isAcepted: createAuditPatient === false || createAuditPatient === null ? null : true
        },
      });
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(message), language);
      } catch (err) {
        enrrollMessage = "Error";
      }
      setResponse(enrrollMessage);
      setTypeForm("success");  
    } catch (err) {
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(err.message), language);
      } catch (err) {
        enrrollMessage = "Error";
      }
      setErrorResponse(enrrollMessage);
      console.log("errorrrrrr", err);
      setTypeForm("error");
    }
    setFormInfo(null); 
    // resetForm();
  }

  if(enrrollLoading || userRegisteredLoading) {
    return(
      <S.InvitePatientsModalDiv>
        <ZaiaReaction
          widthImg="200px"
          zaiaImg={logoZaiaLoading}
        />
      </S.InvitePatientsModalDiv>
    )
  }
  
  return (
    <Formik
      initialValues={{
        name: formInfo?.name ? formInfo?.name: "",
        lastname: formInfo?.lastname ? formInfo?.lastname : "",
        email: formInfo?.email ? formInfo?.email : "",
        document: formInfo?.document ? formInfo?.document : "",
        processId: formInfo?.processId ? formInfo?.processId : "",
        descript: formInfo?.descript ? formInfo?.descript : "",
      }}
      validationSchema={
        Yup.object().shape(
            typeForm === "invitation" ?
            {
              document: Yup.string()
                .when("email", {  
                  is: (document) => !document || document.length === 0,     
                  then: Yup.string().required(t("patients.patientInvitation.docEmailValid")),
                }),
              email: Yup.string()
                .email(t("register.validation.email"))
                .when("document", {
                  is: (email) => !email || email.length === 0,     
                  then: Yup.string().required(t("patients.patientInvitation.docEmailValid")),
                }),
              processId: Yup.number()
                .positive(t("register.validation.invalid"))
                .required(t("register.validation.required")),
            } : 
            {
              name: Yup.string()
                .min(3, t("register.validation.min3"))
                .max(15, t("register.validation.max"))
                .required(t("register.validation.required")),
              lastname: Yup.string()
                .min(3, t("register.validation.min3"))
                .max(15, t("register.validation.max"))
                .required(t("register.validation.required")),
              // document: Yup.string()
              //   .when("email", {  
              //     is: (document) => !document || document.length === 0,     
              //     then: Yup.string().required(t("patients.patientInvitation.docEmailValid")),
              //   }),
              // email: Yup.string()
              //   .email(t("register.validation.email"))
              //   .when("document", {
              //     is: (email) => !email || email.length === 0,     
              //     then: Yup.string().required(t("patients.patientInvitation.docEmailValid")),
              //   }),
              document: Yup.string()
                .required(t("register.validation.required")),
              email: !createAuditPatient 
                ? Yup.string()
                  .email(t("register.validation.email"))
                  .required(t("register.validation.required"))
                : null,
              processId: Yup.number()
                .positive(t("register.validation.invalid"))
                .required(t("register.validation.required")),
            }, ['email', 'document']
          )
      }
      onSubmit={handleSubmit}
    >
      {(props) => (
        <S.InvitePatientsModalDiv>
          <form onSubmit={ props.handleSubmit }>
            <div className="card-inputs">
              { 
                typeForm === "invitation" ?
                  <FormInvitationInputs /> : 
                typeForm === "register" ?
                  <FormRegisterInputs createAuditPatient={createAuditPatient} setCreateAuditPatient={setCreateAuditPatient}/> :
                typeForm === "error" ? 
                <ZaiaReaction
                  text={errorResponse}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaAlert}
                /> : 
                <ZaiaReaction
                  text={response}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaOk}
                />
              }
            </div>
            <div className="card-buttons">
              { 
                typeForm === "invitation" ? 
                <>
                {/* buttons for invitation form */}
                  <ZaiaButton
                    type="submit"
                    option="primary"
                    marginTop="30"
                    marginBottom="10"
                    title={t("patients.btn")}
                  />
                  <p>{t("globally.or")}</p>
                  <div onClick={() => setTypeForm("register")}>
                    <ZaiaButton
                      type="button"
                      option="secondary"
                      marginTop="10"
                      title={t("patients.patientInvitation.registerNewUser")}
                    />
                  </div>
                </> : 
                typeForm === "register" ? 
                <>
                {/* buttons for register form */}
                  <ZaiaButton
                    type="submit"
                    option="primary"
                    marginTop="30"
                    marginBottom="10"
                    title={t("patients.patientInvitation.registerNewUser")}
                  />
                  <p>{t("globally.or")}</p>
                  <div onClick={() => setTypeForm("invitation")}>
                    <ZaiaButton
                      type="button"
                      option="secondary"
                      marginTop="10"
                      title={t("patients.btn")}
                    />
                  </div>
                </> :
                typeForm === "error" ? 
                <>
                {/* buttons for error alert */}
                  <div onClick={() => setTypeForm("register")}>
                    <ZaiaButton
                      type="button"
                      option="primary"
                      marginBottom="10"
                      marginTop="20"  
                      title={t("patients.patientInvitation.registerNewUser")}
                    />
                  </div>
                  <p>{t("globally.or")}</p>
                  <div onClick={() => setTypeForm("invitation")}>
                    <ZaiaButton
                      type="button"
                      option="secondary"
                      marginTop="10"
                      title={t("patients.btn")}
                    />
                  </div>
                </> : 
                <>
                  {/* buttons for success alert */}
                  <div onClick={() => desactiveModal()}>
                    <ZaiaButton
                      type="button"
                      marginBottom="10"
                      option="primary"
                      marginTop="20"
                      title={t("patients.ResponseBtnPrimary")}
                    />
                  </div>
                  <p>{t("globally.or")}</p>
                  <div onClick={() => setTypeForm("invitation")}>
                    <ZaiaButton
                      type="reset" 
                      onClick={() => props.resetForm()}
                      option="secondary"
                      marginTop="10"
                      title={t("patients.btn")}
                    />
                  </div>
                </>
              }
            </div>
          </form>
        </S.InvitePatientsModalDiv>
      )}
    </Formik>
  );
}
  
  
// components

const FormInvitationInputs = () => {
  const { t, i18n: { language } } = useTranslation("global");
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );

  return(
    <>
      <h4>{t("patients.invite")}</h4>
      <div className="card-inputs-container">
        <p>{t("patients.doc")}</p>
        <ZaiaInputForm 
          placeholder={t("patients.doc")}
          type="text"
          name="document"
          icon="icono-id"
          min={0}
        />
        <p>{t("patients.email")}</p>
        <ZaiaInputForm 
          placeholder={t("patients.email")}
          type="text"
          name="email"
          icon="icono-id"
        />
        {/* <p>{t("patients.chatName")}</p>
        <ZaiaInputForm 
          placeholder={t("patients.chatName")}
          type="text"
          name="chatName"
          icon="icono-chat-relleno"
        /> */}
        <p>{t("patients.program")}</p>
        <ZaiaSelectForm 
          placeholder={t("patients.select")}
          type="text"
          name="processId"
          // icon="icono-salud"
          items={
            processData?.process.map( process => {
              return (
                { value: process.id, text: P.dataTranslation(process.processName, language)}
              )
            })
          }
        />
        <p>{t("patients.msg")}</p>
        <InputParagraph
          placeholder={t("patients.msgPlaceholder")}
          name="descript"
          type="textarea"
        />
      </div>
    </>
  )
}

const FormRegisterInputs = ({ createAuditPatient, setCreateAuditPatient }) => {
  const { t, i18n: { language } } = useTranslation("global");
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );
  const theme = useContext(ThemeContext);

  return(
    <>
      <h4>{t("patients.patientInvitation.registerNewUser")}</h4>
      <div className="card-inputs-container">
        <div className="card-inputs-container-toggle">
          <ZaiaToggleCircular
            active={createAuditPatient}
            toogle={(e) => setCreateAuditPatient(e)}
            color={theme.mainProfileColor}
          />
          <p>{t("patients.sendInvitationToPatient")}</p>
        </div>
        <p>{t("patients.name")}</p>
        <ZaiaInputForm 
          placeholder={t("patients.name")}
          type="text"
          name="name"
          icon="icono-nombre"
        />
        <p>{t("patients.lastname")}</p>
        <ZaiaInputForm 
          placeholder={t("patients.lastname")}
          type="text"
          name="lastname"
          icon="icono-nombre"
        />
        {/* <p>{t("patients.chatName")}</p>
        <ZaiaInputForm 
          placeholder={t("patients.chatName")}
          type="text"
          name="chatName"
          icon="icono-chat-relleno"
        /> */}
        <p>{!createAuditPatient ? t("patients.doc") : t("patients.idetificationCode")}</p>
        <ZaiaInputForm 
          placeholder={!createAuditPatient ? t("patients.doc") : t("patients.idetificationCode")}
          type="text"
          name="document"
          icon="icono-id"
        />
        
        {
          !createAuditPatient ?
          <>
            <p>{t("patients.email")}</p>
            <ZaiaInputForm 
              placeholder={t("patients.email")}
              type="text"
              name="email"
              icon="icono-id"
            />
          </> : null
        }
        <p>{t("patients.program")}</p>
        <ZaiaSelectForm 
          placeholder={t("patients.select")}
          type="text"
          name="processId"
          // icon="icono-salud"
          items={
            processData?.process.map( process => {
              return (
                { value: process.id, text: P.dataTranslation(process.processName, language)}
              )
            })
          }
        />
        {
          !createAuditPatient ?
          <>
            <p>{t("patients.msg")}</p>
            <InputParagraph
              placeholder={t("patients.msgPlaceholder")}
              name="descript"
              type="textarea"
            />
          </> : null
        }
      </div>
    </>
  )
}

const InputParagraph = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <S.InputParagraphDiv>
      <textarea className="text-area-input" placeholder={placeholder} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </S.InputParagraphDiv>
  );
};


export default InvitePatientForm;
