import React, { useEffect } from "react";
import * as S from "./ProfileHeader.style";
import { useTranslation } from "react-i18next";

// Hooks
import useUploadTempImg from "../../../../../hooks/useUploadTempImg"

// components 
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// pipe
import * as P from "../../../../../utils/pipes"
import useActivateModal from "../../../../../hooks/useActivateModal";
import InvitationsManagement from "../../../patients/components/invitationsManagement";
import InvitePatientButton from "../../../patients/components/invitePatientButton";

// assets
const defaultDoctor = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-doctor.png`;

function ProfileHeader({setImageData, doctorData, documentTypeData}) {
  const { i18n: { language }} = useTranslation("global");
  const hiddenFileInput = React.useRef(null);
  const {dataImg, tempImg, uploadImg} = useUploadTempImg(doctorData?.picture);
  const { activeModal, desactiveModal } = useActivateModal();
  

  useEffect(() => {
    setImageData(dataImg);
  }, [setImageData, dataImg])
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const modalInvitationsManagement = () => {
    return (
      <>
        <InvitationsManagement/>
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  } 

  const additionalSettings = [
    // {
    //   id: 0,
    //   name: "Notifications",
    //   icon: "zaia-alert",
    //   action: ""
    // },
    {
      id: 1,
      name: "Patients management",
      icon: "zaia-icono-pacient",
      action: () => activeModal(() => modalInvitationsManagement())
    }
  ]

  return (
    <S.ProfileHeaderDiv>
      <div className="card-doctor">
        {/* <div className="image-container">
          <ZaiaImage 
            img={tempImg}
            size="85"
          />
        </div> */}
        {/* <div className="options">
          <div onClick={handleClick} className="options__photo options__photo--edit">
            <i className="icon zaia-icono-editar"></i>
            <span >Editar foto de perfil</span>
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={uploadImg}
            style={{display: 'none'}}
            accept="image/*"  
          />
          <div onClick={deleteImg} className="options__photo options__photo--delete">
            <i className="icon zaia-icono-borrar"></i>
            <span>Eliminar foto de perfil</span>
          </div>
        </div> */}
        <div className="image-container">
          <div className="image-change">
            <ZaiaImage 
              img={tempImg}
              size={120}
              defaultImg={defaultDoctor}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={uploadImg}
              style={{display: 'none'}}
              accept="image/*"  
            />
            <div className="button-change-image" onClick={handleClick}>
              <i className="zaia-icono-nuevo-mensaje"></i>
            </div>
          </div>
        </div>
        <h4>{P.drTranslation(doctorData?.gender, language)} {doctorData?.name} {doctorData?.lastname}</h4>
        <span>{documentTypeData?.enDocumentType.find(type => type.value === doctorData?.documentType)?.comment.code} {doctorData?.documentNumber}</span>
      </div>
      <div className="additional-settings">
        <h3>Additional settings</h3>
        {
          additionalSettings.map(setting => {
            return <div className="setting-box" onClick={setting.action} key={setting.id}>
              <div className="setting-box-icon">
                <i className={`settings-icon icon ${setting.icon}`}></i>
              </div>
              <div className="setting-box-text">{setting.name}</div>
            </div>
          })
        }
      </div>
    </S.ProfileHeaderDiv>
  );
}

export default ProfileHeader;
