  import styled from "styled-components";

  export const ZaiaToggle = styled.div`
    background-color: ${({theme, backgroundColor}) => backgroundColor ? backgroundColor : theme.inputPlaceholder};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '4'}px;
    display: flex;
    height: 100%;
    width: 100%;
    font-family: 'Open Sans Semibold';
    p {
      width: 100%;
      font-size: ${({sizeFont}) => sizeFont ? sizeFont : '0.7rem'};
      border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '4'}px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 3px;
      color: ${({theme}) => theme.inputBorder};
      cursor: pointer;
    }
    .activeTab {
      background-color: ${({color}) => color};
      color: ${({theme}) => theme.onGreyBackground};
    }
  `