import { useState, useEffect } from 'react'

export default function useUploadTempImg(img) {

  const [dataImg, setDataImg] = useState();
  const [tempImg, setTempImg] = useState(); 

  useEffect(() => {
    setTempImg(img)
  }, [img])

  const getExtensionFile = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename) : undefined;
  }
  
  const uploadImg = event => {
    try {
      const fileUploaded = event.target.files[0];
      if (fileUploaded) {
        let ext = getExtensionFile(fileUploaded.name);
        if (ext && (ext[0] === 'jpg' || ext[0] === 'png' || ext[0] === 'jpeg')) {
          setDataImg(fileUploaded);
          const reader = new FileReader();
          reader.onload = ((e) => {
            const imageInfo = e;
            setTempImg(imageInfo.target.result)
          });
          reader.readAsDataURL(event.target.files[0]);
        }
      }
    } catch(err) {
      console.log(err);
    }
  };
  
  const deleteImg = () => {
    setDataImg();
    setTempImg();
  }  

  return { 
    dataImg,
    tempImg,
    uploadImg, 
    deleteImg
  }
}
