import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const PatientReviewBoxDiv = styled.div`
  background-color: ${({theme}) => theme.welcomeCard}${getHexOpacity(0.5)};
  border-radius: 15px;
  display: flex;
  overflow: hidden;
  /* width: fit-content; */
  height: 85px;
  justify-content: space-between;
  .text-container {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 600;
      color: ${({theme}) => theme.onWelcomeCard};
      font-family: 'Nunito ExtraBold';
    }
    p {
      color: ${({theme}) => theme.onWelcomeCard};
      margin-top: 10px;
      font-size: 1em;
      font-family: 'Open Sans';
      strong {
        font-family: "Open Sans Bold";
      }
    }
  }
  .image-container {
    margin: 0 30px 0 30px;
    img {
      height: 100%;
    }
  }
`;
