import { gql } from "@apollo/client";

export const QUERY_GET_PROCESS_NAMES = gql`
  query QUERY_GET_PROCESS_NAMES(
    $whereFilter: process_bool_exp
  ) {
    process(where: $whereFilter) {
      id
      processName
      descript
      icon
      nameKey
      triggerText
      processToCustomForms {
        customFormId
        id
        processId
      }
      userToProcesses {
        id
        userId
        isAcepted
        process {
          processToCustomForms {
            customFormId
            id
            processId
          }
        }
      }
    }
  }
`;


export const QUERY_GET_PROCESS_TYPES_BY_ID = gql`
  query QUERY_GET_PROCESS_TYPES (
    $processId: [Int!]
  ) {
    process(where: {id: {_in: $processId}}) {
      id
      processName
      processToSideEffects {
        id
        typeSideEffect
        severity
      }
      processToVitalSigns {
        id
        max
        min
        vitalSign
        severity
      }
    }
  }
`;

export const QUERY_GET_PROCESS_TYPES = gql`
  query QUERY_GET_PROCESS_TYPES {
    process {
      id
      processName
      processToSideEffects {
        id
        typeSideEffect
        severity
      }
      processToVitalSigns {
        id
        max
        min
        vitalSign
        severity
      }
    }
  }
`;