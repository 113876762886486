import styled from "styled-components";

export const ZaiaButton = styled.button`
  background-color: ${
      ({
        theme,
        backgroundColor, 
        option
      }) => 
      backgroundColor 
      ? backgroundColor 
      : option === "secondary" 
        ? theme.background
        : theme.primaryColor
    };
  border: ${
      ({
        theme,
        color, 
        option, 
        border
      }) => 
      border 
      ? color 
        ? `1px solid ${color}` 
        : option === "secondary" 
          ? `1px solid ${theme.primaryColor}`
          : "none" : "none"
    };
  border-radius: ${
      ({borderRadius}) => 
      borderRadius 
        ? borderRadius 
        : '20'
    }px;
  color: ${
      ({
        theme,
        color, 
        option
      }) => 
        color 
        ? color 
        : option === "secondary" 
          ? theme.primaryColor 
          : theme.onPrimaryColor
    };
  /* box-shadow: ${({theme, shadow, color, option}) => `0px 3px 10px ${theme.primaryColorShadow}56`}; */
  box-shadow: ${
      ({theme, shadow, color, option}) => 
        shadow 
          ? color 
            ? `3px 3px 5px ${theme.blackShadow}29`
            : option === "secondary"
              ? ``
              : `0px 3px 10px ${theme.primaryColorShadow}56`
          : ""
    };
  width: 100%;
  height: ${
      ({height}) => 
        height 
        ? height 
        : '42'
    }px;
  cursor: pointer;
  font-family:  ${
      ({fontFamily}) => 
        fontFamily 
        ? fontFamily 
        : 'Open Sans Semibold'
    };
  margin-top: ${({marginTop}) => marginTop}px;
  margin-bottom: ${({marginBottom}) => marginBottom}px;
  outline: none;
  font-size: ${({fontSize}) => fontSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-container {
    margin-top: 8px;
    .button-icon {
      font-size: 25px;
      margin: -5px 10px 0 0;
    }
  }
`