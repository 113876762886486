import React from "react";
import * as S from "./ZaiaToggleCircular.style";

function ZaiaToggleCircular({ active, color, toogle }) {

  return (
    <S.ZaiaToggleCircular color={color} active={active} >
      <div className="toogle-back" onClick={() => toogle(!active)}>
        <div className="circle-toogle"></div>
      </div>
    </S.ZaiaToggleCircular>
  )
}

export default ZaiaToggleCircular;