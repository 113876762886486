import React from "react";
import * as S from "./Profile.style";

// Components
import ProfileForm from "./components/profileForm";
// import MedicalInstitutions from "./components/medicalInstitutions";

function Profile() {

  return (
    <S.ProfileDiv>
      <div className="profile__left-side">
        <ProfileForm />
      </div>
      {/* <div className="profile__right-side">
        <MedicalInstitutions />
      </div> */}
    </S.ProfileDiv>
  );
}

export default Profile;
