import styled from "styled-components";

export const CurrentState = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  .current-state {
    width: 100%;
    &-header {
      display: flex;
      justify-content: space-between;
      h5 {
        font-family: "Open Sans Bold";
      }
      .date-selector {
        background-color: var(--background-header-input);
        color: var(--text-grey);
        font-family: "Open Sans Bold";
        font-size: 13px;
        padding: 5px 8px;
        display: flex;
        border-radius: 5px;
        align-items: center;
        &-icons {
          display: flex;
        }
        &-icon {
          font-size: 14px;
          margin-left: 15px;
          cursor: pointer;
          display: flex;
        }
      }
    }
    &-add-event {
      font-family: "Open Sans Bold";
      font-size: 10.5px;
      margin: 10px 0 17px 0;
      color: var(--home-title);
      &-trigger {
        display: flex;  
        align-items: center;
        cursor: pointer;
      }
      &-icon {
        font-size: 6px;
        display: flex;
        align-items: center;
        margin: 1px 2px 0 0;
      }
      &-form {
        position: relative;
        width: 100%;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      height: 350px;
      overflow: auto;
      &-comments {
        position: relative;
        width: 100%;
      }
    }
  }
`;

export const ReportBox = styled.div`
  font-family: "Open Sans";
  display: flex;
  margin-bottom: 23px;
  font-size: 12px;
  .report-box {
    &-indicator {
      width: 45px;
      position: relative;
      &-point {
        margin: 20px auto 10px auto;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: ${({point}) => point ? 'var(--blue-color)' : ''};
        border: solid 1px var(--blue-color);
      }
      &-line {
        visibility: ${({line}) => line ? 'visible' : 'hidden'};
        position: absolute;
        border-right: solid 1px ${({line}) => line ? 'var(--blue-color)' : ''};;
        width: 21.7px;
        height: calc(100% - 0.5em);
        z-index: 10;
      }
    }
    &-info {
      width: calc(100% - 45px);
      &-text {
        color: var(--black-titles);
        &-change {
          font-size: 11px;
          color: var(--blue-color);
        }
      }
      &-options {
        margin-top: 3px;
        display: flex;
        justify-content: space-between;
        &-text {
          display: flex;
          font-size: 10px;
          
          &-date {
            color: var(--subtitle-color);
          }
          &-report {
            color: var(--blue-color);
            font-weight: 600;
            margin-left: 4px;
          }
        }
        &-comments {
          display: flex;
          align-items: center;
          cursor: pointer;
          &-icon {
            display: flex;
            align-items: center;
            font-size: 13px;
            color: var(--text-toogle-gray);
          }
          &-quantity {
            margin-left: 3px;
            font-size: 10px;
            color: var(--black-titles);
          }
        }
      }
    }
  }
`

export const EventCommentsContainer = styled.div`
  position: absolute;
  background-color: var(--white);
  z-index: 100;
  width: 98%;
  margin-left: 1%;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px var(--box-shadow);
  border-radius: 10px;
  margin-top: -15px;
  .comments-container {
    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      h5 {
        font-family: "Open Sans Bold";
      }
    }
    &-add-comment {
      font-family: "Open Sans Bold";
      font-size: 10.5px;
      color: var(--home-title);
      display: flex;
      cursor: pointer;
      &-icon {
        font-size: 6px;
        display: flex;
        align-items: center;
        margin: 1px 2px 0 0;
      }
    }
  }

`

export const CurrentStateForm = styled.div`
  position: ${({absolute}) => absolute ? 'absolute' : 'relative'};
  background-color: var(--white);
  z-index: 100;
  width: 100%;
  padding: 5px 0 10px;
  .current-state-form {
    &-content-double {
      display: flex;
      justify-content: space-between;
    }
    &-input {
      margin-bottom: 5px;
      &--small {
        width: 49%;
      }
    }
    &-buttons {
      display: flex;
      justify-content: end;
    }
    &-button {
      margin-left: 10px;
      width: 100px;
    }
  }
`

export const CommentBox = styled.div`
  font-size: 12px;
  font-family: "Open Sans";
  color: var(--black-titles);
  background-color: var(--background-header-input);
  padding: 8px 10px 5px 10px;
  .comment-box {
    &-date-content {
      display: flex;
      color: var(--subtitle-color);
      font-size: 7.5px;
      margin-top: 2px;
      justify-content: end;
    }
    &-hour {
      margin-left: 2px;
    }
  }
`