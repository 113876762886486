import Login from "../pages/public/login";
import ResetPassword from "../pages/public/reset-password";
import Register from "../pages/public/register";
import ChangePassword from "../pages/public/change-password";
import EmailVerified from "../pages/public/emailVerified";

const publicRoutesList = [
  {
    id: "empty",
    key: "empty",
    path: "/",
    component: () => <Login />,
  },
  {
    id: "register",
    key: "register",
    path: "/register",
    component: () => <Register />,
  },
  {
    id: "reset-pasword",
    key: "reset-pasword",
    path: "/reset-password",
    component: () => <ResetPassword />,
  },
  {
    id: "recoveremail",
    key: "recoveremail",
    path: "/recoveremail",
    component: () => <ChangePassword />,
  },
  {
    id: "validateemail",
    key: "validateemail",
    path: "/validateemail",
    component: () => <EmailVerified />,
  },
];

export default publicRoutesList;
