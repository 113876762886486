import styled from "styled-components";

export const UserHeaderDiv = styled.div`
  height: 100%;
  width: 100%;
  .header { 
    height: 100%;
    background-color: ${({theme}) => theme.background};
    display: flex;
    box-shadow: 4px 2px 11px -3px ${({theme}) => theme.blackShadow}29;
    border-top-left-radius: 30px;
    justify-content: space-between;

    &__left-content {
      flex: 0.3;
      display: flex;
      padding-left: 20px;
      margin: 18px 0;
      align-items: center;

      .location-name {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 7px 7px 7px 6px;
        border-radius: 10px;
        font-size: 15px;
        background-color: ${({colorIcon, theme}) => colorIcon ? colorIcon : theme.primaryColor};
        box-shadow: 0px 2px 6px ${({colorShadow, theme}) => colorShadow ? colorShadow : theme.primaryVariantColor+'33' }
      }
      h3 {
        color: ${({colorFont, theme}) => colorFont ? colorFont : theme.onBackground};
        margin-left: 10px;
        font-family: "Nunito Extrabold";
      }
    }

    &__right-content {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      .header__img-user {
        border-radius: 10px;
        cursor: pointer;
        box-shadow: 1px 1px 6px 0px ${({theme}) => theme.blackShadow}29;
      }
      h3 {
        cursor: pointer;
        font-size: 0.9rem;
        margin-left: 10px;
        color: ${({theme}) => theme.onBackgroundVariant};
        font-family: "Nunito Extrabold";
      }
      i {
        cursor: pointer;
        z-index: 1;
        color: ${({theme}) => theme.onBackgroundVariant};
        font-size: 10px;
        margin: 5px 7px 0 7px
      }
    }

  }
`;
