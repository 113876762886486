import styled from "styled-components";

export const BoxInfoPatientDiv = styled.div`
  background-color: var(--white);
  margin: 5px 0;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  .boxInfoPatient__header {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--left {
      width: 30%;
      justify-content: flex-start;
    }
    &--center {
      width: 60%;
      justify-content: center;
    }
    &--right {
      width: 30%;
      justify-content: flex-end;
    }
    div {
      display: flex;
    }
    .boxInfoPatient__title {
      display: flex;
      align-items: center;
      div:first-child {
        padding: 2px;
        border-radius: 6px;
        background-color: ${(props) => props.color};
        color: var(--white);
        font-size: 13px;
        height: auto;
      }
      h4 {
        color: ${(props) => props.colorTitle};
        margin-left: 5px;
        font-family: "Nunito ExtraBold";
      }
    }
    .boxInfoPatient__time {
      height: 100%;
      width: 200px; 
    }
    .boxInfoPatient__date {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      cursor: pointer;
      font-family: "Open Sans";
      color: ${(props) => props.colorTitle};
      .boxInfoPatient__text-date {
        align-items: flex-end;
        strong {
          margin: 0 4px;
          font-size: 0.75rem;
        }
      }
      i {
        margin-top: auto;
      }   
    }
  }
`;

export const DateOptions =styled.div`
  position: relative;
  background-color: var(--white);
  height: 120px;
  width: 145px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  &::-webkit-scrollbar{
    display: none;
  }
  .select-date {
    font-size: 0.75rem;
    color: ${(props) => props.color} !important;
    cursor: pointer;
    padding: 5px 10px;
  }
`