import styled from "styled-components";
const waveBottom = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

export const OutcomesDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--background-patient);
  background-image: url(${waveBottom});
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: 125%;
    .outcomes-container {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      background-color: var(--white);
      filter: ${(p) => p.modal ? "blur(4px)" : "none"};
      overflow: hidden;
      &__sidebar-patients {
        width: auto;
      }
      &__info-content {
        background-color: var(--background-patient);
        width: 100%;
        padding: 20px 25px 20px ${({selectedPatient}) => selectedPatient ? "25px" : '10px'};
        overflow: hidden;
      }
    }
`;

export const ProgramBoxes = styled.div`
  font-family: "Nunito ExtraBold";
  margin: auto;
  max-width: 100%;
  min-width: 60%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .reaction-content {
    margin: 40px 0;
  }
  .algorithms__containerBoxes {
    background-color: var(--blue-transparent-200);
    display: flex;
    align-items: center;
    border-radius: 50px;
    margin: 0px 46px 20px;
    overflow-y: auto;
    max-height: calc(100% - 220px);
    overflow: auto;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px 0;
    ::-webkit-scrollbar {
      position: sticky;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      margin: 30px 30px; 
    }
    .box-container {
      padding: 20px
    }
    .fade {
      animation: fadeOut 1s forwards;
    }
  }
`

export const BoxDiv = styled.div`
  background-color: var(--title-register-color);
  cursor: pointer;
  height: 130px;
  width: 130px;
  padding: 20px;
  border-radius: 50px;
  border: 5px solid var(--white);
  box-shadow: 0px 5px 35px #3E3DA980;;
  display: flex;
  align-items: center;
  flex-direction: column;
  .box__iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-color);
    color: white;
    border-radius: 15px;
    margin: 20px;
    width: 40px;
    height: 40px;
    i {
      font-size: 23px;
      margin-bottom: -6.5px;
    }
  }
  h5 {
    color: var(--white);
    text-align: center;
    word-break: break-word;
    font-family: "Nunito";
    font-size: 15px;
  }
  .box__patients {
    display: flex;
    color: var(--white);
    font-size: 13px;
    margin-top: 15px;
    &-number {
      font-family: "Open Sans Semibold";
    }
    &-text{
      font-family: "Open Sans";
      margin-left: 5px;
    }
  }
`;

export const OutcomesEmpty = styled.div`
  background-color: var(--background-patient);
  width: 100%; 
  height: 100%;
  display: flex;
  flex-direction: column;
`