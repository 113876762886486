import styled from "styled-components";

export const DashboardPatient = styled.div`
  background-color: var(--background-patient);
  width: 100%; 
  height: 100%;
  .cards-container {
    overflow-y: auto;
    height: calc(100% - 60px)
  }
`;