import styled from "styled-components";
import { getHexOpacity } from "../../utils/pipes";

export const Private = styled.div`
  .private {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: relative;

    &__sidebar {
      position: relative;
      z-index: 300;
      width: 225px;
      height: 100%;
    }

    &__right {
      position: relative;
      z-index: 200;
      width: calc(100vw - 225px);
      height: 100%;
      background-color: ${({theme}) => theme.primaryColor};
      &-header{
        position: relative;
        z-index: 200;
        width: 100%;
        height: 70px;
        background-color: ${({theme}) => theme.primaryVariantColor};
      }
      
      &-body {
        position: relative;
        z-index: 100;
        width: 100%;
        height: calc(100vh - 70px);
        background-color: ${({theme}) => theme.background};
        display: flex;
        border-bottom-left-radius: 30px;
        overflow: hidden;
        &-content {
          background-color: ${({theme}) => theme.primaryColor};;
          filter: ${({activeModal}) => activeModal ? 'blur(4px)': ''};
          height: 100%;
          width: 100%;
        }
        &-modal {
          height: 100%;
          width: 100%;
          position: absolute;
          background-color: ${({theme}) => `${theme.background}${getHexOpacity(0.8)}`};
        }
      }

    }

  }

`;