  import styled from "styled-components";

  export const TooltipProcesses = styled.h4`
  background-color: ${({theme}) => theme.background};
  padding: 5px 5px;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  font-family: 'Nunito ExtraBold';
  border-radius: 15px;
  overflow: auto;
  max-height: 250px;
  .box-process {
    border-radius: 5px;
    color: ${({theme}) => theme.primaryColor};
    display: flex;
    height: 40px;
    box-sizing: border-box;
    font-size: 13px;
    font-family: "Nunito Bold";
    cursor: pointer;
    min-width: 120px;
    padding: 5px 10px;
    &__image {
      margin-right: 8px;
      background-color: ${({theme}) => theme.primaryVariantColor};
      height: 30px;
      width: 30px;
      border-radius: 25%;
      box-shadow: 0px 2px 6px ${({theme}) => theme.blackShadow}1A;
      display: flex;
      align-items: center;
    }
    &__icon {
      color: ${({theme}) => theme.onPrimaryColor};
      display: flex;
      margin: auto;
      font-size: 16px;
    }
    &__name {
      color: ${({theme}) => theme.primaryColor} !important;
      align-self: center;
    }
    &:hover {
      background-color: ${({theme}) => theme.hoverGrey};
    }
  }
`