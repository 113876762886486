import React, { useEffect, useMemo, useState } from "react";
import * as S from "./CmpSliderHorizontalOptions.style";
import * as P from "../../../../../../../utils/pipes";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";

// pipes
// import * as P from "../../../../../../../utils/pipes";

function CmpSliderHorizontalOptions({
  title,
  item,
  insertfunction,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  isHistorical,
}) {
  // const {i18n: { language }} = useTranslation("global");

  // const changeOptionItem = (fieldOption) => {
  //   const userToCustomFormField = {
  //     customFormFieldId: dataForm?.customFormFields[0].id,
  //     value: P.dataTranslation(fieldOption.names, language),
  //     key: fieldOption.key
  //   }
  //   insertfunction(userToCustomFormField)
  // }
  const {
    i18n: { language },
  } = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const changeOptionItem = (fieldOption) => {
    const userToCustomFormField = {
      customFormFieldId: item?.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === fieldOption.key
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(fieldOption.names, language),
      key: fieldOption.key,
    };
    insertfunction(userToCustomFormField);
  };

  const listOptions = useMemo(() => {
    const options =
      item?.customFormFieldParentId && !isHistorical
        ? itemParent
          ? item?.customFormTypeField.fieldOptions.filter(
              (field) => field.parentKey === itemParent.key
            )
          : item?.customFormTypeField.fieldOptions.filter(
              (field) =>
                field.parentKey ===
                customFormFields.find(
                  (field) => field.id === item.customFormFieldParentId
                )?.userToCustomFormFields[0]?.key
            )
        : item?.customFormTypeField.fieldOptions;
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemParent, customFormFields]);

  const optionKey = customFormChangeItem
    ? customFormChangeItem.key
    : item?.userToCustomFormFields[0]?.key;

  const currentPayload = useMemo(() => {
    const currentOption = listOptions.find((item) => item.key === optionKey);
    let payload;
    if (P.dataTranslation(currentOption.payload, language)) {
      payload = P.dataTranslation(currentOption.payload, language);
    } else {
      const payloadFormated = JSON.parse(currentOption.payload);
      try {
        payload = "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        payload = null;
      }
    }

    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionKey]);

  // eslint-disable-next-line no-unused-vars
  const [activeItem, setActiveItem] = useState({
    id: 1,
    title: "Very Low Risk",
    subtitle: "Unique ID Numbers",
    code: "PCL1",
    details: [
      "T1c",
      "Grade group 1",
      "PS/5510 ng/mL ",
      "Fewer Man 3 prostate biopsy fragments/cores positive, 55096 cancer in each fragment/core",
      "PSAtlensity 035 ng/mL/g",
    ],
  });

  // eslint-disable-next-line no-unused-vars
  const [listItems, setListItems] = useState([]);

  const listItemsTemp = [
    {
      id: 1,
      title: "Very Low Risk",
      subtitle: "Unique ID Numbers",
      code: "PCL1",
      details: [
        "T1c",
        "Grade group 1",
        "PS/5510 ng/mL ",
        "Fewer Man 3 prostate biopsy fragments/cores positive, 55096 cancer in each fragment/core",
        "PSAtlensity 035 ng/mL/g",
      ],
    },
    {
      id: 2,
      title: "Low Risk",
      subtitle: "Unique ID Numbers",
      code: "PCL2",
      details: [
        "Consider confirmatory prostate biopsy ± mpMRI to establish candidacy for active surveilance",
      ],
    },
    {
      id: 3,
      title: "Intermediate Favorable Risk",
      subtitle: "Unique ID Numbers",
      code: "PCL3",
      details: ["Recommended"],
    },
    {
      id: 4,
      title: "High Risk",
      subtitle: "Unique ID Numbers",
      code: "PCL4",
      details: ["Recommended"],
    },
    {
      id: 5,
      title: "Very High Risk",
      subtitle: "Unique ID Numbers",
      code: "PCL5",
      details: ["Not routinely recommended"],
    },
  ];

  useEffect(() => {
    let listTemp = [];
    if (item) {
      item?.customFormTypeField.fieldOptions.forEach((field) => {
        listTemp.push({
          id: field.id,
          title: P.dataTranslation(field.names, language),
          // payload here
          subtitle: "",
          code: "",
          details: [],
        });
      });
      setListItems(listTemp);
    } else {
      setListItems(listItemsTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const carouselButton = (position, disabled) => {
    return (
      <S.CarouselButton position={position} disabled={disabled} type="button">
        <i
          className={`icon ${
            position === "prev" ? "zaia-icono-atras" : "zaia-i-next"
          }`}
        />
      </S.CarouselButton>
    );
  };
  // eslint-disable-next-line no-unused-vars
  const optionId = customFormChangeItem
    ? customFormChangeItem.id
    : item?.userToCustomFormFields[0]?.id;

  return (
    <S.CmpSliderHorizontalOptions>
      <h5>{title}</h5>
      <div className="boxes-container">
        {/* {carouselButton('prev')} */}
        <AliceCarousel
          // disableButtonsControls
          mouseTracking
          disableDotsControls
          autoWidth
          renderPrevButton={(e) => carouselButton("prev", e.isDisabled)}
          renderNextButton={(e) => carouselButton("next", e.isDisabled)}
        >
          {
            // dataForm?.customFormFields[0].customFormTypeField.fieldOptions.map(data => {
            //   return (
            //     <S.SliderBox active={data.key === dataForm?.customFormFields[0].userToCustomFormFields[0]?.key} key={data.id} onClick={() => changeOptionItem(data)}>
            //       <div className="slider-box">
            //         <div className="slider-box-title">Intermediate Favorable Risk</div>
            //         <div className="slider-box-subtitle">Unique ID Numbers</div>
            //         <div className="slider-box-code">PCL3</div>
            //       </div>
            //     </S.SliderBox>
            //   )
            // })
          }

          {listOptions.map((data) => {
            return (
              <S.SliderBox active={data.key === optionKey} key={data.id}>
                <div
                  className="slider-box"
                  onClick={() => changeOptionItem(data)}
                >
                  <div className="slider-box-title">
                    {P.dataTranslation(data.names, language)}
                  </div>
                  {/* <div className="slider-box-subtitle">{fieldItem.subtitle}</div>
                    <div className="slider-box-code">{fieldItem.code}</div> */}
                </div>
              </S.SliderBox>
            );
          })}
        </AliceCarousel>
      </div>
      {!currentPayload ? null : (
        <div className="big-info-card">
          <h4>{currentPayload}</h4>
          {/* <ul>
            {
              activeItem?.details.map((detail, index) => {
                return (
                  <li key={index}>{detail}</li>
                )
              })
            }
          </ul> */}
        </div>
      )}
    </S.CmpSliderHorizontalOptions>
  );
}

export default CmpSliderHorizontalOptions;
