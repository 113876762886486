import styled from "styled-components";

export const MedicinesLogCard = styled.div`
  .medicines-log-card {
    width: 314px;
    height: auto;
    min-height: 111px;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: ${({activeHeader}) => activeHeader ? '0px 3px 6px #00000029' : '' };
    padding: 5px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    &__header {
      display: flex;
      height: 52px;
      align-items: center;
      justify-content: space-between;
      &-days {
        background-color: var(--green-color);
        width: 100px;
        border-radius: 5px;
        display: flex;
        color: var(--white);
        font-size: 27px;
        height: inherit;
        p {
          margin: auto;
          padding: auto;
        }
      }
      &-text {
        margin-left: 10px;
        p {
          margin-top: 5px;
          font-family: "Open Sans";
          color: var(--text-toogle-gray);
          font-size: 12px;
        }
      }
    }
    &__bars-container {
      height: 100%;
      min-height: ${({timeOp}) => 
        timeOp === 'day' ? 'auto' : '40px'
      };;
      display: flex;
      margin: 10px 0;
      &-listlevels {
        width: 55px;
        height: 110px;
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #8A0515;
        p {
          font-size: 9px;
          font-family: "Open Sans Bold"
        } 
      }
      h5 {
        margin: auto;
        color: var(--green-color);
      }
      &-intervals {
        display: flex;
        flex-direction: column;
        height: ${({intervalsQuantity, timeOp}) => 
          timeOp === 'day' ? 'auto' :
          intervalsQuantity > 20 ? '230px' : 
          intervalsQuantity > 14 ? '200px' : 
          intervalsQuantity > 8 ? '150px' : '100px'
        };
      }
      &-interval {
        font-size: ${({intervalsQuantity}) => 
          intervalsQuantity <= 8 ? '9px' : '7px'};
        inline-size: max-content;
        margin: 0 5px 0 10px;
        display: flex;
        height: ${({timeOp}) => 
          timeOp === 'day' ? '14px' : '100%'
        };
        align-items: center;
        color: var(--text-toogle-gray);
      }
      &-bars {
        height: 100%;
        width: 100%;
        margin-right: 10px;
        display: flex;
        overflow: auto;
        justify-content: space-between;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          height: 15px;
          margin: 0;
          opacity: .9;
        }
        ::-webkit-scrollbar-track {
          margin-left: 0px;
          box-shadow: inset 0 0 10px 10px transparent;
          opacity: .5;
          background-color: transparent;
        }
        ::-webkit-scrollbar-thumb {
          min-height: 10px;
          min-width: 1px;
          width: 1px;
          max-width: 1px;
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px #9A9A9A40;
          border: solid 3px transparent;
          opacity: .8;
        }
        &:hover {
          overflow-x: overlay;
        }
      }
    }
    &__footer-indicator {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 5px;
      .type-indicator {
        display: flex;
        font-family: "Open Sans";
        color: var(--text-toogle-gray);
        font-size: 12px;
        align-items: center;
        &__point {
          &-cross {
            font-size: 9px;
            color: var(--red-color);
            display: flex;
          }
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-right: 5px;
          &--green {
            background-color: var(--green-color);
          }
          &--grey {
            background-color: #F4F4F4;
          }
        }
      }
    }
  }
`;

export const CheckboxMedicine = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: ${({timeOp}) => 
                    timeOp === 'day' ? '55px' : 
                    timeOp === 'month' ? '25px' : null};
  width: 100%;
  text-align: center;
  padding: 0px 3px 0;
  .vertical-progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${({intervalsQuantity, timeOp}) => 
          timeOp === 'day' ? 'auto' :
          intervalsQuantity > 20 ? '230px' : 
          intervalsQuantity > 14 ? '200px' : 
          intervalsQuantity > 8 ? '150px' : '100px'
        };
  }
  .vertical-text {
    margin-top: 8px;
    font-family: "Open Sans";
    color: var(--text-toogle-gray);
    &__time {
      width: 100%;
      font-size: 10px;
    }
  }
`

export const VerticalBoxMedicine = styled.div`
  height: ${({timeOp}) => 
    timeOp === 'day' ? '10px' : '100%'
  };
  width: ${({timeOp, active}) => 
    timeOp === 'day' ? '100%' : 
    active === 'haveTakeIt' || active === 'haveNoTakeIt' ? '7px' : '8px'
  };
  background-color: ${
    ({active}) => 
      active === 'takeIt' ? 'var(--green-color)' : 
      active === 'noTakeIt' || active === 'noReported' ? '#E3E3E3' : ''
  };
  border: ${
    ({active}) => 
      active === 'haveTakeIt' ? `solid 1px var(--green-color)` : 
      active === 'haveNoTakeIt' ? `solid 1px #E3E3E3` : ''
  };
  border-radius: 50px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin: 1.5px 0 1.5px 0;
  i {
    color: var(--red-color);
    position: relative;
    display: flex;
    margin: auto auto auto -0.1px;
    font-size: ${({intervalsQuantity}) => 
      intervalsQuantity > 20 ? '6px' : 
      intervalsQuantity > 14 ? '7px' : 
      intervalsQuantity > 8 ? '8px' : '9px'
    };
  }
`
