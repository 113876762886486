import styled from "styled-components";

export const DashboardRightSideDiv = styled.div`
  background-color: ${({theme}) => theme.background};
  position: relative;
  height: 100%;
  .dashboard__right-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    &-top{
      min-height: 380px;
    }
    &-calendar {
      margin: 10px 0;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.8rem;
      p:nth-child(2) {
        cursor: pointer;
      }
      div:first-child {
        display: flex;
        h2 {
          color: ${({theme}) => theme.secondarySectionCalendar};
          align-self: center;
        }
      }
      &-icon {
        background-color: ${({theme}) => theme.primarySectionCalendar};
        color: ${({theme}) => theme.onSection};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
        padding: 10px;
        margin-right: 10px;
        div {
          font-size: large;
        }
      }
    }
    &-events {
      height: calc(100% - 380px );
    }
  }
`;
