import React from "react";
import { ReverseProgressBarDiv } from "./ReverseProgressBar.style";

const ReverseProgressBar = (props) => {
  const { bgColor, completed, data, bgColor2, noText, height=15 } = props;

  return (
    <ReverseProgressBarDiv
      bgColor={bgColor}
      completed={completed}
      bgColor2={bgColor2}
      height={height}
    >
      <div className="bar__container">
        <div className="fillerStyles">
          {noText ? '' : <span className="labelStyles">{`${data} (${completed}%)`}</span>}
        </div>
      </div>
    </ReverseProgressBarDiv>
  );
};

export default ReverseProgressBar;
