import styled from "styled-components";

export const ListItems = styled.div`
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .listItems-states {
    margin: 0 auto 0 0;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3px;
    .listItems-state {
      cursor: pointer;
      height: 22px;
      margin: 2px 0;
      border-radius: 13px;
      width: 47%;
      color: var(--text-grey);
      font-family: "Nunito";
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;   
      background-color: var(--text-area-grey);   
      &--selected {
        box-shadow: 0px 3px 6px var(--box-shadow);
        background-color: var(--blue-color);    
      }
    }
  }
`
