import styled from "styled-components";
import { getHexOpacity } from "../../../utils/pipes";

export const DashboardDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.background};
  .dashboard__container-left{
    position: relative;
    width: 100%;
    .dashboard__container-left-modal {
      background-color: ${({theme}) => `${theme.background}${getHexOpacity(0.8)}`};
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      display: flex;
      .box-container {
        margin: auto;
        width: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .buttons-box-container {
          .buttons-box-patient {
            display: flex;
            justify-content: space-between;
            .button-box-patient {
              width: 48%;
            }
            &--center {
              justify-content: center;
            }
          }
          .phone-number-container {
            /* height: 43px; */
            width: 212px;
            position: relative;
            margin: 30px auto 0;
            .contact-info {
              background-color: ${
                ({
                  theme,
                }) => 
                  theme.background
              };
              border-radius: 10px;
              box-shadow: ${
                ({theme}) => 
                `3px 3px 5px ${theme.blackShadow}29`
              };
              &-box {
                padding: 10px 15px 7px 15px;
                &--emergency{ 
                  border-top: solid 1px ${({theme}) => theme.lineSeparation};
                  padding: 7px 15px 10px 15px;
                }
                .label {
                  font-size: 12px;
                  font-family: 'Nunito Bold';  
                  color: ${({theme}) => theme.primaryColor};
                  &--emergency{ 
                    color: ${({theme}) => theme.primaryVariantColor};
                  }
                }
                .name {
                  font-family: 'Open Sans';
                  font-size: 15px;  
                  color: ${({theme}) => theme.primaryVariantColor};
                }
                .number {
                  font-family: 'Nunito ExtraBold';  
                  font-size: 18px;
                  color: ${({theme}) => theme.primaryColor};
                  &--emergency{ 
                    color: ${({theme}) => theme.primaryVariantColor};
                  }
                }
              }
            }
          }
          .buttons-box-close {
            width: 35%;
            margin: auto;
          }
        }
      }
    }
    .dashboard__container-left-content{
      padding: 20px;
      filter: ${({selectedBox}) => selectedBox ? "blur(4px)" : "none"};
      height: 100%;
      box-sizing: border-box;
      .dashboard__notifications {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        align-items: center;
      }
      .dashboard__filters {
        display: flex;
        position: relative;
        .notification-container {
          display: flex;
        }
      }
      .dashboard__boxes-container {
        display: flex;
        margin-top: 20px;
        height: calc(100% - 150px);
        .dashboard__boxes-area {
          width: calc(100% - 60px);
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap;
          overflow-y: scroll;
          border-radius: 20px;
          justify-content: space-between;
          .box-container{
            width: 49.5%;
            display: flex;
            margin-bottom: 10px;
          }
        }
        .dashboard__boxes-area::-webkit-scrollbar {
          display: none;
        }
        .dashboard__boxes-filter {
          width: 60px;
          padding-left: 20px;
        }
      }
    }
  }
  .dashboard__container-right {
    width: 330px;
    position: relative;
    padding: 20px 10px;
    border-left: 1px solid ${({theme}) => theme.lineSeparation};
  }

  .invite-modal {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export default DashboardDiv;
