import { Formik } from "formik";
import React from "react";
import CustomSelect from "../../../../public/register/components/CustomSelect";
import CustomTextInput from "../../../../public/register/components/CustomTextInput";
import { AddEventsDiv } from "./style";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

function AddEvents({ setOpenAddEvents }) {
  const [t] = useTranslation("global");
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setTimeout(() => {
      alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
      resetForm(false);
    }, 400);
  };
  return (
    <div>
      <Formik
        initialValues={{
          eventName: "",
          eventType: "",
          patient: "",
          date: null,
          time: "",
        }}
        validationSchema={Yup.object({
          eventName: Yup.string().required("Este campo es requerido."),
          eventType: Yup.string().required("Este campo es requerido."),
          date: Yup.date().nullable(),
          time: Yup.string().required("Este campo es requerido."),
        })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <AddEventsDiv>
            <form onSubmit={props.handleSubmit}>
              <h2>{t("dashboard.events.addEvent")}</h2>
              <div>
                <h3>{t("dashboard.events.name")}</h3>
                <CustomTextInput
                  placeholder={t("dashboard.events.name")}
                  name="eventName"
                  type="text"
                />
                <h3>{t("dashboard.events.type")}</h3>
                <CustomSelect
                  placeholder={t("dashboard.events.type")}
                  name="eventType"
                >
                  <option value="selecciona el tipo de evento">
                    {t("dashboard.events.select")}
                  </option>
                  <option value="revision de sintomas">
                    {t("dashboard.events.review.symptom")}
                  </option>
                  <option value="revision de medicamentos">
                    {t("dashboard.events.review.medicine")}
                  </option>
                  <option value="revision de signos vitales">
                    {t("dashboard.events.review.vitalSign")}
                  </option>
                  <option value="cita con patiente">
                    {t("dashboard.events.boxes.three.title")}
                  </option>
                </CustomSelect>
                <h3>{t("patients.title")}</h3>
                <CustomTextInput
                  placeholder={t("patients.name")}
                  name="patient"
                  type="text"
                />
                <h3 className="addEvents__date">
                  {t("dashboard.events.date")}
                </h3>
                <h3 className="addEvents__time">
                  {t("dashboard.events.time")}
                </h3>
                <CustomTextInput
                  placeholder="hh : mm"
                  name="time"
                  type="text"
                />
              </div>
              <button className="addEvent__btn" type="submit">
                {props.isSubmitting
                  ? "Loading ..."
                  : `${t("dashboard.events.schedule")}`}
              </button>
              <button
                className="addEvent__closeBtn"
                onClick={() => setOpenAddEvents(false)}
              >
                {t("globally.close")}
              </button>
            </form>
          </AddEventsDiv>
        )}
      </Formik>
    </div>
  );
}

export default AddEvents;
