import React from "react";
import * as S from "./PatientHeader.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import * as SidebarS from "../../shared/sidebar/Sidebar.style";
import { useStateValue } from "../../../../contextAPI/StateProvider";
import { Link } from "react-router-dom";


// Components
import ZaiaButton from "../../../zaiaComponents/zaiaButton";
import ZaiaImage from "../../../zaiaComponents/zaiaImage";
import TooltipChats from "../../shared/tooltipChats";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_PERSONAL_INFO } from "../../../../graphql/queries/User";

// pipes
import * as P from "../../../../utils/pipes"
import InvitePatientButton from "../../patients/components/invitePatientButton";
import useActivateModal from "../../../../hooks/useActivateModal";
import NewEventModal from "../../outcomes/components/dasboardOutcomesByPatient/components/outcomesProcessByPatient/components/newEventModal";

// Assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;

function PatientHeader({ patientID, processID, closeLink, viewName, processToCustomFormID }) {

  const { t, i18n: { language }}  = useTranslation("global");
  const { activeModal, desactiveModal } = useActivateModal();
  const [{ selectedPatient }] = useStateValue();

  const { data: userData } = useQuery(QUERY_GET_USER_PERSONAL_INFO, {
    variables: { patientId: patientID },
  });

  const patient = {
    id: userData?.user[0].id,
    img: userData?.user[0].picture,
    name: userData?.user[0].name ? userData?.user[0].name + " " + userData?.user[0].lastname : '',
    process: userData?.user[0].userToProcesses?.length > 1 ? t("sidebar.variousProcesses") : P.dataTranslation(userData?.user[0].userToProcesses[0].process.processName, language),
    phone: userData?.user[0].phone,
    weight: userData?.user[0].weight,
    height: userData?.user[0].height,
    birthdate: P.getAge(userData?.user[0].birthdate),
  }

  const modalPatient = () => {
    return (
      <>
        <NewEventModal patientID={patientID} processID={processID} processToCustomFormID={processToCustomFormID} />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  } 

  return (
    <S.PatientHeaderDiv>
      <div className="dashboard-patient-header">
        <div className="dashboard-patient-header__left">
          <div className="dashboard-patient-header__left-img">
            <ZaiaImage img={patient?.img} alt="afsdf" size={60} defaultImg={defaultUser}/>
          </div>
          <div className="dashboard-patient-header__left-text">
            <div className="dashboard-patient-header__left-text-title">
              <h3>{patient.name}</h3>
              <Popup
                trigger={
                  <h5>{patient.process}</h5>
                }
                keepTooltipInside
                nested
                // position={"center bottom"}
                on="hover"
                disabled={userData?.user[0].userToProcesses?.length > 1 ? false : true}
              >
                {
                  <ProcessName userToProcesses={userData?.user[0]?.userToProcesses}/>
                }
              </Popup>
            </div>
            <div className="dashboard-patient-header__left-text-tags">
              {patient.birthdate && <Tag name={t("patients.age")} descript={patient.birthdate + ' ' + t("patients.years")}/>}
              {patient.weight && <Tag name={t("patients.weight")} descript={patient.weight + ' ' + t("patients.kg")}/>}
              {patient.height && <Tag name={t("patients.height")} descript={patient.height + ' ' + t("patients.cm")}/>}
              {patient.height && patient.weight && <Tag name={t("patients.bodySurface")} descript={P.getBodySurface(patient.height, patient.weight) + ' ' + t("patients.m2")}/>}
            </div>
          </div>
        </div>
        <div className="dashboard-patient-header__right">
            <Popup
              trigger={
              <div className="dashboard-patient-header__right-button">
                <div>
                  <SquareButton icon="zaia-icono-llamar" />
                </div>
              </div>
              }
            >
              <TooltipCelphone>
                {patient?.phone}
              </TooltipCelphone>
            </Popup>
            <Popup
              trigger={
                <div className="dashboard-patient-header__right-button">
                  <div>
                    <SquareButton icon="zaia-icono-chat-relleno" />
                  </div>
                </div>
              }
              position={ selectedPatient ? "bottom right" : "bottom center"}
            >
              <TooltipChats patientID={patient?.id}/>
            </Popup>
          {
            viewName === 'outcomes' && processToCustomFormID &&
            <div className="dashboard-patient-header__right-button">
              <div onClick={() => activeModal(() => modalPatient())}>
                <SquareButton icon="zaia-icono-pacient" />
              </div>
            </div>
          }
          {
            selectedPatient ? null :
            <div className="dashboard-patient-header__right-button dashboard-patient-header__right-button--close">
              <Link to={closeLink}>
                <ZaiaButton option="secondary" title={t("globally.close")} />
              </Link>
            </div>
          }
        </div>
      </div>
    </S.PatientHeaderDiv>
  );
}

const Tag = ({name, descript}) => {
  return (
    <S.Tag>
      {name}: {descript}
    </S.Tag>
  )
}

const SquareButton = ({icon}) => {
  return (
    <S.SquareButton>
      <i className={`icon ${icon}`} />
      <div className="button-plus">+</div>
    </S.SquareButton>
  )
}

const TooltipCelphone = ({children}) => {
  return (
    <S.TooltipCelphone>{children}</S.TooltipCelphone>
  )
}

const ProcessName = ({userToProcesses}) => {
  const { i18n: { language } } = useTranslation("global");

  return (
    <SidebarS.ProcessNameContainer>
        {
          userToProcesses.map((userToProcess, index) => {
            return (
              <div key={userToProcess.id}>
                <SidebarS.ProcessName last={index === userToProcesses.length - 1} >
                  <div className="card-selected-patient-process">
                    {P.dataTranslation(userToProcess.process.processName, language)}
                  </div>
                </SidebarS.ProcessName>
              </div>
            )
          })
        }
    </SidebarS.ProcessNameContainer>
  )
}

export default PatientHeader;
