import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Pipes
import * as P from "../../../../../utils/pipes";

// GraphQL
import { useQuery, useSubscription } from "@apollo/client";
import { QUERY_GET_USERS } from "../../../../../graphql/queries/User";
import {
  QUERY_GET_PROCESS_TYPES,
  QUERY_GET_PROCESS_TYPES_BY_ID,
} from "../../../../../graphql/queries/Process";
import {
  SUSCRIPTION_GET_SIDE_EFFECTS,
  SUSCRIPTION_GET_SIDE_EFFECTS_BY_USER,
} from "../../../../../graphql/subscriptions/SideEffects";
import {
  SUSCRIPTION_GET_VITAL_SIGNS,
  SUSCRIPTION_GET_VITAL_SIGNS_BY_USER,
} from "../../../../../graphql/subscriptions/VitalSigns";
import {
  SUSCRIPTION_FEELINGS_COMMENT_REPORTS,
  SUSCRIPTION_FEELINGS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/subscriptions/Feelings";
import {
  SUSCRIPTION_DRUGS_COMMENT_REPORTS,
  SUSCRIPTION_DRUGS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/subscriptions/drugs";
// import { QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS, QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER } from "../../../../../graphql/queries/VitalSigns";
import {
  SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS,
  SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/subscriptions/VitalSigns";

// components
import Box from "../../components/box";
import NoPatients from "../../../shared/noPatients";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-cards.svg`;

function ListOfBoxes({
  filterSelect,
  processIds,
  setAllNotificationNumber,
  setMildNotificationNumber,
  setMediumNotificationNumber,
  setSeriousNotificationNumber,
  setCommentNotificationNumber,
  levelReport,
  initialDate,
  finishDate,
  selectedPatient,
  setSelectedBox,
}) {
  const addDay = (date, day) => {
    date.setDate(date.getDate() + day);
    return date;
  };
  const newFinishDate = addDay(finishDate, +1);

  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  const {
    data: usersData,
    error: usersError,
    loading: usersLoading,
  } = useQuery(QUERY_GET_USERS);

  const { data: processTypes } = useQuery(
    processIds.length === 0
      ? QUERY_GET_PROCESS_TYPES
      : QUERY_GET_PROCESS_TYPES_BY_ID,
    {
      variables:
        processIds.length === 0
          ? null
          : {
              processId: processIds,
            },
    }
  );

  const sideEffects = useMemo(() => {
    let sideEffects1 = [];
    let sideEffects2 = [];
    let sideEffects3 = [];
    processTypes?.process.forEach((process) => {
      process.processToSideEffects.forEach((sideEffect) => {
        if (sideEffect.severity === 1)
          sideEffects1.push(sideEffect.typeSideEffect);
        if (sideEffect.severity === 2)
          sideEffects2.push(sideEffect.typeSideEffect);
        if (sideEffect.severity === 3)
          sideEffects3.push(sideEffect.typeSideEffect);
      });
    });
    return [sideEffects1, sideEffects2, sideEffects3];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processTypes]);

  const vitalSigns = useMemo(() => {
    let vitalSigns1 = [];
    let vitalSigns2 = [];
    let vitalSigns3 = [];
    processTypes?.process.forEach((process) => {
      process.processToVitalSigns.forEach((vitalSign) => {
        if (vitalSign.severity === 1) {
          if (vitalSign.min) {
            vitalSigns1.push({
              _and: {
                measure1: { _lt: vitalSign.min },
                vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
              },
            });
          }
          if (vitalSign.max) {
            vitalSigns1.push({
              _and: {
                measure1: { _gt: vitalSign.max },
                vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
              },
            });
          }
        }
        if (vitalSign.severity === 2) {
          if (vitalSign.min) {
            vitalSigns2.push({
              _and: {
                measure1: { _lt: vitalSign.min },
                vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
              },
            });
          }
          if (vitalSign.max) {
            vitalSigns2.push({
              _and: {
                measure1: { _gt: vitalSign.max },
                vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
              },
            });
          }
        }
        if (vitalSign.severity === 3) {
          if (vitalSign.min) {
            vitalSigns3.push({
              _and: {
                measure1: { _lt: vitalSign.min },
                vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
              },
            });
          }
          if (vitalSign.max) {
            vitalSigns3.push({
              _and: {
                measure1: { _gt: vitalSign.max },
                vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
              },
            });
          }
        }
      });
    });

    return [vitalSigns1, vitalSigns2, vitalSigns3];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processTypes]);

  const dateNow = new Date(Date.now());
  const dateTwoWeeksBefore = new Date(
    new Date(dateNow.setDate(dateNow.getDate() - 14)).setHours(0, 0, 0, 0)
  );

  const {
    data: sideEffectsData1,
    loading: sideEffectsDataLoading1,
    error: sideEffectsDataError1,
  } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_GET_SIDE_EFFECTS_BY_USER
      : SUSCRIPTION_GET_SIDE_EFFECTS,
    {
      variables: selectedPatient
        ? {
            sideEffects: sideEffects[0],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            sideEffects: sideEffects[0],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const {
    data: sideEffectsData2,
    loading: sideEffectsDataLoading2,
    error: sideEffectsDataError2,
  } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_GET_SIDE_EFFECTS_BY_USER
      : SUSCRIPTION_GET_SIDE_EFFECTS,
    {
      variables: selectedPatient
        ? {
            sideEffects: sideEffects[1],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            sideEffects: sideEffects[1],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const {
    data: sideEffectsData3,
    loading: sideEffectsDataLoading3,
    error: sideEffectsDataError3,
  } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_GET_SIDE_EFFECTS_BY_USER
      : SUSCRIPTION_GET_SIDE_EFFECTS,
    {
      variables: selectedPatient
        ? {
            sideEffects: sideEffects[2],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            sideEffects: sideEffects[2],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const {
    data: vitalSignsData1,
    loading: vitalSignsDataloading1,
    error: vitalSignsDataError1,
  } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_GET_VITAL_SIGNS_BY_USER
      : SUSCRIPTION_GET_VITAL_SIGNS,
    {
      variables: selectedPatient
        ? {
            vitalSigns: vitalSigns[0],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            vitalSigns: vitalSigns[0],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const {
    data: vitalSignsData2,
    loading: vitalSignsDataloading2,
    error: vitalSignsDataError2,
  } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_GET_VITAL_SIGNS_BY_USER
      : SUSCRIPTION_GET_VITAL_SIGNS,
    {
      variables: selectedPatient
        ? {
            vitalSigns: vitalSigns[1],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            vitalSigns: vitalSigns[1],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const {
    data: vitalSignsData3,
    loading: vitalSignsDataloading3,
    error: vitalSignsDataError3,
  } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_GET_VITAL_SIGNS_BY_USER
      : SUSCRIPTION_GET_VITAL_SIGNS,
    {
      variables: selectedPatient
        ? {
            vitalSigns: vitalSigns[2],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            vitalSigns: vitalSigns[2],
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  // Comment reports
  const { data: feelingsComments } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_FEELINGS_COMMENT_REPORTS_BY_USER
      : SUSCRIPTION_FEELINGS_COMMENT_REPORTS,
    {
      variables: selectedPatient
        ? {
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const { data: drugsComments } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_DRUGS_COMMENT_REPORTS_BY_USER
      : SUSCRIPTION_DRUGS_COMMENT_REPORTS,
    {
      variables: selectedPatient
        ? {
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const { data: vitalSignsComments } = useSubscription(
    selectedPatient
      ? SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER
      : SUSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS,
    {
      variables: selectedPatient
        ? {
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
            userId: selectedPatient,
          }
        : {
            initialDate: initialDate ? initialDate : dateTwoWeeksBefore,
            finalDate: newFinishDate,
          },
    }
  );

  const getSideEffects = (sideEffect, reportlevel) => {
    return sideEffect?.userToSideEffect.map((sideEffect) => {
      let sideEffectTemp = JSON.parse(JSON.stringify(sideEffect));
      sideEffectTemp.type = 1;
      sideEffectTemp.orderDate = sideEffect.date;
      sideEffectTemp.description = P.dataTranslation(
        sideEffect.typeSideEffectVal.comment,
        language
      );
      sideEffectTemp.reportlevel = reportlevel;
      return sideEffectTemp;
    });
  };

  const getVitalSigns = (vitalSign, reportlevel) => {
    return vitalSign?.userVitalSignHistory.map((vitalSign) => {
      let vitalSignTemp = JSON.parse(JSON.stringify(vitalSign));
      vitalSignTemp.type = 3;
      vitalSignTemp.orderDate = vitalSign.created_at;
      vitalSignTemp.description =
        reportlevel === 4
          ? `"${vitalSign.comment}" ${t(
              "dashboard.boxes.comment.symptom"
            )} "${P.dataTranslation(vitalSign.vitalSignVal.comment, language)}"`
          : `${P.dataTranslation(vitalSign.vitalSignVal.comment, language)}  (${
              vitalSign.measure1
            } ${vitalSign.unit})`;
      vitalSignTemp.reportlevel = reportlevel;
      return vitalSignTemp;
    });
  };

  const getDrugs = (drug, reportlevel) => {
    return drug?.userToDrugHistory.map((drug) => {
      let drugTemp = JSON.parse(JSON.stringify(drug));
      drugTemp.type = 2;
      drugTemp.orderDate = drug.created_at;
      drugTemp.description = drug.comment;
      drugTemp.reportlevel = reportlevel;
      return drugTemp;
    });
  };

  const getFeelings = (feeling, reportlevel) => {
    return feeling?.userToFeeling.map((feeling) => {
      let feelingTemp = JSON.parse(JSON.stringify(feeling));
      feelingTemp.type = 1;
      feelingTemp.orderDate = feeling.date;
      feelingTemp.description = feeling.description;
      feelingTemp.reportlevel = reportlevel;
      return feelingTemp;
    });
  };

  function cleanArray(actual) {
    var newArray = [];
    for (var i = 0, j = actual.length; i < j; i++) {
      if (actual[`${i}`]) {
        newArray.push(actual[`${i}`]);
      }
    }
    return newArray;
  }

  const dataForCard = useMemo(
    () => {
      let cardsInfo = [];

      let allCardsInfo = cleanArray(
        cardsInfo.concat(
          getVitalSigns(vitalSignsData1, 1),
          getSideEffects(sideEffectsData1, 1),
          getVitalSigns(vitalSignsData2, 2),
          getSideEffects(sideEffectsData2, 2),
          getVitalSigns(vitalSignsData3, 3),
          getSideEffects(sideEffectsData3, 3),
          getDrugs(drugsComments, 4),
          getVitalSigns(vitalSignsComments, 4),
          getFeelings(feelingsComments, 4)
        )
      );

      allCardsInfo = allCardsInfo
        .slice(0)
        .reverse()
        .filter((value, index, self) => {
          return self.findIndex((v) => v.id === value.id) === index;
        });

      const mildCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 1;
      });

      const mediumCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 2;
      });

      const seriousCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 3;
      });

      const commentCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 4;
      });

      if (levelReport === 0) {
        cardsInfo = allCardsInfo;
      } else if (levelReport === 1) {
        cardsInfo = mildCardsInfo;
      } else if (levelReport === 2) {
        cardsInfo = mediumCardsInfo;
      } else if (levelReport === 3) {
        cardsInfo = seriousCardsInfo;
      } else {
        cardsInfo = commentCardsInfo;
      }

      cardsInfo = cardsInfo.sort(function (a, b) {
        return new Date(a.orderDate) - new Date(b.orderDate);
      });

      // console.log(cardsInfo);
      return {
        cardsInfo,
        allCardsInfo,
        mildCardsInfo,
        mediumCardsInfo,
        seriousCardsInfo,
        commentCardsInfo,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      sideEffectsData1,
      sideEffectsData2,
      sideEffectsData3,
      vitalSignsData1,
      vitalSignsData2,
      vitalSignsData3,
      vitalSignsComments,
      feelingsComments,
      drugsComments,
      levelReport,
    ]
  );

  useEffect(() => {
    setAllNotificationNumber(dataForCard.allCardsInfo?.length);
    setMildNotificationNumber(dataForCard.mildCardsInfo?.length);
    setMediumNotificationNumber(dataForCard.mediumCardsInfo?.length);
    setSeriousNotificationNumber(dataForCard.seriousCardsInfo?.length);
    setCommentNotificationNumber(dataForCard.commentCardsInfo?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForCard]);

  if (
    sideEffectsDataLoading1 ||
    sideEffectsDataLoading2 ||
    sideEffectsDataLoading3 ||
    vitalSignsDataloading1 ||
    vitalSignsDataloading2 ||
    vitalSignsDataloading3 ||
    usersLoading
  )
    return (
      <>
        <ZaiaReaction widthImg="150px" zaiaImg={logoZaiaLoading} />
      </>
    );

  if (
    sideEffectsDataError1 ||
    sideEffectsDataError2 ||
    sideEffectsDataError3 ||
    vitalSignsDataError1 ||
    vitalSignsDataError2 ||
    vitalSignsDataError3 ||
    usersError
  )
    return (
      <>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="125px"
          text={t("dashboard.boxes.noAlertsError")}
          sizeText="17px"
          widthText="240px"
        />
      </>
    );

  if (usersData.user.length <= 0)
    return (
      <>
        <NoPatients title={t("dashboard.boxes.noAlerts")} img={logoZaiaEmpty} />
      </>
    );

  if (
    dataForCard.cardsInfo?.length < 1 ||
    dataForCard.cardsInfo?.filter(
      (dataCard) => !filterSelect || filterSelect === dataCard.type
    ).length <= 0
  )
    return (
      <>
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthImg="200px"
          text={t("dashboard.boxes.noAlerts")}
          sizeText="17px"
          widthText="200px"
        />
      </>
    );

  return (
    <>
      <h1>
        {JSON.stringify(
          console.log(dataForCard.allCardsInfo.slice(0).reverse())
        )}
      </h1>

      {dataForCard?.cardsInfo
        ?.slice(0)
        .reverse()
        .map((dataCard, index) => {
          return dataCard ? (
            !filterSelect || filterSelect === dataCard.type ? (
              <div
                className="box-container"
                key={dataCard.id}
                onClick={() => (dataCard ? setSelectedBox(dataCard) : null)}
              >
                <Box
                  patient={
                    dataCard?.user?.name + " " + dataCard?.user?.lastname
                  }
                  time={P.hoursIn12format(
                    new Date(dataCard?.orderDate).getHours(),
                    new Date(dataCard?.orderDate).getMinutes()
                  )}
                  date={P.shortDate(new Date(dataCard?.orderDate), t, true)}
                  description={
                    dataCard?.userToDrug?.drug?.genericName
                      ? `${dataCard?.userToDrug?.drug.genericName} - ${dataCard?.description}`
                      : ` ${dataCard?.description}`
                  }
                  report={t("dashboard.boxes.vitalSigns.report")}
                  type={dataCard?.type}
                  patientPhoto={dataCard?.user?.picture}
                  levelReport={dataCard?.reportlevel}
                />
              </div>
            ) : null
          ) : null;
        })}
      {/* <div className="box-container">
        <Box
          time="03:07 pm"
          patient="Victor Cárdenas"
          description={t("dashboard.boxes.vitalSigns.text")}
          report={t("dashboard.boxes.vitalSigns.report")}
          type={1}
        />
      </div>
      <div className="box-container">
        <Box
          time="03:07 pm"
          patient="Victor Cárdenas"
          description={t("dashboard.boxes.vitalSigns.text")}
          report={t("dashboard.boxes.vitalSigns.report")}
          type={2}
        />
      </div>
      <div className="box-container">
        <Box
          time="02:24 pm"
          patient="José Robles"
          description={t("dashboard.boxes.symptom.text")}
          report={t("dashboard.boxes.symptom.report")}
          type={3}
        />
      </div>
      <div className="box-container">
        <Box
          time="01:58 pm"
          patient="Juan De La Cruz"
          description={t("dashboard.boxes.medicine.text")}
          report={t("dashboard.boxes.medicine.report")}
          type={4}
          buttonText={t("dashboard.boxes.profile.button")}
        />
      </div>
      <div className="box-container">
        <Box
          description={t("dashboard.boxes.calendar.text")}
          time="03:15 pm"
          type={5}
        />
      </div> */}
    </>
  );
}

export default ListOfBoxes;
