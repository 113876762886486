import React from "react";
import * as S from "./CmpYesNoList.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";

function CmpYesNoList({
  // data,
  item,
  insertfunction,
  customFormChangeItem,
}) {
  const {t, i18n: { language }} = useTranslation("global");

  const yesNoItems = [
    {
      key: 'yes',
      name: t("globally.yes")
    },
    {
      key: 'no',
      name: t("globally.no")
    }
  ]

  const changeOptionItem = (customField, fieldOption) => {

    const userToCustomFormField = {
      customFormFieldId: customField.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(field => field.key === fieldOption.key)?.parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: fieldOption.name,
      key: fieldOption.key
    }
    insertfunction(userToCustomFormField)
  }

  const optionKey = customFormChangeItem ? customFormChangeItem.key : item?.userToCustomFormFields[0]?.key;

  return (
    <S.CmpYesNoList active={optionKey === 'yes' ? true : false}>
      <div className="yesNoList-name">{P.dataTranslation(item?.fieldName, language)}</div>
      <div className="yesNoList-states">
        <div className={`yesNoList-state ${optionKey === 'yes' && 'yesNoList-state--selected'}`} onClick={() => optionKey === 'yes' ? null : changeOptionItem(item, yesNoItems[0])}>{yesNoItems[0].name}</div>
        <div className={`yesNoList-state ${optionKey === 'no' && 'yesNoList-state--selected'}`} onClick={() => optionKey === 'no' ? null : changeOptionItem(item, yesNoItems[1])}>{yesNoItems[1].name}</div>
      </div>
    </S.CmpYesNoList>
  );
}

export default CmpYesNoList;
