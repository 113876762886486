import React from "react";
import * as S from "./OutcomesDashboard.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../../../utils/pipes";

// GraphQL
import GenericTemplate from "../../../../../outcomesComponents/shared/genericTemplate";

function OutcomesDashboard({
  customForm,
  customFormLoading,
  userToProcessData,
}) {
  const {
    // eslint-disable-next-line no-unused-vars
    t,
    i18n: { language },
  } = useTranslation("global");

  return (
    <S.OutcomesDashboard>
      {customForm?.customForm_by_pk.formGroups.map((formGroup) => {
        return formGroup.DoctorToUserProcessBoards[0] ? (
          formGroup.DoctorToUserProcessBoards[0].typeView !== "history" ? (
            <div className="box-container">
              <GenericTemplate
                title={P.dataTranslation(formGroup.customFormName, language)}
                dataForm={customForm?.customForm_by_pk.formGroups?.find(
                  (cmp) => cmp.id === formGroup.id
                )}
                insertfunction={() => {}}
                customFormChangesList={[]}
                typeView={"view"}
                isHistorical={false}
              />
            </div>
          ) : (
            // history
            <div className="box-container">
              <GenericTemplate
                title={P.dataTranslation(formGroup.customFormName, language)}
                dataForm={customForm?.customForm_by_pk.formGroups?.find(
                  (cmp) => cmp.id === formGroup.id
                )}
                insertfunction={() => {}}
                customFormChangesList={[]}
                typeView={"view"}
                isHistorical={true}
              />
            </div>
          )
        ) : null;
      })}
    </S.OutcomesDashboard>
  );
}

export default OutcomesDashboard;
