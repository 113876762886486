import styled from "styled-components";

export const DropdownDiv = styled.div`
  background-color: ${({theme}) => theme.background};
  position: absolute;
  height: 280px;
  width: 250px;
  border-radius: 20px;
  box-shadow: 0px 3px 15px ${({theme}) => theme.blackShadow}29;
  right: -11px;
  top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  justify-content: center;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div:first-child {
    border-bottom: 1px solid ${({theme}) => theme.onBackgroundTextSoft};
    img {
      margin: 5px 0 5px 0;
      width: 60px;
    }
    p {
      color: ${({theme}) => theme.onBackgroundTextSoft};
    }
    button {
      background-color: ${({theme}) => theme.primaryColor};
      border: none;
      border-radius: 20px;
      padding: 3px;
      color: white;
      box-shadow: 0px 3px 10px ${({theme}) => theme.blackShadow}29;
      cursor: pointer;
      margin: 12px 0 12px 0;
      width: 196px;
      height: 37px;
      &:focus {
        outline: none;
      }
    }
  }
  div:nth-child(2) {
    p {
      color: ${({theme}) => theme.primaryColor};
      margin: 10px 0 10px 0;
    }
    button {
      background-color: ${({theme}) => theme.background};;
      border: 1px solid ${({theme}) => theme.primaryColor};
      border-radius: 20px;
      padding: 3px;
      color: ${({theme}) => theme.primaryColor};;
      box-shadow: 0px 3px 10px ${({theme}) => theme.blackShadow}29;
      cursor: pointer;
      margin: 12px 0 12px 0;
      width: 196px;
      height: 37px;
    }
  }
`;
