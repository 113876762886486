import styled from "styled-components";

export const ZaiaReaction = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin: auto;
  img {
    width: ${(p) => p.widthImg};
    margin-bottom: ${(p) => p.separation};
  }
  h2 {
    font-size: ${(p) => p.sizeText };
    color: ${(p) => p.textColor};
    width: ${(p) => p.widthText};
    margin: auto;
  }
`