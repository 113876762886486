import React, { useContext, useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { ThemeContext } from "styled-components";
import * as S from "./InvitationsManagement.style";

// components
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaToggle from "../../../../zaiaComponents/zaiaToggle";
import ZaiaRoundInput from "../../../../zaiaComponents/zaiaRoundInput";
import InvitePatientButton from "../../../patients/components/invitePatientButton";

// GraphQL
import { useMutation, useLazyQuery } from "@apollo/client";
import { QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED } from "../../../../../graphql/queries/UserToProcess";
import { MUTATION_DELETE_USER_TO_PROCESS_BY_ID } from "../../../../../graphql/mutations/UserToProcess";


// Utils
import * as P from "../../../../../utils/pipes";
import Modal from "../../../../../utils/modal";

// Assets
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-alerta.svg`;

function InvitationsManagement() {
  const { t, i18n: { language } } = useTranslation("global");
  const theme = useContext(ThemeContext);
  const [activedModal, setActivedModal] = useState(false);
  const [idUserToProcess, setIdUserToProcess] = useState();

  const [deleteUserToProcess] = useMutation(MUTATION_DELETE_USER_TO_PROCESS_BY_ID);
  const [getListAcepted, { data: userToProcessListAcepted }] = useLazyQuery(QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED);
  const [getListNotAcepted, { data: userToProcessListNotAcepted}] = useLazyQuery(QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED, {variables: {isAcepted: false}});

  const [activeTab, setActiveTab] = useState(1);
  const [ inputText, setInputText ] = useState('');

  const tabs = [
    {id: 1, name: t('profile.invitationManagement.current')},
    {id: 2, name: t('profile.invitationManagement.invited')}
  ]

  useEffect(() => {
    getListAcepted({variables: {isAcepted: true}})
    getListNotAcepted({variables: {isAcepted: false}})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteUserToProcessHandler = async (idUserToProcess) => {
    try {
      const { 
        data: returning
      } = await deleteUserToProcess({
        variables: {
          id: idUserToProcess
        },
      });
      setActivedModal(false);
      if (returning.delete_userToProcess_by_pk.isAcepted) {
        getListAcepted({variables: {isAcepted: true}});
      } else {
        getListNotAcepted({variables: {isAcepted: false}});
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  return (
    <S.InvitationsManagement>
      <div className="card">
        <div className="card-info">
          <S.ToogleContainer>
            <ZaiaToggle
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              color={theme.primaryColor}
              backgroundColor={'transparent'}
              borderRadius={'15'}
              sizeFont={'15px'}
            />
          </S.ToogleContainer>
          <ZaiaRoundInput
            placeholder={t("globally.search")} 
            padding="8px" 
            type={'text'} 
            getText={(e) => setInputText(e)}
          />
          <div className="card-info-invitations-List">
            { activeTab === 1 
              ? userToProcessListAcepted?.userToProcess
              // .filter(o => hash[o.user.id] ? false : hash[o.user.id] = true) /// add
              .map((patient, index) => {
                return (
                  ( 
                    inputText 
                    ? String(patient.user?.documentNumber).startsWith(inputText) || 
                      String(patient.user?.name).startsWith(inputText) ||
                      String(patient.user?.lastname).startsWith(inputText) 
                    : true
                  ) ? <S.PatientBox key={patient.id}>
                      <div className="box-info">
                        <ZaiaImage img={patient.user?.picture} alt="Zaia user" size={56} defaultImg={defaultUser}/>
                        <div>
                          <h4>{patient.user?.name + ' ' + patient.user?.lastname}</h4>
                          <p className="second-text">
                            {patient.user?.enDocumentType.comment.code}: {patient.user?.documentNumber}
                          </p>
                          <p className="first-text">{P.dataTranslation(patient?.process.processName, language)}</p>
                        </div>
                      </div>
                      <div className="box-options" onClick={() => {setActivedModal(true); setIdUserToProcess(patient.id)}}>
                        <p>{t('profile.invitationManagement.delete')}</p>
                        <i className="zaia-icono-cerrar settings__header-icon"></i>
                      </div>
                      {
                        <Modal open={(activedModal && activeTab === 1)}>
                          <DeletePatientModal 
                            setActivedModal={setActivedModal} 
                            deleteUserToProcessHandler={deleteUserToProcessHandler} 
                            idUserToProcess={idUserToProcess}
                          />
                        </Modal>
                      }
                    </S.PatientBox>
                    : null 
                )
              })
              : <>
                {
                  userToProcessListNotAcepted?.userToProcess
                  // .filter(o => hash[o.user.id] ? false : hash[o.user.id] = true) /// add
                  .map((patient, index) => {
                    return (
                      ( 
                        inputText 
                        ? String(patient.user?.name).startsWith(inputText) ||
                          String(patient.user?.lastname).startsWith(inputText) 
                        : true
                      ) 
                      ? <S.PatientBox key={patient.id}>
                        <div className="box-info">
                          <ZaiaImage img={patient.user?.picture} alt="Zaia user" size={56} defaultImg={defaultUser}/>
                          <div>
                            <h4>{patient.user?.name + ' ' + patient.user?.lastname}</h4>
                            <p className="first-text">{P.dataTranslation(patient?.process.processName, language)}</p>
                          </div>
                        </div>
                        <div className="box-options">
                          <div className="box-options-text">
                            <p className="box-options-text-label">{t('profile.invitationManagement.lastSent')}</p>
                            <p className="box-options-text-date">{P.longDate(patient?.updated_at, t)}</p>
                          </div>
                          <i className="zaia-reenviar-icono settings__header-icon"></i>
                          <i className="zaia-icono-borrar settings__header-icon" onClick={() => {setActivedModal(true); setIdUserToProcess(patient.id)}}></i>
                        </div>
                      </S.PatientBox>
                    : null 
                    )
                  })
                }                  
                <Modal open={(activedModal && activeTab === 2)}>
                  <DeleteInvitedPatientModal 
                    setActivedModal={setActivedModal} 
                    deleteUserToProcessHandler={deleteUserToProcessHandler} 
                    idUserToProcess={idUserToProcess}
                  />
                </Modal>
              </>
            }
          </div>
        </div>
      </div>
    </S.InvitationsManagement>
  );
}

const DeleteInvitedPatientModal = ({setActivedModal, deleteUserToProcessHandler, idUserToProcess}) => {
  const [t] = useTranslation("global");

  return (
    <S.deleteInvitationModal>
      <div className="content-delete">
        <img className="zaia-image" src={logoZaiaAlert} alt="ZAIA" />
        <h1 className="content-delete__title">{t('profile.invitationManagement.deleteInvitationConfirmation')}</h1>
        <div className="message">{t('profile.invitationManagement.deleteInvitationWarning')}</div>
        <div className="button-container-principal" onClick={() => console.log('resend email')}>
          <ZaiaButton 
            title={t('profile.invitationManagement.resendEmail')}
            shadow={true}
          />
        </div>
        <div  className="cancel-button" onClick={() => deleteUserToProcessHandler(idUserToProcess)}>
          {t('profile.invitationManagement.delete')}
        </div>
      </div>
      <div onClick={() => setActivedModal(false)}>
        <InvitePatientButton activate={true} />
      </div>
    </S.deleteInvitationModal>
  )
}

const DeletePatientModal = ({setActivedModal, deleteUserToProcessHandler, idUserToProcess}) => {
  const [t] = useTranslation("global");

  return (
    <S.deleteInvitationModal>
      <div className="content-delete">
        <img className="zaia-image" src={logoZaiaAlert} alt="ZAIA" />
        <h1 className="content-delete__title">{'¿Seguro que deseas eliminar este paciente?'}</h1>
        <div className="message">{t('profile.invitationManagement.deletePatientWarning')}</div>
        <div className="button-container-principal" onClick={() => setActivedModal(false)}>
          <ZaiaButton 
            title={t('profile.invitationManagement.keepPatient')}
            shadow={true}
          />
        </div>
        <div className="cancel-button" onClick={() => deleteUserToProcessHandler(idUserToProcess)}>
          {t('profile.invitationManagement.delete')}
        </div>
      </div>
      <div onClick={() => setActivedModal(false)}>
        <InvitePatientButton activate={true} />
      </div>
    </S.deleteInvitationModal>
  )
}

export default InvitationsManagement;
