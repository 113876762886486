import styled from "styled-components";

export const ErrorMessage = styled.div`
  background-color: ${({theme}) => theme.warning};
  position: absolute;
  width: 326px;
  text-align: center;
  font-family: 'Nunito Semibold';
  padding: 7px;
  border-radius: 6px;
  color: ${({theme}) => theme.onPrimaryColor};;
  font-size: 15px;
  transform: ${({activeError}) => activeError ? 'translate(0px, -70px)' : 'translate(0px, -90px)'};
  visibility: ${({activeError}) => activeError ? 'visible' : 'hidden'};
  opacity: ${({activeError}) => activeError ? '1' : '0'};
  transition: all 1s ease;
`