import React from "react";
import LoginForm from "./components/LoginForm";
import { LoginDiv } from "./Login.style";
import CoverPage from "../coverPage";

function Login() {  

  return (
    <LoginDiv>
      <CoverPage/>
      <div className="login__right">
        <LoginForm />
      </div>
    </LoginDiv>
  );
}

export default Login;
