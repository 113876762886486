import { gql } from "@apollo/client";

export const QUERY_GET_DOCTOR_DATA = gql`
  query QUERY_GET_DOCTOR_DATA {
    doctor {
      id
      email
      name
      lastname
      picture
      isEmailVerified
      gender
    }
  }
`;

export const QUERY_GET_COMPLETE_DOCTOR_DATA = gql`
  query QUERY_GET_DOCTOR_DATA {
    doctor {
      id
      picture
      isEmailVerified
      email
      name
      lastname
      birthdate
      countryPhoneId
      phone
      documentType
      documentNumber
      medicalCode
      gender
      doctorData {
        id
        medicalField
        academicTitle
        universityName
      }
    }
  }
`;

