import { gql } from "@apollo/client";

export const QUERY_GET_PROCESS_STAGE = gql`
  query QUERY_GET_PROCESS_STAGE($processId: Int) {
    processStage(where: {processId: {_eq: $processId}}) {
      id
      descript
      expirationTime
      isAutomatic
      nextStageId
      processId
      stageName
    }
  }
`;