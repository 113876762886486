import styled from "styled-components";

export const ZaiaSmallSelectForm = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  .input-container {
    background-color: ${({theme}) => theme.inputBackgroundVariant};
    padding: ${({padding}) => padding ? padding :'7px 7px'};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: ${({listActive}) => listActive ? 'none' :'8px' };
    border-bottom-right-radius: ${({listActive}) => listActive ? 'none' :'8px' };
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    justify-content: space-between;
    .text-container {
      color: ${({theme}) => theme.primaryVariantColor};
      cursor: pointer;
      font-size: 16px;
      font-family: "Open Sans";
      display: inline-flex;
      overflow-x: auto;
      label {
        cursor: pointer;
        margin-right: 5px;
      }
      ::-webkit-scrollbar{
        display: none;
      }
    }
    i {
      padding-top: 5px;
      font-size: 12px;
      transition: .3s ease;
      transform: ${({listActive}) => listActive ? 'rotate(180deg)' : 'none'};
      margin-left: auto;
    }
  }
  .select-list {
    position: absolute;
    background-color: ${({theme}) => theme.inputBackgroundVariant};
    z-index: 1;
    display: ${({listActive}) => listActive ? 'block' :'none' };
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    font-family: "Open Sans";
    .select-item {
      cursor: pointer;
      padding: 3px 7px;
      color: ${({theme}) => theme.primaryVariantColor};
      label {
        cursor: pointer;
      }
      strong {
        cursor: pointer;
      }
    }
    ::-webkit-scrollbar{
      display: none;
    }
  }
`
