import React, { useState } from "react";
import { DashboardRightSideDiv } from "./DashboardRightSide.style";
import CalendarDashboard from "../calendarDashboard";
import ListOfEvents from "../listOfEvents";
import { useTranslation } from "react-i18next";
import AddEvents from "../AddEvents";

function DashboardRightSide({
  initialDate,
  finishDate,
  setInitialDate,
  setFinishDate,
}) {
  const [openAddEvents, setOpenAddEvents] = useState(false);
  const [t] = useTranslation("global");

  return (
    <DashboardRightSideDiv >
      {openAddEvents && <AddEvents setOpenAddEvents={setOpenAddEvents} />}
      <div className="dashboard__right-container">  
        <div className="dashboard__right-container-top">
          <div className="dashboard__right-container-header">
            <div>
              <div className="dashboard__right-container-header-icon">
                <div className="icon zaia-icono-calendario"></div>
              </div>
              <h2>{t("dashboard.events.title")}</h2>
            </div>
            {/* <p onClick={() => setOpenAddEvents(true)}>
              + {t("dashboard.events.addEvent")}
            </p> */}
          </div>
          <div className="dashboard__right-container-calendar">
            <CalendarDashboard 
              initialDate={initialDate}
              finishDate={finishDate}
              setInitialDate={setInitialDate}
              setFinishDate={setFinishDate}
            />
          </div>
        </div>
        <div className="dashboard__right-container-events">
          <ListOfEvents title={t("dashboard.events.upcomingEvents")} />
        </div>
      </div>
    </DashboardRightSideDiv>
  );
}

export default DashboardRightSide;
