import styled from "styled-components";

export const CustomSelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  .customSelect {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--disabled-input-color);
    display: flex;
    align-items: center;
    margin: 5px 0 10px 0;
    select {
      border: none;
      margin-left: 5px;
      width: 100%;
      &:focus {
        outline: none;
      }
      &:invalid {
        color: var(--subtitle-color);
      }
    }
    div {
      color: var(--input-text-color);
      margin: 3px 0 0 7px;
    }
  }
`;
