import styled from "styled-components";

export const CmpYesNoList = styled.div`
  border-radius: 10px;
  margin-bottom: ${({last}) => last ? '' : '5px'};
  /* color: var(--title-register-color); */
  display: flex;
  justify-content: space-between;
  .yesNoList-name {
    font-family: "Open Sans";
    font-size: 13px;
    margin: auto;
    width: 70%;
  }
  .yesNoList-states {
    width: 30%;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    .yesNoList-state {
      cursor: pointer;
      height: 22px;
      border-radius: 13px;
      width: 47%;
      color: var(--white);
      font-family: "Nunito";
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;   
      background-color: var(--text-area-grey);   
      &--selected {
        box-shadow: 0px 3px 6px var(--box-shadow);
        background-color: var(--blue-color);    
      }
    }
  }
`
