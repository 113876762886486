import { gql } from "@apollo/client";

export const QUERY_GET_USERS = gql`
  query QUERY_GET_USERS {
    user {
      id
      lastname
      picture
      id
      documentNumber
    }
  }
`
export const QUERY_GET_USERS_BY_FILTER = gql`
  query QUERY_GET_USERS_BY_FILTER(
    $text: String
  ) {
    user(
      limit: 10, 
      where: {_or: 
        [
          {name: {_ilike: $text}}, 
          {lastname: {_ilike: $text}}, 
          {documentNumber: {_ilike: $text}}
        ]
      }
    ) {
      id
      name
      lastname
      picture
      id
      documentNumber
      enDocumentType {
        comment
      }
      userToProcesses {
        process {
          id
          processName
        }
      }
    }
  }
`

export const QUERY_GET_USER_BY_PROCESS = gql`
  query QUERY_GET_USER_BY_PROCESS(
    $processId: Int
  ) {
  user(where: {userToProcesses: {processId: {_eq: $processId}}}) {
      name
      lastname
      picture
      id
      documentNumber
      userToProcesses {
        process {
          id
          processName
        }
      }
    }
  }
`;

export const QUERY_GET_USERS_WITH_PROCESS = gql`
  query QUERY_GET_USERS_WITH_PROCESS {
    user {
      name
      lastname
      picture
      id
      documentNumber
      userToProcesses {
        process {
          id
          processName
        }
      }
    }
  }
`;

export const QUERY_GET_USER_PERSONAL_INFO = gql`
  query QUERY_GET_USER_PERSONAL_INFO(
    $patientId: uuid
  ) {
    user(where: {id: {_eq: $patientId}}) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      userToProcesses {
        process {
          id
          processName
        }
      }
    }
  }
`;

export const QUERY_GET_USER_BY_ID_AND_PROCESS_ID = gql`
  query QUERY_GET_USER_PERSONAL_INFO(
    $patientId: uuid
    $processId: Int
  ) {
    user(where: {id: {_eq: $patientId}, userToProcesses: {processId: {_eq: $processId}}}) {
      name
      lastname
      id
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      userToProcesses {
        process {
          id
          processName
        }
      }
    }
  }
`;

export const QUERY_GET_USER_FEELINGS = gql`
  query QUERY_GET_USER_FEELINGS(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    user(where: {id: {_eq: $patientId}}) {
      userToFeelings(where: {_and: {date: { _gte: $startDate, _lte: $finishDate}}}) {
        id
        levelFeeling
        date
        description
      }
    }
  }
`;

export const QUERY_GET_USER_SIDE_EFFECT = gql`
  query QUERY_GET_USER_SIDE_EFFECT(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    user(where: {id: {_eq: $patientId}}) {
      userToSideEffects(where: {_and: {date: { _gte: $startDate, _lte: $finishDate}}}) {
        id
        date
        severity
        typeSideEffectVal {
          value
          comment
          groupSideEffect
        }
      }
    }
  }
`;

export const QUERY_GET_USER_MEDICINES = gql`
  query QUERY_GET_USER_MEDICINES(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    user(where: {id: {_eq: $patientId}}) {
      userToDrugs {
        id
        isFix
        isNotify
        isPeriodic
        quantity
        frequency
        comment
        dose
        doseType
        startTreatment
        endTreatment
        startHour
        daysOfWeek
        doseShapeValue {
          value
          comment
        }
        userToDrugDates {
          daySpecific
        }
        frequencyTypeValue {
          comment
          value
        }
        drug {
          id
          genericName
          drugNames {
            id
            name
          }
        }
        drugDose {
          doseType
          doseTypeVal {
            value
            comment
          }
        }
        userToDrugHistories(where: {_and: {created_at: {_gte: $startDate, _lte: $finishDate}}}) {
          id
          created_at
          delay
          dose
          doseType
          drugId
          comment
          isTaken
        }
      }
    }
  }
`;


export const QUERY_GET_USER_VITAL_SIGNS = gql`
  query QUERY_GET_USER_VITAL_SIGNS(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    userToProcess(where: {user: {id: {_eq: $patientId}}}) {
      user {
        userToVitalSigns {
          id
          frequency
          frequencyType
          startHour
          unit
          vitalSignVal {
            comment
            value
            userVitalSignHistories(where: {_and: {created_at: {_gte: $startDate, _lte: $finishDate}}}, order_by: {created_at: asc}) {
              id
              measure1
              measure2
              unit
              created_at
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_USER_VITAL_SIGNS_SIMPLE = gql`
  query QUERY_GET_USER_VITAL_SIGNS(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    enVitalSign {
      comment
      value
      userVitalSignHistories(where: {_and: {created_at: {_gte: $startDate, _lte: $finishDate}}, user: {id: {_eq: $patientId}}}, order_by: {created_at: desc}) {
        id
        measure1
        measure2
        unit
        created_at
        userId
      }
    }
  }
`

export const QUERY_GET_ALL_USER_FEELINGS = gql`
  query QUERY_GET_ALL_USER_FEELINGS(
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    userToFeeling (where: {_and: {date: { _gte: $startDate, _lte: $finishDate}}}) {
      id
      levelFeeling
      date
    }
  }
`;

export const QUERY_GET_ALL_USER_SIDE_EFFECT = gql`
  query QUERY_GET_ALL_USER_SIDE_EFFECT(
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    userToSideEffect (where: {_and: {date: { _gte: $startDate, _lte: $finishDate}}}) {
      id
      date
      userId
      typeSideEffectVal {
        value
        comment
        groupSideEffect
      }
    }
  }
`;

export const QUERY_GET_ALL_USER_MEDICINES = gql`
  query QUERY_GET_ALL_USER_MEDICINES(
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    userToDrugHistory (where: {_and: {created_at: {_gte: $startDate, _lte: $finishDate}}}) {
      id
      created_at
      isTaken
      userId
      userToDrug {
        drugId
        drug {
          genericName
        }
      }
    }
  }
`;