import styled from "styled-components";

export const noPatients = styled.div`
  margin: auto;
  .message {
    margin: 25px 0;
    font-family: "Nunito Bold";
    font-size: 18px;
    color: ${({theme}) => theme.onBackgroundTextSoft};
  }
`;
