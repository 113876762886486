import styled from "styled-components";

export const PatientHeaderDiv = styled.div`
  .dashboard-patient-header {
    width: 100%;
    /* background-color: ${({theme}) => theme.background}; */
    display: flex;
    justify-content: space-between;  
    margin-bottom: 20px;
    &__left {
      display: flex;
      align-items: center;
      &-text-title {
        display: flex;
        align-items: baseline;
        h3 {
          color: ${({theme}) => theme.onBackground};
        }
        h5 {
          margin-left: 10px;
          color: ${({theme}) => theme.primaryColor};
          cursor: default;
        }
      }
      &-text-tags {
        display: flex;
        margin-top: 8px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      &-button {
        height: auto;
        margin: 0 5px;
        &--close {
          margin: 0 0 0 10px;
          width: 110px;
        }
      }
    }
  }
`;

export const Tag = styled.p`
  background-color: ${({theme}) => theme.cardFilter};
  color: ${({theme}) => theme.onCardFilter};
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 10px;
  margin-right: 5px;
  font-weight: normal;
  font-family: "Nunito"
`;

export const SquareButton = styled.button`
  height: 42px;
  width: 42px;
  background-color: ${({theme}) => theme.primaryColor};
  border: none;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 3px 3px 5px ${({theme}) => theme.blackShadow}29;
  color: ${({theme}) => theme.onPrimaryColor};
  font-size: 24px;
  padding-top: 5px;
  position: relative;
  .button-plus {
    position: absolute;
    /* float: right; */
    bottom: -5px;
    right: -5px;
    background-color: var(--white);
    border: 2px solid var(--blue-color-200);
    color: var(--blue-color-200);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: none;
    border-radius: 50%;
    transform: none;
    font-size: 15px;
  }
`;

export const TooltipCelphone = styled.h4`
  background-color: ${({theme}) => theme.onPrimaryColor};
  padding: 5px 10px;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  font-family: 'Nunito ExtraBold';
  color: ${({theme}) => theme.primaryColor};
  border-radius: 15px;
`