import React, { useEffect, useState } from "react";
import * as S from "./CircleChart.style";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const CircleChart = ({
  icon="zaia-icono-signos-vitales-relleno", 
  colorPrimary="#3E3DA9",
  colorSecundary="#6C6BD380",
  colorIcon="#1F1F55",
  percentage=60,
  widthGraph
}) => {

  const [charge, setCharge] = useState(0);

  useEffect(() => {
    setCharge(percentage)
  }, [percentage]);

  return (
    <S.CircleChart
      primaryColor={colorPrimary}
      colorSecundary={colorSecundary}
      colorIcon={colorIcon}
      widthGraph={widthGraph}
    >
      <div className="circle-chart">
        <CircularProgressbar 
          value={charge}
          styles={buildStyles({
            pathTransitionDuration: 3
          })}
        />
        {
          icon ? 
          <div className="circle-chart__center-icon">
            <i className={`icon ${icon}`}></i>
          </div> : null
        }
      </div>
    </S.CircleChart>
  );
};

export default CircleChart;
