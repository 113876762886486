import { useState, useEffect } from "react";

// graphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_BY_PROCESS, QUERY_GET_USERS_WITH_PROCESS } from "../graphql/queries/User";


export default function usePatientList(processIdFilter, filterProccessIds) {
  
  const {
    data: usersData, 
    loading: usersLoading,
    error: usersError,
  } = useQuery(
    processIdFilter
    ? QUERY_GET_USER_BY_PROCESS
    : QUERY_GET_USERS_WITH_PROCESS,
    { 
      variables: 
      processIdFilter
      ? {
        processId: processIdFilter
      }
      : null
    }
  );
  const [patientsList, setpatientsList] = useState([]);

  useEffect(() => {

    let patientListTemp = []
    usersData?.user.forEach(patient => {
      if( filterProccessIds?.length > 0) {
        if (patient.userToProcesses.length > 1 ? 
          multipleProcessesFilter(patient.userToProcesses) : 
          (filterProccessIds?.indexOf(patient.userToProcesses[0].process.id) !== -1)) {
            patientListTemp.push(patient)
          }
        } else {
          patientListTemp.push(patient)
        }
      });
    setpatientsList(patientListTemp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[usersData, filterProccessIds, processIdFilter])

  const multipleProcessesFilter = (userToProcesses) => {
    let bool = false;
    userToProcesses.forEach(userToProcess => {
      if(filterProccessIds?.indexOf(userToProcess.process.id) !== -1) {
        bool = true;
      }
    })
    return bool;
  }

  return { 
    patientList: patientsList,
    patientListLoading: usersLoading,
    patientListError: usersError,
    patientQuantity: patientsList?.length
  };
}