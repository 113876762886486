import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DashboardPatient.style";

// Components
import BoxInfoTimeFilter from "../boxInfoTimeFilter";
import FeelingsBoxInfo from "./components/feelingsBoxInfo";
import SymptomsBoxInfo from "./components/symptomsBoxInfo";
import MedicinesBoxInfo from "./components/medicinesBoxInfo";
import VitalsignsBoxInfo from "./components/vitalsignsBoxInfo";
import PatientHeader from "../../../shared/patientHeader";

function DashboardPatient({ patientID }) {

  const { t }  = useTranslation("global");
  const [feelingsTime, setFeelingsTime] = useState();
  const [symptomsTime, setSymptomsTime] = useState();
  const [medicinesTime, setmedicinesTime] = useState();
  const [vitalsignsTime, setVitalsignsTime] = useState();  

  return (
    <S.DashboardPatient>
      <PatientHeader patientID={patientID} closeLink={"/patients"} viewName={'patients'}/>
      <div className="cards-container">
        <BoxInfoTimeFilter
          icon="zaia-icono-sintoma-bien-off"
          title={t("globally.feeling")}
          color="var(--blue-color)"
          colorTitle="var(--blue-color-200)"
          setTime={setFeelingsTime}
          time={feelingsTime}
        >
          <FeelingsBoxInfo time={feelingsTime} icon={"zaia-icono-sintomas"}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-sintomas"
          title={t("globally.symptom")}
          color="var(--blue-color)"
          colorTitle="var(--blue-color-200)"
          setTime={setSymptomsTime}
          time={symptomsTime}
        >
          <SymptomsBoxInfo time={symptomsTime} icon={"zaia-icono-sintomas"}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-medicina"
          title={t("globally.medicine")}
          color="var(--green-color)"
          colorTitle="var(--green-color-100)"
          setTime={setmedicinesTime}
          time={medicinesTime}
        >
          <MedicinesBoxInfo time={medicinesTime} icon={"zaia-icono-medicina"}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-signos-vitales"
          title={t("globally.vitalSign")}
          color="var(--red-color)"
          colorTitle="var(--red-color-100)"
          setTime={setVitalsignsTime}
          time={vitalsignsTime}
        >
          <VitalsignsBoxInfo time={vitalsignsTime} icon={"zaia-icono-signos-vitales"}/>
        </BoxInfoTimeFilter>
      </div>
    </S.DashboardPatient>
  );
}

export default DashboardPatient;
