import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { RegisterFormDiv } from "../../Register.style";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import CheckBoxGroup from "../CheckBoxGroup";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { StepFiveDiv } from "./style";
import { useTranslation } from "react-i18next";

function StepFive({ activeStep, setActiveStep }) {
  const [t] = useTranslation("global");
  const [other, setOther] = useState("");
  const [, dispatch] = useStateValue();
  const handleNext = (values, { setSubmitting, resetForm }) => {
    dispatch({
      type: "SET_STEPFIVE",
      stepFive: values,
    });
    resetForm();
    setSubmitting(false);
    setActiveStep(90);
  };

  const checkBoxOptions = [
    { key: `${t("register.asistencia")}`, value: "asistencial" },
    { key: `${t("register.admin")}`, value: "admnistrativa" },
    { key: `${t("register.industria")}`, value: "industria farmaceutica" },
    { key: `${t("register.investigacion")}`, value: "investigacion" },
    { key: `${t("register.docencia")}`, value: "docencia/academia" },
    { key: `${t("register.other")}`, value: "other" },
  ];

  return (
    <Formik
      initialValues={{
        checkBoxOption: [],
      }}
      validationSchema={Yup.object({
        checkBoxOption: Yup.array(),
      })}
      onSubmit={handleNext}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {activeStep === 75 && (
            <>
              <Header stepFive setActiveStep={setActiveStep} />
              <ProgressBar done={activeStep} />
              <RegisterFormDiv>
                <StepFiveDiv>
                  <h3 className="title-register">
                    {t("register.experience")}:
                  </h3>
                  <p>{t("register.select")}</p>
                  <CheckBoxGroup
                    name="checkBoxOption"
                    options={checkBoxOptions}
                  />
                  <input
                    className="stepFiveDiv__input"
                    type="text"
                    value={other}
                    onChange={(e) => setOther(e.target.value)}
                    placeholder={t("register.others")}
                  />
                  <ZaiaButton
                    type="submit"
                    marginTop="42"
                    title={
                      props.isSubmitting
                        ? "Loading ..."
                        : `${t("register.continue")}`
                    }
                  />
                </StepFiveDiv>
              </RegisterFormDiv>
            </>
          )}
        </form>
      )}
    </Formik>
  );
}

export default StepFive;
