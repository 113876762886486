import React from "react";
import * as S from "./ProgressBar.style";

const ProgressBar = ({ 
  bgColor, 
  completed, 
  symptom, 
  bgColor2, 
  textPercentage=true,
  animationFromZero=true
}) => {

  return (
    <S.ProgressBar
      bgColor={bgColor}
      completed={completed}
      bgColor2={bgColor2}
      animationFromZero={animationFromZero}
    >
      <div className="fillerStyles">
        <span className="labelStyles">{symptom}</span>
        {
          textPercentage &&
          <span className="labelStyles">{`${completed}%`}</span>
        }
      </div>
    </S.ProgressBar>
  );
};

export default ProgressBar;
