import React, { useMemo, useState, useEffect } from "react";
import * as S from "./FeelingsBoxInfo.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// pipes
import * as P from "../../../../../../../utils/pipes";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_FEELINGS } from "../../../../../../../graphql/queries/User";

// Components
import FacesCard from "../../../../../shared/charts/FacesCard";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import TimeVerticalBars from "../../../../../shared/charts/timeVerticalBars";
import TimeHorizontalBars from "../../../../../shared/charts/timeHorizontalBars";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;
const loadingZaia = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;

function FeelingsBoxInfo({
  time,
  icon,
}) {

  const [t] = useTranslation("global");
  const listLevels = [
    t("patients.boxInfoPatient.report.statusList.good"),
    t("patients.boxInfoPatient.report.statusList.regular"),
    t("patients.boxInfoPatient.report.statusList.bad")
  ]
  const { patientID } = useParams();
  const { 
    data: userToFeelings, 
    loading: userToFeelingsLoading 
  } = useQuery(
    QUERY_GET_USER_FEELINGS, 
    {
      variables: { 
        patientId: patientID, 
        startDate: new Date(time?.startDate), 
        finishDate: new Date(time?.finishDate) 
      },
    }
  );
  const [totalPercentage, setTotalPercentage] = useState(0);

  // const dataTemp = [
  //   {id: "ab8975ff-b010-47ea-aaa0-1a2c18af7c8e", levelFeeling: 1, date: "2021-05-09T09:19:20.58576+00:00", description: null},
  //   {id: "42db6e09-1ed0-4e04-885f-cd5e7b5cc5ba", levelFeeling: 2, date: "2021-05-10T10:20:53.843842+00:00", description: "test"},
  //   {id: "50238ca0-1cc4-498a-954b-d97c5a5f6f08", levelFeeling: 3, date: "2021-05-11T11:22:07.758637+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-9770-513373e08b60", levelFeeling: 1, date: "2021-05-13T13:38:48.419747+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-97safsdf0dddddddd", levelFeeling: 2, date: "2021-05-14T14:38:48.419747+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-asdfasdfsad513370", levelFeeling: 3, date: "2021-05-15T15:38:48.419747+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-asafsddfsad513370", levelFeeling: 3, date: "2021-05-17T16:38:48.419747+00:00", description: null},
  //   {id: "ab8975ff-b010-47ea-aaa0-1a2c18af7c8e", levelFeeling: 1, date: "2021-05-04T09:19:20.58576+00:00", description: null},
  //   {id: "42db6e09-1ed0-4e04-885f-cd5e7b5cc5ba", levelFeeling: 2, date: "2021-05-04T10:20:53.843842+00:00", description: "test"},
  //   {id: "50238ca0-1cc4-498a-954b-d97c5a5f6f08", levelFeeling: 3, date: "2021-05-04T11:22:07.758637+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-9770-513373e08b60", levelFeeling: 1, date: "2021-05-04T16:38:48.419747+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-97safsdf0dddddddd", levelFeeling: 2, date: "2021-05-04T16:38:48.419747+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-asdfasdfsad513370", levelFeeling: 3, date: "2021-05-04T16:38:48.419747+00:00", description: null},
  //   {id: "44dc779c-6cc6-4d96-asafsddfsad513370", levelFeeling: 3, date: "2021-05-04T16:38:48.419747+00:00", description: null}
  // ]

  const color = {
    primary: "var(--blue-color)",
    secundary: "var(--box-shadow-blue-color)",
    dark: "var(--blue-dark-color)",
    varitation: "var(--dashboard-color)"
  }

  const getPercentage = (arrayFeelings) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const quantityFeelings = arrayFeelings?.length;
    const sumFeelings = arrayFeelings?.reduce(reducer); 
    const average = (sumFeelings / quantityFeelings); 
    return P.getPercentage(average, listLevels.length);
  } 

  const filterDataByDay = async (info) => {
    // console.log("reunida day", info);
    let levelFeelingsDay = [];
    info?.length > 0 ? setTotalPercentage(100) : setTotalPercentage(0);
    info?.forEach(infoItem => {
      levelFeelingsDay.push(
        {
          // day: new Date(infoItem.date).getHours() + ":" + new Date(infoItem.date).getMinutes(),
          day: P.hoursIn12format(new Date(infoItem.date).getHours(), new Date(infoItem.date).getMinutes()),
          percentage: getPercentage([infoItem.levelFeeling])
        }
      )
      console.log("hora", infoItem.date);
    });
    console.log("transformada day", levelFeelingsDay);
    return levelFeelingsDay;
  }

  const filterDataByWeek = async (info) => {
    const infoJoined = timesJoined(info);

    const totalPercentage = P.getPercentage(infoJoined?.length, 7);
    setTotalPercentage(totalPercentage); 

    // console.log("reunida week", infoJoined);
    let levelFeelingsWeek = [];
    let dateStartWeek = new Date(time.startDate);
    let dateFinishWeek = new Date(time.startDate);
    dateFinishWeek.setDate(dateFinishWeek.getDate() + 7);

    while(dateStartWeek.getTime() !== dateFinishWeek.getTime()) {
      let dateFiltered = infoJoined.filter(info => new Date(info.date).getDate() === new Date(dateStartWeek).getDate());
      let percentage = dateFiltered.length ? getPercentage(dateFiltered[0].levelFeelings) : 0;
      levelFeelingsWeek.push(
        {
          day: new Date(dateStartWeek).getDate(),
          dayName: P.nameDayWeek(new Date(dateStartWeek).getDay(), 'S', t),
          percentage: percentage,
        }
      )
      dateStartWeek.setDate(dateStartWeek.getDate() + 1);
    }
    // console.log("transformada week", levelFeelingsWeek);
    return levelFeelingsWeek;
  }

  const filterDataByMonth = async (info) => {
    const infoJoined = timesJoined(info);
    
    // console.log("reunida month", infoJoined);
    let levelFeelingsMonth = [];
    let dateStartMonth = new Date(time.startDate);
    let dateFinishMonth = new Date(time.startDate);
    const numberDaysMonth = new Date(dateStartMonth.getFullYear(), dateStartMonth.getMonth() + 1, 0).getDate();
    dateFinishMonth.setDate(dateFinishMonth.getDate() + numberDaysMonth);


    const totalPercentage = P.getPercentage(infoJoined?.length, numberDaysMonth);
    setTotalPercentage(totalPercentage); 

    while(dateStartMonth.getTime() !== dateFinishMonth.getTime()) {
      let dateFiltered = infoJoined.filter(info => new Date(info.date).getDate() === new Date(dateStartMonth).getDate());
      let percentage = dateFiltered.length ? getPercentage(dateFiltered[0].levelFeelings) : 0;
      levelFeelingsMonth.push(
        {
          day: new Date(dateStartMonth).getDate(),
          percentage: percentage,
        }
      )
      dateStartMonth.setDate(dateStartMonth.getDate() + 1);
    }
    // console.log("transformada month", levelFeelingsMonth);
    return levelFeelingsMonth;
  }

  const timesJoined = (info) => {
    let filteredFeelings = []
    info?.forEach(feeling => {
      const searchFeeling = filteredFeelings.find(feel => new Date(feel.date).getDate() === new Date(feeling.date).getDate() );
      if (!searchFeeling) {
        const newFeeling = { date: feeling.date, levelFeelings: [feeling.levelFeeling] };
        filteredFeelings.push(newFeeling);
      } else {
        const feelIndex = filteredFeelings.indexOf(searchFeeling);
        let levelFeelingsArray = filteredFeelings[`${feelIndex}`].levelFeelings;
        levelFeelingsArray.push(feeling.levelFeeling);
        filteredFeelings[`${feelIndex}`].levelFeelings = levelFeelingsArray;
      }
    });
    return (filteredFeelings);
  }

  const timesJoinedByFeelings = (info) => {
    let filteredFeelings = []
    info?.forEach(feeling => {
      const searchFeeling = filteredFeelings.find(feel => 
        feel.levelFeeling === feeling.levelFeeling
      );
      if (!searchFeeling) {
        const newFeeling = { levelFeeling: feeling.levelFeeling, quantity: 1 };
        filteredFeelings.push(newFeeling);
      } else {
        const feelIndex = filteredFeelings.indexOf(searchFeeling);
        filteredFeelings[`${feelIndex}`].quantity += 1;
      }
    });
    return (filteredFeelings);
  }

  const averageByDay = useMemo(() => {
    const info = userToFeelings?.user[0].userToFeelings;
    // const info = dataTemp;
    if(time?.timeOp === 'day') return filterDataByDay(info);
    if(time?.timeOp === 'week') return filterDataByWeek(info);
    if(time?.timeOp === 'month') return filterDataByMonth(info);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToFeelings, time])  
  // },[dataTemp])  

  const averageByLevelFeelings = useMemo(() => {
    const info = userToFeelings?.user[0].userToFeelings;
    // const info = dataTemp;
    const dataJoined = timesJoinedByFeelings(info);
    let percentageFeelings = []
    dataJoined.forEach(data => {
      percentageFeelings.push(
          {
            levelFeeling: data.levelFeeling,
            percentage: P.getPercentage(data.quantity, info.length)
          }
        )
    });
    // console.log(percentageFeelings);
    return (percentageFeelings);
  },[userToFeelings]);  

  const getFacePercentage = (levelFace) => {
    if(Number(averageByLevelFeelings.filter(level => level.levelFeeling === levelFace)[0]?.percentage) % 1 === 0 ) {
      return averageByLevelFeelings.filter(level => level.levelFeeling === levelFace)[0]?.percentage;
    } else {
      return averageByLevelFeelings.filter(level => level.levelFeeling === levelFace)[0]?.percentage;
    }
  }
  
  return (
    <S.FeelingsBoxInfo>
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left">
          <div className="boxInfoPatient__info-left-first">
            {
              <SymptomsBoxContainer 
                symptomsInfo={averageByDay}
                time={time}
                color={color}
                listLevels={listLevels}
                loading={userToFeelingsLoading}
              />
            }
          </div>
          <div className="boxInfoPatient__info-left-second">
            <FacesCard 
              colorPrimary={color.primary}
              colorSecundary={color.dark}
              flex={false}
              goodPercentage={
                getFacePercentage(3)
              }
              regularPercentage={
                getFacePercentage(2)
              }
              badPercentage={
                getFacePercentage(1)
              }
            />
          </div>
        </div>
        <div className="boxInfoPatient__info-right">
          <CircleChartCard 
            icon={icon} 
            title={t("patients.boxInfoPatient.report.symptoms")}
            percentagePrincipal={totalPercentage}
            textPrincipal={t("patients.boxInfoPatient.report.reported")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div>
      </div>
    </S.FeelingsBoxInfo>
  );
}

const SymptomsBoxContainer = ({symptomsInfo, time, color, listLevels, loading}) => {
  const [infoSymptoms, setInfoSymptoms] = useState();
  const [t] = useTranslation("global");

  useEffect(() => {
    symptomsInfo?.then((info) => {
      setInfoSymptoms(info);
    })
  },[symptomsInfo, time])

  if (loading) return (
    <>
      <ZaiaReaction
        zaiaImg={loadingZaia}
        widthImg="100px"
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  if (infoSymptoms?.length === 0 || !infoSymptoms) return (
    <>
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthImg="100px"
        text={t('globally.noReports')}
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  return (
    <>
      {
        time?.timeOp === 'day' ? 
        <TimeHorizontalBars
          activeHeader={false}
          colorSecundary={color.secundary}
          colorPrimary={color.primary}
          colorVariation={color.varitation}
          time={time}
          chartInfo={infoSymptoms}
          listLevels={listLevels}
        />:
        <TimeVerticalBars 
          activeHeader={false}
          colorSecundary={color.secundary}  
          colorPrimary={color.primary}
          colorVariation={color.varitation}
          time={time}
          chartInfo={infoSymptoms}
          listLevels={listLevels}
        />
      }
    </>
  )
}

export default FeelingsBoxInfo;
      