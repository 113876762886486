/* .react-calendar {
  width: 350px;
  max-width: 100%;
  font-family: "Nunito";
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  color: #313131;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #ffc43b;
  color: white;
  border-radius: 60px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
} */
import styled from "styled-components";

export const CalendarDashboard = styled.div`
  .Calendar {
    box-shadow: none !important;
    padding: 0 !important;
    font-family: "Open Sans" !important;
    font-size: 8.5px !important;
    min-height: 0 !important;
    background-color: ${({theme}) => theme.background};
  }
  
  .Calendar__header {
    padding: 2em 1em !important;
  }
  
  .Calendar__section {
    font-family: "Open Sans";
    padding: 0 0em !important;  
  }
  
  .Calendar__section.-hiddenPrevious {
    transform: translateX(-100%) !important;
  }
  
  .Calendar__section.-hiddenNext {
    transform: translateX(100%) !important;
  }
  
  .Calendar__weekDays {
    padding: 0;
    font-family: "Nunito ExtraBold";
    color: ${({theme}) => theme.onBackgroundVariant};
  }
  
  .Calendar__monthYear{
    font-family: "Nunito ExtraBold" !important;
  }
  
  .Calendar__day.-ltr {
    color: ${({theme}) => theme.onBackgroundVariant};
    min-height: 3.1em !important;
  }

  .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background-color: ${({theme}) => theme.onBackgroundVariant}1A;
    color: ${({theme}) => theme.onBackgroundVariant};
  }

  .Calendar__day.-selectedBetween {
    color: ${({theme}) => theme.primarySectionCalendar};
  }
  
  .Calendar__day.-ltr.-selectedStart {
    border-radius: ${({range}) => !range.to || range.from === range.to ? '100em !important' : ''} ;
    color: ${({theme}) => theme.primaryVariantColor};
  }
  
  .Calendar__day.-ltr.-selectedEnd {
    color: ${({theme}) => theme.primaryVariantColor};
  }
  
  .Calendar__day:focus {
    outline: none;
  }

  .Calendar > :not(.Calendar__footer) button {
    color: ${({theme}) => theme.onBackgroundVariant};
  }
  
  .Calendar__monthSelector.-open, .Calendar__yearSelector.-open {
    background-color: ${({theme}) => theme.background} !important;
  }

  .Calendar__yearSelectorWrapper::before {
    background-image: linear-gradient(to top,  ${({theme}) => theme.background},  ${({theme}) => theme.background} 10%, rgba(245, 245, 245, 0));
  }
  .Calendar__yearSelectorWrapper::after {
    background-image: linear-gradient(to bottom,  ${({theme}) => theme.background},  ${({theme}) => theme.background} 10%, rgba(245, 245, 245, 0));
  }

  .-active {
    button {
      color: ${({theme}) => theme.primaryVariantColor} !important;
    }
  }

  .Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover {
    background-color: ${({theme}) => theme.onBackgroundVariant}1A;
    color: ${({theme}) => theme.onBackgroundVariant};
  }

  .Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus, .Calendar__monthYear > *.-activeBackground {
    background-color: ${({theme}) => theme.onBackgroundVariant}1A;
  }

  .Calendar__monthSelectorAnimationWrapper {
    height: 85%;
  }

  .Calendar__monthSelector, .Calendar__yearSelector {
    background-color: ${({theme}) => theme.background};
  }
  
  .Calendar__sectionWrapper {
    min-height: 30em !important;
  }
  
  .todayDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    border: 2px solid ${({theme}) => theme.primarySectionCalendar} !important;
    color: ${({theme}) => theme.primarySectionCalendar} !important;
  }
  .todayDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected)::after {
    background: none !important;
  }

  .Calendar__monthArrow {
    filter: ${ ({currentTheme}) => currentTheme === 'dark' ? 'invert(100%)' : null}
  }
`