import styled from "styled-components";

export const InvitePatientsModalDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  form {
    width: 350px;
    max-height: 606px;
    margin: auto;
    padding: 20px;
    .card-inputs {
      max-height: 420px;
      background-color: ${({theme}) => theme.background};
      padding: 40px;
      border-radius: 30px;
      box-shadow: 1px 1px 11px 5px ${({theme}) => theme.blackShadow}29;
      /* overflow: auto; */
      h4 {
        margin-bottom: 15px;
        font-size: 17px;
        font-family:"Nunito ExtraBold";
      }
      p {
        font-family: "Open Sans";
        font-size: .9rem;
        margin: 5px 0 10px;
      }
      .card-inputs-container {
        max-height: 380px;
        overflow: auto;
        &-toggle {
          display: flex;
          margin-bottom: 10px;
          p {
            margin: 10px 0 10px 10px;
          }
        }
        ::-webkit-scrollbar {
          display: none;
        }
      }
      .invitePatientsModal__msgContainer {
        background-color: var(--background-header-input);
        padding: 5px 5px 60px 5px;
        border-radius: 10px;
        margin-top: 10px;
        max-width: 310px;
        p {
          line-height: 1.2;
        }
      }
    }
    .card-buttons {
      padding-bottom: 20px;
      text-align: center;
      p {
        font-size: .9rem;
      }
    }
  }
  .sendBtn {
    background-color: var(--blue-color);
    border: none;
    border-radius: 20px;
    padding: 5px 80px;
    color: white;
    box-shadow: 3px 3px 5px var(--box-shadow);
    width: 308px;
    height: 42px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
`;

export const InputParagraphDiv = styled.div`
  display: flex;
  .text-area-input {
    width: 290px;
    height: 100px;
    overflow: auto;
    background-color: var(--background-header-input);
    border-radius: 10px;
    padding: 5px;
    display: flex;
    outline: none;
    border: none;
    resize: none;
    font-family: "Open Sans";
  }
`;