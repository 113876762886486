import { useState, useEffect } from 'react';

export default function useInfoToken() {
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const [expiration, setExpiration] = useState();
  const [creation, setCreation] = useState();

  useEffect(() => {
    getTokenInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTokenInfo = () => {
    try {
      let token = localStorage.getItem("accessToken");
      let dateNow = new Date(Date.now()).getTime();
      if(token) {
        setToken(token);
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
  
        const tokenDecoded = JSON.parse(jsonPayload);
        setUserId(tokenDecoded.sub);
        setExpiration(tokenDecoded.exp * 1000);
        setCreation(tokenDecoded.iat * 1000);
        if (dateNow > (tokenDecoded.exp * 1000)) {
          return null
        } else {
          return { token, userId: tokenDecoded.sub, expiration: tokenDecoded.exp * 1000, creation: tokenDecoded.iat * 1000}
        }
      } else {
        return null
      }
    } catch(e) {
      return null
    }
  }

  return { 
    token,
    userId,
    expiration,
    creation,
    getTokenInfo 
  };
}