import React, { useRef, useState, useEffect, useContext  } from "react";
import { useField } from "formik";
import * as S from "./ZaiaInputForm.style.js";
import * as P from "../../../utils/pipes";
import { ThemeContext } from 'styled-components';
import { useTranslation } from "react-i18next";

function ZaiaInputForm({ 
  placeholder, 
  icon,
  setErrors,
  padding,
  height,
  fontSize,
  textarea,
  errorOption = true,
  ...props
}) {  
  const {i18n: { language }} = useTranslation("global");
  const container = useRef(null);
  const textInput = useRef(null);
  const [field, meta, helpers] = useField(props);
  const [active, setActive] = useState(false);
  const [seePass, setSeePass] = useState(false);
  const theme = useContext(ThemeContext);
  const { setValue } = helpers;
  const [activedMic, setActivedMic] = useState(false);
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const [ recognition ] = useState(SpeechRecognition ? new SpeechRecognition() : null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const activeInput = () => {
    if (!props.disabled) {
      textInput.current.focus(); 
      setActive(true);
    } 
  } 

  const btnStopRecord = () => {
    recognition.stop();
    setActivedMic(false);
  };

  const btnStartRecord = () => {
    const languageSetting = P.getMicLanguage(language);
    recognition.lang = languageSetting;
    recognition.continuous = true;
    // recognition.interimResults = false;
    recognition.start();
    recognition.onresult = (event) => {
      const results = event.results;
      const frase = results[results.length - 1][0].transcript;
      console.log(field.value);
      if(field.value !== undefined) field.value += frase;
      else field.value = frase;
      setValue(field.value)
    }
    setActivedMic(true);
  };

  return (
    <S.ZaiaInputForm 
      padding={padding} 
      active={active} 
      error={meta} 
      disabled={props.disabled} 
      fontSize={fontSize}
      activedMic={activedMic}
    >
        <div className="input-container" onClick={() => activeInput()} ref={container}>
          <i className={'icon-input-form icon zaia-' + icon} style={{ color: active ? theme.inputSelected : theme.inputPlaceholder }} />
          {textarea && SpeechRecognition
            ? <>
              <div className='mic-container' onClick={() => !activedMic ? btnStartRecord() : btnStopRecord()}>
                <i className='mic-icon icon zaia-microphone'/>
              </div>
            </>
            : null
          }
          {
            textarea ? 
            <textarea 
              ref={textInput}
              {...field} 
              {...props}
              type={seePass ? "text" : props.type}
              placeholder={placeholder}
            /> :
            <input 
              ref={textInput}
              {...field} 
              {...props}
              type={seePass ? "text" : props.type}
              placeholder={placeholder} 
            />
          }
          {
            props.type === "password" && field?.value ? 
            <i className={'icon-input-pass icon zaia-' + (seePass ? "icono-visibility" : 'icono-invisible')} data-testid="button-icon-seepass" onClick={() => setSeePass(!seePass)} style={{ color: seePass ? theme.inputSelected : theme.inputPlaceholder }} />
            : null
          }
        </div>
        {
          errorOption ?
          <div className="input-error">{
            meta.touched && meta.error ? 
            <div>
              <i className="icon zaia-icono-ayuda"></i>
              {meta.error }
            </ div>
            : null}
          </div> : null
        }
    </S.ZaiaInputForm>
  );
}

export default ZaiaInputForm;
