import React from "react";
import * as S from "./DatetimeScatterChart.style";
import ReactApexChart from 'react-apexcharts';
import * as P from "../../../../../utils/pipes";
import { useTranslation } from "react-i18next";


const DatetimeScatterChart = ({
  dataChart = [],
  colors,
  minDate = new Date(Date.now()).getTime(),
  maxDate = new Date(Date.now() + 604800000).getTime(),
  chartYMax,
  chartYMin,
  labels
}) => {

  const [t] = useTranslation("global");

  // SERIES FORMAT
  // const seriesss = [{
  //     name: 'TEAM 1',
  //     data: generateDayWiseTimeSeries(new Date('11 Feb 2022 GMT').getTime(), 10, {
  //       min: 0,
  //       max: 3
  //     })
  //   },
  //   {
  //     name: 'TEAM 2',
  //     data: generateDayWiseTimeSeries(new Date('11 Feb 2022 GMT').getTime(), 10, {
  //       min: 0,
  //       max: 3
  //     })
  //   },
  //   {
  //     name: 'TEAM 3',
  //     data: generateDayWiseTimeSeries(new Date('11 Feb 2022 GMT').getTime(), 6, {
  //       min: 0,
  //       max: 3
  //     })
  //   },
  //   {
  //     name: 'TEAM 4',
  //     data: generateDayWiseTimeSeries(new Date('11 Feb 2022 GMT').getTime(), 10, {
  //       min: 0,
  //       max: 3
  //     })
  //   },
  //   {
  //     name: 'TEAM 5',
  //     data: generateDayWiseTimeSeries(new Date('11 Feb 2022 GMT').getTime(), 20, {
  //       min: 0,
  //       max: 3
  //     })
  //   },
  // ]

  const options = {
    colors: colors,
    chart: {
      height: 350,
      type: 'scatter',
      zoom: {
        type: 'xy'
      },
      defaultLocale: 'en/es',
      locales: [{
        name: 'en/es',
        options: {
          months: [
            P.nameMonth(0, 'L', t),
            P.nameMonth(1, 'L', t),
            P.nameMonth(2, 'L', t),
            P.nameMonth(3, 'L', t),
            P.nameMonth(4, 'L', t),
            P.nameMonth(5, 'L', t),
            P.nameMonth(6, 'L', t),
            P.nameMonth(7, 'L', t),
            P.nameMonth(8, 'L', t),
            P.nameMonth(9, 'L', t),
            P.nameMonth(10, 'L', t),
            P.nameMonth(11, 'L', t),
          ],
          shortMonths: [
            P.nameMonth(0, 'S', t),
            P.nameMonth(1, 'S', t),
            P.nameMonth(2, 'S', t),
            P.nameMonth(3, 'S', t),
            P.nameMonth(4, 'S', t),
            P.nameMonth(5, 'S', t),
            P.nameMonth(6, 'S', t),
            P.nameMonth(7, 'S', t),
            P.nameMonth(8, 'S', t),
            P.nameMonth(9, 'S', t),
            P.nameMonth(10, 'S', t),
            P.nameMonth(11, 'S', t),
          ],
          days: [
            P.nameDayWeek(0, 'L', t),
            P.nameDayWeek(1, 'L', t),
            P.nameDayWeek(2, 'L', t),
            P.nameDayWeek(3, 'L', t),
            P.nameDayWeek(4, 'L', t),
            P.nameDayWeek(5, 'L', t),
            P.nameDayWeek(6, 'L', t),
          ],
          shortDays: [
            P.nameDayWeek(0, 'S', t),
            P.nameDayWeek(1, 'S', t),
            P.nameDayWeek(2, 'S', t),
            P.nameDayWeek(3, 'S', t),
            P.nameDayWeek(4, 'S', t),
            P.nameDayWeek(5, 'S', t),
            P.nameDayWeek(6, 'S', t),
          ],
          toolbar: {
            download: t("dateTimeAreaChart.svg"),
            selection: t("dateTimeAreaChart.selection"),
            selectionZoom: t("dateTimeAreaChart.selectionZoom"),
            zoomIn: t("dateTimeAreaChart.zoomIn"),
            zoomOut: t("dateTimeAreaChart.zoomOut"),
            pan: t("dateTimeAreaChart.pan"),
            reset: t("dateTimeAreaChart.reset"),
          }
        }
      }]
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    xaxis: {
      type: 'datetime',
      min: minDate, // Where the 6 is the number of days
      max: maxDate, // Today
    },
    yaxis: {
      max: chartYMax,
      min: chartYMin,
      labels: {
        formatter: (val) => {
          return labels?.find(label => label.id === Math.floor(val)).name
        }
      },
      tickAmount: chartYMax - 1,
    },
    legend: {
      show: true,
      position: 'left',
      fontFamily: "Open Sans SemiBold"
    }
  }

  return (
    <S.DatetimeScatterChart>

      <ReactApexChart
        options={options}

        series={dataChart.map(dataChart => {
          return ({
            name: `<div style="
            display: flex;
            align-items: center;
            height: 4px;
            margin-top: 5px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.40);
            position:relative;
            ">
              <p style="
                position: relative;
                bottom: 3px;
                left:-25px ;
                width: 100%;
                height: 18px; 
                padding: 5px 40px;
                display: flex;
                align-items: center;
                margin-left: 20px;
                font-size  : 16px;
                margin-bottom:13px;
                margin-top:5px;
              ">
                ${dataChart.name} ( ${dataChart.data.length} )
              </p>
          </div>`,
            data: dataChart.data
          })
        })}
        type={"scatter"}
        height={'100%'}
        width={'100%'}
      />
    </S.DatetimeScatterChart>
  );
};

export default DatetimeScatterChart;