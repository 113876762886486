import React, { useState } from "react";
import * as S from "./SidebarPatients.style";
import { useTranslation } from "react-i18next";

// components
import ListOfPatients from "../../patients/components/listOfPatients";

function SidebarPatients({ 
  buttonHide, 
  children, 
  heightTop="100px", 
  defaultHide, 
  urlRedirection,
  processIdFilter,
}) {
  const [t] = useTranslation("global");
  const [hide, setHide] = useState(defaultHide ? defaultHide : false);
  const [processIds, setProcessIds] = useState([]);
  const [patientsQuantity, setPatientsQuantity] = useState(0);
  const [idInputFilter, setInputFilter] = useState('');

  const hideSection = () => {
    !hide ? setHide(true) : setHide(false);
  }

  return (
    <S.SidebarPatients heightTop={heightTop}>
      {
        hide ? null :
        <div className="sidebar-patient">
          <div className="sidebar-patient__top">     
            {
              React.isValidElement(children) ? 
                React.cloneElement(children, { 
                  processIds: processIds, 
                  setInputFilter: setInputFilter, 
                  setProcessIds: setProcessIds, 
                  quantity: patientsQuantity,
                  typeFilter: "number",
                  filterText: t("patients.doc"),
                  filterPlaceholder: t("patients.patientList.search")
                }) : null
            }
          </div>
          <div className="sidebar-patient__list" >
            <ListOfPatients 
              filterProccessIds={processIds} 
              idPatientFilter={idInputFilter}
              patientsQuantity={patientsQuantity} 
              setPatientsQuantity={setPatientsQuantity} 
              urlRedirection={urlRedirection}
              processIdFilter={processIdFilter}
            /> 
          </div>
        </div>
      }
      {
        buttonHide ? 
        ( <S.SidebarPatientsButton>
            <div
              style={{ cursor: "pointer" }}
              className="sidebarPatientsButton__icon"
              onClick={hideSection}
            >
              {
                !hide 
                ? <i className="sidebarPatientsButton__icon icon zaia-icon-ionic-md-arrow-dropdown" /> 
                : <i className="sidebarPatientsButton__icon icon zaia-icon-ionic-md-arrow-dropdown-1" />
              }
            </div>
          </S.SidebarPatientsButton> ) : null
      }
    </S.SidebarPatients>
  );
}

export default SidebarPatients;
