import styled from "styled-components";

export const AddEventsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: var(--white);
  padding: 20px 20px 0 20px;
  font-size: 0.8rem;
  border-left: 1px solid lightgray;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      color: var(--yellow-color);
      margin-bottom: 30px;
    }
    .addEvent__btn {
      background-color: var(--blue-color);
      border: none;
      border-radius: 20px;
      padding: 3px;
      color: white;
      box-shadow: 3px 3px 5px var(--box-shadow);
      cursor: pointer;
      margin: 23px 0 12px 0;
      width: 226px;
      height: 37px;
    }
    .addEvent__btn:focus {
      outline: none;
    }
    .addEvent__closeBtn {
      background-color: var(--white);
      border: 1px solid var(--blue-color);
      border-radius: 20px;
      padding: 3px;
      color: var(--blue-color);
      box-shadow: 3px 3px 5px var(--box-shadow);
      cursor: pointer;
      margin: 15px 0 14px 0;
      width: 226px;
      height: 37px;
    }
    .addEvent__closeBtn:focus {
      outline: none;
    }
    .addEvents__time {
      margin-top: 10px;
    }
    .addEvents__date {
      margin-bottom: 10px;
    }
  }
`;
