import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import ReactGA from "react-ga";

const env = process.env.REACT_APP_ENV;

const PublicRoute = ({ component: Component, authed, ...rest }) => {

  useEffect(() => {
    if (env === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, [Component])

  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{  pathname: "/dashboard", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
