import React, { useContext } from "react";
import * as S from "./ZaiaLoadingPoints.style";
import { ThemeContext } from 'styled-components';


function ZaiaLoadingPoints({ color }) {

  const theme = useContext(ThemeContext);

  return (
    <S.ZaiaLoadingPoints color={color ? color : theme.primaryColor}>
      <div className="dot-elastic"></div>
    </S.ZaiaLoadingPoints>
  )
}

export default ZaiaLoadingPoints;