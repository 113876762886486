import styled from "styled-components";

export const DasboardOutcomesByPatientDiv = styled.div`
  background-color: var(--background-patient);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  .checkboxes-header-container {
    display: flex;
    margin-bottom: 15px;
    .checkbox-container {
      margin-right: 15px;
    }
  }
  .graphs-outcomes-container {
    overflow-y: auto;
    height: calc(100% - 60px)
  }
`
