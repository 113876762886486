import React, { useContext } from "react";
import * as S from "./ZaiaReaction.style.js";
import { ThemeContext } from 'styled-components';

function ZaiaReaction({ 
  textColor, 
  text, 
  zaiaImg, 
  separation, 
  widthImg, 
  widthText, 
  sizeText 
}) {
  const theme = useContext(ThemeContext);

  return (
    <S.ZaiaReaction
      textColor={textColor || theme.onBackgroundTextSoft}
      separation={separation}
      widthImg={widthImg}
      widthText={widthText}
      sizeText={sizeText}
    >
      <img src={zaiaImg} alt={text}/>
      {
        text ? <h2>{text}</h2> : null
      }
    </S.ZaiaReaction>
  );
}

export default ZaiaReaction;
