import React, { useRef, useState, useEffect } from "react";
import { useField } from "formik";
import * as S from "./ZaiaSmallSelectForm.style.js";

function ZaiaSmallSelectForm({ 
  placeholder, 
  icon,
  setErrors,
  padding,
  items= [],
  ...props
}) {

  
  const container = useRef(null);
  const [field, , helpers] = useField(props);
  const [listActive, setlistActive] = useState(false);
  const { setValue } = helpers;
  
  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setlistActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  
  function handleChange(item) {
    setValue(item);
    setlistActive(false);
  }

  return (
    <S.ZaiaSmallSelectForm padding={padding} listActive={listActive} >
      <div  ref={container}>
        <div className="input-container" onClick={(e) => setlistActive(e)}>
          {field?.value ?
            <div className="text-container">
              <label>{items?.find(item => item.value === field.value)?.text}</label>
              <strong>{items?.find(item => item.value === field.value)?.textBold}</strong> 
            </div>
          : null
          }
          <i className="icon zaia-icono-dropdown"></i>
        </div>
        <div className="select-list">
          {
            items?.map(item => {
              return (
                <div className="select-item" key={item.value} onClick={() => handleChange(item.value)}>
                  <label>{item.text} </label>
                  <strong>{item.textBold}</strong>
                </div>
              )
            })
          }
        </div>
      </div>
    </S.ZaiaSmallSelectForm>
  );
}

export default ZaiaSmallSelectForm;
