import styled from "styled-components";

export const CustomRadioBoxDiv = styled.div`
  display: flex;
  align-items: center;
  .radio {
    width: 100px;
    display: flex;
    align-items: center;
  }
  .radio > input[type="radio"] {
    cursor: pointer;
    margin-right: 20px;
  }
`;
