import styled from "styled-components";

export const PhoneCodeDiv = styled.div`
  select {
    width: 84px;
    height: 40px;
    border: none;
    box-shadow: 1px 1px 8px 1px var(--box-shadow);
    border-radius: 10px;
    margin: 0 5px 0 0;
  }
`;
