import styled from "styled-components";
import { getHexOpacity } from "../../../../utils/pipes";
const background = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico.svg`;

export const Sidebar = styled.div` 
  flex: 0.19;
  background-color: ${({theme}) => theme.primaryColor};
  height: 100vh;
  .title-container {
    height: 80px;
    background-image: url(${background});
    background-repeat: no-repeat;
    margin-bottom: 40px;
    display: flex;
    .zaia-title {
      width: 150px;
      margin: auto 0 auto 20px;
    }
  }
  .sidebar-tabs-container {
    height: ${({selectedPatient}) => selectedPatient ? "calc(100% - 220px)" : "calc(100% - 120px)"};
    overflow-y: auto;
    .sidebarOption {
      display: flex;
      align-items: center;
      margin: 15px 26px;
      color: white;
      padding: 10px;
      font-size: larger;
    }
    .sidebar-tab-name {
      margin-left: 15px;
      font-size: 1rem;
      font-family: "Open Sans Bold";
      font-weight: 600;
    }
    .sidebar-tab-alarm {
      width: 10px;
      height: 10px;
      background-color: ${({theme}) => theme.warning};
      border-radius: 50%;
      margin-left: auto;
    }
    .icon-container {
      width: 25px;
      height: 26px;
      overflow: hidden;
      font-size: 24px;
      margin-right: 5px;
    }
    .sidebarOption:hover {
      background-color: ${({theme}) => theme.primaryVariantColor}${getHexOpacity(0.22)};
      border-radius: 10px;
      cursor: pointer;
    }
    .active {
      background-color: white;
      color: ${({theme}) => theme.primaryColor};
      border-radius: 10px;
    }
    .active:hover {
      background-color: white;
      color: ${({theme}) => theme.primaryColor};
      border-radius: 10px;
    }
  }

  .sidebar-selected-patient {
    height: 100px;
    margin: 0 18px;
    p {
      color: ${({theme}) => theme.onPrimaryColor};
      font-family: "Open Sans Bold";
    }
    .card-selected-patient {
      margin: 10px 0;
      background-color: ${({theme}) => theme.onPrimaryColorPrimary};
      border-radius: 10px;
      display: flex;
      padding: 5px;
      align-items: center;
      .card-selected-patient-image-container {

      }
      .card-selected-patient-info-container {
        justify-content: space-between;
        cursor: default;
        .card-selected-patient-name {
          font-family: "Nunito Bold";
          color: ${({theme}) => theme.onPrimaryColor};
          display: -webkit-box;
          max-width: 100%;
          max-height: 38px;
          /* height: 43px; */
          margin: 0 auto;
          font-size: 14px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .card-selected-patient-program {
          /* max-height: 15px;
          width: min-content;
          display: -webkit-box;
          font-size: 12px;
          color: var(--white);
          background-color: var(--blue-color-hover);
          padding: 0 3px 2px 3px;
          border-radius: 5px;
          overflow: hidden;
          text-overflow: ellipsis; */
          font-family: "Open Sans";
          color: ${({theme}) => theme.onPrimaryColor};
          display: -webkit-box;
          max-width: max-content;
          max-height: 15px;
          padding: 0 3px 2px 3px;
          font-size: 12px;
          background-color: ${({theme}) => theme.primaryVariantColor}${getHexOpacity(0.4)};
          border-radius: 5px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .card-selected-patient-close-patient {
        font-size: 12px;
        color: ${({theme}) => theme.onPrimaryColor};
        margin: 0 5px;
        cursor: pointer;
      }
    }
  }
`

export const ProcessNameContainer = styled.div` 
  background-color: ${({theme}) => theme.background};
  padding: 5px;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
`

export const ProcessName = styled.div` 
  font-size: 12px;
  font-family: "Nunito Bold";
  border-bottom: ${({last, theme}) => !last ? '1px solid ' + theme.lineSeparation : null};
  /* margin: 5px 0px; */
  padding: 2px 5px; 
  .card-selected-patient-process {
    color: ${({theme}) => theme.onBackgroundVariant} !important;
  }
`
