import React, { useMemo, useEffect, useState } from "react";
import * as S from "./VitalsignsBoxInfo.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// pipes
import * as P from "../../../../../../../utils/pipes";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_VITAL_SIGNS_SIMPLE } from "../../../../../../../graphql/queries/User";

// components
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import VitalSignTimeLineCard from "../../../../../shared/charts/vitalSignTimeLineCard";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;
const loadingZaia = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;

function VitalsignsBoxInfo({
  time,
  icon
}) {
  const { t, i18n: { language } } = useTranslation("global");
  const { patientID } = useParams();
  const { 
    data: userToVitalSigns, 
    loading: userToVitalSignsLoading 
  } = useQuery(
    QUERY_GET_USER_VITAL_SIGNS_SIMPLE, {
    variables: { 
      patientId: patientID, 
      startDate: new Date(time?.startDate), 
      finishDate: new Date(time?.finishDate) 
      }
    }
  );
  const [totalPercentage, setTotalPercentage] = useState(0);

  const color = {
    primary: "var(--red-color)",
    secundary: "var(--box-shadow-red-color)",
    dark: "var(--red-box-headercolor)",
  }

  // console.log(userToVitalSigns?.userToProcess[0].user.userToVitalSigns);

  const getListLevels = (range) => {
    const orderRange = range.slice().sort((a, b) => {return b - a});
    return [
      '>' + orderRange[0],
      orderRange[0] + ' - ' + orderRange[1],
      '<' + orderRange[1]
    ]
  }

  const getPercentageInRange = (arrayVitalSigns, range) => {
    const orderRange = range.slice().sort((a, b) => {return b - a});
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const quantityFeelings = arrayVitalSigns?.length;
    const sumFeelings = arrayVitalSigns?.reduce(reducer); 
    const average = (sumFeelings / quantityFeelings);
    const percentage = (orderRange[0] - average)/(orderRange[0] - orderRange[1]) * 100;
    return percentage;
  } 

  const filterDataByDay = async (info) => {
    // console.log("por días", info);
    let vitalSignDay = [];
    info?.forEach(vitalSign => {
      let vitalSignDayHistory = [];
      let vitalSignPercentage = [];
      let dateStartDay = new Date(time.startDate);
      let dateFinishDay = new Date(time.startDate);
      dateFinishDay.setHours(23,59,59,59);

      vitalSign?.userVitalSignHistories.forEach(infoItem => {
        let percentageInRange = getPercentageInRange([infoItem.measure1], vitalSign.comment.range);
        vitalSignPercentage.push(
          {
            day: P.hoursIn12format(new Date(infoItem.created_at).getHours(), new Date(infoItem.created_at).getMinutes()),
            percentage: percentageInRange > 100 ? 100 : percentageInRange
          }
        )
      }); 

      vitalSign?.userVitalSignHistories.forEach(vitalSignHistory => {
        vitalSignDayHistory.push(
          [new Date(vitalSignHistory.created_at).getTime() - 18000000, P.convertVitalSign(vitalSign.value, vitalSignHistory.measure1, vitalSignHistory.unit)]
        )
      });

      let historieslength = vitalSign.userVitalSignHistories.length;
      let lastReportDate = historieslength !== 0 ? new Date(vitalSign.userVitalSignHistories[`${historieslength-1}`].created_at).getTime() : 0;
      
      vitalSignDay.push(
        {
          id: vitalSign.value,
          name: P.dataTranslation(vitalSign.comment, language),
          // frequency: vitalSign.frequency,
          // frequencyType: vitalSign.frequencyType,
          vitalSignPercentage: vitalSignPercentage,
          vitalSignToChart: vitalSignDayHistory,
          listLevels: getListLevels(vitalSign.comment.range),
          minDate: dateStartDay.getTime(),
          maxDate: dateFinishDay.getTime(),
          unit: vitalSign.comment.unit[0],
          lastReportDate: lastReportDate
        }
      )
    });
    getTotalPercentage(vitalSignDay);
    return vitalSignDay;
  }

  const filterDataByWeek = async (info) => {

    let vitalSignWeek = [];
    info?.forEach(vitalSign => {
      let vitalSignDayHistory = [];
      let vitalSignPercentage = [];
      const infoJoined = timesJoined(vitalSign?.userVitalSignHistories);
      // console.log("reunida", infoJoined);
      let dateStartWeek = new Date(time.startDate);
      let dateFinishWeek = new Date(time.startDate);
      let dateStartWeekTemp = new Date(time.startDate);
      let dateFinishWeekTemp = new Date(time.startDate);
      dateFinishWeek.setDate(dateFinishWeek.getDate() + 7);
      dateFinishWeekTemp.setDate(dateFinishWeekTemp.getDate() + 7);

      while(dateStartWeekTemp.getTime() !== dateFinishWeek.getTime()) {
        let dateFiltered = infoJoined.filter(info => new Date(info.created_at).getDate() === new Date(dateStartWeekTemp).getDate());
        let percentageInRange = getPercentageInRange(dateFiltered[0]?.measures, vitalSign.comment.range);
        let percentage = dateFiltered.length ? percentageInRange > 100 ? 100 : percentageInRange : 0;
        vitalSignPercentage.push(
          {
            day: new Date(dateStartWeekTemp).getDate(),
            dayName: P.nameDayWeek(new Date(dateStartWeekTemp).getDay(), 'S', t),
            percentage: percentage,
          }
        )
        dateStartWeekTemp.setDate(dateStartWeekTemp.getDate() + 1);
      }

      vitalSign?.userVitalSignHistories.forEach(vitalSignHistory => {
        vitalSignDayHistory.push(
          [new Date(vitalSignHistory.created_at).getTime() - 18000000, P.convertVitalSign(vitalSign.value, vitalSignHistory.measure1, vitalSignHistory.unit)]
        )
      });

      
      let historieslength = vitalSign.userVitalSignHistories.length;
      let lastReportDate = historieslength !== 0 ? new Date(vitalSign.userVitalSignHistories[`${historieslength-1}`].created_at).getTime() : 0;

      vitalSignWeek.push(
        {
          id: vitalSign.value,
          name: P.dataTranslation(vitalSign.comment, language),
          // frequency: vitalSign.frequency,
          // frequencyType: vitalSign.frequencyType,
          vitalSignToChart: vitalSignDayHistory,
          vitalSignPercentage: vitalSignPercentage,
          listLevels: getListLevels(vitalSign.comment.range),
          minDate: dateStartWeek.getTime(),
          maxDate: dateFinishWeek.getTime(),
          unit: vitalSign.comment.unit[0],
          lastReportDate: lastReportDate
        }
      )
    });
    // console.log("transformada week", vitalSignDay);
    getTotalPercentage(vitalSignWeek);
    return vitalSignWeek;
  }

  const filterDataByMonth = async (info) => {

    let vitalSignMonth = [];
    info?.forEach(vitalSign => {
      let vitalSignPercentage = [];
      let vitalSignDayHistory = [];
      const infoJoined = timesJoined(vitalSign?.userVitalSignHistories);
      // console.log("reunida", infoJoined);
      let dateStartMonth = new Date(time.startDate);
      let dateFinishMonth = new Date(time.startDate);
      let dateStartMonthTemp = new Date(time.startDate);
      let dateFinishMonthTemp = new Date(time.startDate);
      const numberDaysMonth = new Date(dateStartMonth.getFullYear(), dateStartMonth.getMonth() + 1, 0).getDate();
      dateFinishMonth.setDate(dateFinishMonth.getDate() + numberDaysMonth);
      dateFinishMonthTemp.setDate(dateFinishMonthTemp.getDate() + numberDaysMonth);

      while(dateStartMonthTemp.getTime() !== dateFinishMonthTemp.getTime()) {
        let dateFiltered = infoJoined.filter(info => new Date(info.created_at).getDate() === new Date(dateStartMonthTemp).getDate());
        let percentageInRange = getPercentageInRange(dateFiltered[0]?.measures, vitalSign.comment.range);
        let percentage = dateFiltered.length ? percentageInRange > 100 ? 100 : percentageInRange : 0;
        vitalSignPercentage.push(
          {
            percentage: percentage,
          }
        )
        dateStartMonthTemp.setDate(dateStartMonthTemp.getDate() + 1);
      }

      vitalSign?.userVitalSignHistories.forEach(vitalSignHistory => {
        vitalSignDayHistory.push(
          [new Date(vitalSignHistory.created_at).getTime() - 18000000, P.convertVitalSign(vitalSign.value, vitalSignHistory.measure1, vitalSignHistory.unit)]
        )
      });

      let historieslength = vitalSign.userVitalSignHistories.length;
      let lastReportDate = historieslength !== 0 ? new Date(vitalSign.userVitalSignHistories[`${historieslength-1}`].created_at).getTime() : 0;

      vitalSignMonth.push(
        {
          id: vitalSign.value,
          name: P.dataTranslation(vitalSign?.comment, language),
          // frequency: vitalSign.frequency,
          // frequencyType: vitalSign.frequencyType,
          vitalSignPercentage: vitalSignPercentage,
          vitalSignToChart: vitalSignDayHistory,
          listLevels: getListLevels(vitalSign?.comment.range),
          minDate: dateStartMonth.getTime(),
          maxDate: dateFinishMonth.getTime(),
          unit: vitalSign.comment.unit[0],
          lastReportDate: lastReportDate
        }
      )
    });
    // console.log("transformada week", vitalSignMonth);
    getTotalPercentage(vitalSignMonth);
    return vitalSignMonth;
  }

  const timesJoined = (info) => {
    let filteredVitalSigns = []
    info?.forEach(vitalSign => {
      const searchVitalSign = filteredVitalSigns.find(vital => new Date(vital.created_at).getDate() === new Date(vitalSign.created_at).getDate() );
      if (!searchVitalSign) {
        const newVital = { created_at: vitalSign.created_at, measures: [vitalSign.measure1] };
        filteredVitalSigns.push(newVital);
      } else {
        const vitalIndex = filteredVitalSigns.indexOf(searchVitalSign);
        let measuresArray = filteredVitalSigns[`${vitalIndex}`].measures;
        measuresArray.push(vitalSign.measure1);
        filteredVitalSigns[`${vitalIndex}`].measures = measuresArray;
      }
    });
    return (filteredVitalSigns);
  }

  const getTotalPercentage = (vitalSignData) => {
    // console.log(medicinesData);
    let quantityDays = 0;
    let quantityDaysTotal = 0;
    const currentDate = new Date(time.startDate);
    const quantityDaysByMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

    vitalSignData.forEach( vitalSign => {
      quantityDays += (
        (time.timeOp === 'day') ? ( vitalSign.vitalSignPercentage.length > 0 ? 1 : 0 ) :
        vitalSign.vitalSignPercentage.filter(history => history.percentage > 0).length
      )
      quantityDaysTotal += (
        ( time.timeOp === 'month') ? quantityDaysByMonth :
        ( time.timeOp === 'week' ) ?  7 : 1
      )
    });
    
    const totalPercentage = P.getPercentage(quantityDays, quantityDaysTotal);
    setTotalPercentage(totalPercentage);
  }


  const infoVitalSigns = useMemo(() => {
    const info = userToVitalSigns?.enVitalSign;
    if(time?.timeOp === 'day') return filterDataByDay(info);
    if(time?.timeOp === 'week') return filterDataByWeek(info);
    if(time?.timeOp === 'month') return filterDataByMonth(info);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToVitalSigns, time])  

  return (
    <S.VitalsignsBoxInfo>
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left">
          {
            <VitalSignsList 
              vitalSingsInfo={infoVitalSigns}
              time={time}
              loading={userToVitalSignsLoading}
              color={color}
            />
          }
        </div>
        <div className="boxInfoPatient__info-right">
          <CircleChartCard 
            icon={icon} 
            title={t("patients.boxInfoPatient.report.vitalsigns")}
            percentagePrincipal={totalPercentage}
            textPrincipal={t("patients.boxInfoPatient.report.reported")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div>
      </div>
    </S.VitalsignsBoxInfo>
  );
}

const VitalSignsList = ({vitalSingsInfo, time, loading, color}) => {
  const [infoVitalSigns, setinfoVitalSigns] = useState();
  const [t] = useTranslation("global");

  useEffect(() => {
    vitalSingsInfo?.then((info) => {
      let tempVitalSings = []
      let pressureVitalSign = null;
      info.forEach(vitalSign => {
        if(vitalSign.id === "pressure" || vitalSign.id === "pressuredias") {
          if(!pressureVitalSign) {
            pressureVitalSign = vitalSign;
          } else {

            pressureVitalSign.unit = [`${pressureVitalSign.name} (${pressureVitalSign.unit})`, `${vitalSign.name} (${vitalSign.unit})`];
            pressureVitalSign.name = t("patients.boxInfoPatient.vitalSigns.pressure");
            pressureVitalSign.vitalSignToChart = [pressureVitalSign.vitalSignToChart, vitalSign.vitalSignToChart];
            pressureVitalSign.multipleChart = true;
            tempVitalSings.push(pressureVitalSign);

          }
        } else {
          tempVitalSings.push(vitalSign);
        }
      });
      setinfoVitalSigns(tempVitalSings);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vitalSingsInfo])
  
  
  if (loading) return (
    <>
      <ZaiaReaction
        zaiaImg={loadingZaia}
        widthImg="100px"
        sizeText="15px"
        widthText="240px"
        />
    </>
  )
  
  if (infoVitalSigns?.length === 0) return (
    <>
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthImg="100px"
        text={t('globally.noReports')}
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  return (
    <>
      {
        infoVitalSigns?.sort(function(a, b){return b.lastReportDate - a.lastReportDate}).map(vitalSign => {

          // console.log("multipleChart", vitalSign.multipleChart);
          // console.log("minDate", vitalSign.minDate);
          // console.log("maxDate", vitalSign.maxDate);
          // console.log("vitalSignToChart", vitalSign.vitalSignToChart);
          return (
            <VitalSignTimeLineCard
              key={vitalSign.id}
              colorSecundary={color.secundary}
              colorPrimary={color.dark}
              colorVariation={color.primary}
              name={vitalSign.name}
              frequency={vitalSign.frequency}
              frequencyType={vitalSign.frequencyType}
              chartInfo={vitalSign.vitalSignToChart}
              listLevels={vitalSign.listLevels}
              time={time}
              listLevelsStripe={false}
              minDate={vitalSign.minDate}
              maxDate={vitalSign.maxDate}
              unit={vitalSign.unit}
              multipleChart={vitalSign.multipleChart ? vitalSign.multipleChart : false}
            />         
          )
        })
      }
    </>
  )
}

export default VitalsignsBoxInfo;
