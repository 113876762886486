import React from "react";
import * as S from "./Message.style"

function Message({ message, option, time, date, senderName, children }) {
  return (
    <S.Message time={time} option={option}>
      {
        senderName ? 
        <div className="message__sender-name">{senderName}</div> : null
      }
      { option === 'doctor' ? 
        <div className="message message--patient" time={time}>
          {children ? children : message}
        </div> :
        <div className="message message--reciever">
          {children ? children : message} 
        </div>
      }
      {
        time && date ? 
        <div className="message__time">{time} • {date}</div> : null
      }
    </S.Message>
  );
}

export default Message;
