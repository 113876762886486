import React from "react";
import * as S from "./Face.style";

function Face({ btnColor, percent, btn, img, hide, flex }) {
  return (
    <S.Face btnColor={btnColor} hide={hide} flex={flex}>
      <div>
        <img alt="face" src={img} />
        <h4>{percent}%</h4>
      </div>
      <button>{btn}</button>
    </S.Face>
  );
}

export default Face;
