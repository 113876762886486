import styled from "styled-components";

export const SymptomsBoxInfo = styled.div`
  .boxInfoPatient__info {
    display: flex;
    
    &-left {
      width: 25%;
      padding-right: 10px;
      display: flex;
    }

    &-center {
      width: 35%;
      padding: 0 10px 0 10px;
    }

    &-right {
      width: 40%;
      padding-left: 10px;
    }
  }
`;