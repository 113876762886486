export const initialState = {
  stepOne: undefined,
  stepTwo: undefined,
  stepThree: undefined,
  stepFour: undefined,
  stepFive: undefined,
  stepSix: undefined,
  stepSeven: undefined,
  lastStep: undefined,
  
  isAuth: false,
  popUp: {
    isActive: false,
    modal: () => <></>
  },
  colorTheme: 'light',
  selectedPatient: undefined,
  doctorData: undefined
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTH":
      return {
        ...state,
        isAuth: action.isAuth,
      };
    case "SET_POP_UP":
      return {
        ...state,
        popUp: action.popUp,
      };
    case "SET_COLOR_THEME":
      return {
        ...state,
        colorTheme: action.colorTheme,
      };
    case "SET_SELECTED_PATIENT":
      return {
        ...state,
        selectedPatient: action.selectedPatient,
      };
    case "SET_DOCTOR_DATA":
      return {
        ...state,
        doctorData: action.doctorData,
      };
    case "SET_STEPONE":
      return {
        ...state,
        stepOne: action.stepOne,
      };
    case "SET_STEPTWO":
      return {
        ...state,
        stepTwo: action.stepTwo,
      };
    case "SET_STEPTHREE":
      return {
        ...state,
        stepThree: action.stepThree,
      };
    case "SET_STEPFOUR":
      return {
        ...state,
        stepFour: action.stepFour,
      };
    case "SET_STEPFIVE":
      return {
        ...state,
        stepFive: action.stepFive,
      };
    case "SET_STEPSIX":
      return {
        ...state,
        stepSix: action.stepSix,
      };
    case "SET_STEPSEVEN":
      return {
        ...state,
        stepSeven: action.stepSeven,
      };
    case "SET_LASTSTEP":
      return {
        ...state,
        lastStep: action.lastStep,
      };
    default:
      return state;
  }
};

export default reducer;
