import styled from "styled-components";
const waveTop = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-negra.svg`;

export const ProfileHeaderDiv = styled.div`
  width: 100%;
  height: 100%; 
  display: flex;
  flex-direction: column;
  padding: 35px 40px;
  box-sizing: border-box;
  .card-doctor {
    background-color: var(--skyblue-dark);
    background-image: url(${waveTop});
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    background-position-y: -120px;
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    border-radius: 20px;
    height: 300px;
    align-items: center;
    color: var(--white);
    box-shadow: 2px 4px 16px -5px var(--skyblue-dark);
    /* .image-container {
      align-self: center;
    } */
    /* .options {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 5px;
      font-size: 13px;
      font-family: 'Open Sans regular';
      padding: 10px 0;
      &__photo {
        cursor: pointer;
        i {
          font-size: 13px;
          margin-right: 5px;
        }
        &--edit {
          color: var(--skyblue-box-headercolor);
        }
        &--delete {
          color: var(--red-color);
        }
      }
    } */
    .image-container {
      justify-content: center;
      margin: 40px auto;
      .image-change {
        position: relative;
        .button-change-image {
          position: absolute;
          border-radius: 50%;
          background-color: var(--skyblue-color);
          font-size: 15px;
          width: 40px;
          height: 40px;
          box-shadow: 0px 3px 10px #1f1f5559;
          bottom: -17px;
          right: -5px;
          color: var(--background-header-input);
          display: flex;
          cursor: pointer;
          font-size: 20px;
          i {
            margin: auto 9px 3px auto;
          }
        }
      }
    }
    h4 {
      /* font-family: "Nunito Bold"; */
    }
    span {
      /* font-family: "Open Sans"; */
      margin-top: 4px;
      font-size: 12px; 
    }
  }
  .additional-settings {
    padding: 50px 25px;
    
    h3 {
      color: var(--skyblue-dark);
      font-family: "Nunito Extrabold";
    }
    .setting-box {
      margin-top: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      .setting-box-icon {
        padding: 4px 6px 0px;
        font-size: 20px;
        border-radius: 5px;
        color: var(--white);
        background-color: var(--skyblue-dark);
        margin-right: 10px;
        box-shadow: 2px 3px 11px -4px var(--skyblue-dark);
      }
      .setting-box-text {
        font-size: 17px;
      }
    }
  }
`;
