import styled from "styled-components";

export const ChangePassDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  form {
    width: 350px;
    height: 415px;
    margin: auto;
    padding: 20px;
    .card-inputs {
      background-color: var(--white);
      padding: 30px;
      border-radius: 30px;
      box-shadow: 1px 1px 11px 5px var(--box-shadow);
      h4 {
        margin: 20px 0 30px;
        font-family:"Nunito ExtraBold";
        text-align: center;
        color: var(--blue-color);
      }
      .space-between {
        height: 12px;
      }
      .invitePatientsModal__msgContainer {
        background-color: var(--background-header-input);
        padding: 5px 5px 60px 5px;
        border-radius: 10px;
        margin-top: 10px;
        max-width: 310px;
        p {
          line-height: 1.2;
        }
      }
    }
    .card-buttons {
      padding-bottom: 20px;
      .cancel-button {
        text-align: center;
        font-family: "Open Sans";
        margin-top: 30px;
        color: var(--blue-color);
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
`;
