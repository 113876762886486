import React from "react";
import { Link } from "react-router-dom";
import { HeaderRegisterDiv, HeaderRegisterSkipDiv } from "./style";
import PropTypes from "prop-types";
import { Routes } from "../../../../../utils/constants";
import { useTranslation } from "react-i18next";

function Header({
  setActiveStep,
  stepOne,
  stepTwo,
  stepThree,
  stepFour,
  stepFive,
  stepSix,
  stepSeven,
  lastStep,
}) {
  const { HOME } = Routes;
  const [t] = useTranslation("global");

  return (
    <>
      {stepOne && (
        <HeaderRegisterDiv>
          <Link to={HOME.route} style={{ color: "white" }}>
            <div className="icon zaia-icono-atras"></div>
          </Link>
          <p>{t("register.title")}</p>
        </HeaderRegisterDiv>
      )}
      {stepTwo && (
        <HeaderRegisterDiv>
          <div onClick={() => setActiveStep(15)} style={{ cursor: "pointer" }}>
            <div className="icon zaia-icono-atras"></div>
          </div>
          <p>{t("register.title")}</p>
        </HeaderRegisterDiv>
      )}
      {stepThree && (
        <HeaderRegisterDiv>
          <div onClick={() => setActiveStep(30)} style={{ cursor: "pointer" }}>
            <div className="icon zaia-icono-atras"></div>
          </div>
          <p>{t("register.title")}</p>
        </HeaderRegisterDiv>
      )}
      {stepFour && (
        <HeaderRegisterSkipDiv>
          <div onClick={() => setActiveStep(45)} style={{ cursor: "pointer" }}>
            <div className="icon zaia-icono-atras"></div>
          </div>
          <p>{t("register.title")}</p>
          <p onClick={() => setActiveStep(0)} style={{ cursor: "pointer" }}>
            {t("register.skip")}
          </p>
        </HeaderRegisterSkipDiv>
      )}
      {stepFive && (
        <HeaderRegisterSkipDiv>
          <div onClick={() => setActiveStep(60)} style={{ cursor: "pointer" }}>
            <div className="icon zaia-icono-atras"></div>
          </div>
          <p>{t("register.title")}</p>
          <p onClick={() => setActiveStep(0)} style={{ cursor: "pointer" }}>
            {t("register.skip")}
          </p>
        </HeaderRegisterSkipDiv>
      )}
      {stepSix && (
        <HeaderRegisterSkipDiv>
          <div onClick={() => setActiveStep(75)} style={{ cursor: "pointer" }}>
            <div className="icon zaia-icono-atras"></div>
          </div>
          <p>{t("register.title")}</p>
          <p onClick={() => setActiveStep(0)} style={{ cursor: "pointer" }}>
            {t("register.skip")}
          </p>
        </HeaderRegisterSkipDiv>
      )}
      {stepSeven && (
        <HeaderRegisterSkipDiv>
          <div onClick={() => setActiveStep(90)} style={{ cursor: "pointer" }}>
            <div className="icon zaia-icono-atras"></div>
          </div>
          <p>{t("register.title")}</p>
          <p onClick={() => setActiveStep(0)} style={{ cursor: "pointer" }}>
            {t("register.skip")}
          </p>
        </HeaderRegisterSkipDiv>
      )}
      {lastStep && (
        <HeaderRegisterDiv>
          <div onClick={() => setActiveStep(100)} style={{ cursor: "pointer" }}>
            <div className="icon zaia-icono-atras"></div>
          </div>
          <p>{t("register.title")}</p>
        </HeaderRegisterDiv>
      )}
    </>
  );
}

Header.propTypes = {
  setActiveStep: PropTypes.func,
  stepOne: PropTypes.bool,
  stepTwo: PropTypes.bool,
  stepThree: PropTypes.bool,
  stepFour: PropTypes.bool,
  stepFive: PropTypes.bool,
  stepSix: PropTypes.bool,
  stepSeven: PropTypes.bool,
  lastStep: PropTypes.bool,
};

Header.defaultProps = {
  setActiveStep: undefined,
  stepOne: undefined,
  stepTwo: undefined,
  stepThree: undefined,
  stepFour: undefined,
  stepFive: undefined,
  stepSix: undefined,
  stepSeven: undefined,
  lastStep: undefined,
};

export default Header;
