import { Field } from "formik";
import React from "react";
import { CustomRadioBoxDiv } from "./style";

function CustomRadioBox(props) {
  const { name, options, disabled, ...rest } = props;
  return (
    <CustomRadioBoxDiv>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <div key={option.key} className="radio">
                <input
                  type="radio"
                  id={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value === option.value}
                  disabled={disabled}
                />
                <label htmlFor={option.value}>{option.key}</label>
              </div>
            );
          });
        }}
      </Field>
    </CustomRadioBoxDiv>
  );
}

export default CustomRadioBox;
