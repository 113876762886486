import styled from "styled-components";

export const CmpSelect = styled.div`
  display: ${({inline}) => inline ? "flex" : "block"}};
  align-items: ${({inline}) => inline ? "center" : "auto"}};
  justify-content: ${({inline}) => inline ? "space-between" : "none"}};
  h5 {
    font-family: "Open Sans Bold";
  }
  i {
    margin-left: 5px;
  }
  .select-container {
    width: ${({inline}) => inline ? "150px" : "100%"}};
    margin-left: ${({inline}) => inline ? "10px" : "0"}};
  }
`;
