// import React, { useState } from "react";
// import Calendar from "react-calendar";
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css'; 

// function Calendario() {
  //   const [value, onChange] = useState(new Date());
  
  //   return (
    //     <div>
    //       <Calendar onChange={onChange} value={value} />
    //     </div>
    //   );
    // }
    
    // export default Calendario;
    
import React, { useState, useContext } from "react";
import { ThemeContext } from 'styled-components';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';

// import 'react-modern-calendar-datepicker/lib/DatePicker.css';
// import { Calendar } from 'react-modern-calendar-datepicker';

import { useStateValue } from "../../../../../contextAPI/StateProvider";
import * as S from "./CalendarDashboard.style";
import * as P from "../../../../../utils/pipes";
import { useTranslation } from "react-i18next";

const CalendarDashboard = ({
  initialDate,
  finishDate,
  setInitialDate,
  setFinishDate,
}) => {
  const dateNow = new Date(Date.now());
  const theme = useContext(ThemeContext);
  const [{ colorTheme }] = useStateValue();
  const [t] = useTranslation("global");
  const defaultFrom = null;
  const defaultTo = null;
  const defaultValue = {
    from: defaultFrom,
    to: defaultTo,
  };
  const [selectedDayRange, setSelectedDayRange] = useState(
    defaultValue
  );

  const myCustomLocale = {
    // months list by order
    months: [
      P.nameMonth(0, 'L', t),
      P.nameMonth(1, 'L', t),
      P.nameMonth(2, 'L', t),
      P.nameMonth(3, 'L', t),
      P.nameMonth(4, 'L', t),
      P.nameMonth(5, 'L', t),
      P.nameMonth(6, 'L', t),
      P.nameMonth(7, 'L', t),
      P.nameMonth(8, 'L', t),
      P.nameMonth(9, 'L', t),
      P.nameMonth(10, 'L', t),
      P.nameMonth(11, 'L', t),
    ],
  
    // week days by order
    weekDays: [
      {
        name: P.nameDayWeek(0, 'L', t),
        short: P.nameDayWeek(0, 'S', t),
        isWeekend: true, // is it a formal weekend or not?
      },
      {
        name: P.nameDayWeek(1, 'L', t),
        short: P.nameDayWeek(1, 'S', t),
      },
      {
        name: P.nameDayWeek(2, 'L', t),
        short: P.nameDayWeek(2, 'S', t),
      },
      {
        name: P.nameDayWeek(3, 'L', t),
        short: P.nameDayWeek(3, 'S', t),
      },
      {
        name: P.nameDayWeek(4, 'L', t),
        short: P.nameDayWeek(4, 'S', t),
      },
      {
        name: P.nameDayWeek(5, 'L', t),
        short: P.nameDayWeek(5, 'S', t),
      },
      {
        name: P.nameDayWeek(6, 'L', t),
        short: P.nameDayWeek(6, 'S', t),
        isWeekend: true,
      },
    ],
  
    // just play around with this number between 0 and 6
    weekStartingIndex: 0,
  
    // return a { year: number, month: number, day: number } object
    getToday(gregorainTodayObject) {
      return gregorainTodayObject;
    },
  
    // return a native JavaScript date here
    toNativeDate(date) {
      return new Date(date.year, date.month - 1, date.day);
    },
  
    // return a number for date's month length
    getMonthLength(date) {
      return new Date(date.year, date.month, 0).getDate();
    },
  
    // return a transformed digit to your locale
    transformDigit(digit) {
      return digit;
    },
  
    // texts in the date picker
    nextMonth: 'Next Month',
    previousMonth: 'Previous Month',
    openMonthSelector: 'Open Month Selector',
    openYearSelector: 'Open Year Selector',
    closeMonthSelector: 'Close Month Selector',
    closeYearSelector: 'Close Year Selector',
    defaultPlaceholder: 'Select...',
  
    // for input range value
    from: 'from',
    to: 'to',
  
  
    // used for input value when multi dates are selected
    digitSeparator: ',',
  
    // if your provide -2 for example, year will be 2 digited
    yearLetterSkip: 0,
  
    // is your language rtl or ltr?
    isRtl: false,
  }
  
  const handleCalendarSelect = (e) => {
    const dates = e;
    if(dates.to && dates.from) {
      if (JSON.stringify(e.to) === JSON.stringify(e.from)) {
        dates.to = null;
        dates.from = null;
        setInitialDate(null);
        setFinishDate(new Date(Date.now()));
      } else {
        setInitialDate(new Date(dates.from.year, dates.from.month-1, dates.from.day, 0,0,0));
        setFinishDate(new Date(dates.to.year, dates.to.month-1, dates.to.day, 23,59,59));
      }
    } else {
      setInitialDate(new Date(dates.from.year, dates.from.month-1, dates.from.day, 0,0,0));
      setFinishDate(new Date(dates.from.year, dates.from.month-1, dates.from.day, 23,59,59));
    }
    setSelectedDayRange(dates);
  }


  return (
    <S.CalendarDashboard range={selectedDayRange} currentTheme={colorTheme}>
      <Calendar
        value={selectedDayRange}
        onChange={(e) => handleCalendarSelect(e)}
        colorPrimary={theme.primarySectionCalendar}
        colorPrimaryLight={theme.primarySectionCalendar + '29'}
        customDaysClassName={[
          { year: dateNow.getFullYear(), month: dateNow.getMonth()+1, day: dateNow.getDate(), className: 'todayDay' },
        ]}
        locale={myCustomLocale}
      />
    </S.CalendarDashboard>
  );
};

export default CalendarDashboard;