import styled from "styled-components";

export const HeaderRegisterDiv = styled.div`
  background-color: var(--blue-color);
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  padding: 30px 5px 5px 10px;
  p {
    margin-left: 265px;
  }
`;
export const HeaderRegisterSkipDiv = styled.div`
  background-color: var(--blue-color);
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: stretch;
  padding: 30px 5px 5px 10px;
  justify-content: space-between;
  p:nth-child(3) {
    margin-right: 8px;
  }
`;
