import styled from "styled-components";

export const PatientBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid var(--hover-box);
  cursor: pointer;
  color: ${({active}) => active ? "var(--white)" : ""};
  background-color: ${({active}) => active ? 'var(--blue-color)' : 'var(--white)' };
  &:hover {
    background-color: ${({active}) => active ? "" : "var(--hover-box)"};
  } 
  div {
    p {
      font-size: 0.67rem;
    }
    p:nth-child(2) {
      color: ${({active}) => active ? "var(--white)" : "var(--subtitle-color)"};
      margin: 2px 0;
    }
  }
`;