import styled from "styled-components";

export const YesOrNo = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  
  .yesOrNo-states {
    margin: 0 0 0 auto;
    width: 150px;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    .yesOrNo-state {
      cursor: pointer;
      height: 22px;
      border-radius: 13px;
      width: 47%;
      color: var(--white);
      font-family: "Nunito";
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;   
      background-color: var(--text-area-grey);   
      &--selected {
        box-shadow: 0px 3px 6px var(--box-shadow);
        background-color: var(--blue-color);    
      }
    }
  }
`
