import React, { useEffect } from "react";
import * as S from "./ListOfChats.style";
import { useTranslation } from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

// Components
import OneSingleChat from "../OneSingleChat";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Pipes 
import * as P from "../../../../../utils/pipes";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;

function ListOfChats({
  setQuantityChats, 
  setSelectedChat, 
  groupChatsOrderRecent,  
  groupChatsLoading, 
  groupChatsError,
  filterText,
  filterProccessIds
}) {
  const {t, i18n: { language }} = useTranslation("global");
  const { chatID } = useParams();
  const history = useHistory();
  const location = history.location.pathname.split('/')[1];

  useEffect(() => {
    setQuantityChats(groupChatsOrderRecent?.length);
  },[groupChatsOrderRecent, setQuantityChats, chatID])

  const handleClick = (group) => {
    history?.push(`/${location}/` + group.id);
    setSelectedChat(group);
  }

  if(groupChatsLoading) 
    return (
      <S.ListOfGroups>
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        />  
      </S.ListOfGroups>
    );

  if(groupChatsError) 
    return (
      <S.ListOfGroups>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="100px"
          text={t("chat.errorLoadingChats")}
          sizeText="15px"
          widthText="240px"
        />
      </S.ListOfGroups>
    );

  if(groupChatsOrderRecent?.length < 1) 
    return (
      <S.ListOfGroups>
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthImg="100px"
          text={t("chat.emptyChats")}
          sizeText="15px"
          widthText="200px"
        />
      </S.ListOfGroups>
    );

  return (
    <S.ListOfGroups>
      {
        groupChatsOrderRecent?.map(group => {

          if (
            filterText ? 
            String(group.nameChat).toLowerCase().includes(filterText.toLowerCase())  : 
            true
          ) {
            if( filterProccessIds?.length > 0) {
              if (
                filterProccessIds?.indexOf(group.userToProcess.processId) !== -1
              ) {
                return (
                  <div className="chat-box" 
                    key={group.id}
                    onClick={() => handleClick(group)}>
                    <OneSingleChat
                      active={group.id === chatID ? true : false}
                      img={group.chatPhotoUser}
                      patient={group.chatNameUser}
                      processName={P.dataTranslation(group.userToProcess.process.processName, language)}
                      time={group.messages[0]?.createdAt ? P.getDynamicTime(group.messages[0]?.createdAt, t) : null}
                      numMsg={null}
                      message={group.messages[0]?.message}
                      online
                    />
                  </div>
                ) 
              } else return null
            } else {
              return (
                <div className="chat-box" 
                  key={group.id}
                  onClick={() => handleClick(group)}>
                  <OneSingleChat
                    active={group.id === chatID ? true : false}
                    img={group.chatPhotoUser}
                    patient={group.chatNameUser}
                    processName={P.dataTranslation(group.userToProcess.process.processName, language)}
                    time={group.messages[0]?.createdAt ? P.getDynamicTime(group.messages[0]?.createdAt, t) : null}
                    numMsg={null}
                    message={group.messages[0]?.message}
                    online
                  />
                </div>
              ) 
            }
          } else {
            return null
          }
          
          

          // console.log(group.userToProcess);
          // if (
          //   filterText ? 
          //   String(group.nameChat).toLowerCase().includes(filterText.toLowerCase())  : 
          //   true
          // ) {
          //   return (
          //     <div className="chat-box" 
          //       key={group.id}
          //       onClick={() => handleClick(group)}>
          //       <OneSingleChat
          //         active={group.id === patientID ? true : false}
          //         img={group.chatPhotoUser}
          //         patient={group.chatNameUser}
          //         processName={P.dataTranslation(group.userToProcess.process.processName, language)}
          //         time={group.messages[0]?.createdAt ? P.getDynamicTime(group.messages[0]?.createdAt, t) : null}
          //         numMsg={null}
          //         message={group.messages[0]?.message}
          //         online
          //       />
          //     </div>
          //   ) 
          // } else {
          //   return null
          // }
        })
      }
    </S.ListOfGroups>
  )
}

export default ListOfChats;
