import React from "react";
import * as S from "./FilterPrograms.style";
import { useTranslation } from "react-i18next";

import Popup from "reactjs-popup";

import DropdownPatient from "../../patients/components/dropdownPatient";

function FilterPrograms({
  processIds,
  setProcessIds,
  activeQuantity=true
}) {
  const [t] = useTranslation("global");

  return (
    <>
      <S.FilterPrograms>
        <p>{t("globally.filter")}</p>
        <Popup 
          trigger={
            <div className="dropdown-activer">
              <h5>
                {t("patients.patientList.allPrograms")}
              </h5>
              <div
                className="icon zaia-icono-flechas-arriba-abajo"
                style={{ cursor: "pointer" }}
              ></div>
            </div>
          }
          position='bottom left'
        >
          <DropdownPatient setProcessIds={setProcessIds} processIds={processIds} activeQuantity={activeQuantity} />
        </Popup>
      </S.FilterPrograms>
    </>
  )
}

export default FilterPrograms;