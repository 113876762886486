import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Routes } from "../../../../../utils/constants";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { useTranslation } from "react-i18next";
import * as S from "./loginFormStyle";
import { Link } from "react-router-dom"
import ReCaptcha from "react-google-recaptcha";
import ErrorMessage from "../../../errorMessage";

// GraphQL
import { useMutation } from "@apollo/client";
import { MUTATION_LOG_IN } from "../../../../../graphql/mutations/Auth";

// components
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
const env = process.env.REACT_APP_ENV;

function LoginForm() {
  const { DASHBOARD } = Routes;
  const history = useHistory();
  const [t] = useTranslation("global");
  const [{ colorTheme }, dispatch] = useStateValue();
  const [errors, setErrors] = useState();
  const [errorCaptcha, setErrorCaptcha] = useState(false);
  const [ activeErrorForm, setActiveErrorForm ] = useState(false);
  const loginCaptchaRef = useRef(null)
  const [loginDoctor, { loading: loginLoading}] = useMutation(MUTATION_LOG_IN);

  const handleSubmit = async (values, { resetForm }) => {
    if (env !== 'test' && env !== 'development') {
      const captchaToken = await loginCaptchaRef.current.executeAsync();
      if (captchaToken) {
        loginFunction(values, resetForm, captchaToken);
      } else {
        setErrorCaptcha(true);
        activateError();
        loginCaptchaRef.current.reset();
      }
    } else {
      loginFunction(values, resetForm);
    }
  };

  const loginFunction = async (values, resetForm, captchaToken) => {
    try {
      const accessToken = await  getAccessToken(values, captchaToken);
      localStorage.setItem("accessToken", accessToken);
      dispatch({
        type: "SET_AUTH",
        isAuth: true,
      });
      history.push(DASHBOARD.route);
    } catch (err) {
      setErrors(true);
      setErrorCaptcha(false);
      activateError();
      resetForm();
      if(captchaToken) loginCaptchaRef.current.reset();
    }
  }

  const getAccessToken = async (values, captchaToken) => {
    const {
      data: {
        loginDoctor: { accessToken },
      },
    } = await loginDoctor({
      variables: {
        email: values.email,
        password: values.password,
        captchaToken: captchaToken ? captchaToken : ''
      },
    });
    return accessToken
  }

  const activateError = () => {
    setActiveErrorForm(true);
    setTimeout(function() {
      setActiveErrorForm(false);      
    }, 5000);
  }

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("register.validation.email"))
            .required(t("register.validation.required")),
            password: Yup.string().required(t("register.validation.password")),
          })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <S.LoginFormDiv>
            <form className="loginForm" data-testid="login-form" onSubmit={props.handleSubmit}>
              <ErrorMessage
                errorMessage={errorCaptcha ? t("home.errorCaptcha") : errors ? t("home.error")  : ''}
                activeError={activeErrorForm}
              />
              <h2>{t("home.logIn")}</h2>
              <div>
                <h3 className="title-register login-title">
                  {t("home.email")}
                </h3>
                <ZaiaInputForm
                  placeholder={t("home.emailPlace")}
                  name="email"
                  type="email"
                  icon="icono-email"
                />
                <h3 className="title-register login-title">
                  {t("home.password")}
                </h3>
                <ZaiaInputForm
                  placeholder={t("home.passwordPlace")}
                  name="password"
                  type="password"
                  icon="icono-contrasena"
                  setErrors={setErrors}
                />
                <div className="captcha-container">
                  {
                    env !== 'test' && env !== 'development' &&
                    <ReCaptcha
                      ref={loginCaptchaRef}
                      sitekey={reCaptchaKey}
                      size="invisible"
                      theme={colorTheme}
                    />
                  }
                </div>
                <p className="text-center">
                  <Link to="/reset-password">{t("home.forgot")}</Link>
                </p>
                <ZaiaButton
                  // id={'login-button'}
                  type="submit"
                  marginTop="80"
                  marginBottom="30"
                  title={
                    loginLoading ? 
                    `${t("globally.loading")}...` : 
                    `${t("home.signIn")}`
                  }
                />
                {/* <p className="text-center">
                  {t("home.signUp")}{" "}
                  <Link style={{boxShadow: "none"}} to="/register">{t("home.register")}</Link>
                </p> */}
              </div>  
            </form>
          </S.LoginFormDiv>
        )}
      </Formik>
    </>
  );
}

export default LoginForm;
