import { gql } from "@apollo/client";

export const MUTATION_SIGN_UP = gql`
  mutation MUTATION_SIGN_UP(
    $name: String!
    $lastname: String!
    $document: String!
    $email: String!
  ) { 
    signup(
      name: $name, 
      lastname: $lastname, 
      password: $document,
      email: $email,
      isInvited: true
    ) {
      message
    }
  }
`;