import { useField } from "formik";
import React from "react";
import { PhoneCodeDiv } from "./style";

function PhoneCode({ placeholder, ...props }) {
  const [field, meta] = useField(props);
  return (
    <PhoneCodeDiv>
      <select required {...field} {...props}></select>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </PhoneCodeDiv>
  );
}

export default PhoneCode;
