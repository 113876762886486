import React from "react";
import * as S from "./TooltipProcesses.style";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../../graphql/queries/Process";

// pipes
import * as P from "../../../../utils/pipes";

function TooltipProcesses({patientID}) {

  const { i18n: { language } }= useTranslation("global");

  const { data: groupProcessesData } = useQuery(
    QUERY_GET_PROCESS_NAMES, 
    { 
      variables: { 
        whereFilter: { 
          userToProcesses: { 
            userId: { 
              _in: [ patientID ]
            }
          }
        }
      } 
    }
  );

  return (
    <S.TooltipProcesses oneChat={groupProcessesData?.chatGroup >= 1 ? false : true}>
      {
        groupProcessesData?.process.map(process => {
          return (
            <Link to={`/outcomes/${process?.nameKey}/${patientID}?panelActive=false`} key={process.id}>
              <div className="box-process">
                <div className="box-process__image">
                  <i className={`box-process__icon icon ${process?.icon}`}/>
                </div>
                <div className="box-process__name">{`${P.dataTranslation(process.processName, language)}`}</div>
              </div>
            </Link> 
          )
        })
      }
    </S.TooltipProcesses>
  )
}

export default TooltipProcesses;