import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router"; 
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Routes } from "../../../utils/constants";

import * as S from "./ChangePassword.style";

// Components
import Header from "../header";
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import ZaiaInputForm from "../../zaiaComponents/zaiaInputForm";
import { useStateValue } from "../../../contextAPI/StateProvider";

// GraphQL
import { useMutation } from "@apollo/client";
import { MUTATION_ACTIVE_TOKEN } from "../../../graphql/mutations/Auth"; 

// Components
import CoverPage from "../coverPage";
import ErrorMessage from "../errorMessage";

function ResetPassword() {

  const [t] = useTranslation("global");
  
  const { DASHBOARD } = Routes;
  const history = useHistory(); 
  const location = useLocation();
  const [, dispatch] = useStateValue();
  const [ linkInfo, setLinkInfo ] = useState();
  const [ activeErrorForm, setActiveErrorForm ] = useState(false);
  const [changePass] = useMutation(MUTATION_ACTIVE_TOKEN, { errorPolicy: 'all' });
  
  useEffect(() => {
    const searchLocation = location.search;
    const params = new URLSearchParams(searchLocation);
    setLinkInfo({ 
      token: params.get("token"),
      email: params.get("email")
    })
    if (!params.get("token") || !params.get("email")) {
      history?.push('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
      try {
        const { 
          data: {
            verifyTokenDoctor: { accessToken }
          }
        } = await changePass({
          variables: {
            email: linkInfo.email,
            pass: values.confirmNewPass,
            token: linkInfo.token
          },
        });
        localStorage.setItem("accessToken", accessToken);
        dispatch({
          type: "SET_AUTH",
          isAuth: true,
        });
        history.push(DASHBOARD.route);
      } catch (err) {
        console.log(err);
        resetForm();
        activateError();
      }
  };

  const activateError = () => {
    setActiveErrorForm(true);
    setTimeout(function() {
      setActiveErrorForm(false);      
    }, 5000);
  }

  // if(changePassError) 
  //   return (
  //     <S.ChangePassword>Error</S.ChangePassword>
  //   );

  return (
    <S.ChangePassword>
      <CoverPage/>
      <div className="resetPassword__right">
        <Header route="/" />
        <Formik
          initialValues={{
            newPass: "",
            confirmNewPass: ""
          }}
          validationSchema={Yup.object({
            newPass: Yup.string()
              .min(3, `${t("register.validation.min")}`)
              .required(`${t("register.validation.password")}`)
              .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/i,
                `${t("register.validation.matches")}`
              ),
            confirmNewPass: Yup.string()
              .min(3, `${t("register.validation.min")}`)
              .required(`${t("register.validation.confirmPass")}`)
              .when("newPass", {
                is: (newPass) => (newPass && newPass.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("newPass")],
                  `${t("register.validation.match")}`
                ),
              }),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className="reset-pass-form">
              <>
                <S.ResetPasswordFormDiv messageError={activeErrorForm}>
                  <div className="reset-pass-container">
                    <ErrorMessage
                      errorMessage={t("changePassword.errorData")}
                      activeError={activeErrorForm}
                    />
                    <h2>{t("changePassword.title")}</h2>
                    <p>{
                      // t("changePassword.subtitle")
                    }</p>
                    <div className="resetPassword__email">
                      <h3 className="title-register">{t("changePassword.input")}</h3>
                      <ZaiaInputForm 
                        placeholder={t("profile.newPass")}
                        type="password"
                        name="newPass"
                        icon="icono-contrasena"
                      />
                      <div className="space-between"></div>
                      <h3 className="title-register">{t("changePassword.inputRepeat")}</h3>
                      <ZaiaInputForm 
                        placeholder={t("profile.confimNewPass")}
                        type="password"
                        name="confirmNewPass"
                        icon="icono-contrasena"
                      />
                      <ZaiaButton
                        type="submit"
                        marginTop="100"
                        shadow={true}
                        title={
                          props.isSubmitting
                            ? `${t("globally.loading")}...`
                            : `${t("globally.send")}`
                        }
                      />
                    </div>
                  </div>
                </S.ResetPasswordFormDiv>
              </>
            </form>
          )}
        </Formik>
      </div>
    </S.ChangePassword>
  );
}

export default ResetPassword;
