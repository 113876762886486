import styled from "styled-components";

export const OutcomesDashboard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  border-radius: 20px;
  justify-content: space-between;
  .box-container{
    width: 49.5%;
    display: flex;
    margin-bottom: 10px;
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`