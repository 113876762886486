import styled from "styled-components";

export const ProfileDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  .profile__left-side {
    height: 100%;
    /* width: calc(100% - 400px); */
    width: 100%;
  }
  /* .profile__right-side {
    height: 100%;
    max-width: 400px;
    width: 100%;
    border-left: #E3E3E3 1px solid;
  } */
`;
