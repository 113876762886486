// Pipes 

// data translations 
export function dataTranslation( data, currentLanguage ) {
  if(currentLanguage.split('-')[0] !== 'en' && currentLanguage.split('-')[0] !== 'es') return data ? data['en'] : '';
  else return data ? (data[`${currentLanguage.split('-')[0]}`] ? data[`${currentLanguage.split('-')[0]}`] : data['en']) : ''
}

// dr translations 
export function drTranslation( gender, currentLanguage ) {
  const language = currentLanguage.split('-')[0];
  if (language === 'es') {
    if (gender === 'femenine') {
      return 'Dra.'
    } else {
      return 'Dr.'
    }
  } else {
    return 'Dr.'
  }
}

// date in '01 march 2021' format 
export function longDate ( data, languageFuntion ) {
  const day = new Date(data).getDate();
  const monthNumber = new Date(data).getMonth();
  const year = new Date(data).getFullYear();
  let month = nameMonth(monthNumber, 'L', languageFuntion);
  return ((day < 10 ? '0' + day : day) + ' ' + month + ', ' + year);
}

// date in '01 mar 2021' Format
export function shortDate ( data, languageFuntion, yearActive ) {
  const day = new Date(data).getDate();
  const monthNumber = new Date(data).getMonth();
  const year = new Date(data).getFullYear();
  let month = nameMonth(monthNumber, 'S', languageFuntion);
  return ((day < 10 ? '0' + day : day) + ' ' + month + (yearActive ? ' ' + year : '' ));
}

// date in '01/03/2021' 
export function formatNumberDate ( data, yearActive ) {
  const day = new Date(data).getDate();
  const month = new Date(data).getMonth() + 1;
  const year = new Date(data).getFullYear();
  return ((day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + (yearActive ? '/' + year : '' ));
}

export function formatNumberDateInput ( data ) {
  const day = new Date(data).getDate();
  const month = new Date(data).getMonth() +1;
  const year = new Date(data).getFullYear();
  return (( year + "-" ) + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day));
}

// name Month
export function nameMonth(month, size, languageFuntion) {
  switch(month) {
    case 0: return (
      size === 'S' ? 
      languageFuntion('months.januaryS') : 
      languageFuntion('months.januaryL')
      )
    case 1: return (
      size === 'S' ? 
      languageFuntion('months.februaryS') : 
      languageFuntion('months.februaryL')
      )
    case 2: return (
      size === 'S' ? 
      languageFuntion('months.marchS') : 
      languageFuntion('months.marchL')
      )
    case 3: return (
      size === 'S' ? 
      languageFuntion('months.aprilS') : 
      languageFuntion('months.aprilL')
      )
    case 4: return (
      size === 'S' ? 
      languageFuntion('months.mayS') : 
      languageFuntion('months.mayL')
      )
    case 5: return (
      size === 'S' ? 
      languageFuntion('months.juneS') : 
      languageFuntion('months.juneL')
      )
    case 6: return (
      size === 'S' ? 
      languageFuntion('months.julyS') : 
      languageFuntion('months.julyL')
      )
    case 7: return (
      size === 'S' ? 
      languageFuntion('months.augustS') : 
      languageFuntion('months.augustL')
      )
    case 8: return (
      size === 'S' ? 
      languageFuntion('months.septemberS') : 
      languageFuntion('months.septemberL')
      )
    case 9: return (
      size === 'S' ? 
      languageFuntion('months.octoberS') : 
      languageFuntion('months.octoberL')
      )
    case 10: return (
      size === 'S' ? 
      languageFuntion('months.novemberS') : 
      languageFuntion('months.novemberL')
      )
    case 11: return (
      size === 'S' ? 
      languageFuntion('months.decemberS') : 
      languageFuntion('months.decemberL')
      )
    default: return '';
  }
}

// day of week name
export function nameDayWeek(dayWeek, size, languageFuntion) {
  console.log();
  switch(dayWeek) {
    case 0: return (
      size === 'S' ? 
      languageFuntion('weekdays.sundayS') : 
      languageFuntion('weekdays.sundayL')
      )
    case 1: return (
      size === 'S' ? 
      languageFuntion('weekdays.mondayS') : 
      languageFuntion('weekdays.mondayL')
      )
    case 2: return (
      size === 'S' ? 
      languageFuntion('weekdays.tuesdayS') : 
      languageFuntion('weekdays.tuesdayL')
      )
    case 3: return (
      size === 'S' ? 
      languageFuntion('weekdays.wednesdayS') : 
      languageFuntion('weekdays.wednesdayL')
      )
    case 4: return (
      size === 'S' ? 
      languageFuntion('weekdays.thursdayS') : 
      languageFuntion('weekdays.thursdayL')
      )
    case 5: return (
      size === 'S' ? 
      languageFuntion('weekdays.fridayS') : 
      languageFuntion('weekdays.fridayL')
      )
    case 6: return (
      size === 'S' ? 
      languageFuntion('weekdays.saturdayS') : 
      languageFuntion('weekdays.saturdayL')
      )
    default: return '';
  }
}

// get age with the birthdate
export function getAge(birthdate) {
  const now = new Date(Date.now()).getFullYear();
  const birthdateYear = new Date(birthdate).getFullYear();
  return birthdate ? (now - birthdateYear) : "";
}

// hours in 12 format '01:00'
export function hoursIn12format(hours, minutes) {
  // var hours = date.getHours();
  // var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0'+ minutes : minutes;
  hours = hours < 10 ? '0'+ hours : hours;
  var stringTime = hours + ':' + minutes + ' ' + ampm;
  return stringTime;
}

// get percentage
export function getPercentage(quantity, total, percentTotal=100) {
  if(quantity || total) {
    // return (quantity * percentTotal) / total;
    if(total !== 0) {
      const percentage = (quantity * percentTotal) / total;
      return percentage !== 0 ? percentage !== 100 ? percentage % 1 === 0 ? percentage : percentage.toFixed(1) : 100 : 0;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

// capitalize first letter of a sentence
export function firstLetterUpper(phrase) {
  let firstLetter = phrase[0];
  return firstLetter.toUpperCase() + phrase.substring(1);
}

// lowercase the first letter of a sentence
export function firstLetterLower(phrase) {
  let firstLetter = phrase[0];
  return firstLetter.toLowerCase() + phrase.substring(1);
}

// get time depend of the day (today: return hour, in the same week: return weekday name, other: return date XX/XX/XXXX)
export function getDynamicTime(date, languageFuntion) {
  const now = new Date(Date.now());
  const weekDayNow = now.getDay();

  const nowDay = now.setHours(0,0,0,0)
  const yesterday = new Date(now.setDate(now.getDate() - 1)).setHours(0,0,0,0);
  const firstDayWeek = new Date(now.setDate(now.getDate() - (weekDayNow - 1))).setHours(0,0,0,0);

  const newDate = new Date(date).getTime();
  
  if (newDate >= nowDay) {
    return hoursIn12format(new Date(newDate).getHours(), new Date(newDate).getMinutes())
  } else if (newDate >= yesterday) {
    return languageFuntion('globally.yesterday');
  } else if (newDate >= firstDayWeek) {
    return nameDayWeek(new Date(newDate).getDay(), 'L', languageFuntion);
  } else {
    return formatNumberDate(new Date(newDate), true);
  }
}

// get body surface calc
export function getBodySurface(height, weight) {
  return parseFloat(Math.sqrt((weight * height)/ 3600)).toFixed(2);
}

// vital sign conversion by type

export function convertVitalSign(vitalSign, measure, unit) {

  let measureConverted = measure;

  switch(vitalSign) {
    case 'weight': 
     if(unit === 'Lb') measureConverted = measure * 2; break;
    case 'temperature':
      if(unit === 'C') measureConverted = (measure * (9/5)) + 32; break;
    case 'glucometry': 
      if(unit === 'mgdl') measureConverted = measure * 18; break;
    default: measureConverted = measure; break;
  }

  return measureConverted;
}

// hex color with opacity 

export function hexColorOpacity(colorName, opacity) {
  const color = getComputedStyle(document.body).getPropertyValue(colorName);;
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

// opacity to hex code

export function getHexOpacity(opacity) {
  return Math.floor(opacity * 255).toString(16).padStart(2, 0).toUpperCase();
}

// get language for mic setting

export function getMicLanguage(currentLanguage) {
  const language = currentLanguage.split('-')[0];
  if (language === 'es') {
    return 'es-ES'
  } else {
    return 'en-US'
  }
}

// laskalkjsdlkfjñasldfkj