import React from "react";
import { DropdownPatientDiv, DropdownPatientOptionsDiv } from "./DropdownPatient.style";
import { useTranslation } from "react-i18next";

// Pipes
import * as P from '../../../../../utils/pipes';

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";
import usePatientList from "../../../../../hooks/usePatientList";

function DropdownPatient({ processIds, setProcessIds, activeQuantity }) {
  const { i18n: { language } } = useTranslation("global");
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );    

  return (
    <DropdownPatientDiv >
      {
        processData?.process.map(process => {
          return (
            <DropdownPatientOptions
              title={P.dataTranslation(process.processName, language)}
              id={process.id}
              key={process.id}
              setProcessIds={setProcessIds}
              processIds={processIds}
              activeQuantity={activeQuantity}
            />  
          )
        })
      } 
    </DropdownPatientDiv>
  );
}

const DropdownPatientOptions = ({ title, id, processIds, setProcessIds, activeQuantity }) => {

  const {patientQuantity} = usePatientList(id);


  const checkProcessId = (id) => {
    if(setProcessIds) {
      const indexProcess = processIds.indexOf(id);
      if(indexProcess === -1) setProcessIds([...processIds, id])
      else setProcessIds(processIds.filter(item => item !== id))
    }
  }

  return (
    <DropdownPatientOptionsDiv>
      <input type="checkbox" id={`check${id}`} onClick={() => checkProcessId(id)} defaultChecked={processIds ? processIds.indexOf(id) !== -1 : false}/>
      <label htmlFor={`check${id}`}>{title}</label>
      { activeQuantity ? <div className="process-quantity">({patientQuantity})</div>: null}
    </DropdownPatientOptionsDiv>
  );
};

export default DropdownPatient;