import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./listOfPatients.style"

import {useHistory, useParams} from "react-router-dom"

// Components
import PatientBox from "../patientBox/patientBox";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Utils
import * as P from "../../../../../utils/pipes";
import usePatientList from "../../../../../hooks/usePatientList";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;

function ListOfPatients({
  filterProccessIds,
  idPatientFilter,
  patientsQuantity,
  setPatientsQuantity,
  urlRedirection,
  processIdFilter
}) {
  const { patientID } = useParams();
  const history = useHistory();
  const { t, i18n: { language } } = useTranslation("global");

  const {patientList, patientListLoading, patientListError, patientQuantity} = usePatientList(processIdFilter, filterProccessIds);

  // let hash = {}; /// add
  useEffect(() => {
    setPatientsQuantity(patientQuantity);
  },[patientQuantity, setPatientsQuantity])

  if(patientListLoading) 
    return (
      <S.ListOfPatientsContainer>
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        />  
      </S.ListOfPatientsContainer>
    );

  if(patientListError) 
    return (
      <S.ListOfPatientsContainer>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="100px"
          text={t("patients.errorLoadingPatients")}
          sizeText="15px"
          widthText="240px"
        />
      </S.ListOfPatientsContainer>
    );

  if(patientList?.length < 1) 
    return (
      <S.ListOfPatientsContainer>
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthImg="100px"
          text={t("patients.patientsEmpty")}
          sizeText="15px"
          widthText="200px"
        />
      </S.ListOfPatientsContainer>
    );

  return (
    <S.ListOfPatientsContainer>
      {patientList
      // .filter(o => hash[o.user.id] ? false : hash[o.user.id] = true) /// add
      .map((patient, index) => {
        return (
          ( 
            idPatientFilter ? 
            String(patient.documentNumber).startsWith(idPatientFilter)  : 
            true
          ) ? 
          <div className="patient-box" key={index} onClick={() => history?.push(`${urlRedirection}/` + patient.id)}>
            <PatientBox
              active={patient.id === patientID ? true : false}
              img={patient.picture}
              medicalProcedure={patient?.userToProcesses.length > 1 ? t("sidebar.variousProcesses") : P.dataTranslation(patient?.userToProcesses[0].process.processName, language)}
              patient={patient.name + ' ' + patient.lastname}
              id={patient.documentNumber}
            /> 
          </div> : null 
        )
      })}
    </S.ListOfPatientsContainer>
  );
}

export default ListOfPatients;
