import React from "react";
import * as S from "./ZaiaImage.style.js";

function ZaiaImage({ img, alt, size=56, defaultImg, rounded=false }) {

  return (
    <S.ZaiaImage size={size} src={img ? img : defaultImg} alt={alt} rounded={rounded}/>
  );
}

export default ZaiaImage;
