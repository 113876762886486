import { Formik } from "formik";
import React from "react";
import CustomTextInput from "../CustomTextInput";
import * as Yup from "yup";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { StepOneDiv } from "./style";
import { RegisterFormDiv } from "../../Register.style";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import { useTranslation } from "react-i18next";

function StepOne({ activeStep, setActiveStep }) {
  const [, dispatch] = useStateValue();
  const [t] = useTranslation("global");
  const handleNext = (values, { setSubmitting, resetForm }) => {
    dispatch({
      type: "SET_STEPONE",
      stepOne: values,
    });
    resetForm();
    setSubmitting(false);
    setActiveStep(30);
  };
  return (
    <Formik
      initialValues={{
        name: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(3, `${t("register.validation.min")}`)
          .max(15, `${t("register.validation.max")}`)
          .required(`${t("register.validation.required")}`),
        lastName: Yup.string()
          .min(3, `${t("register.validation.min")}`)
          .max(15, `${t("register.validation.max")}`)
          .required(`${t("register.validation.required")}`),
        email: Yup.string()
          .email(`${t("register.validation.email")}`)
          .required(`${t("register.validation.required")}`),
        password: Yup.string()
          .min(3, `${t("register.validation.min")}`)
          .required(`${t("register.validation.password")}`)
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/i,
            `${t("register.validation.matches")}`
          ),
        confirmPassword: Yup.string()
          .required(`${t("register.validation.confirmPass")}`)
          .when("password", {
            is: (password) => (password && password.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("password")],
              `${t("register.validation.match")}`
            ),
          }),
      })}
      onSubmit={handleNext}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {activeStep === 15 && (
            <>
              <Header stepOne />
              <ProgressBar done={activeStep} />
              <RegisterFormDiv>
                <StepOneDiv>
                  <h3 className="title-register">{t("register.nameEnter")}</h3>
                  <CustomTextInput
                    placeholder={t("register.name")}
                    name="name"
                    type="text"
                    icon="zaia-icono-nombre"
                  />
                  <CustomTextInput
                    placeholder={t("register.lastName")}
                    name="lastName"
                    type="text"
                    icon="zaia-icono-nombre"
                  />
                  <h3 className="title-register">{t("home.email")}</h3>
                  <CustomTextInput
                    placeholder={t("home.emailPlace")}
                    name="email"
                    type="email"
                    icon="zaia-icono-email"
                  />
                  <h3 className="title-register">{t("home.password")}</h3>
                  <CustomTextInput
                    placeholder={t("home.passwordPlace")}
                    name="password"
                    type="password"
                    icon="zaia-icono-contrasena"
                  />
                  <CustomTextInput
                    placeholder={t("register.repeat")}
                    name="confirmPassword"
                    type="password"
                    icon="zaia-icono-contrasena"
                  />
                  <ZaiaButton
                    type="submit"
                    marginTop="25"
                    title={
                      props.isSubmitting
                        ? "Loading ..."
                        : `${t("register.continue")}`
                    }
                  />
                </StepOneDiv>
              </RegisterFormDiv>
            </>
          )}
        </form>
      )}
    </Formik>
  );
}

export default StepOne;
