import React from "react";
import { FiltersDiv } from "./Filters.style";
import { useTranslation } from "react-i18next";

function Filters({
  selected,
  setSelected,
}) {
  const [t] = useTranslation("global");

  const handleSelected = (selected) => {
    setSelected(selected);
  };

  return (
    <FiltersDiv>
      {/* <div className="filters__moreIcon">
        <div
          className="icon zaia-icono-dots-horizontal"
          style={{ cursor: "pointer", marginTop: "3px" }}
        ></div>
      </div> */}
      <div className="filters__Icons">
        <p>{t("globally.filter")}</p>
        <div
          className="icon zaia-icono-dropdown"
          style={{ margin: "8px 0 -3px 0", fontSize: "small" }}
        ></div>
        <div onClick={() => handleSelected(0)} className={` ${selected === 0 && "active"}`}>
          <div className="icon zaia-icono-home filters__icon filters__icon--one"></div>
        </div>
        <div onClick={ () => handleSelected(1)} className={`${selected === 1 && "active"}`}>
          <div className="icon zaia-icono-sintomas filters__icon filters__icon--two"></div>
        </div>

        <div onClick={() => handleSelected(2)} className={`${selected === 2 && "active"}`}>
          <div className="icon zaia-icono-medicina filters__icon filters__icon--three"></div>
        </div>

        <div onClick={() => handleSelected(3)} className={`${selected === 3 && "active"}`}>
          <div className="icon zaia-icono-signos-vitales filters__icon filters__icon--four"></div>
        </div>
        <div onClick={() => handleSelected(4)}className={`${selected === 4 && "active"}`}>
          <div className="icon zaia-icono-perfil filters__icon filters__icon--five"></div>
        </div>
        <div onClick={() => handleSelected(5)} className={`${selected === 5 && "active"}`}>
          <div className="icon zaia-icono-calendario filters__icon filters__icon--six"></div>
        </div>
      </div>
    </FiltersDiv>
  );
}

export default Filters;
