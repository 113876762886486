import React from "react";
import * as S from "./FacesCard.style";
import { useTranslation } from "react-i18next";

// components
import Face from "../Face";

//  assets
const faceGood = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/sintoma-regular-on.svg`;
const faceRegular = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/sintoma-bien-on.svg`;
const faceBad = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/sintoma-mal-on.svg`;

function FacesCard({
  goodPercentage = "0",
  regularPercentage = "0",
  badPercentage = "0",
  flex = true,
  colorPrimary = "var(--blue-color)",
  colorSecundary = "var(--blue-dark-color)",
}) {
  const [t] = useTranslation("global");

  return (
    <S.FacesCard colorPrimary={colorPrimary} colorSecundary={colorSecundary}>
      <h4>{t("patients.boxInfoPatient.report.status")}</h4>
      <div className="card-faces-container">
        <Face
          percent={goodPercentage}
          btn={t("patients.boxInfoPatient.report.statusList.good")}
          btnColor={colorSecundary}
          img={faceGood}
          flex={flex}
        />
        <Face
          percent={regularPercentage}
          btn={t("patients.boxInfoPatient.report.statusList.regular")}
          btnColor={colorSecundary}
          img={faceRegular}
          flex={flex}
        />
        <Face
          percent={badPercentage}
          btn={t("patients.boxInfoPatient.report.statusList.bad")}
          btnColor={colorSecundary}
          img={faceBad}
          flex={flex}
        />
      </div>
    </S.FacesCard>
  );
}

export default FacesCard;
