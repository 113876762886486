import React, { useState, useEffect, useMemo } from "react";
import * as S from "./Chat.style";
import {useHistory, useParams, Redirect} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";

// Components
import MessageDisplay from "./components/messageDisplay";
import SettingsChat from "./components/settingsChat";
import SidebarTopPatients from "../patients/components/sidebarTopPatients";
import ListOfChats from "./components/listOfChats";

// GraphQL
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_GROUP_CHATS, SUBSCRIPTION_GET_GROUP_CHATS_BY_USER } from "../../../graphql/subscriptions/Chat";

// Hooks
import useDoctorData from "../../../hooks/useDoctorData";
import useChatVerification from "../../../hooks/useChatVerification";

function Chat() {
  const [{ selectedPatient }] = useStateValue();
  const { chatID } = useParams();
  const history = useHistory();
  const { doctorData } = useDoctorData();
  const location = history.location.pathname.split('/')[1];
  const [t] = useTranslation("global");
  const [selectedChat, setSelectedChat] = useState();
  const [inputFilter, setInputFilter] = useState();
  const [quantityChats, setQuantityChats] = useState(0);
  const [settingsGroup, setSettingsGroup] = useState(false);
  const [filterProccessIds, setFilterProccessIds] = useState([]);

  const { chatValid, loadingValidation }  = useChatVerification(chatID);

  useEffect(() => {
    if(chatValid === false && !loadingValidation) history.push('/chat');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatValid, loadingValidation])

  const { 
    data: groupChatsData, 
    loading: groupChatsLoading, 
    error: groupChatsError
  } = useSubscription(
    selectedPatient 
    ? SUBSCRIPTION_GET_GROUP_CHATS_BY_USER
    : SUBSCRIPTION_GET_GROUP_CHATS, 
    { 
      variables: 
      selectedPatient 
      ? {
        userId: selectedPatient?.id
      }
      : null
    }
  );

  useEffect(() => {
    if(groupChatsData?.chatGroup.length > 0) {
      if(chatID) {
        setSelectedChat(groupChatsData?.chatGroup.find(chatGroup => chatGroup.id === chatID ));
      }
    }
  }, [chatID, groupChatsData, location, history])

  const groupChatsOrderRecent = useMemo(() => {
    let groupsChat = groupChatsData?.chatGroup;
    groupsChat?.sort(function(a,b){
      return new Date(b.messages.length === 0 ? 0 : b.messages[0]?.createdAt) - new Date(a.messages.length === 0 ? 0 : a.messages[0]?.createdAt)
    });
    let groupsChatWithName = groupsChat?.map(group => {
      let chatNameUser = group.nameChat;
      let chatPhotoUser = group.imagen;
      if (group.personToChats.length === 1 ) {
        if (group.ownerUserId) {
          chatNameUser = `${group.ownerUser.name} ${group.ownerUser.lastname}`; 
          chatPhotoUser = group.ownerUser.picture;
        } else if(group.ownerDoctorId !== doctorData?.id) {
          chatNameUser = `${group.ownerDoctor.name} ${group.ownerDoctor.lastname}`; 
          chatPhotoUser = group.ownerDoctor.picture;
        } else {
          if (!group.personToChats[0].doctorId && group.personToChats[0].user) {
            chatNameUser = `${group.personToChats[0].user.name} ${group.personToChats[0].user.lastname}`; 
            chatPhotoUser = group.personToChats[0].user.picture;
          }
        }
      }
      group.chatNameUser = chatNameUser; 
      group.chatPhotoUser = chatPhotoUser; 
      return group;
    })
    return groupsChatWithName;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[groupChatsData])

  if (groupChatsData?.chatGroup.length > 0 && !chatID) {
    return <Redirect to={`/${location}/` + groupChatsOrderRecent[0].id} />
  } 

  return (
    <S.ChatDiv settingsGroup={settingsGroup}>
      <div className="chat__left">
        <div className="chat__centerContainer">
          <div className="chat__centerContainer-top">
            <SidebarTopPatients 
              title={'Chats'} 
              quantity={quantityChats ? quantityChats : 0} 
              setInputFilter={setInputFilter}
              typeFilter="text"
              filterText={t("chat.chatName")}
              filterPlaceholder={t("chat.search")}
              processIds={filterProccessIds}
              setProcessIds={setFilterProccessIds}
            />
          </div>
          <div className="chat__centerContainerChats">
            <ListOfChats 
              setQuantityChats={setQuantityChats}
              setSelectedChat={setSelectedChat}
              groupChatsOrderRecent={groupChatsOrderRecent}
              groupChatsLoading={groupChatsLoading}
              groupChatsError={groupChatsError}
              filterText={inputFilter}
              filterProccessIds={filterProccessIds}
            />
            {/* <div className="chat__centerContainerBtnDiv">
              <div className="chat__centerContainerBtn">
                <button>{t("globally.newChat")}</button>
                <div>+</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="chat__right">
        <div className="chat__right-message-display">
          <MessageDisplay
            selectedChat={selectedChat}
            groupChatsData={groupChatsData}
            groupChatsLoading={groupChatsLoading}
            groupChatsError={groupChatsError}
            setSettingsGroup={setSettingsGroup}
            setSelectedChat={setSelectedChat}
          />
        </div>
        {
        settingsGroup ? 
          <div className="chat__right-settings">
            <SettingsChat
              setSettingsGroup={setSettingsGroup}
              selectedChat={selectedChat}
            />
          </div> : null
        }
      </div>
    </S.ChatDiv>
  );
}

export default Chat;
