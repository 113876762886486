import styled from "styled-components";

export const FormByProcess = styled.div`
  width: 100% ;
  height: 100% ;
  display: flex;
  flex-direction: column;
  .header-progress-bar {
    padding: 25px 15%;
  }
  .component-container {
    padding: 10px 10%;
    min-height: 500px;
    display: flex;
    .generic-template {
      margin: auto;
      /* display: flex; */
      min-width: 400px;
    }
  }
  .next-button-container {
    margin: auto;
    width: 250px;
  }
  
  // first page
  .title {
    color: ${({theme}) => theme.primaryColor };
    margin: 100px auto 10px;
    font-family: "Nunito Black";
  }
  .subtitle {
    color: ${({theme}) => theme.primaryVariantColor };;
    margin: 10px auto 10px;
    font-family: "Nunito";
  }
  .intruction {
    color: ${({theme}) => theme.onBackgroundSoft };;
    margin: 70px auto 30px;
    font-family: "Open sans";
    font-size: 14px;
    width: 400px;
    text-align: center;  
  }
  .input-container {
    margin: 10px auto 10px;
    width: 400px;
  }
  .select-patient-button-container {
    margin: 70px auto;
    width: 400px; 
  }

  // final page
  .result-form-component-container {
    padding: 20px 20% 25px;
  }
  .disclaimer-message {
    width: 450px;
    margin: 0px auto;
    text-align: center;
    color: ${({theme}) => theme.onBackgroundTextSoft};
    font-size: 14px;
  }
  .assign-patient-button-container {
    margin: 50px auto;
    width: 300px;
  }
`