import styled from "styled-components";

export const ListOfPatientsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .patient-box {
    width: 100%;
  }
`;