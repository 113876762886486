import React, { useState } from "react";
import * as S from "./CmpCheckMultipleOptionList.style";

//Components
import CheckboxRound from "../../../checkboxRound"

function CmpCheckMultipleOptionList() {

  // const variants = [
  //   {
  //     id: 0,
  //     name: "Mutación"
  //   },
  //   {
  //     id: 1,
  //     name: "Inestable"
  //   },
  //   {
  //     id: 2,
  //     name: "Otros significativos"
  //   },
  //   {
  //     id: 3,
  //     name: "Sin datos"
  //   }
  // ]

  let diseaseControl = [
    {
      id: 1,
      name: "BRCA1/2",
      options: [
        {
          id: 0,
          name: "Mutación"
        },
        {
          id: 1,
          name: "Inestable"
        },
        {
          id: 2,
          name: "Otros significativos"
        },
        {
          id: 3,
          name: "Sin datos"
        }
      ],
      optionsSelected: [2, 3]
    },
    {
      id: 2,
      name: "HDR/MMR",
      options: [
        {
          id: 0,
          name: "Mutación"
        },
        {
          id: 1,
          name: "Inestable"
        },
        {
          id: 2,
          name: "Otros significativos"
        },
        {
          id: 3,
          name: "Sin datos"
        }
      ],
      optionsSelected: [0, 3]
    },
    {
      id: 3,
      name: "RAD51/ARID1A",
      options: [
        {
          id: 0,
          name: "Mutación"
        },
        {
          id: 1,
          name: "Inestable"
        },
        {
          id: 2,
          name: "Otros significativos"
        },
        {
          id: 3,
          name: "Sin datos"
        }
      ],
      optionsSelected: [1, 2]
    }
  ];

  const [data, setdata] = useState(diseaseControl);

  const changeOptionItem = (id, option, optionSet) => {
    let tempData = data;
    let objIndex = tempData.findIndex((obj => obj.id === id));
    let tempOptionsSelected = tempData[`${objIndex}`].optionsSelected;
    if(optionSet === 'add') {
      tempOptionsSelected.push(option);
    } else if (optionSet === 'del') {
      let objIndexOption = tempOptionsSelected.indexOf((option));
      tempOptionsSelected.splice( objIndexOption, 1 );
    }
    setdata([...tempData]);
  }

  return (
    <S.CmpCheckMultipleOptionList>
      <h5>Variantes moleculares</h5>
        <div className="molecular-variant-boxes-container">
        {
          data.map((item) => {
            return (
              <S.Box key={item.id}>
                <div className="molecular-variant-name">{item.name}</div>
                <div className="molecular-variant-states">
                  {
                    item.options.map((itemOption) => {
                      const indexInOptionsSelected = item.optionsSelected.indexOf(itemOption.id);
                      const itemSelected = indexInOptionsSelected !== -1 ? item.optionsSelected[`${indexInOptionsSelected}`] : null;
                      return <div key={itemOption.id}> 
                        <CheckboxRound
                          checkFunction={(e) => changeOptionItem(item.id, e.id, (itemOption.id !== itemSelected ? 'add' : 'del')) }
                          itemCheckbox={itemOption}
                          selectedItemId={itemSelected}
                        />
                      </div>
                    })
                  }
                </div>
              </S.Box>
            )
          })
        }
      </div>
    </S.CmpCheckMultipleOptionList>
  );
}

export default CmpCheckMultipleOptionList;
