import React, { useState, useContext, useEffect, useMemo } from 'react';
import * as S from './FormByProcess.style';
import { ThemeContext } from 'styled-components';
import { useTranslation } from "react-i18next";
import * as P from '../../../../../utils/pipes'

// components
import ProgressBar from '../../../shared/charts/progressBar';
import ZaiaButton from '../../../../zaiaComponents/zaiaButton';
import CmpSliderHorizontalOptions from '../../../outcomes/components/outcomesComponents/prostateCancer/cmpSliderHorizontalOptions';

// Hooks
import useDoctorData from "../../../../../hooks/useDoctorData";

// GraphQL
import { useMutation, useSubscription, useLazyQuery } from "@apollo/client";
import { SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID } from "../../../../../graphql/subscriptions/CustomForm";
import { QUERY_GET_USERS_BY_FILTER } from "../../../../../graphql/queries/User";
import { MUTATION_INSERT_USER_TO_CUSTOM_FORM_FIELD } from "../../../../../graphql/mutations/UserToCustomForm";
import GenericTemplate from '../../../outcomes/components/outcomesComponents/shared/genericTemplate';
import ZaiaRoundInput from '../../../../zaiaComponents/zaiaRoundInput';

function FormByProcess ({
  processId,
  patientID="b179ace3-4cc0-40d6-9c18-be2ab35afbf9"
}) {
  const { t, i18n: { language }} = useTranslation("global");
  const [ selectedPatient, setSelectedPatient ] = useState(null);
  const [ startAlgorithm, setStartAlgorithm ] = useState(false)
  const [ inputText, setInputText ] = useState('');
  const [ formCompleted, setFormCompleted ] = useState(false);
  const [ formsquantity, setFormsquantity ] = useState();
  const [ currentFormNumber, setCurrentFormNumber ] = useState(1);
  const { doctorData } = useDoctorData();
  const theme = useContext(ThemeContext);

  const { data: customForm } = useSubscription(SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID, {
    variables: { 
      userId: patientID, 
      // doctorId: doctorData.id,
      customFormId: processId
    },
  });

  const [ getPatients, { data: patientsData }] = useLazyQuery(
    QUERY_GET_USERS_BY_FILTER, 
    {
      onCompleted: data => {
        // console.log(data.user);
      }
    }
  );

  const [ insertUserToCustomFormField ] = useMutation(MUTATION_INSERT_USER_TO_CUSTOM_FORM_FIELD);

  const insertFormField = async (e) => {
    try {
      const { 
        // eslint-disable-next-line no-unused-vars
        data
      } = await insertUserToCustomFormField({
        variables: {
          customFormFieldId: e.customFormFieldId,
          doctorId: doctorData.id,
          userId: patientID,
          value: e.value,
          key: e.key
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
  
  useEffect(() => {
    setFormsquantity(customForm?.customForm_by_pk.formGroups.length);
  }, [customForm])

  const getPercentageProcess = useMemo(() =>{
    return (currentFormNumber * 100)/formsquantity;
  }, [currentFormNumber, formsquantity]);

  const getResults = () => {
    // here will be the executing of the data calculation based on the forms
    setFormCompleted(true)
  }

  const formPagination = () => {
    return(
      <>
      <div className="component-container">
        <div className="generic-template">
          <GenericTemplate
            title={P.dataTranslation(customForm?.customForm_by_pk.formGroups[`${currentFormNumber-1}`].customFormName, language)}
            dataForm={customForm?.customForm_by_pk.formGroups[`${currentFormNumber-1}`]}
            insertfunction={insertFormField}
          />
        </div>
      </div>
      <div className="next-button-container" onClick={
        () => 
        (currentFormNumber) !== formsquantity 
         ? setCurrentFormNumber(currentFormNumber + 1)
         : getResults() 
      }>
        <ZaiaButton
          title={
            (currentFormNumber) !== formsquantity 
              ? "Siguiente" 
              : "Finalizar"
          }
        />
      </div>
      </>
    )
  }

  const formResult = () => {
    return(
      <>
        <div className="result-form-component-container">
          <CmpSliderHorizontalOptions
            title={P.dataTranslation(customForm?.customForm_by_pk.formGroups[8].customFormName, language)}
            dataForm={customForm?.customForm_by_pk.formGroups[8]}
            insertfunction={insertFormField}
          />
        </div>
        <div className="disclaimer-message">
          Este algoritmo predictivo fue creado para 
          predecir el riesgo en la enfermedad {P.dataTranslation(customForm?.customForm_by_pk.customFormName, language)}. Esta basado en la 
          información recolectada en el registro nacional de 
          pacientes con {P.dataTranslation(customForm?.customForm_by_pk.customFormName, language)} y es una 
          herramienta creada y validada con una evidencia del 
          mundo real. Por lo anterior, el profesional que lo utilice 
          debe aceptar que el resultado obtenido no reemplaza el 
          criterio clínico ni determina la decisión médica.
        </div>
        <div className="assign-patient-button-container">
          <ZaiaButton
            title={"Asignar resultado a paciente"}
          />
        </div>
      </>
    )
  }

  const getText = (e) => {
    setInputText(e);
    if ( e.length > 1 ) 
    getPatients(
      {
        variables: {
          text: `%${inputText}%`,
        }
      }
    );
  }

  const deletePatientSelected = () => {
    setSelectedPatient(null);  
    getPatients(
      {
        variables: {
          text: ``,
        }
      }
    );
  }

  if (!startAlgorithm) {
    return (
      <S.FormByProcess>
        <h2 className="title">
          {P.dataTranslation(customForm?.customForm_by_pk.customFormName, language)}
        </h2>
        <h4 className="subtitle">
          {P.dataTranslation(customForm?.customForm_by_pk.descript, language)}
        </h4>
        <h3 className="intruction">
          {"Selecciona el paciente al cual vas a asignarle los resultados de este algoritmo"}
        </h3>
        <div className="input-container">
          <ZaiaRoundInput
            placeholder={t("globally.search")} 
            padding="8px" 
            type={'text'} 
            getText={getText}
            tagSelectedText={selectedPatient ? `${selectedPatient?.documentNumber} - ${selectedPatient?.name} ${selectedPatient?.lastname}` : null}
            items={patientsData?.user.map((patient) => {
              return {
                patient: patient,
                value: patient.id,
                image: patient.picture,
                title: `${patient.name} ${patient.lastname}`,
                subtitle: `${patient.enDocumentType.comment.code} ${patient.documentNumber}`
              }
            }
            )}
            selectItem={(e) => setSelectedPatient(e)}
            deleteSelect={deletePatientSelected}
          />
        </div>
        <div className="select-patient-button-container" onClick={() => selectedPatient ? setStartAlgorithm(true) : null}>
          <ZaiaButton
            title={"Comenzar algoritmo"}
          />
        </div>
      </S.FormByProcess>
    )
  }


  return (
    <S.FormByProcess>
      <div className="header-progress-bar">
        <ProgressBar
          bgColor={theme.warning}
          bgColor2={theme.inputPlaceholder}
          completed={getPercentageProcess}
          textPercentage={false}
          animationFromZero={false}
        />
      </div>
      {
        formCompleted
        ? formResult()
        : formPagination()
      }
    </S.FormByProcess>
  )
}

export default FormByProcess;