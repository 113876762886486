import React, { useEffect, useState }  from "react";
import * as S from "./Sidebar.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { useStateValue } from "../../../../contextAPI/StateProvider";

// Routes
import { Routes } from "../../../../utils/constants";
import { useHistory, useParams } from "react-router-dom";

// Hooks
import useActivateModal from "../../../../hooks/useActivateModal"
import useDoctorData from "../../../../hooks/useDoctorData"

// Components
import ZaiaImage from "../../../zaiaComponents/zaiaImage";

// Pipes
import * as P from '../../../../utils/pipes';

// GraphQL
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_LAST_MESSAGE } from "../../../../graphql/subscriptions/Chat";



// Assets
const sidebarImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-doctor.svg`;
const DefaultImgUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const notificationSoundMp3 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaia-sounds/notificationSound.mp3`;

function Sidebar({ namePage }) {
  const [{ selectedPatient }, dispatch] = useStateValue();
  const { doctorData } = useDoctorData();
  const history = useHistory();
  const { patientID } = useParams();
  const { DASHBOARD, PACIENTS, ALGORITHMS, OUTCOMES, CHAT } = Routes;
  const { t, i18n: { language } } = useTranslation("global");
  const lang = language.split('-')[0];
  const {desactiveModal} = useActivateModal()
  const [incomingMsg, setIncomingMsg] = useState(false);
  const [incomingMsgCounter, setIncomingMsgCounter] = useState(0);
  const notificationSoundAudio = new Audio(notificationSoundMp3);

  useEffect(() => {
    desactiveModal();
    if(namePage === "chat") {
      // if (incomingMsg) setIncomingMsg(incomingMsg);
      if (incomingMsgCounter > 1) setIncomingMsgCounter(1)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePage])

  const deletePatientSelected = () => {
    dispatch({
      type: "SET_SELECTED_PATIENT",
      selectedPatient: null,
    });
  }

  const playNotificationSound = () => {
    const playPromise = notificationSoundAudio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(_ => {})
        .catch(error => {
          console.log(error);
        });
    }
  }

  const {
    // eslint-disable-next-line no-unused-vars
    data: lastMessagesData 
  } = useSubscription(
    SUBSCRIPTION_GET_LAST_MESSAGE,
    {
      variables: { 
        doctorId: doctorData?.id,
      },
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        const newMessage = data.message[0];

        // temporal conditional 
        if (newMessage?.chatGroup && (newMessage?.chatGroup.ownerDoctorId === doctorData.id || newMessage?.chatGroup.personToChats.find(element => element.doctorId === doctorData.id))) {
          // 
          if (newMessage) {
            if (incomingMsg === false ) {
              setIncomingMsg(newMessage);
              setIncomingMsgCounter(1);
            } else {
              if (incomingMsg) {
                if (incomingMsg.id !== newMessage?.id) {
                  if (!newMessage?.fromDoctorId || newMessage.fromDoctorId !== doctorData.id ) {
                    if(namePage !== "chat") setIncomingMsgCounter(incomingMsgCounter + 1);
                    setIncomingMsg(newMessage);
                    playNotificationSound();
                  }
                }
              } else {
                setIncomingMsg(newMessage);
                playNotificationSound();
              }
            }
          }
        }
      }
    }
  );
  
  return (
    <S.Sidebar selectedPatient={selectedPatient}>
      <div className="title-container">
        <img className="zaia-title" alt="sidebarImg" src={sidebarImg} />
      </div>

      <div className="sidebar-tabs-container">

        <div
          onClick={() => history.push(DASHBOARD.route)}
          className={`sidebarOption ${namePage === "dashboard" && "active"}`}
        >
          <div className="icon-container">
            <i className={`icon zaia-${namePage === "dashboard" ? "icono-home-relleno" : "icono-home"}`}></i>
          </div>
          <div className="sidebar-tab-name">{t("sidebar.dashboard")}</div>
        </div>

        <div
          onClick={() => selectedPatient ? history.push(`${PACIENTS.route}/${selectedPatient.id}`) : history.push(PACIENTS.route)}
          className={`sidebarOption ${namePage === "patients" && "active"} `}
        >
          <div className="icon-container">
            <i className={`icon zaia-${namePage === "patients" ? "icono-paciente-relleno" : "icono-pacient"} `}></i>
          </div>
          <div className="sidebar-tab-name">{t("sidebar.patients")}</div>
        </div>

        <div
          onClick={() => history.push(ALGORITHMS.route)}
          className={`sidebarOption ${namePage === "algorithms" && "active"} `}
        >
          <div className="icon-container">
            <i className={`icon zaia-${namePage === "algorithms" ? "icono-algoritmos" : "icono-algoritmos-1"}`}></i>
          </div>
          <div className="sidebar-tab-name">{t("sidebar.algorithms")}</div>
        </div>

        <div
          onClick={() => history.push(OUTCOMES.route)}
          className={`sidebarOption ${namePage === "outcomes" && "active"} `}
        >
          <div className="icon-container">
            <i className={`icon zaia-${namePage === "outcomes" ? "icono-resultados-relleno" : "icono-resultados"}`}></i>
          </div>
          <div className="sidebar-tab-name">{t("sidebar.outcomes")}</div>
        </div>

        <div
          onClick={patientID && namePage === "chat" ? null : () => {history.push(CHAT.route)}}
          className={`sidebarOption ${namePage === "chat" && "active"} `}
        >
          <div className="icon-container">
            <i className={`icon zaia-${namePage === "chat" ? "icono-chat-relleno" : "icono-chat"}`}></i>
          </div>
            <div className="sidebar-tab-name">{t("sidebar.chat")}
             {/* {JSON.stringify(incomingMsg)} */}
             </div>
          {
            incomingMsg && incomingMsgCounter > 1? 
            <div className="sidebar-tab-alarm"></div> 
            : null
          }
        </div>

        {/* <div
          onClick={() => {window.open(`https://www.maia.health/blog/categories/maia-vision${lang === 'en' ? '?lang=en' : ''}`)}}
          className={`sidebarOption ${namePage === "vision" && "active"} `}
        >
          <div className="icon-container">
            <i className={`icon zaia-icono-zaia-vision`}></i>
          </div>
          <div className="sidebar-tab-name">{t("sidebar.vision")}</div>
        </div> */}

        <div
          onClick={() => {window.open(`${lang === 'es' ? 'https://www.zaia.health/es/blog' : 'https://www.zaia.health/blog'}`)}}
          className={`sidebarOption ${namePage === "blog" && "active"} `}
        >
          <div className="icon-container">
            <i className={`icon zaia-icono-blog-web`}></i>
          </div>
          <div className="sidebar-tab-name">{t("sidebar.blog")}</div>
        </div>

      </div>
      {
        selectedPatient ?  
        <div className="sidebar-selected-patient">
          <p>{t("sidebar.selectedPatientMsg")}:</p>
          <div className="card-selected-patient">
            <div className="card-selected-patient-image-container">
              <ZaiaImage
                size="40" 
                img={selectedPatient?.picture}
                defaultImg={DefaultImgUser}
              />
            </div>
            <div className="card-selected-patient-info-container">
              <div className="card-selected-patient-name">{selectedPatient?.name + ' ' + selectedPatient?.lastname}</div>
              <Popup
                trigger={
                  <div className="card-selected-patient-program">
                    {selectedPatient?.userToProcesses.length > 1 ? t("sidebar.variousProcesses") : P.dataTranslation(selectedPatient?.userToProcesses[0].process.processName, language)}
                  </div>
                }
                keepTooltipInside
                nested
                // position={"right bottom"}
                on="hover"
                disabled={selectedPatient?.userToProcesses.length > 1 ? false : true}
              >
                {
                  <ProcessName userToProcesses={selectedPatient?.userToProcesses}/>
                }
              </Popup>
            </div>
            <i className="card-selected-patient-close-patient icon zaia-icono-cerrar" onClick={() => deletePatientSelected()}/>
          </div>
        </div> : null
      }
    </S.Sidebar>
  );
}

const ProcessName = ({userToProcesses}) => {
  const { i18n: { language } } = useTranslation("global");

  return (
    <S.ProcessNameContainer>
        {
          userToProcesses.map((userToProcess, index) => {
            return (
              <S.ProcessName last={index === userToProcesses.length - 1}>
                <div className="card-selected-patient-process">
                  {P.dataTranslation(userToProcess.process.processName, language)}
                </div>
              </S.ProcessName>
            )
          })
        }
    </S.ProcessNameContainer>
  )
}

export default Sidebar;
