import React from "react";
import { OneSingleChatDiv } from "./OneSingleChat.style";

// components
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// Assets
const defaultChatGroup = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-chat-group.png`;

function OneSingleChat({ img, patient, processName, message, time, numMsg, online, active }) {
  return (
    <OneSingleChatDiv online={online} active={active}>
      <div className="oneSingleChat__imgContainer">
        <ZaiaImage img={img} alt="Zaia user" size={56} defaultImg={defaultChatGroup}/>
      </div>
      <div className="oneSingleChat__container">
        <h4>{patient}</h4>
        <p className="oneSingleChat__process">{processName}</p>
        <p className="oneSingleChat__message">{message}</p>
      </div>
      <div className="oneSingleChat__time">
        <p>{time}</p>
        {
          numMsg !== null && numMsg !== 0 ? 
          <div>{numMsg}</div> : null
        }
      </div>
    </OneSingleChatDiv>
  );
}

export default OneSingleChat;
