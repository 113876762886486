import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Header from "../Header";
import CustomSelect from "../CustomSelect";
import CustomTextInput from "../CustomTextInput";
import PhoneCode from "../PhoneCode";
import ProgressBar from "../ProgressBar";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { RegisterFormDiv } from "../../Register.style";
import { StepTwoDiv } from "./style";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import { useTranslation } from "react-i18next";

function StepTwo({ activeStep, setActiveStep }) {
  const [t] = useTranslation("global");
  const [
    active, 
    setActive
  ] = useState({
    activeObject: null,
    genders: [
      { id: 1, value: `${t("register.genders.women")}` },
      { id: 2, value: `${t("register.genders.men")}` },
      { id: 3, value: `${t("register.genders.other")}` },
    ],
  });

  const toggleActive = (idx) => {
    setActive({ ...active, activeObject: active.genders[`${idx}`] });
  };

  const toggleActiveStyles = (idx) => {
    if (active.genders[`${idx}`] === active.activeObject) {
      return "active";
    } else {
      return "";
    }
  };
  const [, dispatch] = useStateValue();
  const handleNext = (values, { setSubmitting, resetForm }) => {
    dispatch({
      type: "SET_STEPTWO",
      stepTwo: values,
    });
    resetForm();
    setSubmitting(false);
    setActiveStep(45);
  };

  return (
    <Formik
      initialValues={{
        country: "",
        date: null,
        codePhone: "",
        phone: "",
        gender: "",
      }}
      validationSchema={Yup.object({
        country: Yup.string()
          .oneOf(["Colombia", "Canada", "Chile", "Costa Rica"])
          .required(`${t("register.validation.required")}`),
        date: Yup.date().nullable(),
        codePhone: Yup.string(),
        phone: Yup.number()
          .min(10, `${t("register.validation.min10")}`)
          .required(`${t("register.validation.required")}`),
        gender: Yup.string(),
      })}
      onSubmit={handleNext}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {activeStep === 30 && (
            <>
              <Header stepTwo setActiveStep={setActiveStep} />
              <ProgressBar done={activeStep} />
              <RegisterFormDiv>
                <StepTwoDiv>
                  <h3 className="title-register">{t("register.countrySe")}</h3>
                  <CustomSelect name="country" icon="zaia-icono-pais">
                    <option value="" disabled hidden>
                      {t("register.country")}
                    </option>
                    <option value="Colombia">Colombia</option>
                    <option value="Canada">Canada</option>
                    <option value="Chile">Chile</option>
                    <option value="Costa Rica">Costa Rica</option>
                  </CustomSelect>
                  <h3 className="register__dateTitle title-register">
                    {t("register.birthday")}
                  </h3>
                  <h3 className="register__phoneTitle title-register">
                    {t("register.phone")}
                  </h3>
                  <div className="registerForm__phone">
                    <PhoneCode
                      placeholder="Select your phonecode"
                      name="codePhone"
                    >
                      <option value="54">AR (+54)</option>
                      <option value="57">CO (+57)</option>
                      <option value="56">CL (+56)</option>
                      <option value="1">US (+1)</option>
                      <option value="598">UR (+598)</option>
                      <option value="591">BO (+591)</option>
                      <option value="506">CR (+506)</option>
                      <option value="53">CU (+53)</option>
                      <option value="1809">DO (+1809)</option>
                      <option value="503">SV (+503)</option>
                      <option value="593">EC (+593)</option>
                    </PhoneCode>
                    <CustomTextInput
                      placeholder={t("register.phonePlace")}
                      name="phone"
                      type="number"
                      icon="zaia-icono-telefono"
                    />
                  </div>

                  <h3 className="title-register">{t("register.gender")}</h3>
                  <div className="registerForm__gender">
                    {active.genders.map((gender, idx) => (
                      <input
                        type="button"
                        key={idx}
                        name="gender"
                        value={gender.value}
                        className={toggleActiveStyles(idx)}
                        onClick={() => toggleActive(idx)}
                      />
                    ))}
                  </div>
                  <ZaiaButton
                    type="submit"
                    marginTop="25"
                    title={
                      props.isSubmitting
                        ? "Loading ..."
                        : `${t("register.continue")}`
                    }
                  />
                </StepTwoDiv>
              </RegisterFormDiv>
            </>
          )}
        </form>
      )}
    </Formik>
  );
}

export default StepTwo;
