import styled from "styled-components";

export const VitalsignsBoxInfo = styled.div`

  .boxInfoPatient__info {
    display: flex;
    
    &-left {
      display: flex;
      width: calc(100% - 200px);
      overflow-y: auto;
      border-right: #E3E3E3 1px solid;
      min-height: 285px;;
    }

    &-right {
      width: 200px;
      padding-left: 10px;
      display: flex;
    }
  }
`;