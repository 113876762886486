import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useStateValue } from "./contextAPI/StateProvider";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme, GlobalStyles } from "./themes"

// Routes
import publicRoutesList from "./routes/publicRoutesList";
import PublicRoute from "./routes/components/PublicRoute";
import privateRoutesList from "./routes/privateRoutesList";

// Components
import Private from "./pages/private"
import NotFound from "./pages/public/foundError"

// Hooks
import useInfoToken from "./hooks/useInfoToken";

// SEO
import SEO from "./utils/SEO"

function App({ setAccessToken }) {
  const [{ isAuth, colorTheme }, dispatch] = useStateValue();
  const tokenVerified = localStorage.getItem("accessToken");
  const [ existToken, setExistToken ] = useState(tokenVerified ? true : false);
  const { getTokenInfo } = useInfoToken();
  
  useEffect(() => {
    const tokenInfo = getTokenInfo()
    // let token = localStorage.getItem("accessToken");
    // let errorToken;
    // try {
    //   jwt.verify(`${token}`, "springiest49Nightcap62outwitting");
    // } catch (err) {
    //   console.log("cuando ingrese y se reviso el token", err);

    //   errorToken = true;
    // }
    
    if (!tokenInfo) {
      dispatch({
        type: "SET_AUTH",
        isAuth: false,
      });
      localStorage.removeItem("accessToken");
      setAccessToken(null);
      setExistToken(false);
      // doctorVar(null);
      // localStorage.removeItem("apollo-cache-persist");
    } else {
      dispatch({
        type: "SET_AUTH",
        isAuth: true,
      });
      localStorage.setItem("accessToken", tokenInfo.token);
      setAccessToken(tokenInfo.token);
      setExistToken(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, dispatch, setAccessToken]);

  

  return (
    <ThemeProvider theme={colorTheme === 'light' ? lightTheme : darkTheme} >
      <GlobalStyles />
      <Router>
        <SEO/>
        <Switch>

          <Route exact path={privateRoutesList.map(route => route.path)}>
            <Private authed={existToken}/>
          </Route>

          <Route exact path={publicRoutesList.map(route => route.path)}>
            <Switch>
              {publicRoutesList.map(publicRoute => (
                <PublicRoute 
                  {...publicRoute}
                  exact
                  authed={existToken}
                />
              ))}
            </Switch>
          </Route>

          <Route path="*">
            <Switch>
              <Route component={NotFound} />
            </Switch>
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
  
}

export default App;
