import { gql } from "@apollo/client";

export const QUERY_GET_USER_TO_PROCESS = gql`
  query QUERY_GET_USER_TO_PROCESS(
    $patientId: uuid, 
    $processId: Int,
  ) {
    userToProcess(where: {processId: {_eq: $processId}, userId: {_eq: $patientId}}) {
      created_at
      descript
      doctorId
      id
      isAcepted
      processId
      stageId
      updated_at
      userId
      processStage {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
      }
    }
  }
`

export const QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED = gql`
  query QUERY_GET_USER_TO_PROCESS(
    $isAcepted: Boolean
  ) {
    userToProcess(where: {isAcepted: {_eq: $isAcepted}}) {
      id
      created_at
      doctorId
      isAcepted
      processId
      stageId
      updated_at
      userId
      user {
        documentNumber
        documentType
        id
        lastname
        name
        picture
        enDocumentType {
          comment
          value
        }
      }
      process {
        id
        nameKey
        processName
      }
    }
  }
`