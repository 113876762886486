import React, { useEffect, useState, useMemo } from "react";
import * as S from "./MessageDisplay.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { Formik } from "formik";
import * as Yup from "yup";

// Components
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import Message from "../../../algorithms/components/message";
import NoPatients from "../../../shared/noPatients";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// graphQL
import { useSubscription, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { SUBSCRIPTION_GET_MESSAGES } from "../../../../../graphql/subscriptions/Chat";
import { QUERY_GET_MESSAGES } from "../../../../../graphql/queries/Chat";
import { MUTATION_CHAT_MESSAGES } from "../../../../../graphql/mutations/Chat";

// Pipe
import * as P from "../../../../../utils/pipes"

//Hooks
import useInfoToken from "../../../../../hooks/useInfoToken";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;
const defaultChatGroup = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-chat-group.png`;

function MessageDisplay({
  selectedChat,
  groupChatsData,
  groupChatsLoading,
  groupChatsError,
  setSettingsGroup
}) {
  const {t, i18n: { language }} = useTranslation("global");
  const [allMessages, setAllMessages] = useState([]);
  const [currentChat, setCurrentChat] = useState();
  const [offset, setOffset] = useState(20);
  const { userId } = useInfoToken();

  useEffect(() => {
    if(currentChat?.id !== selectedChat?.id) {
      setCurrentChat(selectedChat);
      setAllMessages([]);
      // refetch();
      setOffset(20);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChat, currentChat, setCurrentChat])

  const [
    sendMessage
  ] = useMutation(MUTATION_CHAT_MESSAGES);

  // Query get past messages with offset
  const [
    getMessages,
    {
      loading: oldMessagesLoading
    }
  ] = useLazyQuery(
    QUERY_GET_MESSAGES,
    {
      onCompleted: (data) => {
        setAllMessages(prevState => [...prevState, ...data.message])
      },
      fetchPolicy: "network-only"
    }
  );

  // Query get first last 20 messages
  const {
    loading: lastMessagesloading,
    refetch
  } = useQuery(QUERY_GET_MESSAGES,
    {
      variables: { groupChatId: selectedChat?.id },
      onCompleted: (data) => {
        setAllMessages([]);
        setAllMessages(prevState => [...prevState, ...data.message]);
      },
      fetchPolicy: "network-only"
    }
  );

  // Subscription last message
  const {
    // eslint-disable-next-line no-unused-vars
    data: lastMessagesData
  } = useSubscription(
      SUBSCRIPTION_GET_MESSAGES,
    {
      variables: { groupChatId: selectedChat?.id },
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        const newMessage = data.message[0];
        if (allMessages?.findIndex(msg => msg.id === newMessage?.id) === -1) {
          setAllMessages(prevState => [...data.message, ...prevState])
          setOffset(offset + 1);
          refetch();
        }
      }
    }
  );

  const getOldMessages = () => {
    getMessages({ variables: { groupChatId: selectedChat?.id, offset: offset }});
    setOffset(offset + 20);
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      if(resetForm) resetForm();
      if(setSubmitting) setSubmitting(false);
      const {
        data: {
          insert_message: { returning }
        }
      } = await sendMessage({
        variables: {
          message: values.msg,
          groupChatId: selectedChat?.id,
        },
      });
      if (allMessages?.findIndex(msg => msg.id === returning[0].id) === -1) {
        setAllMessages(prevState => [...returning, ...prevState])
        setOffset(offset + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.clientHeight - e.target.scrollHeight >= e.target.scrollTop - 2;
    if (bottom && !oldMessagesLoading) {
      getOldMessages();
     }
  }

  const messagesFilter = useMemo(() => {
    let messages = [];
    allMessages?.forEach(msg => {
      let option = 'receiver';
      // if(msg.fromDoctorId) {
      if(msg.fromDoctorId === userId) option = 'doctor'
      let senderName;

      senderName = msg.fromUserId ?
        `${msg.user?.name + " " + msg.user?.lastname}` :
        `${P.drTranslation(msg.doctor.gender, language)} ${msg.doctor.name + " " + msg.doctor.lastname}`

      const time = P.hoursIn12format(new Date(msg.createdAt).getHours(), new Date(msg.createdAt).getMinutes());
      const date = P.shortDate(new Date(msg.createdAt), t, true);
      messages.push(
        {
          key: msg.id,
          message: msg.message,
          option: option,
          time: time,
          date: date,
          senderName: senderName
        }
      )
    });
    return messages;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMessages])



  if(groupChatsLoading || lastMessagesloading)
    return (
      <>
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        />
      </>
    );

  if(groupChatsError)
    return (
      <>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="100px"
          text={t("chat.errorLoadingChats")}
          sizeText="15px"
          widthText="240px"
        />
      </>
    );

  if (!selectedChat?.id && groupChatsData?.chatGroup.length === 0)
    return (
      <>
        <NoPatients
          title={t("dashboard.boxes.noAlerts")}
          img={logoZaiaEmpty}
        />
      </>
    );

  return (
    <S.MessageDisplayDiv id="messageDisplay">
      <header className="messageDisplay__header">
        <div className="messageDisplay__headerName">
          <div className="oneSingleChat__imgContainer">
            <ZaiaImage img={selectedChat?.chatPhotoUser} alt="Zaia user" size={40} defaultImg={defaultChatGroup}/>
            {/* <div className="oneSingleChat__online"></div> */}
          </div>
          <h4>{selectedChat?.chatNameUser}</h4>
        </div>
        <Popup
          trigger={
            <div className="icon zaia-icono-dots-horizontal"></div>
          }
          keepTooltipInside="#messageDisplay"
          nested
        >
          { close =>
            <ChatSettings setSettingsGroup={setSettingsGroup} close={close}/>
          }
        </Popup>
      </header>
      {/* <button onClick={() => getOldMessages()}>get old msgs</button> */}
      <div className="rightside__container-msgs">
        <div className="rightside__msgs" onScroll={handleScroll}>
          {messagesFilter.map((message) => {
            return (
              <Message
                key={message.key}
                message={message.message}
                option={message.option}
                time={message.time}
                date={message.date}
                senderName={message.senderName}
              />
            )
          })}
        </div>
      </div>
      <Formik
        initialValues={{
          msg: "",
        }}
        validationSchema={
            Yup.object({
              msg: Yup.string()
              .required("Este campo es obligatorio."),
            })
        }
        onSubmit={handleSubmit}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="messageDisplay__containerFooter"
          >
            <button type="submit" disabled={props.isSubmitting}>
              <i className="send-icon icon zaia-icono-agregar"></i>
            </button>
            <button type="submit" disabled={props.isSubmitting}>
              <i className="send-icon icon zaia-icono-camera"></i>
            </button>
            <button type="submit" disabled={props.isSubmitting}>
              <i className="send-icon icon zaia-icono-microphone"></i>
            </button>
            <input
              placeholder={t("algorithms.msg")}
              name="msg"
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.msg}
              autoComplete="off"
            />
            <button type="submit" disabled={props.isSubmitting}>
              <i className="send-icon icon zaia-icono-send send-icon--big"></i>
            </button>
          </form>
        )}
      </Formik>
    </S.MessageDisplayDiv>
  );
}

const ChatSettings = ({setSettingsGroup, close}) => {
 const [t] = useTranslation("global");
  return (
    <S.ChatSettings>
      <div className="chat-settings-box" onClick={() => {setSettingsGroup(true); close()}}>
        <i className="icono-settings zaia-alert"></i>
        {t("chat.settingsChat.info")}
      </div>
    </S.ChatSettings>
  )
}


export default MessageDisplay;
