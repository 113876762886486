import React, { useEffect, useState } from "react";
import { ProgressBarDiv } from "./style";

function ProgressBar({ done }) {
  const [style, setStyle] = useState({});
  useEffect(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
    };
    setStyle(newStyle);
  }, [done]);
  return (
    <ProgressBarDiv>
      <div style={style}></div>
    </ProgressBarDiv>
  );
}

export default ProgressBar;
