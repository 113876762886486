import styled from "styled-components";

export const ListOfGroups = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .chat-box {
    width: 100%;
    display: flex;
  }
`