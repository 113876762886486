import { gql } from "@apollo/client";

export const SUSCRIPTION_FEELINGS_COMMENT_REPORTS = gql`
  subscription SUSCRIPTION_FEELINGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz
  ) {
    userToFeeling(where: {date: {_gte: $initialDate, _lte: $finalDate}, description: {_is_null: false}}) {
      date
      description
      id
      levelFeeling
      userId
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUSCRIPTION_FEELINGS_COMMENT_REPORTS_BY_USER = gql`
  subscription SUSCRIPTION_FEELINGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid
  ) {
    userToFeeling(where: {date: {_gte: $initialDate, _lte: $finalDate}, userId: {_eq: $userId}, description: {_is_null: false}}) {
      date
      description
      id
      levelFeeling
      userId
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`