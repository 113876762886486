import styled from "styled-components";

export const ImgMessage = styled.img`
  /* width: ${({width}) => width ? `${width}px` : 'auto'}; */
  height: ${({height}) => height ? `${height}px` : 'auto'};
  margin: ${({marginAuto}) => marginAuto ? '10px 10px 20px 0px' : 'auto'};
  border-radius: ${({rounded}) => rounded ? "10px 10px 10px 0" : null};
  max-height: 600px;
  object-fit: cover;
  box-shadow: 0px 2px 6px ${({theme}) => `${theme.blackShadow}1A`}; 
  font-size: 8px;
  vertical-align: middle;
  cursor: ${({pointer}) => pointer ? 'pointer' : null };
`

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`