import { useField } from "formik";
import React from "react";
import { CustomCheckBoxDiv } from "./style";

function CustomCheckBox({ children, ...props }) {
  const [field, meta] = useField(props, "checkbox");
  return (
    <CustomCheckBoxDiv>
      <label className="checkbox">
        <input type="checkbox" {...field} {...props} />
        {children}
      </label>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </CustomCheckBoxDiv>
  );
}

export default CustomCheckBox;
