import styled from "styled-components";

export const OutcomesTable = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 10px;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  .illness-control-reaction {
    width: 100%;
    height: 100%;
    padding: 100px 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
    thead {
      padding: 10px;
      tr {
        th {
          border-bottom: 1.6px solid var(--no-notification-background);
          color: var(--blue-color);
          padding: 18px 10px 15px 10px;
          text-align: left;
          font-size: 17px;
        }
      }
    }
    tbody {
      padding: 10px;
      tr {
        .icon-save-form-field {
          margin-right: 10px;
          color: ${({theme}) => theme.inputSelected};
          cursor: pointer;
        }
        td {
          padding: 13px 10px;
          text-align: left;
          color: var(--blue-dark-color);
          vertical-align: text-top;
          p {
            color: var(--blue-color);
            font-family: "Open Sans Bold";
            font-size: 12px;
            margin-bottom: 5px;
          }
          .template-header-item-icon {
            color: var(--blue-color);
            font-size: 12px;
            margin-left: 5px;
          }
          i {
            color: #909090;
          }
        }
        .illness-control-document-icon {
          margin-left: 19px;
          font-size: 17px;
          display: flex;
          cursor: pointer;
          color: var(--blue-color);
        }
        :nth-child(odd) {
          background-color: var(--table-color);
        }
      }
    }
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`