import { gql } from "@apollo/client";

export const MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD = gql`
  mutation MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD(
    $customFormId: bigint
    $doctorId: uuid
    $id: uuid
    $typeView: String 
    $userToProcessId: uuid
  ) {
    insert_DoctorToUserProcessBoard_one(
      object: {
        customFormId: $customFormId,
        userToProcessId: $userToProcessId,
        doctorId: $doctorId
        typeView: $typeView
      }
    ) {
      customFormId
      doctorId
      id
      typeView
      userToProcessId
    }
  }
`


export const MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD = gql`
  mutation MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD(
    $id: uuid!
  ) {
    delete_DoctorToUserProcessBoard_by_pk(id: $id) {
      customFormId
      doctorId
      id
      typeView
      userToProcessId
    }
  }
`

export const MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD = gql`
  mutation MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD(
    $id: uuid!
    $typeView: String
  ) {
    update_DoctorToUserProcessBoard_by_pk(pk_columns: {id: $id}, _set: {typeView: $typeView}) {
      customFormId
      doctorId
      id
      typeView
      userToProcessId
    }
  }
`