import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./PrivateStyle";
import { Switch, useLocation } from "react-router-dom";
import { useStateValue } from "../../contextAPI/StateProvider";
import ReactGA from "react-ga";

// Routes
import privateRoutesList from "../../routes/privateRoutesList";
import PrivateRoute from "../../routes/components/PrivateRoute";

// Components
import UserHeader from "./shared/userHeader";
import Sidebar from "./shared/sidebar";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_DOCTOR_DATA } from "../../graphql/queries/Doctor";

// Hooks
import useDoctorData from "../../hooks/useDoctorData";

const env = process.env.REACT_APP_ENV;

function Private ({ authed: isAuth }) {
  const { pathname } = useLocation();
  const idPath = pathname.split('/')[1];
  const [t] = useTranslation("global");
  const [{ popUp }] = useStateValue();
  const { saveDoctorData } = useDoctorData();
  const currentPage = privateRoutesList.find(route => route.id === idPath);

  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(QUERY_GET_DOCTOR_DATA);

  useEffect(() => {
    saveDoctorData(data?.doctor[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (env === 'production') {
      ReactGA.pageview(currentPage.id);
    }
  }, [currentPage])


  return (
    <S.Private activeModal={popUp.isActive}>
      <div className="private">
        <div className="private__sidebar">
          <Sidebar namePage={currentPage.id}/>
        </div>
        
        <div className="private__right">

        <div className="private__right-header">
          <UserHeader 
            title={t(currentPage.name)}
            icon={currentPage.icon}
          />
        </div>

        <div className="private__right-body">
          <div className="private__right-body-content">
            <Switch>
              {privateRoutesList.map(privateRoute => (
                <PrivateRoute 
                  {...privateRoute}
                  exact
                  authed={isAuth}
                />
              ))}
            </Switch>
          </div>
          {
            popUp.isActive ? 
              <div className="private__right-body-modal">
                <popUp.modal /> 
              </div>
            : null
          }
        </div>
      </div>
      </div>
    </S.Private>
  )

}

export default Private;
