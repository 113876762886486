import React from "react";
import * as S from "./InvitePatientButton.style";

function InvitePatientButton({activate}) {

  return (
    <S.InvitePatientButton modal={activate}>
      <div className="rightsidePatient__btn">
        <div className="rightsidePatient__btnContainer">
          <button>
            <div className="icon zaia-icono-perfil"></div>
          </button>
          <div className="rightsidePatient__btnContainerplus">+</div>
        </div>
      </div>
    </S.InvitePatientButton>
  );
}

export default InvitePatientButton;
