import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { RegisterFormDiv } from "../../Register.style";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import CustomRadioBox from "../CustomRadioBox";
import CustomTextInput from "../CustomTextInput";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { StepFourDiv } from "./style";
import { useTranslation } from "react-i18next";

function StepFour({ activeStep, setActiveStep }) {
  const [t] = useTranslation("global");
  const [, dispatch] = useStateValue();
  const handleNext = (values, { setSubmitting, resetForm }) => {
    dispatch({
      type: "SET_STEPFOUR",
      stepFour: values,
    });
    resetForm();
    setSubmitting(false);
    setActiveStep(75);
  };

  const radioOptions = [
    { key: `${t("register.yes")}`, value: "si" },
    { key: `${t("register.no")}`, value: "no" },
  ];

  return (
    <Formik
      initialValues={{
        firstEspecialty: "",
        radioOption1: "",
        secondEspecialty: "",
        radioOption2: "",
      }}
      validationSchema={Yup.object({
        firstEspecialty: Yup.string(),
        radioOption1: Yup.string(),
        secondEspecialty: Yup.string(),
        radioOption2: Yup.string(),
      })}
      onSubmit={handleNext}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {activeStep === 60 && (
            <>
              <Header stepFour setActiveStep={setActiveStep} />
              <ProgressBar done={activeStep} />
              <RegisterFormDiv>
                <StepFourDiv>
                  <h3 className="title-register">{t("register.especialty")}</h3>
                  <div className="registerForm__radio">
                    <CustomRadioBox
                      name="radioOption1"
                      options={radioOptions}
                    />
                  </div>
                  <h3 className="title-register">
                    {t("register.firstEspecialty")}
                  </h3>
                  <CustomTextInput
                    placeholder={t("register.firstEspecialtyPlace")}
                    type="text"
                    name="firstEspecialty"
                    disabled={props.values.radioOption1 === "si" ? false : true}
                  />

                  <h3 className="title-register secondSpecialty">
                    {t("register.especialty2")}
                  </h3>
                  <div className="registerForm__radio">
                    <CustomRadioBox
                      name="radioOption2"
                      options={radioOptions}
                      disabled={
                        props.values.radioOption1 === "si" ? false : true
                      }
                    />
                  </div>
                  <h3 className="title-register">
                    {t("register.secondEspecialty")}
                  </h3>
                  <CustomTextInput
                    placeholder={t("register.secondEspecialtyPlace")}
                    type="text"
                    name="secondSpecialty"
                    disabled={
                      props.values.radioOption1 === "si" &&
                      props.values.radioOption2 === "si"
                        ? false
                        : true
                    }
                  />
                  <ZaiaButton
                    type="submit"
                    marginTop="37"
                    title={
                      props.isSubmitting
                        ? "Loading ..."
                        : `${t("register.continue")}`
                    }
                  />
                </StepFourDiv>
              </RegisterFormDiv>
            </>
          )}
        </form>
      )}
    </Formik>
  );
}

export default StepFour;
