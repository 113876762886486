import styled, {keyframes} from "styled-components";


const expansion = () => keyframes`
 0% {
  box-shadow: 0 0 0 0px rgba(255,0,0,.5);
 }
 100% {
  box-shadow: 0 0 0 5px rgba(255,0,0,.5);
 }
`

export const ZaiaInputForm = styled.div`
  width: -webkit-fill-available;
  .input-container {
    background-color: ${({theme}) => theme.inputBackground};
    border: none;
    box-sizing: border-box;
    padding: ${({padding}) => padding ? padding :'7px 7px'};
    border-radius: 8px;
    display: flex;
    align-items: center;
    min-width: 100px;
    cursor: ${({disabled}) => disabled ? '' :'text'};
    position: relative;
    border: 1.8px solid ${
        ({ 
          theme, 
          active, 
          error
        }) => 
          active 
          ? theme.inputSelected : 
          error?.error && error?.touched 
            ? theme.warning 
            : theme.inputBorder
      };
    .icon-input-form {
      margin-bottom: -5px;
      font-size: 14px;
    }
    .icon-input-pass {
      margin-bottom: -5px;
      font-size: 14px;
      cursor: pointer;
    }
    input {
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Open Sans";
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      margin-left: 7px;
      color: ${({theme}) => theme.inputText};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme }) => theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Open sans";
      }
    }
    textarea {
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Open Sans";
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      margin-left: 7px;
      height: 80px;
      resize: none;
      color: ${({theme}) => theme.inputText};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme }) => theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Open sans";
      }
      &::-webkit-scrollbar {
          height: 4px;
          width: 20px;
          margin: 0;
          opacity: .9;
          padding-bottom: 10px;
          cursor: pointer;
        }
        ::-webkit-scrollbar-track {
          min-height: 5px !important;
        }
        ::-webkit-scrollbar-thumb {
          min-height: 30px !important;
        }
    }
    .mic-container {
      color: transparent;
      position: absolute;
      right: 7px;
      bottom: 7px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      animation: ${({activedMic}) => activedMic ? expansion() : null} 2s ease infinite;
      cursor: pointer;
      display: flex;
      .mic-icon {
        color: ${({activedMic, theme}) => !activedMic ? theme.primaryColor : theme.warning};
        margin: 3px auto;
      }
    }
    .play-record-button {
    }
  }
  .input-error {
    height: 15px;
    padding: 1px 5px;
    font-size: 10px;
    color: ${({theme}) => theme.warning};
    i {
      margin-right: 2px;
    }
  }
`