import { gql } from "@apollo/client";

// $doctorId: uuid
export const SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID = gql`
  subscription DashBoardProstate( 
    $userId: uuid
    $customFormId: bigint!
    $userToProcessId: uuid!
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(where: {_or: {userId: {_eq: $userId}}}, order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`

//(where: {_or: {doctorId: {_eq: $doctorId}, userId: {_eq: $userId}}}, order_by: {created_at: desc})