import styled from "styled-components";

export const EventsDiv = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  h3 {
    margin-bottom: 10px;
    height: 15px;
    font-size: 16px;
    font-family: 'Nunito ExtraBold';
    color: ${({theme}) => theme.onBackground}
  }
  .cards-calendar-list {
    overflow-y: auto; 
    height: calc(100% - 25px);
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  p {
    font-weight: 200 !important;
    font-size: 0.7rem;
  }
`;
