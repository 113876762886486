import styled from "styled-components";

export const ChatDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  .chat__left {
    width: 380px;
    z-index: 200;
    .chat__centerContainer {
      display: flex;
      flex-direction: column;
      background-color: var(--white);
      height: 100%;
      border-right: 1px solid lightgray;
      position: relative;
      .chat__centerContainer-top {
        height: 173px;
      }
      .chat__centerContainerChats {
        height: calc(100% - 173px);
        padding: 0 10px;
        border-radius: 30px;
        ::-webkit-scrollbar {
          display: none;
        }
      }
      .chat__centerContainerBtnDiv {
        position: sticky;
        z-index: 100;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
      }
      .chat__centerContainerBtn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          background-color: var(--blue-box-headercolor);
          color: var(--white);
          border-radius: 20px;
          padding: 15px 70px 15px 70px;
          border: none;
          cursor: pointer;
        }
        div {
          background-color: var(--white);
          color: var(--blue-box-headercolor);
          border: 2px solid var(--blue-box-headercolor);
          border-radius: 50%;
          height: 25px;
          width: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          bottom: -6px;
          right: -6px;
          font-size: 1.3rem;
        }
      }
    }
  }
  .chat__right {
    display: flex;
    position: relative;
    background-color: var(--white);
    width: calc(100% - 380px);
    height: 100%;
    z-index: 100;
    &-message-display {
      display: flex;
      height: 100%;
      width: ${({settingsGroup}) => settingsGroup ? "calc(100% - 310px)" : "100%"};
      z-index: 100;
    }
    &-settings {
      height: 100%;
      width: 310px;
      box-shadow: 0px 3px 6px #00000029;
      z-index: 200;
    }
  }
`;
