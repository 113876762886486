import { gql } from "@apollo/client";

export const MUTATION_ENRROLL_PATIENCE = gql`
  mutation MUTATION_ENRROLL_PATIENCE(
    $descript: String
    $lastname: String
    $processId: Int!  
    $stageId: String!
    $document: String
    $email: String
    $isAcepted: Boolean
  ) { enrrollPatient(descript: $descript, lastname: $lastname, processId: $processId, stageId: $stageId, document: $document, email: $email, isAcepted: $isAcepted) {
      message
    }
  }
`;
