import styled from "styled-components";

export const SidebarTopPatients = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 0.9rem;
`;

export const ToogleContainer = styled.div`
  height: 26px;
  margin-bottom: 10px;
`;

export const FilterContainer = styled.div`
  margin: 0 5px 10px 5px;
`;

export const InputContainer = styled.div`
  margin: 0 5px;
`
export const TitleContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  color: var(--blue-color);
  font-family: 'Nunito ExtraBold';
  align-items: center;

  span {
    font-family: 'Nunito Semibold';
    font-size: 17px;
  }
`

