// // graphQL
// import { useQuery } from "@apollo/client";
// import { QUERY_GET_DOCTOR_DATA } from "../graphql/queries/Doctor";

// export default function useDoctorData() {
  
//   const { data: doctorData } = useQuery(QUERY_GET_DOCTOR_DATA);

//   return { 
//     doctorData: doctorData?.doctor[0]
//   };
// }

import { useStateValue } from "../contextAPI/StateProvider";

export default function useDoctorData() {
  const [{ doctorData }, dispatch] = useStateValue();

  const saveDoctorData = (doctor) => {
    if( doctor ) {
      dispatch({
        type: "SET_DOCTOR_DATA",
        doctorData: doctor,
      });        
    } 
  }

  return {
    doctorData: doctorData,
    saveDoctorData: saveDoctorData
  };
}