import styled from "styled-components";

export const Notification = styled.div`
  cursor: pointer;
  margin: auto 10px auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme, color, selected, colorSelected}) => color ? color : selected ? colorSelected ? colorSelected : theme.allCards : theme.cardFilter };
  border-radius: 30px;
  height: ${(p) => p.icon ? "30px" : "25px"};
  padding: ${(p) => p.info ? "0 10px" : "0 7px 0 7px"};
  color: ${({theme, colorText, selected}) => colorText ? colorText : selected ? theme.onCard : theme.onCardFilter};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .icon-container {
    /* margin: 4px 3px 0 2px; */
    font-size: 16px;
    width: 16px;
    height: 18px;
    overflow: hidden;
    margin-right: ${(p) => p.info ? "5px" : "0"};
  }
  p {
    font-size: ${(p) => p.icon ? "1rem": "0.77rem"};
    font-family: ${(p) => p.icon ? "Nunito ExtraBold": ""};
  }
`