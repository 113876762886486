import styled, {keyframes} from "styled-components";

const load = (completed) => keyframes`
  0% { width: 0; }
  100% { width: ${completed}%; }
`;

export const ReverseProgressBarDiv = styled.div`

 .bar__container {
    height: ${(props) => props.height+'px'};
    width: 100%;
    background-color: ${(props) => props.bgColor2};
    border-radius: 50px;
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    .fillerStyles {
      height: 100%;
      width: 0;
      background-color: ${(props) => props.bgColor};
      border-radius: inherit;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      animation: ${({completed}) => load(completed)} 2s normal forwards;
      --webkit-animation: ${({completed}) => load(completed)} 2s normal forwards;
    }
    .labelStyles {
      color: var(--white);
      font-size: 0.55rem;
      margin: 0 5px;
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
    }
  }
`;
