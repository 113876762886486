import styled from "styled-components";

export const Header = styled.div`
  background-color: ${({theme}) => theme.primaryColor};
  height: 75px;
  display: flex;
  flex-direction: column;
  a {
    margin: auto auto auto 20px;
    padding-top: 6px;
    /* position: absolute;
    margin: 24px auto 24px 20px; */
  }
`;
