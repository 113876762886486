import styled from "styled-components";

export const InvitePatientButton = styled.div`
  .rightsidePatient__btn {
    position: absolute;
    bottom: 25px;
    right: 25px;
    cursor: pointer;
    .rightsidePatient__btnContainer {
      background-color: var(--blue-color-200);
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;
      box-shadow: 2px 2px 7px 3px var(--box-shadow);
      button {
        border: none;
        background-color: var(--blue-color-200);
        color: var(--white);
        font-size: 1.3rem;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
      .rightsidePatient__btnContainerplus {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--white);
        border: 1px solid var(--blue-color-200);
        border: ${(p) => p.modal ? "3px solid var(--blue-color-200)" : "2px solid var(--blue-color-200)"};
        color: var(--blue-color-200);
        width: ${(p) => p.modal ? "45px" : "16px"};
        height: ${(p) => p.modal ? "45px" : "16px"};
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${(p) => p.modal ? "50px" : "none"};
        border-radius: 50%;
        transform: ${(p) => p.modal ? "rotate(-45deg)" : "none"};    
        padding: ${(p) => p.modal ? '1px 2px 4px 1px' : ''};
      }
    }
  }
`;
