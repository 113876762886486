import { gql } from "@apollo/client";

export const QUERY_GET_CUSTOM_FORM_BY_IDS = gql`
  query DashBoardProstate(
    $customFormIds: [bigint!]!
  ) {
    customForm(where: {id: {_in: $customFormIds}}) {
      id
      customFormName
      descript
    }
  }
`