  import styled from "styled-components";

  export const FilterPrograms = styled.div`
    display: flex;
    align-items: center;
    text-align: right;
    color: ${({theme}) => theme.onBackground};
    h5 {
      font-family: 'Open Sans Bold';
      color: ${({theme}) => theme.onBackgroundVariant};
      margin-left: 5px;
      cursor: pointer;
      justify-content: end;
    } 
    .dropdown-activer {
      color: ${({theme}) => theme.onBackgroundVariant};
      display: flex;
      align-items: center;
      user-select: none; 
      p {
        font-family: 'Open Sans SemiBold';
        color: red;
        font-size: 0.65rem;
      }
      div {
        margin: 6px 0 0 5px;
        font-size: small;
      }
    }
  `