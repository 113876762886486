import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { RegisterFormDiv } from "../../Register.style";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import CustomCheckBox from "../CustomCheckBox";
import CustomSelect from "../CustomSelect";
import CustomTextInput from "../CustomTextInput";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { StepThreeDiv } from "./style";
import { useTranslation } from "react-i18next";

function StepThree({ activeStep, setActiveStep }) {
  const [t] = useTranslation("global");
  const [, dispatch] = useStateValue();
  const handleNext = (values, { setSubmitting, resetForm }) => {
    dispatch({
      type: "SET_STEPTHREE",
      stepThree: values,
    });
    resetForm();
    setSubmitting(false);
    setActiveStep(60);
  };

  return (
    <Formik
      initialValues={{
        typeOfId: "",
        id: "",
        medicalRecords: "",
        medicalRecordsBool: false,
      }}
      validationSchema={Yup.object({
        typeOfId: Yup.string()
          .oneOf(["Cédula de cuidadanía", "Cédula extranjera", "Pasaporte"])
          .required(`${t("register.validation.required")}`),
        id: Yup.number()
          .min(10, `${t("register.validation.min10")}`)
          .required(`${t("register.validation.required")}`),
        medicalRecords: Yup.number()
          .min(10, `${t("register.validation.min10")}`)
          .required(`${t("register.validation.required")}`),
        medicalRecordsBool: Yup.boolean(),
      })}
      onSubmit={handleNext}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {activeStep === 45 && (
            <>
              <Header stepThree setActiveStep={setActiveStep} />
              <ProgressBar done={activeStep} />
              <RegisterFormDiv>
                <StepThreeDiv>
                  <h3 className="title-register">
                    {t("register.identification")}
                  </h3>
                  <CustomSelect icon="zaia-icono-id" name="typeOfId">
                    <option value="" hidden>
                      {t("register.typeOfId")}
                    </option>
                    <option value="Cédula de cuidadanía">
                      {t("register.greenCard")}
                    </option>
                    <option value="Cédula extranjera">
                      {t("register.foreign")}
                    </option>
                    <option value="Pasaporte">{t("register.passport")}</option>
                  </CustomSelect>
                  <CustomTextInput
                    placeholder={t("register.numberId")}
                    name="id"
                    type="number"
                    icon="zaia-icono-id"
                  />
                  <h3 className="title-register">
                    {t("register.medicalRecord")}
                  </h3>
                  <CustomTextInput
                    placeholder={t("register.medicalRecordNum")}
                    name="medicalRecords"
                    type="number"
                    disabled={props.values.medicalRecordsBool}
                    icon="zaia-icono-id"
                  />
                  <div className="registerForm__checkboxLabel">
                    <CustomCheckBox name="medicalRecordsBool">
                      {t("register.dontMedical")}
                    </CustomCheckBox>
                  </div>
                  <ZaiaButton
                    type="submit"
                    marginTop="26"
                    title={
                      props.isSubmitting
                        ? "Loading ..."
                        : `${t("register.continue")}`
                    }
                  />
                </StepThreeDiv>
              </RegisterFormDiv>
            </>
          )}
        </form>
      )}
    </Formik>
  );
}

export default StepThree;
