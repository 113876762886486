import { Formik } from "formik";
import React from "react";
import CustomTextInput from "../CustomTextInput";
import * as Yup from "yup";
import Header from "../Header";
import { useMutation } from "@apollo/client";
import { MUTATION_SIGN_UP_DOCTOR } from "../../../../../graphql/mutations/Auth";
import { RegisterFormDiv } from "../../Register.style";
import { LastStepDiv } from "./style";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import { useTranslation } from "react-i18next";

function LastStep({ activeStep, setActiveStep }) {
  const [t] = useTranslation("global");
  const [signup] = useMutation(MUTATION_SIGN_UP_DOCTOR);
  const [{ stepOne }, dispatch] = useStateValue();

  const handleNext = async (values, { setSubmitting, resetForm }) => {
    dispatch({
      type: "SET_LASTSTEP",
      lastStep: values,
    });
    const response = await signup({
      variables: {
        email: stepOne.email,
        lastname: stepOne.lastName,
        name: stepOne.name,
        password: stepOne.password,
      },
    });
    console.log('response', response);
    resetForm();
    setSubmitting(false);
  };


  return (
    <Formik
      initialValues={{
        user: "",
      }}
      validationSchema={Yup.object({
        user: Yup.string()
          .min(4, `${t("register.validation.min4")}`)
          .required(`${t("register.validation.required")}`),
      })}
      onSubmit={handleNext}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {activeStep === 0 && (
            <>
              <Header lastStep setActiveStep={setActiveStep} />
              <RegisterFormDiv>
                <LastStepDiv>
                  <h1 className="title-register">{t("register.last.title")}</h1>
                  <p className="paragraph">{t("register.last.subtitle")}</p>
                  <h3 className="title-register">
                    {t("register.usernameEnter")}
                  </h3>
                  <CustomTextInput
                    placeholder={t("register.username")}
                    name="user"
                    type="text"
                    icon="zaia-icono-nombre"
                  />
                  <ZaiaButton
                    type="submit"
                    marginTop="150"
                    title={
                      props.isSubmitting
                        ? "Loading ..."
                        : `${t("register.continue")}`
                    }
                  />
                </LastStepDiv>
              </RegisterFormDiv>
            </>
          )}
        </form>
      )}
    </Formik>
  );
}

export default LastStep;
