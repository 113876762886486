import React, { useState } from "react";
import * as S from "./ChangePassForm.style";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

// GraphQL
import { useMutation } from "@apollo/client";
import { MUTATION_CHANGE_PASS } from "../../../../../graphql/mutations/Auth";

// components
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";

// Hooks 
import useActivateModal from "../../../../../hooks/useActivateModal";

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-ok.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;

function ChangePassForm({ doctorEmail }) {

  const [typeForm, setTypeForm] = useState("changePass");
  const { t } = useTranslation("global");
  const {desactiveModal} = useActivateModal()

  const [changePass, { loading: changePassLoading }] = useMutation(MUTATION_CHANGE_PASS);

  const handleSubmit =  (values, { resetForm }) => {
    changePassword(values, resetForm)
  };

  const changePassword = async ( values, resetForm ) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const data = await changePass({
        variables: {
          email: doctorEmail,
          newPass: values.confirmNewPass,
          pass: values.oldPass,
        },
      });
      console.log("gumball", data);
      setTypeForm("success")
    } catch (err) {
      setTypeForm("error")
    }
    resetForm();
  }

  if(changePassLoading) {
    return(
      <S.ChangePassDiv>
        <ZaiaReaction
          widthImg="200px"
          zaiaImg={logoZaiaLoading}
        />
      </S.ChangePassDiv>
    )
  }
  
  return (
    <Formik
      initialValues={{
        oldPass: "",
        newPass: "",
        confirmNewPass: "",
      }}
      validationSchema={Yup.object({
        oldPass: Yup.string()
          .min(3, `${t("register.validation.min")}`)
          .required(`${t("register.validation.password")}`)
          // .matches(
          //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          //   `${t("register.validation.matches")}`
          // )
          ,
        newPass: Yup.string()
          .min(3, `${t("register.validation.min")}`)
          .required(`${t("register.validation.password")}`)
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/i,
            `${t("register.validation.matches")}`
          ),
        confirmNewPass: Yup.string()
          .min(3, `${t("register.validation.min")}`)
          .required(`${t("register.validation.confirmPass")}`)
          .when("newPass", {
            is: (newPass) => (newPass && newPass.length > 0 ? true : false),
            then: Yup.string().oneOf(
              [Yup.ref("newPass")],
              `${t("register.validation.match")}`
            ),
          }),
      })}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <S.ChangePassDiv>
          <form onSubmit={ props.handleSubmit }>
            <div className="card-inputs">
              { 
                typeForm === "changePass" ?
                  <FormChangePassInputs /> : 
                typeForm === "error" ? 
                <ZaiaReaction
                  // text={P.dataTranslation(JSON.parse(errorResponse.message), language)}
                  text={t("profile.changePassError")}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaAlert}
                /> :
                <ZaiaReaction
                  // text={P.dataTranslation(JSON.parse(response), language)}
                  text={t("profile.changePassCorretly")}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaOk}
                />
              }
            </div>
            <div className="card-buttons">
              { 
                typeForm === "changePass" ? 
                <>
                  <ZaiaButton
                    type="submit"
                    option="primary"
                    marginTop="30"
                    title={t("globally.save")}
                  />
                  <div onClick={() => desactiveModal()} className="cancel-button">
                    {t("globally.cancel")}
                  </div>
                </> : 
                typeForm === "error" ? 
                <>
                  <div onClick={() => setTypeForm("changePass")}>
                    <ZaiaButton
                      type="button"
                      option="primary"
                      marginTop="20"
                      title={t("profile.tryAgain")}
                    />
                  </div>
                </> : 
                <>
                <div onClick={() => desactiveModal()}>
                  <ZaiaButton
                    type="button"
                    option="primary"
                    marginTop="20"
                    title={t("patients.ResponseBtnPrimary")}
                  />
                </div>
                </>
              }
            </div>
          </form>
        </S.ChangePassDiv>
      )}
    </Formik>
  );
}
  
  
// components

const FormChangePassInputs = () => {
  const { t } = useTranslation("global");

  return(
    <>
      <h4>{t("profile.changePass")}</h4>
      <ZaiaInputForm 
        placeholder={t("profile.currentPass")}
        type="password"
        name="oldPass"
        icon="icono-contrasena"
      />
      <div className="space-between"></div>
      <ZaiaInputForm 
        placeholder={t("profile.newPass")}
        type="password"
        name="newPass"
        icon="icono-contrasena"
      />
      <div className="space-between"></div>
      <ZaiaInputForm 
        placeholder={t("profile.confimNewPass")}
        type="password"
        name="confirmNewPass"
        icon="icono-contrasena"
      />
    </>
  )
}


export default ChangePassForm;
