import styled from "styled-components";

export const CircleChartCard = styled.div`
  margin: auto;
  position: relative;
  h4 {
    margin-bottom: 10px;
    font-family: "Open Sans Bold";
    font-size: 15px;
  }
  .boxInfoPatient__reports-circle-graph {
    display: flex;
    width: fit-content;
    justify-content: center;
    width: max-content;
    .boxInfoPatient__infoPercents {
      display: flex;
      flex-direction: column;
      color: ${(props) => props.color};
      padding-left: 10px;
      justify-content: space-evenly;
      align-items: flex-start;
    }
  }
`

export const CircleChartText = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0;
  h2 {
    color: ${(props) => props.colorPrimary};
    margin: auto 5px auto auto;
    font-family: "Nunito ExtraBold"
  }
  p {
    color: ${(props) => props.colorSecundary};
    font-size: 10px;
    font-family: "Open Sans"
  }
`


export const CircleChartTextBig = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 0;
  h2 {
    color: ${(props) => props.colorPrimary};
    font-family: "Nunito ExtraBold"
  }
  p {
    color: ${(props) => props.colorSecundary};
    font-size: 13px;
    font-family: "Open Sans Bold"
  }
`