import styled from "styled-components";
import { getHexOpacity } from '../../../../../utils/pipes';

export const InvitationsManagement = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  .card {
    width: 600px;
    max-height: 606px;
    margin: auto;
    padding: 30px;
    .card-info {
      max-height: 412px;
      min-height: 312px;
      background-color: ${({theme}) => theme.background};
      padding: 30px 50px;
      border-radius: 30px;
      box-shadow: 1px 1px 11px 5px ${({theme}) => theme.blackShadow}29;
      overflow: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      &-invitations-List {
        margin-top: 10px;
        min-height: 305px;
        overflow: auto;
      }
    }
    .card-buttons {
      padding-bottom: 20px;
      max-width: 400px;
      margin: auto;
      text-align: center;
      p {
        font-size: .9rem;
      }
    }
  }
`;

export const ToogleContainer = styled.div`
  height: 40px;
  max-width: 300px;
  margin: auto auto 20px auto;
`;

export const PatientBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid var(--hover-box);
  cursor: pointer;
  color: ${({active}) => active ? "var(--white)" : ""};
  background-color: ${({active}) => active ? 'var(--blue-color)' : 'var(--white)' };
  justify-content: space-between;
  &:hover {
    background-color: ${({active}) => active ? "" : "var(--hover-box)"};
  } 
  .box-info {
    display: flex;
  }
  div {
    font-family: "Open Sans";
    .first-text {
      margin-top: 5px;
    }
    .second-text {
      margin-top: 2px;
      color: ${({active}) => active ? "var(--white)" : "var(--subtitle-color)"};
      font-size: 0.67rem;
      margin: 5px 0;
    }
  }
  .box-options {
    display: flex;
    align-items: center;
    .box-options-text {
      text-align: end;
      &-label {
        color: var(--subtitle-color);
        font-size: 12px;
        font-family: 'Open Sans';
      }
      &-date {
        font-size: 15px;
        color: ${(({theme}) => theme.primaryVariantColor)};
        font-family: 'Open Sans';
      }
    }
    i {
      font-size: 15px;
      margin-left: 13px;
      padding-top: 3px;
    }
    .settings-points {
      font-size: 20px;
    }
  }
`;

export const BoxSettings = styled.div`
  position: relative;
  background-color: var(--white);
  height: auto;
  width: 180px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  &::-webkit-scrollbar{
    display: none;
  }
  .settings-box {
    font-family: "Open Sans";
    font-size: 20px;
    color: ${(({theme}) => theme.warning)} !important;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    cursor: pointer;
    .icono-settings {
      font-size: 20px;
      margin-right: 10px;
      margin-bottom: -6px;
    }
  }
`


export const deleteInvitationModal = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  margin: auto;
  display: flex;
  .content-delete {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: auto;
    .zaia-image {
      width: 280px;
      margin: 0px auto 30px;
    }
    .content-delete__title {
      color: ${({theme}) => theme.primaryColor};
      font-family: "Nunito Black";
    }
    .message {
      font-family: 'Open Sans';
      font-size: 17px;
      margin-top: 20px;
      color: ${({theme}) => theme.onBackground};
    }
    .email-container {
      background-color: ${({theme}) => `${theme.primaryColor}${getHexOpacity(0.1)}`};
      padding: 8px;
      border-radius: 10px;
      color: ${({theme}) => theme.primaryColor};
      margin: 25px 0;
      font-family: "Nunito ExtraBold";
    }
    .button-container-principal {
      width: 300px;
      margin: 50px auto 0;
      .button-message {
        margin-top: 20px;
        padding: 0 15px;
      }
    }
    .button-container-secondary {
      width: 300px;
      margin: 20px auto 0;
      .button-message {
        margin-top: 20px;
        padding: 0 15px;
      }
    }
    .cancel-button {
      text-align: center;
      font-family: "Open Sans";
      margin-top: 30px;
      color: var(--blue-color);
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
    }
  }
`