import { gql } from "@apollo/client";

export const QUERY_GET_COUNTRIES = gql`
  query QUERY_GET_COUNTRIES {
    country {
      id
      phonecode
      name
      sortname
    }
  }
`