import { useState } from 'react';

export default function useUploadImage() {
  const urlEndpoint = 'https://us-central1-oncobot.cloudfunctions.net/storageserver';
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const uploadImage = (imageData) => {
    setLoading(true);
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest();
      var fd = new FormData();
      xhr.open('POST', urlEndpoint, true);
      xhr.onreadystatechange = () => {
        if(xhr.readyState === 4) {
          if(xhr.status === 200) {
            resolve(JSON.parse(xhr.responseText))
            setData(JSON.parse(xhr.responseText));
            setError(null);
            setLoading(false);
          } else {
            reject(xhr.status + ' Error uploading the picture')
            setError(xhr.status + ' Error uploading the picture');
            setData(null)
            setLoading(false);
          }
        }
      };
      fd.append('file', imageData);
      if (imageData) {
        console.log(xhr);
        xhr.send(imageData ? fd : null);
      } else {
        resolve(null);
      }
    });
  }
  
  return {
    uploadImage,
    data,
    loading,
    error,
  }
}