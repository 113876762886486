import React from "react";
import * as S from "./Notification.style";

function Notification({color, colorText, icon, info=null, selected, colorSelected}) {

  return (
    <S.Notification 
      color={color} 
      colorText={colorText} 
      icon={icon}
      info={info}
      selected={selected}
      colorSelected={colorSelected}
    >
      {
        icon ? 
        <div className="icon-container">
          <i className={"icon " + icon}></i>
        </div>
        : null
      }
      <p>
        {info}
      </p>
    </S.Notification>
  )
}

export default Notification