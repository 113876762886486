import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { RegisterFormDiv } from "../../Register.style";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import CheckBoxGroup from "../CheckBoxGroup";
import Header from "../Header";
import ProgressBar from "../ProgressBar";
import { StepFiveDiv } from "./style";
import { useTranslation } from "react-i18next";

function StepSix({ activeStep, setActiveStep }) {
  const [t] = useTranslation("global");
  const [other, setOther] = useState("");
  const [, dispatch] = useStateValue();
  const handleNext = (values, { setSubmitting, resetForm }) => {
    dispatch({
      type: "SET_STEPSIX",
      stepSix: values,
    });
    resetForm();
    setSubmitting(false);
    setActiveStep(100);
  };

  const checkBoxOptions2 = [
    { key: `${t("register.public")}`, value: "institucion publica" },
    { key: `${t("register.priv")}`, value: "institucion privada" },
    { key: `${t("register.collage")}`, value: "institucion universitaria" },
    { key: `${t("register.consul")}`, value: "consultorio privado" },
    { key: `${t("register.other")}`, value: "other" },
  ];

  return (
    <Formik
      initialValues={{
        checkBoxOption2: [],
      }}
      validationSchema={Yup.object({
        checkBoxOption2: Yup.array(),
      })}
      onSubmit={handleNext}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          {activeStep === 90 && (
            <>
              <Header stepSix setActiveStep={setActiveStep} />
              <ProgressBar done={activeStep} />
              <RegisterFormDiv>
                <StepFiveDiv>
                  <h3 className="title-register">
                    {t("register.experience")}:
                  </h3>
                  <p>{t("register.select")}</p>
                  <CheckBoxGroup
                    name="checkBoxOption2"
                    options={checkBoxOptions2}
                  />
                  <input
                    className="stepFiveDiv__input"
                    type="text"
                    value={other}
                    onChange={(e) => setOther(e.target.value)}
                    placeholder={t("register.others")}
                  />
                  <ZaiaButton
                    type="submit"
                    marginTop="79"
                    title={
                      props.isSubmitting
                        ? "Loading ..."
                        : `${t("register.continue")}`
                    }
                  />
                </StepFiveDiv>
              </RegisterFormDiv>
            </>
          )}
        </form>
      )}
    </Formik>
  );
}

export default StepSix;
