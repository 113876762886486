import { gql } from "@apollo/client";

export const SUSCRIPTION_DRUGS_COMMENT_REPORTS = gql`
  subscription SUSCRIPTION_DRUGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz
  ) {
    userToDrugHistory(where: {created_at: {_gte: $initialDate, _lte: $finalDate}, isTaken: {_eq: false}, comment: {_is_null: false, _neq: ""}}) {
      id
      comment
      created_at
      delay
      dose
      doseType
      drugId
      userId
      userToDrug {
        drug {
          genericName
        }
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUSCRIPTION_DRUGS_COMMENT_REPORTS_BY_USER = gql`
  subscription SUSCRIPTION_DRUGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid
  ) {
    userToDrugHistory(where: {created_at: {_gte: $initialDate, _lte: $finalDate}, userId: {_eq: $userId}, isTaken: {_eq: false}, comment: {_is_null: false, _neq: ""}}) {
      id
      comment
      created_at
      delay
      dose
      doseType
      drugId
      userId
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`