import React, { useState } from "react";
import * as S from "./Dashboard.style";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";
import Popup from "reactjs-popup";

import {useHistory} from "react-router-dom"

// Components
import Box from "./components/box";
import Filters from "./components/filters";
import WelcomeBox from "./components/welcomeBox";
import TooltipChats from "../shared/tooltipChats";
import ListOfBoxes from "./components/listOfBoxes";
import Notification from "./components/notification"
import FilterPrograms from "../shared/filterPrograms";
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import TooltipProcesses from "../shared/tooltipProcesses";
import PatientReviewBox from "./components/patientReviewBox";
import DashboardRightSide from "./components/dashboardRightSide";

// Pipes
import * as P from "../../../utils/pipes"
import InvitePatientButton from "../patients/components/invitePatientButton";

function Dashboard() {
  const [t] = useTranslation("global");
  const history = useHistory();

  const [{ selectedPatient }] = useStateValue();

  const [filterSelected, setFilterSelected] = useState(0);
  const [processIds, setProcessIds] = useState([]);
  const [allNotificationNumber, setAllNotificationNumber] = useState(0);
  const [mildNotificationNumber, setMildNotificationNumber] = useState(0);
  const [mediumNotificationNumber, setMediumNotificationNumber] = useState(0);
  const [seriousNotificationNumber, setSeriousNotificationNumber] = useState(0);
  const [commentNotificationNumber, setCommentNotificationNumber] = useState(0);
  const [levelReport, setLevelReport] = useState(0);
  const [initialDate, setInitialDate] = useState();
  const [finishDate, setFinishDate] = useState(new Date(Date.now()));
  const [selectedBox, setSelectedBox] = useState();
  const [seePhone, setSeePhone] = useState(false);
  const levels = [
    {
      id: 1,
      name: t("dashboard.reports.mildReport"),
      color: 'var(--box-mild-report-box)',
    },
    {
      id: 2,
      name: t("dashboard.reports.mediumReport"),
      color: 'var(--box-middle-report-box)'
    },
    {
      id: 3,
      name: t("dashboard.reports.seriousReport"),
      color: 'var(--box-serious-report-box)',
    },
    {
      id: 4, 
      name: t("dashboard.reports.commentReport"),
      color: "var(--box-comment-report-box)"
    }
  ]

  return (
    <S.DashboardDiv selectedBox={selectedBox}>
        <div className="dashboard__container-left">
          {
            !selectedBox ? null : 
            <div className="dashboard__container-left-modal">
              <div className="box-container">
                <Box
                  patient={selectedBox.user?.name + " " + selectedBox.user?.lastname}
                  time={P.hoursIn12format(new Date(selectedBox.orderDate).getHours(), new Date(selectedBox.orderDate).getMinutes())}
                  date={P.shortDate(new Date(selectedBox.orderDate), t, true)}
                  description={selectedBox.description}
                  report={t("dashboard.boxes.vitalSigns.report")}
                  type={selectedBox.type}
                  patientPhoto={selectedBox.user?.picture}
                  levelReport={selectedBox.reportlevel}
                  completeBox={true}
                />
                <div className="buttons-box-container">
                  <div className="buttons-box-patient">
                    <div className="button-box-patient" onClick={() => setSeePhone(!seePhone)}>
                      <ZaiaButton
                        type="button"
                        option="primary"
                        marginTop="20"
                        title={t("dashboard.boxes.boxOption.call")}
                        icon="zaia-icono-llamar"
                        fontFamily="Nunito ExtraBold"
                        fontSize="15"
                        borderRadius="10"
                        shadow={true}
                      />
                    </div>
                    
                    <Popup
                      trigger={
                        <div className="button-box-patient">
                          <ZaiaButton
                            type="button"
                            option="primary"
                            marginTop="20"
                            title={t("dashboard.boxes.boxOption.chat")}
                            icon="zaia-icono-chat"
                            fontFamily="Nunito ExtraBold"
                            fontSize="15"
                            borderRadius="10"
                            shadow={true}
                          />
                        </div>
                      }
                    >
                      <TooltipChats patientID={selectedBox?.user.id}/>
                    </Popup>
                  </div>
                  <div className="buttons-box-patient">
                    <div className="button-box-patient" onClick={() => history?.push(`/patients/${selectedBox?.user.id}?panelActive=false`)}>
                      <ZaiaButton
                        type="button"
                        option="primary"
                        marginTop="20"
                        title={t("dashboard.boxes.boxOption.seePatient")}
                        icon="zaia-icono-pacient"
                        fontFamily="Nunito ExtraBold"
                        fontSize="15"
                        borderRadius="10"
                        shadow={true}
                      />
                    </div>
                    <Popup
                      trigger={
                        <div className="button-box-patient">
                          <ZaiaButton
                            type="button"
                            option="primary"
                            marginTop="20"
                            title={t("dashboard.boxes.boxOption.results")}
                            icon="zaia-icono-resultados"
                            fontFamily="Nunito ExtraBold"
                            fontSize="15"
                            borderRadius="10"
                            shadow={true}
                          />
                        </div>
                      }
                    >
                      <TooltipProcesses patientID={selectedBox?.user.id}/>
                    </Popup>
                  </div>
                  <div className="phone-number-container">
                    {
                      seePhone ? 
                      <div className="contact-info">
                        <div className="contact-info-box contact-info-box--patient">
                          <div className="label label--patient">
                            {t("dashboard.boxes.boxOption.callPatient")}
                          </div>
                          <div className="number number--patient">
                            {selectedBox.user.phone}
                          </div>
                        </div>
                        <div className="contact-info-box contact-info-box--emergency">
                          <div className="label label--emergency">
                            {t("dashboard.boxes.boxOption.emergencyContact")}
                          </div>
                          <div className="name">
                            {selectedBox.user.nameCarer}
                          </div>
                          <div className="number number--emergency">
                            {selectedBox.user.phoneCarer}
                          </div>
                        </div>
                      </div>
                      // <ZaiaButton
                      //   type="button"
                      //   option="primary"
                      //   marginTop=""
                      //   title={selectedBox.user.phone}
                      //   shadow={true}
                      //   fontFamily="Nunito ExtraBold"
                      //   fontSize="20"
                      //   color='var(--blue-color)'
                      //   backgroundColor='var(--white)'
                      //   border={false}
                      // /> 
                      : null
                    }
                  </div>
                </div>
              </div> 
              <div onClick={() => setSelectedBox(null)}>
                <InvitePatientButton activate={true} />
              </div>
            </div> 
          }
          <div className="dashboard__container-left-content">
            {
              selectedPatient ? 
              <PatientReviewBox
                selectedPatient={selectedPatient}
              /> : <WelcomeBox/>
            }
            <div className="dashboard__notifications">
              <div className="dashboard__filters">
                <div className="notification-container" onClick={() => setLevelReport(0)}>
                  <Notification 
                    icon="zaia-icono-notificaciones"
                    color="var(--yellow-color)"
                    colorText="var(--white)"
                    info={`${t("dashboard.reports.notifications")} (${allNotificationNumber})`}
                  />
                </div>
                {
                  levels.map(level => {
                    return (
                      <div className="notification-container" key={level.id} onClick={() => setLevelReport(level.id)}>
                        <Notification 
                          info={`
                            ${level.name} 
                            (${
                              level.id === 1 
                              ? mildNotificationNumber 
                              : level.id === 2
                                ? mediumNotificationNumber 
                                : level.id === 3 
                                  ? seriousNotificationNumber  
                                  : commentNotificationNumber 
                            })
                          `} 
                          selected={(levelReport === level.id)} 
                          colorSelected={level.color}
                        />
                      </div>
                    )
                  })
                }
              </div>
              <div className="dashboard__programs">
                <FilterPrograms processIds={processIds} setProcessIds={setProcessIds} activeQuantity={false}/>
              </div>
            </div>
            <div className="dashboard__boxes-container">
              <div className="dashboard__boxes-area">
                <ListOfBoxes 
                  filterSelect={filterSelected} 
                  processIds={processIds} 
                  setAllNotificationNumber={setAllNotificationNumber}
                  setMildNotificationNumber={setMildNotificationNumber}
                  setMediumNotificationNumber={setMediumNotificationNumber}
                  setSeriousNotificationNumber={setSeriousNotificationNumber}
                  setCommentNotificationNumber={setCommentNotificationNumber}
                  levelReport={levelReport}
                  initialDate={initialDate}
                  finishDate={finishDate}
                  selectedPatient={selectedPatient?.id}
                  setSelectedBox={setSelectedBox}
                />
              </div>
              <div className="dashboard__boxes-filter">
                <Filters
                  selected={filterSelected}
                  setSelected={setFilterSelected}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard__container-right">
          <DashboardRightSide 
            initialDate={initialDate}
            finishDate={finishDate}
            setInitialDate={setInitialDate}
            setFinishDate={setFinishDate}
          />
        </div>
    </S.DashboardDiv>
  );
}

export default Dashboard;
