import React from "react";
import * as S from "./DatetimeXAxisAreaChart.style";
import ReactApexChart from 'react-apexcharts';
import * as P from "../../../../../utils/pipes";
import { useTranslation } from "react-i18next";


const DatetimeXAxisAreaChart = ({
  nameIndicator,
  dataChart,
  colors=['var(--blue-color)'],
  lineWidth=4,
  gradient=false,
  curveLine=true,
  multipleChart=false,
  colorMarker='#F44336',
  minDate=new Date(Date.now()).getTime(),
  maxDate=new Date(Date.now() + 604800000).getTime()
}) => {

  const [t] = useTranslation("global");

  const series = multipleChart ? 
  [{
    name: nameIndicator[0],
    data: dataChart[0] ? dataChart[0] : []
  },
  {
    name: nameIndicator[1],
    data: dataChart[1] ? dataChart[1] : []
  }] :
  [{
    name: nameIndicator,
    data: dataChart ? dataChart : []
  }]

  const options = {
    colors : colors,
    chart: {
      id: 'area-datetime',
      // type: 'area',
      // height: 3,
      zoom: {
        autoScaleYaxis: true
      },
      defaultLocale: 'en/es',
      locales: [{
        name: 'en/es',
        options: {
          months: [
            P.nameMonth(0, 'L', t),
            P.nameMonth(1, 'L', t),
            P.nameMonth(2, 'L', t),
            P.nameMonth(3, 'L', t),
            P.nameMonth(4, 'L', t),
            P.nameMonth(5, 'L', t),
            P.nameMonth(6, 'L', t),
            P.nameMonth(7, 'L', t),
            P.nameMonth(8, 'L', t),
            P.nameMonth(9, 'L', t),
            P.nameMonth(10, 'L', t),
            P.nameMonth(11, 'L', t),
          ],
          shortMonths: [
            P.nameMonth(0, 'S', t),
            P.nameMonth(1, 'S', t),
            P.nameMonth(2, 'S', t),
            P.nameMonth(3, 'S', t),
            P.nameMonth(4, 'S', t),
            P.nameMonth(5, 'S', t),
            P.nameMonth(6, 'S', t),
            P.nameMonth(7, 'S', t),
            P.nameMonth(8, 'S', t),
            P.nameMonth(9, 'S', t),
            P.nameMonth(10, 'S', t),
            P.nameMonth(11, 'S', t),
          ],
          days: [
            P.nameDayWeek(0, 'L', t),
            P.nameDayWeek(1, 'L', t),
            P.nameDayWeek(2, 'L', t),
            P.nameDayWeek(3, 'L', t),
            P.nameDayWeek(4, 'L', t),
            P.nameDayWeek(5, 'L', t),
            P.nameDayWeek(6, 'L', t),
          ],
          shortDays: [
            P.nameDayWeek(0, 'S', t),
            P.nameDayWeek(1, 'S', t),
            P.nameDayWeek(2, 'S', t),
            P.nameDayWeek(3, 'S', t),
            P.nameDayWeek(4, 'S', t),
            P.nameDayWeek(5, 'S', t),
            P.nameDayWeek(6, 'S', t),
          ],
          toolbar: {
            download: t("dateTimeAreaChart.svg"),
            selection: t("dateTimeAreaChart.selection"),
            selectionZoom: t("dateTimeAreaChart.selectionZoom"),
            zoomIn: t("dateTimeAreaChart.zoomIn"),
            zoomOut: t("dateTimeAreaChart.zoomOut"),
            pan: t("dateTimeAreaChart.pan"),
            reset: t("dateTimeAreaChart.reset"),
          }
        }
      }]
    },
    stroke: {
      show: true,
      curve: curveLine ? 'smooth' : 'straight',
      // curve: 'straight',
      // curve: 'stepline',
      lineCap: 'round',
      // width: 4
      width: lineWidth
    },
    // annotations: {
    //   yaxis: [{
    //     y: 30,
    //     borderColor: '#999',
    //     label: {
    //       show: true,
    //       text: 'Support',
    //       style: {
    //         color: "#fff",
    //         background: '#00E396'
    //       }
    //     }
    //   }],
    //   xaxis: [{
    //     x: new Date('14 Nov 2012').getTime(),
    //     borderColor: '#999',
    //     yAxisIndex: 0,
    //     label: {
    //       show: true,
    //       text: 'Rally',
    //       style: {
    //         color: "#fff",
    //         background: '#775DD0'
    //       }
    //     }
    //   }]
    // },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
      colors: colorMarker
    },
    // xaxis: {
    //   type: 'datetime',
    //   min: new Date('01 Mar 2012').getTime(),
    //   tickAmount: 1,
    // },
    xaxis: {
      type: 'datetime',
      min: minDate, // Where the 6 is the number of days
      max: maxDate, // Today
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: gradient ? 'gradient' : 'image',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.3,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
  }

  return (
    <S.DatetimeXAxisAreaChart>
      <ReactApexChart 
        options={options} 
        series={series} 
        type={'area'} 
        height={'100%'} 
        width={'100%'}
      />
    </S.DatetimeXAxisAreaChart>
  );
};

export default DatetimeXAxisAreaChart;
