import React from "react";
import { useTranslation } from "react-i18next";
import { EventsDiv } from "./ListOfEvents.Style";

// Components
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;


function Events({ title }) {
  const [t] = useTranslation("global");

  return (
    <EventsDiv>
      <h3>{title}</h3>
      <div className="cards-calendar-list">
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthImg="110px"
          text={t("dashboard.events.noUpcomingEvents")}
          sizeText="17px"
          widthText="240px"
        />
        {/* <RightBox
          icon="zaia-icono-signos-vitales"
          title={t("dashboard.events.boxes.one.title")}
          patient="María Morales"
          date={t("dashboard.events.boxes.one.date")}
          time="03:15 pm"
          img="https://static.iris.net.co/fucsia/upload/images/2018/3/9/85798_1.jpg"
          color="var(--red-color)"
        />
        <RightBox
          title={t("dashboard.events.boxes.two.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.two.date")}
          time="03:15 pm"
          icon="zaia-icono-medicina"
          img="https://www.eluniversal.com.co/sites/default/files/201504/rafael_novoa.jpg"
          color="var(--green-color)"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        />
        <RightBox
          title={t("dashboard.events.boxes.three.title")}
          patient="José Robles"
          date={t("dashboard.events.boxes.three.date")}
          time="03:15 pm"
          icon="zaia-icono-sintomas"
          color="var(--blue-color)"
          img="https://www.cmtv.com.ar/imagenes_artistas/2584.jpg?Camilo"
        /> */}
      </div>
    </EventsDiv>
  );
}

export default Events;
