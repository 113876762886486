import styled from "styled-components";

export const CmpRadioListInfo = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  h5 {
    font-family: "Open Sans Bold";
  }
  .list-scores-container {
    margin: 10px 0 18px;
    .score-item {
      padding: 9px 0;
      border-bottom: 1px solid var(--grey-items-separaton);
    }
  }
  .grade-group-text {
    font-size: 17px;
    font-family: "Open Sans Semibold";
    color: var(--blue-color);
    margin: 20px 0;
    text-align: center;
  } 
`;

