import React, { useMemo } from "react";
import * as S from "./CmpSelect.style";
import { PopUpPayload } from "../genericTemplate/GenericTemplate.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Component
import ZaiaSelect from "../../../../../../zaiaComponents/zaiaSelect";

// import { SUBSCRIPTION_CUSTOM_FORM_FIELD_BY_ID, SUBSCRIPTION_CUSTOM_FORM_FIELD_OPTIONS_BY_KEY } from "../../../../../../../graphql/subscriptions/CustomFormField";
// import { useSubscription } from "@apollo/client";
import Popup from "reactjs-popup";

function CmpSelect({
  item,
  insertfunction,
  inline = false,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  typeView,
  isHistorical,
}) {
  const {
    i18n: { language },
  } = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const handleFunction = (optionKey) => {
    const userToCustomFormField = {
      customFormFieldId: item.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === optionKey
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(
        item?.customFormTypeField.fieldOptions.find(
          (field) => field.key === optionKey
        ).names,
        language
      ),
      key: optionKey,
    };
    insertfunction(userToCustomFormField);
  };

  const listOptions = useMemo(() => {
    const options =
      item?.customFormFieldParentId && !isHistorical
        ? itemParent
          ? item?.customFormTypeField.fieldOptions.filter(
              (field) => field.parentKey === itemParent.key
            )
          : item?.customFormTypeField.fieldOptions.filter(
              (field) =>
                field.parentKey ===
                customFormFields.find(
                  (field) => field.id === item.customFormFieldParentId
                )?.userToCustomFormFields[0]?.key
            )
        : item?.customFormTypeField.fieldOptions;
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemParent, customFormFields]);

  return (
    <S.CmpSelect inline={inline}>
      <h5 className="select-name">
        {P.dataTranslation(item?.fieldName, language)}
        <Popup
          trigger={
            <i className="template-header-item-icon icon zaia-alert"></i>
          }
          on={["hover", "focus"]}
          closeOnDocumentClick
          keepTooltipInside
          position={["right center"]}
          nested
        >
          <PopUpPayload>
            <p>{P.dataTranslation(item.descript, language)}</p>
          </PopUpPayload>
        </Popup>
      </h5>
      <div className="select-container">
        <ZaiaSelect
          type="select"
          name="phoneLocation"
          setValue={handleFunction}
          value={
            customFormChangeItem
              ? customFormChangeItem.key
              : item?.userToCustomFormFields[0]?.key
          }
          items={listOptions.map((field) => {
            return {
              value: field.key,
              text: P.dataTranslation(field.names, language),
            };
          })}
          shadow={true}
          fontSize="14px"
          disabled={typeView === "view"}
        />
      </div>
    </S.CmpSelect>
  );
}

export default CmpSelect;
