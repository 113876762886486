import { Field } from "formik";
import React from "react";
import { CheckBoxGroupDiv } from "./style";

function CheckBoxGroup(props) {
  const { name, options, ...rest } = props;
  return (
    <CheckBoxGroupDiv>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <div key={option.key} className="checkBoxGroup__option">
                <input
                  type="checkbox"
                  id={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value.includes(option.value)}
                />
                <label className="title-register" htmlFor={option.value}>
                  {option.key}
                </label>
              </div>
            );
          });
        }}
      </Field>
    </CheckBoxGroupDiv>
  );
}

export default CheckBoxGroup;
