import styled from "styled-components";

export const ZaiaSelect = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  .input-container {
    cursor: ${({disabled}) => disabled ? 'default' :'pointer'}; ;
    border: none;
    padding: ${({padding}) => padding ? padding :'7px 7px'};
    border-radius: 8px;
    display: flex;
    align-items: center;
    min-width: 100px;
    background-color: ${({theme}) => theme.inputBackground};
    border: 1.8px solid ${
      ({
        theme, 
        listActive, 
        error
      }) => 
        listActive 
        ? theme.inputSelected 
        : error?.error && error?.touched 
          ? theme.warning 
          : theme.inputBorder
    };
    .icon-input-form {
      margin-bottom: -5px;
      font-size: 14px;
    }
    input {
      color: ${({theme}) => theme.inputText};
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Open Sans";
      margin-left: 7px;
      cursor: ${({disabled}) => disabled ? 'default' :'pointer'}; ;
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({theme}) => theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Open sans";
      }
    }
    .icon-input-form-arrow {
      margin-bottom: ${({listActive}) => listActive ? '2px' : '-5px'};;
      color: ${({theme}) => theme.inputBorder};
      font-size: 12px;
      transition: .3s ease;
      transform: ${({listActive}) => listActive ? 'rotate(180deg)' : 'none'};
      margin-left: auto;
    }
  }
  .select-list {
    position: absolute;
    background-color: ${({theme}) => theme.inputBackground};
    z-index: 1;
    display: ${({listActive}) => listActive ? 'block' :'none' };
    width: 100%;
    /* max-height: 100px; */
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    font-family: "Open Sans";
    font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
    .select-item {
      cursor: pointer;
      padding: 3px 7px;
      label {
        cursor: pointer;
      }
    }
    ::-webkit-scrollbar{
      display: none;
    }
  }
  .input-error {
    height: 15px;
    padding: 1px 5px;
    font-size: 10px;
    color: ${({theme}) => theme.warning};
    i {
      margin-right: 2px;
    }
  }
`