import React, { useMemo, useState, useEffect } from "react";
import * as S from "./MedicinesBoxInfo.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"

// components
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import MedicinesLogCard from "../../../../../shared/charts/medicinesLogCard";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_MEDICINES } from "../../../../../../../graphql/queries/User";

// pipes
import * as P from "../../../../../../../utils/pipes";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;
const loadingZaia = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;

function MedicinesBoxInfo({
  time,
  icon
}) {
  const { t, i18n: { language } } = useTranslation("global");
  const { patientID } = useParams();
  const { 
    data: userToMedicines, 
    loading: userToMedicinesLoading 
  } = useQuery(
    QUERY_GET_USER_MEDICINES, 
    {
      variables: { 
        patientId: patientID, 
        startDate: new Date(time?.startDate), 
        finishDate: new Date(time?.finishDate) 
      }
    }
  );
  const [totalPercentage, setTotalPercentage] = useState(0);

  const color = {
    primary: "var(--green-color)",
    secundary: "var(--box-shadow-green-color)",
    dark: "var(--green-color-100)",
  }

  const getFrequencyTimes = (medicine) => {
    let frequencyTimes = []
    let dayStart = new Date(new Date().setHours(0,0,0,0));
    while(dayStart.getHours() !== 24) {
      frequencyTimes.push(new Date(dayStart));
      dayStart.setHours(dayStart.getHours() + medicine.frequency, 0, 0, 0);
      if(dayStart.getHours() === 0) break;
    }
    return frequencyTimes
  }

  // const containerRef = useRef();

  // const onWheel = useCallback(e => {
  //   const containerScrollPosition = containerRef.current.scrollLeft;
  //   containerRef.current.scrollTo({
  //     top: 0,
  //     left: containerScrollPosition + e.deltaY,
  //     behaviour: "smooth"
  //   });
  // }, []);

  const filterDataByDay = async (info) => {
    let medicinesDay = [];
    info?.forEach(medicine => {
      const frequencyTimes = getFrequencyTimes(medicine);
      const filterInsideTreatment = insideIntervalTreatment(medicine);
      const medicinesLog = getMedicinesLog(medicine);
      let frequencyStates = [];
      let medicationDaysHistory = [];
      let dateInMedicinesLog;
      let dateStartDay = new Date(time?.startDate);
      let quantityHistoryMedLog = 0;
      let quantityMedLog = 0;

      if(medicine.isPeriodic) {
        dateInMedicinesLog = medicinesLog.indexOf(new Date(dateStartDay).getDay()) !== -1 ? true : false;
        frequencyTimes.forEach(time => {
          const timefinish = new Date(time);
          timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);
          const timefinishHour = timefinish.getHours() === 0 ? 24 : timefinish.getHours();
          const historyInHour = medicine.userToDrugHistories.find(date => new Date(date.created_at).getHours() >= time.getHours() && new Date(date.created_at).getHours() < timefinishHour);
          if(dateInMedicinesLog) {
            if( historyInHour ) {
              if( historyInHour.isTaken ) {
                frequencyStates.push('takeIt');
              } else {
                frequencyStates.push('noTakeIt');
              }
              quantityHistoryMedLog += 1;
              quantityMedLog += 1;
            } else {
              if( new Date(new Date(dateStartDay).setHours(new Date(time).getHours() + medicine.frequency, 0,0,0)) <= new Date(new Date(Date.now()))) {
                frequencyStates.push('noReported');
              } else {
                frequencyStates.push('haveTakeIt');
              }
              quantityMedLog += 1;
            }
          } else {
            frequencyStates.push('haveNoTakeIt');
          }
        });
      } else {
        const drugDatesList = medicine.userToDrugDates;     
        frequencyTimes.forEach(time => {
          time.setDate(new Date(dateStartDay).getDate())
          const timefinish = new Date(time);
          timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);

          const historyInHour = medicine.userToDrugHistories.find(date => (new Date(date.created_at) >= new Date(time) && new Date(date.created_at) < new Date(timefinish)));
          const drugDateFound = drugDatesList.find(info => new Date(new Date(new Date(info.daySpecific)).setUTCHours(5)).getDate() === new Date(dateStartDay).getDate());
            if( historyInHour ) {
              if( historyInHour.isTaken ) {
                frequencyStates.push('takeIt');
              } else {
                frequencyStates.push('noTakeIt');
              }
              quantityHistoryMedLog += 1;
              quantityMedLog += 1;
            } else {
              if (drugDateFound) {
                if( new Date(new Date(dateStartDay).setHours(new Date(time).getHours() + medicine.frequency, 0,0,0)) <= new Date(new Date(Date.now()))) {
                  frequencyStates.push('noReported');
                } else {
                  frequencyStates.push('haveTakeIt');
                }
                quantityMedLog += 1;
              } else {
                frequencyStates.push('haveNoTakeIt');
              }
            }
        });
      }

      medicationDaysHistory.push(
        {
          name: P.longDate(dateStartDay, t),
          frequencyStates: frequencyStates,
        }
      )

      let historieslength = medicine.userToDrugHistories.length;
      let lastReportDate = filterInsideTreatment === 'inside' 
      ? historieslength !== 0 
        ? new Date(medicine.userToDrugHistories[`${historieslength-1}`].created_at).getTime() 
        : 1
      : 0;
        
      medicinesDay.push(
        {
          id: medicine.id,
          name: medicine.drug.genericName,
          dose: medicine.drugDose.doseTypeVal.comment,
          quantity: medicine.quantity,
          frequency: medicine.frequency,
          frequencyType: P.dataTranslation(medicine.frequencyTypeValue.comment, language),
          medicationToChart: medicationDaysHistory,
          ubicationInTreatment: filterInsideTreatment,
          quantityMedicationLogDays: quantityMedLog,
          quantityMedicationDays: quantityHistoryMedLog,
          frequencyTimes: frequencyTimes,
          lastReportDate: lastReportDate
        }
      )
    });
    getTotalPercentage(medicinesDay);
    return medicinesDay;
  }

  const filterDataByWeek = async (info) => {
    let medicinesWeek = [];
    info?.forEach(medicine => {
      const frequencyTimes = getFrequencyTimes(medicine);
      const filterInsideTreatment = insideIntervalTreatment(medicine);
      let medicationWeekHistory = [];
      const medicinesLog = getMedicinesLog(medicine);
      let quantityHistoryMedLog = 0;
      let quantityMedLog = 0;
      if(filterInsideTreatment === 'inside') {
        let dateStartWeek = new Date(time.startDate);
        let dateFinishWeek = new Date(time.startDate);
        dateFinishWeek.setDate(dateFinishWeek.getDate() + 7);
        while(dateStartWeek.getTime() !== dateFinishWeek.getTime()) {

          let dateInHistory;
          let dateInMedicinesLog;
          let frequencyStates = [];

          dateInHistory = medicine.userToDrugHistories.filter(info => new Date(info.created_at).getDate() === new Date(dateStartWeek).getDate());

          if(medicine.isPeriodic) {
            dateInMedicinesLog = medicinesLog.indexOf(new Date(dateStartWeek).getDay()) !== -1 ? true : false;
            // eslint-disable-next-line no-loop-func
            frequencyTimes.forEach(time => {
              const timefinish = new Date(time);
              timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);
              const timefinishHour = timefinish.getHours() === 0 ? 24 : timefinish.getHours();
              const historyInHour = dateInHistory.find(date => new Date(date.created_at).getHours() >= time.getHours() && new Date(date.created_at).getHours() < timefinishHour);
              if(dateInMedicinesLog) {
                if( historyInHour ) {
                  if( historyInHour.isTaken ) {
                    frequencyStates.push('takeIt');
                  } else {
                    frequencyStates.push('noTakeIt');
                  }
                  quantityHistoryMedLog += 1;
                  quantityMedLog += 1;
                } else {
                  if( new Date(new Date(dateStartWeek).setHours(new Date(time).getHours() + medicine.frequency, 0,0,0,0)) <= new Date(new Date(Date.now()))) {
                    frequencyStates.push('noReported');
                  } else {
                    frequencyStates.push('haveTakeIt');
                  }
                  quantityMedLog += 1;
                }
              } else {
                frequencyStates.push('haveNoTakeIt');
              }
            });
          } else {
            const drugDatesList = medicine.userToDrugDates;     
            // eslint-disable-next-line no-loop-func
            frequencyTimes.forEach(time => {
              time.setDate(dateStartWeek.getDate())
              const timefinish = new Date(time);
              timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);

              const historyInHour = dateInHistory.find(date => (new Date(date.created_at) >= new Date(time) && new Date(date.created_at) < new Date(timefinish)));
              const drugDateFound = drugDatesList.find(info => new Date(new Date(new Date(info.daySpecific)).setUTCHours(5)).getDate() === new Date(dateStartWeek).getDate());
                if( historyInHour ) {
                  if( historyInHour.isTaken ) {
                    frequencyStates.push('takeIt');
                  } else {
                    frequencyStates.push('noTakeIt');
                  }
                  quantityHistoryMedLog += 1;
                  quantityMedLog += 1;
                } else {
                  if (drugDateFound) {
                    if(new Date(new Date(dateStartWeek).setHours(new Date(time).getHours() + medicine.frequency, 0,0,0)) <= new Date(new Date(Date.now()))) {
                      frequencyStates.push('noReported');
                    } else {
                      frequencyStates.push('haveTakeIt');
                    }
                    quantityMedLog += 1;
                  } else {
                    frequencyStates.push('haveNoTakeIt');
                  }
                }
            });
          }

          medicationWeekHistory.push(
            {
              name: P.nameDayWeek(new Date(dateStartWeek).getDay(), 'S', t),
              frequencyStates: frequencyStates,
              date: new Date(dateStartWeek)
            }
          )

          dateStartWeek.setDate(dateStartWeek.getDate() + 1);
        }
      }

      let historieslength = medicine.userToDrugHistories.length;
      let lastReportDate = filterInsideTreatment === 'inside' 
      ? historieslength !== 0 
        ? new Date(medicine.userToDrugHistories[`${historieslength-1}`].created_at).getTime() 
        : 1
      : 0;

      medicinesWeek.push(
        {
          id: medicine.id,
          name: medicine.drug.genericName,
          dose: medicine.drugDose.doseTypeVal.comment,
          quantity: medicine.quantity,
          frequency: medicine.frequency,
          frequencyType: P.dataTranslation(medicine.frequencyTypeValue.comment, language),
          medicationToChart: medicationWeekHistory,
          ubicationInTreatment: filterInsideTreatment,
          medicationLog: medicinesLog,
          medicationHistory: medicine.userToDrugHistories,
          quantityMedicationLogDays: quantityMedLog,
          quantityMedicationDays: quantityHistoryMedLog,
          frequencyTimes: frequencyTimes,
          lastReportDate: lastReportDate
        }
      )
    });
    
    getTotalPercentage(medicinesWeek);
    return medicinesWeek;
  }

  const filterDataByMonth = async (info) => {
    let medicinesMonth = [];
    info?.forEach(medicine => {
      let frequencyTimes = getFrequencyTimes(medicine);
      const filterInsideTreatment = insideIntervalTreatment(medicine);
      let medicationMonthHistory = []
      const medicinesLog = getMedicinesLog(medicine);
      let quantityMedLog = 0;
      let quantityHistoryMedLog = 0;
      if(filterInsideTreatment === 'inside') {
        let dateStartMonth = new Date(time.startDate);
        let dateFinishMonth = new Date(time.startDate);
        const numberDaysMonth = new Date(dateStartMonth.getFullYear(), dateStartMonth.getMonth() + 1, 0).getDate();
        dateFinishMonth.setDate(dateFinishMonth.getDate() + numberDaysMonth);
        while(dateStartMonth.getTime() !== dateFinishMonth.getTime()) {
          let dateInHistory;
          let dateInMedicinesLog;
          let frequencyStates = [];

          dateInHistory = medicine.userToDrugHistories.filter(info => new Date(info.created_at).getDate() === new Date(dateStartMonth).getDate());
          
          if(medicine.isPeriodic) {
            dateInMedicinesLog = medicinesLog.indexOf(new Date(dateStartMonth).getDay()) !== -1 ? true : false;
            // eslint-disable-next-line no-loop-func
            frequencyTimes.forEach(time => {
              const timefinish = new Date(time);
              timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);
              const timefinishHour = timefinish.getHours() === 0 ? 24 : timefinish.getHours();
              let historyInHour;
              historyInHour = dateInHistory.find(date => new Date(date.created_at).getHours() >= time.getHours() && new Date(date.created_at).getHours() < timefinishHour);
              
              if(dateInMedicinesLog) {
                if( historyInHour ) {
                  if( historyInHour.isTaken ) {
                    frequencyStates.push('takeIt');
                  } else {
                    frequencyStates.push('noTakeIt');
                  }
                  quantityHistoryMedLog += 1;
                  quantityMedLog += 1;
                } else {
                  if( new Date(new Date(dateStartMonth).setHours(new Date(time).getHours() + medicine.frequency, 0,0,0,0)) <= new Date(new Date(Date.now()))) {
                    frequencyStates.push('noReported');
                  } else {
                    frequencyStates.push('haveTakeIt');
                  }
                  quantityMedLog += 1;
                }
              } else {
                frequencyStates.push('haveNoTakeIt');
              }
            });
          } else {
            const drugDatesList = medicine.userToDrugDates;     
            // eslint-disable-next-line no-loop-func
            frequencyTimes.forEach(time => {
              time.setDate(dateStartMonth.getDate())
              const timefinish = new Date(time);
              timefinish.setHours(time.getHours() + medicine.frequency, 0, 0, 0);

              const historyInHour = dateInHistory.find(date => (new Date(date.created_at) >= new Date(time) && new Date(date.created_at) < new Date(timefinish)));
              const drugDateFound = drugDatesList.find(info => new Date(new Date(new Date(info.daySpecific)).setUTCHours(5)).getDate() === new Date(dateStartMonth).getDate());              
                if( historyInHour ) {
                  if( historyInHour.isTaken ) {
                    frequencyStates.push('takeIt');
                  } else {
                    frequencyStates.push('noTakeIt');
                  }
                  quantityHistoryMedLog += 1;
                  quantityMedLog += 1;
                } else {
                  if (drugDateFound) {
                    if(new Date(new Date(dateStartMonth).setHours(new Date(time).getHours() + medicine.frequency, 0,0,0)) <= new Date(new Date(Date.now()))) {
                      frequencyStates.push('noReported');
                    } else {
                      frequencyStates.push('haveTakeIt');
                    }
                    quantityMedLog += 1;
                  } else {
                    frequencyStates.push('haveNoTakeIt');
                  }
                }
            });
          }

          medicationMonthHistory.push(
            {
              name: (new Date(dateStartMonth).getDate() < 10 ? '0' : null) + new Date(dateStartMonth).getDate(),
              frequencyStates: frequencyStates,
              date: new Date(dateStartMonth)
            }
          )

          dateStartMonth.setDate(dateStartMonth.getDate() + 1);

        }
      }
      // console.log("infooooooooooo", medicine);

      let historieslength = medicine.userToDrugHistories.length;
      let lastReportDate = filterInsideTreatment === 'inside' 
      ? historieslength !== 0 
        ? new Date(medicine.userToDrugHistories[`${historieslength-1}`].created_at).getTime() 
        : 1
      : 0;

      medicinesMonth.push(
        {
          id: medicine.id,
          name: medicine.drug.genericName,
          dose: medicine.drugDose.doseTypeVal.comment,
          quantity: medicine.quantity,
          frequency: medicine.frequency,
          frequencyType: P.dataTranslation(medicine.frequencyTypeValue.comment, language),
          medicationToChart: medicationMonthHistory,
          ubicationInTreatment: filterInsideTreatment,
          medicationLog: medicinesLog,
          medicationHistory: medicine.userToDrugHistories,
          quantityMedicationLogDays: quantityMedLog,
          quantityMedicationDays: quantityHistoryMedLog,
          frequencyTimes: frequencyTimes,
          lastReportDate: lastReportDate
        }
      )

    });
    getTotalPercentage(medicinesMonth);
    return medicinesMonth;
  }

  // const historiesJoined = (info) => {
  //   let filteredMedicines = [];
  //   info?.forEach(medicine => {
  //     const searchMedHistory = filteredMedicines.find(med => new Date(med.created_at).getDate() === new Date(medicine.created_at).getDate());
  //     if (!searchMedHistory) {
  //       const newMedHistory = { created_at: medicine.created_at, isTaken: medicine.isTaken };
  //       filteredMedicines.push(newMedHistory);
  //     }
  //   });
  //   return (filteredMedicines);
  // }

  const getMedicinesLog = (medicine) => {
    const filterStart = new Date(time.startDate);
    const filterEnd = new Date(time.finishDate);
    if(medicine.isPeriodic) {
      let newDaysOfWeek = medicine.daysOfWeek.map(day => {
        return day !== 6 ? day + 1 : 0
      });
      return newDaysOfWeek;
    } else {
      let daysToMedicate = [];
      medicine.userToDrugDates?.forEach(date => {
        if (new Date(new Date(date.daySpecific).setUTCHours(5)) >= filterStart &&
            new Date(new Date(date.daySpecific).setUTCHours(5)) <= filterEnd) {
          daysToMedicate.push(new Date(new Date(date.daySpecific).setUTCHours(5)));
        }
      });
      return daysToMedicate;
    }
  }

  const insideIntervalTreatment = (medicine) => {
    const filterStart = new Date(time.startDate);
    const filterEnd = new Date(time.finishDate);
    const treatmentStart = new Date(new Date(medicine.startTreatment).setUTCHours(5));
    const treatmentEnd = medicine.endTreatment ? new Date(new Date(medicine.endTreatment).setUTCHours(5)) : null;
    if (medicine.isFix) {
      if ((filterStart >= treatmentStart || filterStart < treatmentStart) && filterEnd > treatmentStart) {
        return 'inside';
      } else {
        return 'before';
      }
    } else {
      if (
        (filterStart <= treatmentStart && filterEnd > treatmentStart) ||
        (filterStart < treatmentEnd && filterEnd >= treatmentEnd) ||
        (filterStart >= treatmentStart && filterEnd <= treatmentEnd)
      ) {
        return 'inside';
      } else if (filterStart > treatmentEnd && filterEnd > treatmentEnd) {
        return 'after';
      } else {
        return 'before'
      }
    }
  }

  const getTotalPercentage = (medicinesData) => {
    // console.log(medicinesData);
    let quantityDays = 0;
    let quantityDaysLog = 0;
    medicinesData.forEach( medicine => {
      quantityDays += medicine.quantityMedicationDays;
      quantityDaysLog += medicine.quantityMedicationLogDays;
    });
    const totalPercentage = P.getPercentage(quantityDays, quantityDaysLog);
    setTotalPercentage(totalPercentage);
  }

  const infoMedicines = useMemo(() => {
    const info = userToMedicines?.user[0].userToDrugs;
    if(time?.timeOp === 'day') return filterDataByDay(info);
    if(time?.timeOp === 'week') return filterDataByWeek(info);
    if(time?.timeOp === 'month') return filterDataByMonth(info);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToMedicines, time])

  return (
    <S.MedicinesBoxInfo>
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left"
          // ref={containerRef}
          // onWheel={onWheel}
        >
          {
            <MedicinesLogList
              medicinesLog={infoMedicines}
              time={time}
              loading={userToMedicinesLoading}
            />
          }
        </div>
        <div className="boxInfoPatient__info-right">
          <CircleChartCard
            icon={icon}
            title={t("patients.boxInfoPatient.report.medicines")}
            percentagePrincipal={totalPercentage}
            textPrincipal={t("patients.boxInfoPatient.report.reported")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div>
      </div>
    </S.MedicinesBoxInfo>
  );
}

const MedicinesLogList = ({medicinesLog, time, loading}) => {
  const [infoMedicine, setinfoMedicine] = useState();
  const [t] = useTranslation("global");

  useEffect(() => {
    medicinesLog?.then((info) => {
      setinfoMedicine(info?.filter(medicine => medicine.ubicationInTreatment !== 'before'));
    })
  },[medicinesLog])

  if (loading) return (
    <>
      <ZaiaReaction
        zaiaImg={loadingZaia}
        widthImg="100px"
        sizeText="15px"
        widthText="240px"
        />
    </>
  )

  if (infoMedicine?.length === 0) return (
    <>
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthImg="100px"
        text={t('globally.noReports')}
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  return (
    <>
      {
        infoMedicine?.sort(function(a, b){return b.lastReportDate - a.lastReportDate}).map(medicine => {
          if(medicine.ubicationInTreatment === 'before') return null;
          return (
            <MedicinesLogCard
              medicinesToChart={medicine}
              time={time}
              key={medicine.id}
            />
          )
        })
      }
    </>
  )
}

export default MedicinesBoxInfo;
