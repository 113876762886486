import { gql } from "@apollo/client";

export const MUTATION_UPDATE_USER_TO_PROCESS = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS(
    $processId: Int!
    $userId: uuid!
    $doctorId: uuid!
    $stageId: String!
    $descript: String!
    $latestCustomFormId: Int
  ) {
    update_userToProcess(where: {processId: {_eq: $processId}, userId: {_eq: $userId}, doctorId: {_eq: $doctorId}, isAcepted: {_eq: true}}, _set: {
      stageId: $stageId
      descript: $descript
      latestCustomFormId: $latestCustomFormId
    }) {
      returning {
        descript
        doctorId
        id
        isAcepted
        processId
        stageId
        userId
        created_at
        updated_at
        latestCustomFormId
      }
    }
  }
`;

export const MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS(
    $processId: Int!
    $userId: uuid!
    $doctorId: uuid!
    $stageId: String!
    $descript: String
    $latestCustomFormId: Int

    $objectsCustomForms: [userToCustomFormField_insert_input!]!
  ) {
    update_userToProcess(where: {processId: {_eq: $processId}, userId: {_eq: $userId}, doctorId: {_eq: $doctorId}, isAcepted: {_eq: true}}, _set: {
      stageId: $stageId
      descript: $descript
      latestCustomFormId: $latestCustomFormId
    }) {
      returning {
        descript
        doctorId
        id
        isAcepted
        processId
        stageId
        userId
        created_at
        updated_at
        latestCustomFormId
      }
    }

    insert_userToCustomFormField(objects: $objectsCustomForms) {
      returning {
        created_at
        customFormFieldId
        doctorId
        id
        key
        updated_at
        userId
        value
      }
    }
  }
`;

export const MUTATION_DELETE_USER_TO_PROCESS_BY_ID = gql`
  mutation MUTATION_DELETE_USER_TO_PROCESS_BY_ID(
    $id: uuid!
  ) {
    delete_userToProcess_by_pk(id: $id) {
      id
      isAcepted
      userId
      processId
      doctorId
      created_at
    }
  }
`
