import { gql } from "@apollo/client";

export const MUTATION_LOG_IN = gql`
  mutation MUTATION_LOG_IN(
    $email: String!, 
    $password: String!
    $captchaToken: String!
  ) {
    loginDoctor(
      email: $email, 
      password: $password
      captchaToken: $captchaToken
    ) {
      accessToken
    }
  }
`;

export const MUTATION_SIGN_UP_DOCTOR = gql`
  mutation MUTATION_SIGN_UP_DOCTOR(
    $email: String!
    $lastname: String!
    $name: String!
    $password: String!
    $captchaToken: String!
  ) {
    signupDoctor (
      email: $email
      lastname: $lastname
      name: $name
      password: $password
      captchaToken: $captchaToken
    ) {
      message
    }
  }
`;

export const MUTATION_CHANGE_PASS = gql`
  mutation MUTATION_CHANGE_PASS($email: String!, $newPass: String!, $pass: String!) {
    passDoctor(email: $email, newPassword: $newPass, password: $pass) {
      accessToken
    }
  }
`;

export const MUTATION_ACTIVE_TOKEN = gql`
  mutation MUTATION_ACTIVE_TOKEN($email: String!, $token: String!, $pass: String) {
    verifyTokenDoctor(email: $email, verifiedToken: $token, password: $pass) {
      accessToken
    }
  }
`

export const MUTATION_VERIFY_EMAIL = gql`
  mutation MUTATION_VERIFY_EMAIL(
    $token: String!
  ) {
    loginDoctor(token: $token) {
      accessToken
    }
  }
`;


export const MUTATION_RESET_PASS = gql`
  mutation MUTATION_RESET_PASS(
    $email: String!
    $captchaToken: String
  ) {
    recoverDoctor(
      email: $email
      captchaToken: $captchaToken
    ) {
      message
    }
  }
`;