import styled from "styled-components";

export const ProfileFormDiv = styled.div`
  width: 100%;
  height: 100%; 
  display: flex;
  .profile-left{
    height: 100%;
    max-width: 400px;
    width: 100%;
  }
  .profile-right{
    height: 100%;
    width: calc(100% - 400px);
    width: 100%;
    .profile-form {
      height: 100%;
      padding: 20px 70px 0 40px;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .inputs-container{
        height: calc(100% - 50px);
        /* overflow-y: auto; */
        .inputs-title {
          font-family: "Nunito ExtraBold";
          font-size: 18px;
          color: var(--skyblue-box-headercolor);
          margin: 10px 0;
        }
        .inputs-flex {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .input-content {
            width: 49%;
            display: flex;
            flex-direction: column;
            span {
              color: var(--skyblue-box-headercolor);
              padding-bottom: 10px;
              opacity: 0.7;
            }
            .double-input {
              display: flex;
              .small-select {
                margin-right: 2%;
                width: 40%;
              }
            }
          }
        }
      }
      .button-container {
        margin: 20px auto;
        width: 300px;
        height: 50px;
      }
    }
  }
`;
