import React, { useState } from "react";
import * as S from "./OutcomesProcessByPatient.style";
import { useTranslation } from "react-i18next";

// Hooks
// import useDoctorData from "../../../../../../../hooks/useDoctorData";

// GraphQL
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID } from "../../../../../../../graphql/subscriptions/CustomForm";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../../../graphql/queries/UserToProcess";
import { useQuery } from "@apollo/client";
import OutcomesTable from "./components/outcomesTable";
import CurrentState from "../../../outcomesComponents/shared/currentState";
import OutcomesDashboard from "./components/outcomesDashboard";

function OutcomesProcessByPatient({
  patientID,
  processID,
  processToCustomFormID,
}) {
  const {
    t,
    i18n: {
      // eslint-disable-next-line no-unused-vars
      language,
    },
  } = useTranslation("global");
  const [viewSelected, setViewSelected] = useState(1);

  const viewsList = [
    {
      id: 1,
      name: t("outcomes.views.summaryView"),
      icon: "zaia-star-on",
    },
    {
      id: 2,
      name: t("outcomes.views.timeline"),
      icon: "zaia-timeline",
    },
    {
      id: 3,
      name: t("outcomes.views.dashboard"),
      icon: "zaia-dashboard",
    },
    {
      id: 4,
      name: t("outcomes.views.summaryView2"),
      icon: "zaia-star-off",
    },
  ];

  // const CUSTOM_FORM_PROSTATE_CANCER = 1;

  const { data: userToProcessData } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientID, processId: processID },
  });

  const { data: customForm, loading: customFormLoading } = useSubscription(
    SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID,
    {
      variables: {
        userId: patientID,
        // doctorId: doctorData.id,
        customFormId: processToCustomFormID,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
      },
    }
  );

  return (
    <S.OutcomesProcessByPatient>
      <div className="table-title">
        <h3>{t("outcomes.views.title")}</h3>
        <div className="buttons-container">
          {viewsList.map((view) => {
            return (
              <S.ButtonView
                key={view.id}
                active={view.id === viewSelected}
                onClick={() => setViewSelected(view.id)}
              >
                <div className="icon-container">
                  <i className={`icon ${view.icon}`} />
                </div>
                {view.name}
              </S.ButtonView>
            );
          })}
        </div>
      </div>
      <div className="view-container">
        {viewSelected === 1 ? (
          <OutcomesTable
            patientID={patientID}
            processID={processID}
            processToCustomFormID={processToCustomFormID}
            customForm={customForm}
            customFormLoading={customFormLoading}
            userToProcessData={userToProcessData}
            highlightsFilter={true}
          />
        ) : viewSelected === 2 ? (
          <CurrentState patientId={patientID} processId={processID} />
        ) : viewSelected === 3 ? (
          <OutcomesTable
            patientID={patientID}
            processID={processID}
            processToCustomFormID={processToCustomFormID}
            customForm={customForm}
            customFormLoading={customFormLoading}
            userToProcessData={userToProcessData}
          />
        ) : (
          <OutcomesDashboard
            customForm={customForm}
            customFormLoading={customFormLoading}
            userToProcessData={userToProcessData}
          />
        )}
      </div>
    </S.OutcomesProcessByPatient>
  );
}

export default OutcomesProcessByPatient;
