import styled, {keyframes} from "styled-components";

export const TimeVerticalBars = styled.div`
  width: ${({activeHeader}) => activeHeader ? null : '100%'};
  .weekly-bars-card {
    width: ${({activeHeader}) => activeHeader ? '314px' : 'auto'};;
    min-width: 314px;
    height: auto;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: ${({activeHeader}) => activeHeader ? '0px 3px 6px #00000029' : '' };
    padding: ${({activeHeader}) => activeHeader ? '5px' : '0 5px'};
    display: flex;
    flex-direction: column;
    margin: ${({activeHeader}) => activeHeader ? '10px' : '0px'};
    &__header {
      display: flex;
      height: 52px;
      align-items: center;
      &-icon {
        background-color: ${({colorVariation}) => colorVariation};
        width: 45px;
        border-top-left-radius: 10px;
        display: flex;
        color: var(--white);
        font-size: 27px;
        padding-top: 14px; 
        i {
          margin: auto;
        }
      }
      &-text {
        margin-left: 10px;
        p {
          margin-top: 5px;
          font-family: "Open Sans";
          color: var(--text-toogle-gray);
          font-size: 12px;
        }
      }
    }
    &__bars-container {
      padding: ${({activeHeader}) => activeHeader ? '10px' : '0 10px'};
      height: 100%;
      display: flex;
      &-listlevels {
        width: 55px;
        height: 100px;
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: ${({colorPrimary}) => colorPrimary};
        p {
          font-size: 9px;
          font-family: "Open Sans SemiBold"
        } 
      }
      &-bars {
        width: 100%;
        margin: 0 10px;
        display: flex;
        justify-content: space-between;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          height: 21px;
          margin: 0;
          opacity: .9;
          padding-bottom: 10px;
        }
        ::-webkit-scrollbar-track {
          margin-left: 0px;
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px transparent;
          border: solid 2px transparent;
          opacity: .5;
          background-color: transparent;
        }
        ::-webkit-scrollbar-thumb {
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px #9A9A9A4d;
          border: solid 6px transparent;
          opacity: .8;
        }
        &:hover {
          overflow-x: overlay;
        }
      }
    }
  }
`;

const load = (completed) => keyframes`
  0% { height: 0; }
  100% { height: ${completed}; }
`;

export const VerticalProgressBarDiv = styled.div`
  height: auto;
  min-width: 22px;
  text-align: center;
  .vertical-progress-bar {
    margin: auto;
    height: 100px;
    width: 10px;
    background-color: ${({active}) => active ? "#D7D7D7" : '#F4F4F4'};
    border-radius: 50px;
    display: flex;
    flex-direction: column-reverse;
    .filler-styles {
      width: 100%;
      background-color: ${({active, colorDark, colorVariation}) => active? colorVariation : colorDark};
      border-radius: inherit;
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: ${({active, colorSecundary}) => active ? "0px 3px 6px " + colorSecundary : 'none'};
      animation: ${({percentage}) => load(percentage)} 2s normal forwards;
    }
  }
  .vertical-text {
    margin-top: 5px;
    padding: 4px 0 3px;
    font-family: "Open Sans";
    background-color: ${({active}) => active ? '#E3E3E399'  : ''};
    border-radius: 10px;
    &__number {
      width: 100%;
      font-size: 10px;
    }
    &__weekday {
      color: ${({active}) => active ? '#191919'  : '#9A9A9A'};
      font-family: ${({active}) => active ? "Open Sans Bold"  : "Open Sans" };
      font-size: 10px;
      width: 100%;
    }
  }
`