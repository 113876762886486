import styled from "styled-components";
import { getHexOpacity } from '../../../utils/pipes';
const waveTop = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

export const EmailSent = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: url(${waveTop});
  background-repeat: no-repeat;
  background-color: ${({theme}) => theme.background};
  background-position: bottom;
  background-position-y: 140%;
  overflow-y: auto;
  .content-email-sent {
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    margin: auto;
    h2 {
      color: ${({theme}) => theme.primaryColor};
      font-family: "Nunito Black";
    }
    .zaia-image {
      width: 150px;
      padding-left: 30px;
      margin: 40px auto 30px;
    }
    .message {
      font-family: 'Open Sans';
      font-size: 13px;
      color: ${({theme}) => theme.onBackground};
    }
    .email-container {
      background-color: ${({theme}) => `${theme.primaryColor}${getHexOpacity(0.1)}`};
      padding: 8px;
      border-radius: 10px;
      color: ${({theme}) => theme.primaryColor};
      margin: 25px 0;
      font-family: "Nunito ExtraBold";
    }
    .button-container {
      width: 267px;
      margin: 60px auto 0;
      .button-message {
        margin-top: 20px;
        padding: 0 15px;
        a {
          color: ${({theme}) => theme.primaryColor};;
        }
      }
    }
  }
`;
