import React, { useState } from "react";
import * as S from "./ProfileForm.style";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

// Hooks 
import  useUploadImg from "../../../../../hooks/useUploadImage";
import  useActivateModal from "../../../../../hooks/useActivateModal";

// GraphQL
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_COMPLETE_DOCTOR_DATA } from "../../../../../graphql/queries/Doctor";
import { QUERY_GET_COUNTRIES } from "../../../../../graphql/queries/Country";
import { QUERY_GET_DOCUMENT_TYPES } from "../../../../../graphql/queries/DocumentType";
import { QUERY_GET_MEDICAL_FIELDS } from "../../../../../graphql/queries/MedicalFields";
import { MUTATION_EDIT_DOCTOR, MUTATION_UPDATE_DOCTOR_DATA, MUTATION_INSERT_DOCTOR_DATA } from "../../../../../graphql/mutations/Doctor";

// components
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaSmallSelectForm from "../../../../zaiaComponents/zaiaSmallSelectForm";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ChangePassForm from "../changePassForm";
import InvitePatientButton from "../../../patients/components/invitePatientButton";
import ZaiaModal from "../../../../zaiaComponents/zaiaModal";

// pipes
import * as P from "../../../../../utils/pipes";

// assets
const zaiaError = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const zaiaLoad = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;

function ProfileForm() {
  const {t, i18n: { language } } = useTranslation("global");
  const [doctorData, setdoctorData] = useState();
  const [imageData, setImageData] = useState();
  const [resetImage] = useState(true);
  const {activeModal, desactiveModal} = useActivateModal()
  const {uploadImage, loading: uploadImgLoading} = useUploadImg();

  const { data: doctorDataQuery, loading: doctorLoadingQuery, error: doctorErrorQuery, refetch } = useQuery(QUERY_GET_COMPLETE_DOCTOR_DATA, { onCompleted: (data) => {
    setdoctorData(data.doctor[0]);
  }});
  const [editInfo, { loading: editInfoLoading }] = useMutation(MUTATION_EDIT_DOCTOR);
  const [editDoctorData] = useMutation(MUTATION_UPDATE_DOCTOR_DATA);
  const [insertDoctorData] = useMutation(MUTATION_INSERT_DOCTOR_DATA);
  const { data: documentTypeData } = useQuery(QUERY_GET_DOCUMENT_TYPES);
  const { data: countriesData } = useQuery(QUERY_GET_COUNTRIES);
  const { data: medicalFieldsData } = useQuery(QUERY_GET_MEDICAL_FIELDS);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let submitPhoto = { load: false, success: true }
    let fileImg
    if (imageData) {
      submitPhoto.load = true;
      try {
        fileImg = await uploadImage(imageData);
      } catch (err) {
        submitPhoto.success = false;
      }
    }
    const modifyInfo = {};
    let modifydoctorData;
    if(imageData) {
      modifyInfo.picture = fileImg ? fileImg.file.toString() : null;
    }
    if(values.name !== doctorDataQuery?.doctor[0].name) modifyInfo.name = values.name;
    if(values.lastname !== doctorDataQuery?.doctor[0].lastname) modifyInfo.lastname = values.lastname;
    if(values.birthdate !== doctorDataQuery?.doctor[0].birthdate) modifyInfo.birthdate = values.birthdate;
    if(values.phoneLocation !== doctorDataQuery?.doctor[0].countryPhoneId) modifyInfo.countryPhoneId = values.phoneLocation;
    if(Number(values.phoneNumber) !== Number(doctorDataQuery?.doctor[0].phone)) modifyInfo.phone = values.phoneNumber.toString();
    if(values.typeDocument !== doctorDataQuery?.doctor[0].documentType) modifyInfo.documentType = values.typeDocument;
    if(Number(values.document) !== Number(doctorDataQuery?.doctor[0].documentNumber)) modifyInfo.documentNumber = values.document.toString();
    if(values.medicalCode !== doctorDataQuery?.doctor[0].medicalCode) modifyInfo.medicalCode = values.medicalCode;

    // doctor data
    if(values.primarySpeciality) {
      if(doctorDataQuery?.doctor[0].doctorData[0]) {
        if(values.primarySpeciality !== doctorDataQuery?.doctor[0].doctorData[0]?.medicalField) {
          modifydoctorData = {}
          modifydoctorData.medicalField = values.primarySpeciality;
        }
      } else {
        modifydoctorData = {}
        modifydoctorData.medicalField = values.primarySpeciality;
        modifydoctorData.universityName = "";
      }
    }

    console.log("22222", doctorDataQuery?.doctor[0].id);

    try {
      const { 
        data: {
          // eslint-disable-next-line no-unused-vars
          update_doctor
        }
      } = await editInfo({
        variables: {
          changes: modifyInfo,
          doctorId: doctorDataQuery?.doctor[0].id
        },
      });

      if(modifydoctorData) {
        if(doctorDataQuery?.doctor[0].doctorData[0]) {
          const data = await editDoctorData({
            variables: {
              idDoctorData: doctorDataQuery?.doctor[0].doctorData[0].id,
              updateDoctorData: modifydoctorData
            },
          });
          console.log(data);
        } else {
          const data = await insertDoctorData({
            variables: {
              doctorData: modifydoctorData
            },
          });
          console.log(data);
        }
      }

      refetch();
      triggerModal(submitPhoto, true);
      // resetForm()
    } catch (err) {
      triggerModal(submitPhoto, false);
      resetForm()
      console.log(err);
    }
  }

  const triggerModal = (submitPhoto, submitInfo) => {
    if (submitInfo) {
      if(submitPhoto.load) {
        if (submitPhoto.success) {
          callModal(false, t("profile.modalMessages.successWithPhoto"))  // update success with photo
        } else {
          callModal(true, t("profile.modalMessages.successInfoNoPhoto"))  // update success without photo
        }
      } else {
        callModal(false, t("profile.modalMessages.success"))  // update success
      }
    } else {
      callModal(true, t("profile.modalMessages.errorInfo"))  // update error
    }
  }

  const callModal = (error, text) => {
    activeModal(() => <ZaiaModal errorModal={error} text={text}/>)
  }

  const changePassModal = () => {
    return (
      <>  
        <ChangePassForm doctorEmail={doctorDataQuery?.doctor[0].email}/>
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  }

  if (doctorLoadingQuery) 
    return (
      <S.ProfileFormDiv>
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={zaiaLoad}
        />  
      </S.ProfileFormDiv>
    );

  if (doctorErrorQuery) 
    return (
      <S.ProfileFormDiv>
        <ZaiaReaction
          zaiaImg={zaiaError}
          widthImg="125px"
          text={t("profile.errorInfo")}
          sizeText="17px"
          widthText="240px"
        />
      </S.ProfileFormDiv>
    );

  return (
    <S.ProfileFormDiv>
      <div className="profile-left">
        <ProfileHeader setImageData={setImageData} resetImage={resetImage} doctorData={doctorData} documentTypeData={documentTypeData}/>
      </div>
      <div className="profile-right">
      <Formik
        initialValues={{
          email: doctorData?.email ? doctorData?.email : '',
          name: doctorData?.name ? doctorData?.name : '',
          lastname: doctorData?.lastname ? doctorData.lastname : '',
          birthdate: doctorData?.birthdate ? doctorData?.birthdate : '',
          phoneLocation: doctorData?.countryPhoneId ? doctorData?.countryPhoneId : '',
          phoneNumber: doctorData?.phone ? doctorData?.phone : '',
          typeDocument: doctorData?.documentType ? doctorData?.documentType : '',
          document: doctorData?.documentNumber ? doctorData?.documentNumber : '',
          medicalCode: doctorData?.medicalCode ? doctorData?.medicalCode : '',
          primarySpeciality: doctorData?.doctorData[0]?.medicalField ? doctorData?.doctorData[0]?.medicalField : '' 
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("register.validation.email"))
            .required(t("register.validation.required")),
          name: Yup.string()
            .min(3, t("register.validation.min"))
            .max(15, t("register.validation.max"))
            .required(t("register.validation.required")),
          lastname: Yup.string()
            .min(3, t("register.validation.min"))
            .max(15, t("register.validation.max"))
            .required(t("register.validation.required")),
          birthdate: Yup.string()
            .required(t("register.validation.required")),
          phoneLocation: Yup.string()
            .required(t("register.validation.required")),
          phoneNumber: Yup.string()
            .required(t("register.validation.required")),
          typeDocument: Yup.string()
            .required(t("register.validation.required")),
          document: Yup.string()
            .required(t("register.validation.required")),
          medicalCode: Yup.string()
            .required(t("register.validation.required")),
          // primarySpeciality: Yup.string()
          //   .required(t("register.validation.required")),
          // secundarySpeciality: Yup.string()
          //   .required(t("register.validation.required")),
        })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <form className="profile-form" onSubmit={ props.handleSubmit }>
            <div className="inputs-container">
              <div className="inputs-title">{t("profile.userInfo")}</div>
              <div className="inputs-flex">
                <div className="input-content">
                  <span>{t("profile.email")}</span>
                  <ZaiaInputForm 
                    placeholder={t("profile.email")}
                    type="text"
                    name="email"
                    icon="icono-email"
                    disabled={true}
                  />
                </div>
                <div className="input-content">
                  <span>{t("profile.password")}</span>
                    <div onClick={() => activeModal(() => changePassModal())}>
                      <ZaiaButton
                        type="button"
                        marginBottom="10"
                        option="secondary"
                        color="var(--skyblue-box-headercolor)"
                        title={t("profile.changePass")}
                      />
                    </div>
                </div>
              </div>
              <div className="inputs-title">{t("profile.generalInfo")}</div>
              <div className="inputs-flex">
                <div className="input-content">
                  <span>{t("profile.name")}</span>
                  <ZaiaInputForm 
                    placeholder={t("profile.name")}
                    type="text"
                    name="name"
                    icon="icono-nombre"
                  />
                </div>
                <div className="input-content">
                  <span>{t("profile.lastname")}</span>
                  <ZaiaInputForm 
                    placeholder={t("profile.lastname")}
                    type="text"
                    name="lastname"
                    icon="icono-nombre"
                  />
                </div>
              </div>
              <div className="inputs-flex">
                <div className="input-content">
                  <span>{t("profile.birthday")}</span>
                  <ZaiaInputForm 
                    type="date"
                    name="birthdate"
                    icon="icono-cumpleanos"
                  />
                </div>
                <div className="input-content">
                  <span>{t("profile.phoneNumber")}</span>
                  <div className="double-input">
                    <div className="small-select">
                      <ZaiaSmallSelectForm
                        type="select"
                        name="phoneLocation"
                        items={
                          countriesData?.country.map((country) => {
                            return ({
                              value: country.id,
                              text: country.sortname,
                              textBold: '+' + country.phonecode
                            })
                          })
                        }
                      />
                    </div>
                    <ZaiaInputForm 
                      placeholder={t("profile.phoneNumber")}
                      type="number"
                      name="phoneNumber"
                      icon="icono-telefono"
                    />
                  </div>
                </div>
              </div>
              <div className="inputs-flex">
                <div className="input-content">
                  <span>{t("profile.document")}</span>
                  <div className="double-input">
                    <div className="small-select">
                      <ZaiaSmallSelectForm
                        type="select"
                        name="typeDocument"
                        items={
                          documentTypeData?.enDocumentType.map((type) => {
                            return ({
                              value: type.value,
                              text: type.comment.code
                            })
                          })
                        }
                      />
                    </div>
                    <ZaiaInputForm 
                      placeholder={t("profile.document")}
                      type="number"
                      name="document"
                      icon="icono-id"
                    />
                  </div>
                </div>
                <div className="input-content">
                  <span>{t("profile.medicalRegister")}</span>
                  <ZaiaInputForm 
                    placeholder={t("profile.medicalRegister")}
                    type="text"
                    name="medicalCode"
                    icon="icono-id"
                  />
                </div>
              </div>
              <div className="inputs-flex">
                <div className="input-content">
                  <span>{t("profile.primarySpeciality")}</span>
                  <ZaiaSelectForm 
                    placeholder={t("profile.primarySpeciality")}
                    type="text"
                    name="primarySpeciality"
                    icon="icono-star"
                    items={
                      medicalFieldsData?.enMedicalField.map((medicalField) => {
                        return ({
                          value: medicalField.value,
                          text: P.dataTranslation(medicalField.comment, language)
                        })
                      })
                    }
                  />
                </div>
                {/* <div className="input-content">
                  <span>{t("profile.secundarySpeciality")}</span>
                  <ZaiaSelectForm 
                    placeholder={t("profile.secundarySpeciality")}
                    type="text"
                    name="secundarySpeciality"
                    icon="icono-star"
                  />
                </div> */}
            </div>
            </div>
            <div className="button-container">
              <ZaiaButton 
                type="submit"
                title={
                  editInfoLoading || uploadImgLoading ?
                  `${t('globally.loading')}...` :
                  t("profile.saveChanges")
                }
              />
            </div>
          </form>
        )}
      </Formik>
      </div>
    </S.ProfileFormDiv>
  );
}

export default ProfileForm;
