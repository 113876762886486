import React, { useEffect, useState } from "react";
import * as S from "./AlgorithmsView.style";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

// graphQL
import { useMutation } from "@apollo/client";
import { MUTATION_CHAT_BOX } from "../../../../../graphql/mutations/Chat";

// components
import Message from "../message";
import ZaiaLoadingPoints from "../../../../zaiaComponents/zaiaLoadingPoints";

// pipes
import * as P from "../../../../../utils/pipes";

// Hooks
import useDoctorData from "../../../../../hooks/useDoctorData";
import YesOrNo from "../yesOrNo";
import ListItems from "../ListItems";
import ImgMessage from "../imgMessage";

function AlgorithmsView({ algorithm, setProgramSelected }) {
  const [messages, setMessages] = useState([]);
  const { t, i18n: { language } }= useTranslation("global");
  const [chatBox, {loading: chatLoading}] = useMutation(MUTATION_CHAT_BOX);
  const { doctorData } = useDoctorData();

  const element = document.getElementById("rightside__containerMsgs");

  useEffect(() => {
    botMessage(algorithm.triggerText);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algorithm]);

  const patientMessage = (msg, type) => {
    let allMessages = messages;
    allMessages.push(
      {
        id: Date.now(),
        message: msg,
        type: type
      }
    )
    setMessages([...allMessages])
  }

  const botMessage = async (msg, setSubmitting, resetForm) => {    
    try {
      if(resetForm) resetForm();
      if(setSubmitting) setSubmitting(false);
      const {
        data: {
          chatBot,
        },
      } = await chatBox({
        variables: {
          message: msg,
          doctorId: doctorData?.id,
        },
      });
      if (!chatLoading) {
        console.log(chatBot);
        setMessages(
          [...messages,
          {
            id: Date.now(),
            message: chatBot.message,
            payload: chatBot.payload,
            type: 'bot',
          }]
        )
        autoScroll()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const autoScroll = () => {
    if(element) element.scrollTop = element.scrollHeight - element.clientHeight;
  }
    
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    patientMessage(values.msg, 'doctor');
    botMessage(values.msg, setSubmitting, resetForm);
    autoScroll();
  };

   const selectYesOrNoOption = (e) => {
    patientMessage(e, 'yesOrNo');
    botMessage(e);
    autoScroll();
  }

  const selectListOption = (e) => {
    patientMessage(e.toString(), 'doctor');
    botMessage(e.toString());
    autoScroll();
  }
  

  return (
    <S.AlgorithmsView>
      <header>
        <i
          className="icon zaia-icono-atras"
          onClick={() => {
            setProgramSelected(null)
          }}
          style={{ cursor: "pointer" }}
        ></i>
        <div className="rightsideAlgorithms__headerTitle">
          <div className="rightsideAlgorithms__headerIcon">
            <i className={`icon ${algorithm.icon}`}></i>
          </div>
          <h4>
            <strong>{P.dataTranslation(algorithm.processName, language)}</strong>
            {/* - {P.dataTranslation(algorithm.descript, language)} */}
          </h4>
        </div>
        <div></div>
      </header>
      <span>{t("algorithms.chatSelect")}</span>
      <div className="rightside__containerMsgs" id="rightside__containerMsgs">
        {/* <Message
          message={t("algorithms.text")}
        /> */}
        {messages.map((message, index) => (
          <div key={message.id}>
            {
              message.type === 'yesOrNo'
              ? <YesOrNo
                option={message.message}
                noEditable={true}
              />
              : <Message
                message={message.message}
                option={message.type}
              />
            }
            {
              message.payload && Object.keys(message.payload).length > 0 
              ? 'OptionsType' in message.payload.fields
                ? message.payload.fields?.OptionsType.listValue.values[0].kind  === 'stringValue'
                  ? messages[`${index + 1}`]?.type !== 'yesOrNo'
                    ? <YesOrNo
                      option={message.message}
                      noEditable={false}
                      selectOption={selectYesOrNoOption}
                    /> 
                    : null
                  : <ListItems
                    items={message.payload.fields.OptionsType.listValue.values}
                    selectOption={selectListOption}
                  />
                : <ImgMessage
                    img={message.payload.fields.img.stringValue}
                    // height={200}
                    // width={300}
                  />
              : null
            }
          </div>
        ))}
        { chatLoading ? 
          <Message>
            <ZaiaLoadingPoints />
          </Message> : 
          // <YesOrNo
          //   selectOption={selectYesOrNoOption}
          // />
          null
        }
      </div>
      <Formik
        initialValues={{
          msg: "",
        }}
        validationSchema={
            Yup.object({
              msg: Yup.string()
              .required("Este campo es obligatorio."),
            })
        }
        onSubmit={handleSubmit}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="messageDisplay__containerFooter"
          >
            <input
              placeholder={t("algorithms.msg")}
              name="msg"
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.msg}
              autoComplete="off"
            />
            <button type="submit" disabled={props.isSubmitting}>
              <i className="send-icon icon zaia-icono-send"></i>
            </button>
          </form>
        )}
      </Formik>
    </S.AlgorithmsView>
  );
};

export default AlgorithmsView;
