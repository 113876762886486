import styled from "styled-components";
import { getHexOpacity } from "../../../../../../../utils/pipes";

export const CmpScrollMultipleOptionList = styled.div`
  border-radius: 10px;
  margin-bottom: ${({last}) => last ? '' : '5px'};
  background-color: var(--no-notification-background);
  color: var(--title-register-color);
  display: flex;
  justify-content: space-between;
  .box-name {
    font-family: "Open Sans";
    font-size: 13px;
    margin: auto 10px;
    width: 40%;
  }
  .box-states {
    width: 60%;
    min-width: 60%;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    &:hover .alice-carousel__prev-btn, &:hover .alice-carousel__next-btn{
      opacity: 1;
      transition: 1s;
    }
    .box-state-container {
      padding: 0 5px;
      position: relative;
      width: 160px;
      .box-state {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                              supported by Chrome, Edge, Opera and Firefox */
        width: 100%;
        cursor: pointer;
        height: 30px;
        border-radius: 7px;
        color: var(--white);
        font-family: "Nunito Bold";
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: var(--blue-button-opacity);
        &--active {
          background-color: ${({active}) => active === true ? 'var(--blue-color)' : 'var(--red-option)'};    
        }
      }
    }
    .alice-carousel__prev-btn {
      position: absolute;
      height: 100%;
      width: 30px;
      top: 0;
      right: left;
      padding: 0;
      opacity: 0;
      transition: 0.5s;
    }
    .alice-carousel__next-btn {
      opacity: 0;;
      position: absolute;
      height: 100%;
      width: 30px;
      top: 0;
      right: 0;
      padding: 0;
      transition: 0.5s;
    }
  }
`

export const CarouselButton = styled.button`{
  height: 100%;
  width: 100%;
  display: ${({disabled}) => disabled ? 'none' : 'block'};
  cursor: pointer;
  border: none;
  background-color: ${({theme}) => `${theme.onBackground}${theme.onBackground[7] ? '' : getHexOpacity(0.2)}`};
  .icon {
    color: ${({theme}) => theme.background}
  }
  left: ${({position}) => position === 'prev' ? 0 : '' };
  right: ${({position}) => position === 'prev' ? '' : 0 };
  border-top-left-radius: ${({position}) => position === 'prev' ? '7px' : '' };
  border-bottom-left-radius: ${({position}) => position === 'prev' ? '7px' : '' };
  border-top-right-radius: ${({position}) => position === 'prev' ? '' : '7px' };
  border-bottom-right-radius: ${({position}) => position === 'prev' ? '' : '7px' };
}` 