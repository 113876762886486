  import styled from "styled-components";

  export const ZaiaToggleCircular = styled.div`
    display: flex;
    cursor: pointer;
    .toogle-back {
      width: 40px;
      height: 20px;
      background-color: ${({theme, active, color}) => active ? color : theme.disableButton};
      border-radius: 20px;
      padding: 2px;
      transition: all 1s ease;
      margin: auto;
      .circle-toogle {
        width: 20px;
        height: 20px;
        background-color: ${({theme}) => theme.onGreyBackground};
        border-radius: 50%;
        box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
        /* margin-left: ${({active}) => active ? 'auto' : '0'}; */
        /* float: ${({active}) => active ? 'right' : 'left'}; */
        transform: ${({active}) => active ? 'translateX(20px)' : 'none'};
        transition: .5s ease;
      }
    }
  `