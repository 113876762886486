import React from "react";
import * as S from "./CmpDateSelection.style";
import SelectorForm from "../selectorForm";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Components
import { PopUpPayload } from "../genericTemplate/GenericTemplate.style";
import Popup from "reactjs-popup";

function CmpDateSelection({
  processId,
  patientId,
  title,
  item,
  insertfunction,
  customFormChangeItem,
  typeView
}) {  
  const { t, i18n: { language } } = useTranslation("global");

  const changeOptionItem = (value) => {
    const userToCustomFormField = {
      customFormFieldParentId: item?.customFormFieldParentId,
      customFormFieldId: item.id,
      value: new Date(new Date(value).setUTCHours(5)),
      key: new Date(new Date(value).setUTCHours(5))
    }
    insertfunction(userToCustomFormField);
  }

  return (
    <S.CmpDateSelection>
      <div className="selection-container">
        <h5>
          {item.fieldName && P.dataTranslation(item.fieldName, language)}
          <Popup
            trigger={
              <i className="template-header-item-icon icon zaia-alert"></i>
            }
            on={['hover', 'focus']}
            closeOnDocumentClick
            keepTooltipInside
            position={['right center']}
            nested
          >
            <PopUpPayload>
              <p>
              {P.dataTranslation(item.descript, language)}
              </p>
            </PopUpPayload>
          </Popup>
        </h5>
        {
          // !edit ?
          <SelectorForm 
            boxShadow={false}
            type='date'
            icon={"icono-cumpleanos"}
            buttonText={t("outcomes.dateProcesSelector.enterDate")}
            dataInput={P.formatNumberDateInput(customFormChangeItem ? customFormChangeItem.value : item?.userToCustomFormFields[0]?.value, t, true)}
            handleSubmit={changeOptionItem}
            disabled={typeView === 'view'}
          /> 
          // :
          // <S.SelectorFormDiv>
          //   <h5 >{item.fieldName && P.dataTranslation(item.fieldName, language)}</h5>
          //   <div className="date-selector-form">
          //       <S.SelectorForm absolute={true}>
          //           <div className="progression-free-survival-form-date">
          //             <i className="progression-free-survival-form-date-icon icon-zaia zaia-icono-cumpleanos"/>
          //             <div className="progression-free-survival-form-date-text">
          //               {
          //                 customFormChangeItem ? customFormChangeItem.value : item?.userToCustomFormFields[0]?.value
          //                 ? P.shortDate(item?.userToCustomFormFields[0]?.value, t, true) 
          //                 : t("outcomes.dateProcesSelector.noDate")}
          //             </div>
          //           </div> 
          //           <div className="progression-free-survival-form-button">
          //             <ZaiaButton
          //               type="submit"
          //               option="primary"
          //               marginTop="5"
          //               title={t("outcomes.dateProcesSelector.changeDate")}
          //               borderRadius="5"
          //               fontSize="10"
          //               height="30"
          //             />
          //           </div>
          //       </S.SelectorForm>
          //   </div>
          // </S.SelectorFormDiv>
        }
      </div>
    </S.CmpDateSelection>
  );
}

export default CmpDateSelection;
