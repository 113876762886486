import React, { useMemo } from "react";
import * as S from "./CmpBoxesContainer.style";
import * as P from "../../../../../../../utils/pipes";
import { useTranslation } from "react-i18next";

function CmpBoxesContainer({
  title,
  item,
  insertfunction,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  isHistorical,
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const changeOptionItem = (fieldOption) => {
    const userToCustomFormField = {
      customFormFieldId: item?.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === fieldOption.key
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(fieldOption.names, language),
      key: fieldOption.key,
    };
    insertfunction(userToCustomFormField);
  };

  const listOptions = useMemo(() => {
    const options =
      item?.customFormFieldParentId && !isHistorical
        ? itemParent
          ? item?.customFormTypeField.fieldOptions.filter(
              (field) => field.parentKey === itemParent.key
            )
          : item?.customFormTypeField.fieldOptions.filter(
              (field) =>
                field.parentKey ===
                customFormFields.find(
                  (field) => field.id === item.customFormFieldParentId
                )?.userToCustomFormFields[0]?.key
            )
        : item?.customFormTypeField.fieldOptions;
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemParent, customFormFields]);

  const optionKey = customFormChangeItem
    ? customFormChangeItem.key
    : item?.userToCustomFormFields[0]?.key;

  const currentPayload = useMemo(() => {
    const currentOption = listOptions.find((item) => item.key === optionKey);
    let payload;
    if (P.dataTranslation(currentOption.payload, language)) {
      payload = P.dataTranslation(currentOption.payload, language);
    } else {
      const payloadFormated = JSON.parse(currentOption.payload);
      try {
        payload = "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        payload = null;
      }
    }

    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionKey]);

  // eslint-disable-next-line no-unused-vars
  const risks = [
    {
      id: 0,
      name: t("outcomes.diagnostic.veryLowRisk"),
    },
    {
      id: 1,
      name: t("outcomes.diagnostic.lowRisk"),
    },
    {
      id: 2,
      name: t("outcomes.diagnostic.intermediateRisk"),
    },
    {
      id: 3,
      name: t("outcomes.diagnostic.highRisk"),
    },
    {
      id: 4,
      name: t("outcomes.diagnostic.veryHighRisk"),
    },
  ];

  return (
    <S.CmpBoxesContainer>
      <h5>{title}</h5>
      <div className="boxes-container">
        {
          // dataForm?.customFormFields[0].customFormTypeField.fieldOptions.map(data => {
          //   return (
          //     <S.RiskGroupBox active={data.key === dataForm?.customFormFields[0].userToCustomFormFields[0]?.key} key={data.id} onClick={() => handleChangesSelect(data)}>
          //       <div className="risk-group-box-text">{P.dataTranslation(data.names, language)}</div>
          //     </S.RiskGroupBox>
          //   )
          // })
        }
        {listOptions.map((data, index) => {
          return (
            <S.Box
              active={data.key === optionKey}
              key={data.id}
              onClick={() => changeOptionItem(data)}
            >
              <div className="risk-group-box-text">
                {P.dataTranslation(data.names, language)}
              </div>
            </S.Box>
          );
        })}
      </div>
      {/* <div className="middle-card-header">
        <h5>Clinical/Patological Features</h5>
        <div className="middle-card-header-right">
          <div className="middle-card-header-right-container">
            <ZaiaSmallSelect
              type="select"
              name="phoneLocation"
              setValue={handleChangesSelect}
              value={risks[4].id}
              items={
                risks.map((risk) => {
                  return ({
                    value: risk.id,
                    text: risk.name,
                  })
                })
              }
              shadow={false}
              fontSize="12px"
            />
          </div>
        </div>
      </div> */}
      {!currentPayload ? null : (
        <div className="big-info-card">
          {/* <h4>{Has all the following:}</h4> */}
          <h4>{currentPayload}</h4>
          {/* <ul>
            <li>31c</li>
            <li>Grade group 1</li>
            <li>PS/5510 ng/mL </li>
            <li>Fewer Man 3 prostate biopsy fragments/cores positive, 55096 cancer in each fragment/core</li>
            <li>PSAtlensity 035 ng/mL/g</li>
          </ul> */}
        </div>
      )}
    </S.CmpBoxesContainer>
  );
}

export default CmpBoxesContainer;
