import React from "react";
import * as S from './ErrorMessage.style';

function ErrorMessage({
  errorMessage,
  activeError
}) {

  return (
    <S.ErrorMessage activeError={activeError}>
      {errorMessage}
    </S.ErrorMessage>
  )
}

export default ErrorMessage;