import { gql } from "@apollo/client";

export const SUSCRIPTION_GET_SIDE_EFFECTS = gql`
  subscription SUSCRIPTION_GET_SIDE_EFFECTS(
    $sideEffects: [String!]
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    userToSideEffect(where: {typeSideEffectVal: {value: {_in: $sideEffects}}, date: {_gte: $initialDate, _lte: $finalDate}}) {
      id
      typeSideEffect
      date
      typeSideEffectVal {
        value
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUSCRIPTION_GET_SIDE_EFFECTS_BY_USER = gql`
  subscription SUSCRIPTION_GET_SIDE_EFFECTS(
    $sideEffects: [String!]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
  ) {
    userToSideEffect(where: {typeSideEffectVal: {value: {_in: $sideEffects}}, date: {_gte: $initialDate, _lte: $finalDate}, userId: {_eq: $userId}}) {
      id
      typeSideEffect
      date
      typeSideEffectVal {
        value
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
        phoneCarer
        nameCarer
      }
    }
  }
`