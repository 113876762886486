import { gql } from "@apollo/client";

export const MUTATION_CHAT_BOX = gql`
  mutation MUTATION_CHAT_BOX(
    $message: String!
    $doctorId: String
    $userId: String
  ) {
    chatBot(message: $message, doctorId: $doctorId, userId: $userId) {
      message
      payload
      sessionId
    }
  }
`;

export const MUTATION_CHAT_MESSAGES = gql`
  mutation MUTATION_CHAT_MESSAGES(
    $message: String!
    $groupChatId: uuid!
  ) {
    insert_message(objects: {message: $message, toChatId: $groupChatId}) {
      returning {
        id
        createdAt
        message
        toChatId
        isPublic
        fromDoctorId
        doctor {
          id
          name
          lastname
          gender
        }
        fromUserId
        user {
          id
          name
          lastname
          gender
        }
      }
    }
  }
`

export const MUTATION_EDIT_CHAT_GROUP = gql`
  mutation MyMutation(
    $idChatGroup: uuid!, 
    $newChatInfo: chatGroup_set_input!
  ) {
    update_chatGroup_by_pk(pk_columns: {id: $idChatGroup}, _set: $newChatInfo) {
      id
      imagen
      nameChat
    }
  }
`;


