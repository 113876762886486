import React, { useMemo } from "react";
import * as S from "./CmpRadioListInfo.style";
import { useTranslation } from "react-i18next";

//Components
import CheckboxRound from "../../../checkboxRound"

// pipes
import * as P from "../../../../../../../utils/pipes";

function CmpRadioListInfo({
  title,
  item,
  insertfunction,  
  customFormChangesList,
  customFormChangeItem,
  customFormFields
}) {

  const {i18n: { language }} = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(change => change.customFormFieldId === item?.customFormFieldParentId)
    return itemParent;
  } ,[item, customFormChangesList])

  const changeOptionItem = (fieldOption) => {
    const userToCustomFormField = {
      customFormFieldId: item?.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(field => field.key === fieldOption.key).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(fieldOption.names, language),
      key: fieldOption.key
    }
    insertfunction(userToCustomFormField)
  }

  const listOptions = useMemo(() => {
    const options = item?.customFormFieldParentId 
                      ? itemParent
                        ? item?.customFormTypeField.fieldOptions.filter(field => field.parentKey === itemParent.key)
                        : item?.customFormTypeField.fieldOptions.filter(field => field.parentKey === customFormFields.find(field => field.id === item.customFormFieldParentId)?.userToCustomFormFields[0]?.key)
                      : item?.customFormTypeField.fieldOptions
    return options;
  } ,[item, itemParent, customFormFields])

  return (
    <S.CmpRadioListInfo>
      <h5>{P.dataTranslation(item?.fieldName, language)}</h5>
      <div className="list-scores-container">
        {
          listOptions.map(data => {
            return <div className="score-item" key={data.id}> 
              <CheckboxRound
                fontSize="13px"
                checkFunction={() => changeOptionItem(data)}
                selectedItemId={customFormChangeItem ? customFormChangeItem.key : item?.userToCustomFormFields[0]?.key}
                itemCheckbox={{
                  id: data.key,
                  name: P.dataTranslation(data.names, language)
                }}
              />
            </div>
          })
        }
      </div>
    </S.CmpRadioListInfo>
  );
}

export default CmpRadioListInfo;
