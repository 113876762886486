import React, { useEffect, useState } from "react";
import * as S from "./CurrentState.style";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Hooks
import useDoctorData from "../../../../../../../hooks/useDoctorData";

// components
import ZaiaButton from "../../../../../../zaiaComponents/zaiaButton";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import ZaiaInputForm from "../../../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../../../zaiaComponents/zaiaSelectForm";

// GraphQL
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_USER_TO_PROCESS_HISTORY } from "../../../../../../../graphql/subscriptions/UserToProccesHistory";
import { QUERY_GET_PROCESS_STAGE } from "../../../../../../../graphql/queries/ProcessStage";
import { MUTATION_UPDATE_USER_TO_PROCESS } from "../../../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_CUSTOM_FORM_BY_IDS } from "../../../../../../../graphql/queries/CustomForm";

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-ok.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;

function CurrentState({ processId, patientId }) {
  const {
    t,
    i18n: {
      // eslint-disable-next-line no-unused-vars
      language,
    },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();

  const [addNewEvent, setAddNewEvent] = useState(false);
  const nowTime = new Date(Date.now());
  const [month, setMonth] = useState(nowTime.getMonth());
  const [year, setYear] = useState(nowTime.getFullYear());
  const [dateStartFilter, setDateStartFilter] = useState(new Date(nowTime));
  const [dateFinishFilter, setDateFinishFilter] = useState(new Date(nowTime));

  const {
    data: userToProcessHistory,
    loading: userToProcessHistoryLoading,
    error: userToProcessHistoryError,
  } = useSubscription(SUBSCRIPTION_GET_USER_TO_PROCESS_HISTORY, {
    variables: {
      patientId: patientId,
      processId: processId,
      initialDate: new Date(dateStartFilter),
      finalDate: new Date(dateFinishFilter),
    },
  });

  useEffect(() => {
    let dateStart = new Date(year, month, 1);
    let dateFinish = new Date(year, month, 1);
    const numberDaysMonth = new Date(year, month + 1, 0).getDate();
    dateFinish.setDate(numberDaysMonth);
    dateStart.setDate(1);
    setDateStartFilter(new Date(dateStart).setHours(0, 0, 0, 0));
    setDateFinishFilter(new Date(dateFinish).setHours(23, 59, 59, 999));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  const backMonth = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
    // caclDatesFilters();
  };

  const nextMonth = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
    // caclDatesFilters();
  };

  return (
    <S.CurrentState>
      <div className="current-state-header">
        <h5>{t("outcomes.currentStatus.title")}</h5>
        <div className="current-state-header-date-selector">
          <div className="date-selector">
            <div className="date-selector-text">
              {P.nameMonth(month, "L", t)} {year}
            </div>
            <div className="date-selector-icons">
              <i
                className="date-selector-icon date-selector-icon--back icon zaia-icono-atras"
                onClick={() => backMonth()}
              />
              <i
                className="date-selector-icon date-selector-icon--next icon zaia-i-next"
                onClick={() => nextMonth()}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="current-state-add-event"
        onClick={!addNewEvent ? () => setAddNewEvent(true) : null}
      >
        {/* <div className="current-state-add-event-trigger">
          { addNewEvent ? null : <i className="current-state-add-event-icon icon zaia-icono-agregar"/> }
          <div className="current-state-add-event-text">{ addNewEvent ? t("outcomes.currentStatus.eventType") : t("outcomes.currentStatus.addNewEvent")}</div>
        </div> */}
        <div className="current-state-add-event-form">
          {addNewEvent ? (
            <AddNewEvent
              setAddNewEvent={setAddNewEvent}
              patientId={patientId}
              processId={processId}
              doctorId={doctorData?.id}
            />
          ) : null}
        </div>
      </div>
      {/* <Popup
        trigger={
          <div className="current-state-add-event">
            <i className="current-state-add-event-icon"></i>
            <div className="current-state-add-event-text">Agregar nuevo evento</div>
          </div>
        }
        keepTooltipInside
        nested
        position="left top"
      >
        <AddNewEvent/>
      </Popup> */}
      <div className="current-state-content">
        <ListOfEvents
          userToProcessHistory={userToProcessHistory}
          userToProcessHistoryLoading={userToProcessHistoryLoading}
          userToProcessHistoryError={userToProcessHistoryError}
        />
      </div>
    </S.CurrentState>
  );
}

function AddNewEvent({ setAddNewEvent, processId, patientId, doctorId }) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const [typeForm] = useState("addEvent");

  const { data: processesStage } = useQuery(QUERY_GET_PROCESS_STAGE, {
    variables: {
      processId: processId,
    },
  });
  const [editUserToProcess] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS);
  const dateNow = new Date(Date.now());

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    if (values.descriptionEvent && values.stageIdEvent) {
      try {
        const { data: returning } = await editUserToProcess({
          variables: {
            processId: processId,
            userId: patientId,
            doctorId: doctorId,
            stageId: values.stageIdEvent,
            descript: values.descriptionEvent,
            // userToProcess: {
            // }
          },
        });
        resetForm();
        setAddNewEvent(false);
        console.log("success", returning);
      } catch (err) {
        resetForm();
        console.log(err);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        stageIdEvent: "",
        timeEvent: `${dateNow.getFullYear()}-${
          dateNow.getMonth() + 1 < 10 ? "0" : ""
        }${dateNow.getMonth() + 1}-${dateNow.getDate()}`,
        descriptionEvent: "",
      }}
      // validationSchema={
      //   Yup.object().shape(
      //       {
      //         nameEvent: Yup.string()
      //           .min(3, t("register.validation.min3"))
      //           .max(15, t("register.validation.max"))
      //           .required(t("register.validation.required")),
      //         descriptionEvent: Yup.string()
      //           .min(3, t("register.validation.min3"))
      //           .max(15, t("register.validation.max"))
      //           .required(t("register.validation.required")),
      //       }
      //     )
      // }
      onSubmit={handleSubmit}
    >
      {(props) => (
        <S.CurrentStateForm absolute={true}>
          <form onSubmit={props.handleSubmit}>
            <div className="current-state-form-inputs">
              {typeForm === "addEvent" ? (
                <>
                  <div className="current-state-form-content-double">
                    <div className="current-state-form-input current-state-form-input--small">
                      <ZaiaSelectForm
                        placeholder={t("outcomes.currentStatus.which")}
                        type="select"
                        name="stageIdEvent"
                        padding={"5px"}
                        fontSize={"12px"}
                        errorOption={false}
                        items={processesStage?.processStage.map((stage) => {
                          return {
                            value: stage.id,
                            text: P.dataTranslation(stage.stageName, language),
                          };
                        })}
                      />
                    </div>
                    <div className="current-state-form-input current-state-form-input--small">
                      <ZaiaInputForm
                        type="date"
                        name="timeEvent"
                        icon="icono-cumpleanos"
                        errorOption={false}
                        padding={"5px"}
                        fontSize={"12px"}
                      />
                      {/* <ZaiaSelectForm
                          placeholder={t("outcomes.currentStatus.which")}
                          type="select"
                          name="timeEvent"
                          padding={'5px'}
                          fontSize={'12px'}
                          items={
                            processesStage?.processStage.map((stage) => {
                              return ({
                                value: stage.id,
                                text: P.dataTranslation(stage.stageName, language)
                              })
                            })
                          }
                        /> */}
                    </div>
                  </div>
                  <div className="current-state-form-input">
                    <ZaiaInputForm
                      placeholder={t("outcomes.currentStatus.eventDescript")}
                      type="text"
                      name="descriptionEvent"
                      padding={"5px"}
                      textarea={true}
                      fontSize={"12px"}
                      errorOption={false}
                    />
                  </div>
                </>
              ) : typeForm === "error" ? (
                <ZaiaReaction
                  text={"error"}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaAlert}
                />
              ) : (
                <ZaiaReaction
                  text={"ok"}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaOk}
                />
              )}
            </div>
            <div className="current-state-form-buttons">
              <div className="current-state-form-button">
                <ZaiaButton
                  type="submit"
                  option="primary"
                  marginTop="5"
                  title={"Añadir"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
              <div
                className="current-state-form-button"
                onClick={() => setAddNewEvent(false)}
              >
                <ZaiaButton
                  type="button"
                  option="secondary"
                  marginTop="5"
                  title={"Cancelar"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
            </div>
          </form>
        </S.CurrentStateForm>
      )}
    </Formik>
  );
}

// eslint-disable-next-line no-unused-vars
function EventCommentsContainer() {
  const [t] = useTranslation("global");
  const [addNewComment, setAddNewComment] = useState(false);

  return (
    <S.EventCommentsContainer>
      <div className="comments-container">
        <div className="comments-container-header">
          <h5>{t("outcomes.currentStatus.comment")}:</h5>
          <div
            className="comments-container-add-comment"
            onClick={!addNewComment ? () => setAddNewComment(true) : null}
          >
            <i className="comments-container-add-comment-icon icon zaia-icono-agregar" />
            <div className="comments-container-add-comment-text">
              {t("outcomes.currentStatus.addComment")}
            </div>
          </div>
        </div>
        <div className="comments-container-add-new-comment">
          {addNewComment ? (
            <AddNewComment setAddNewComment={setAddNewComment} />
          ) : null}
        </div>
        <div className="comments-container-content">
          <CommentBox
            comment="Se le aplicaron los medicamentos correspondientes al tratamiento indicado, el paciente estará en constante revisión."
            date="08 Octubre 2021"
            hour="10:30 am"
          />
        </div>
      </div>
    </S.EventCommentsContainer>
  );
}

function CommentBox({ comment, date, hour }) {
  const [t] = useTranslation("global");

  return (
    <S.CommentBox>
      <div className="comment-box-text">{comment}</div>
      <div className="comment-box-date-content">
        <div className="comment-box-date">{date},</div>
        <div className="comment-box-hour">
          {t("outcomes.currentStatus.hour")}: {hour}
        </div>
      </div>
    </S.CommentBox>
  );
}

function AddNewComment({ setAddNewComment }) {
  const [typeForm] = useState("newComment");

  const handleSubmit = (values, { resetForm }) => {
    console.log();
  };

  return (
    <Formik
      initialValues={{
        comment: "",
      }}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <S.CurrentStateForm absolute={false}>
          <form onSubmit={props.handleSubmit}>
            <div className="current-state-form-inputs">
              {typeForm === "newComment" ? (
                <div className="current-state-form-input">
                  <ZaiaInputForm
                    type="text"
                    name="comment"
                    padding={"5px"}
                    textarea={true}
                    fontSize={"12px"}
                    errorOption={false}
                  />
                </div>
              ) : typeForm === "error" ? (
                <ZaiaReaction
                  text={"error"}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaAlert}
                />
              ) : (
                <ZaiaReaction
                  text={"ok"}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaOk}
                />
              )}
            </div>
            <div className="current-state-form-buttons">
              <div className="current-state-form-button">
                <ZaiaButton
                  type="submit"
                  option="primary"
                  marginTop="5"
                  title={"Añadir"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
              <div
                className="current-state-form-button"
                onClick={() => setAddNewComment(false)}
              >
                <ZaiaButton
                  type="button"
                  option="secondary"
                  marginTop="5"
                  title={"Cancelar"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
            </div>
          </form>
        </S.CurrentStateForm>
      )}
    </Formik>
  );
}

function ListOfEvents({
  userToProcessHistory,
  userToProcessHistoryLoading,
  userToProcessHistoryError,
}) {
  // eslint-disable-next-line no-unused-vars
  const [eventComments, setEventComments] = useState(null);

  const customFormIds = userToProcessHistory?.userToProcessHistory
    .map((history) => history.latestCustomFormId)
    .filter((n) => n);

  const {
    data: customForms,
    loading:
      // eslint-disable-next-line no-unused-vars
      customFormsLoading,
    error:
      // eslint-disable-next-line no-unused-vars
      customFormsError,
  } = useQuery(QUERY_GET_CUSTOM_FORM_BY_IDS, {
    variables: {
      customFormIds: customFormIds,
    },
  });

  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  if (userToProcessHistoryLoading)
    return <ZaiaReaction widthImg="120px" zaiaImg={logoZaiaLoading} />;

  if (userToProcessHistoryError)
    return (
      <ZaiaReaction
        zaiaImg={logoZaiaAlert}
        widthImg="120px"
        text={t("outcomes.currentStatus.errorEvents")}
        sizeText="15px"
        widthText="240px"
      />
    );

  if (userToProcessHistory?.userToProcessHistory.length === 0)
    return (
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthImg="120px"
        text={t("outcomes.currentStatus.noEvents")}
        sizeText="15px"
        widthText="240px"
      />
    );

  return (
    <>
      {userToProcessHistory?.userToProcessHistory.map(
        (processHistory, index, { length }) => {
          return (
            <div key={processHistory.id}>
              <S.ReportBox line={!(index === length - 1)} point={index === 0}>
                <div className="report-box-indicator">
                  <div className="report-box-indicator-point"></div>
                  <div className="report-box-indicator-line"></div>
                </div>
                <div className="report-box-info">
                  <div className="report-box-info-text">
                    {processHistory.descript.split("-")[0] !== "undefined "
                      ? processHistory.descript.split("-")[0]
                      : null}
                  </div>
                  {processHistory.descript
                    .split("-")[1]
                    ?.split(",")
                    .map((item) => {
                      return (
                        <div className="report-box-info-text-change">
                          - {item}
                        </div>
                      );
                    })}
                  <div className="report-box-info-options">
                    <div className="report-box-info-options-text">
                      <div className="report-box-info-options-text-date">
                        {P.longDate(processHistory.created_at, t, true)} •{" "}
                        {P.drTranslation(
                          processHistory.doctor.gender,
                          language
                        ) +
                          " " +
                          processHistory.doctor.name +
                          " " +
                          processHistory.doctor.lastname}{" "}
                        -{" "}
                      </div>
                      <div className="report-box-info-options-text-report">
                        {" "}
                        {P.dataTranslation(
                          customForms?.customForm?.find(
                            (customForm) =>
                              customForm.id ===
                              processHistory.latestCustomFormId
                          )?.customFormName,
                          language
                        )}
                      </div>
                    </div>
                    {/* <div className="report-box-info-options-comments" onClick={() => setEventComments(eventComments ? null : processHistory.id)}>
                      <i className="report-box-info-options-comments-icon icon zaia-icono-chat-relleno"/>
                      <div className="report-box-info-options-comments-quantity">1</div>
                    </div> */}
                  </div>
                </div>
              </S.ReportBox>
              {/* <div className="current-state-content-comments">
                {
                  eventComments === processHistory.id ? 
                  <EventCommentsContainer /> : null  
                }
              </div> */}
            </div>
          );
        }
      )}
      {/* <S.ReportBox line={true} point={false}>
        <div className="report-box-indicator">
          <div className="report-box-indicator-point"></div>
          <div className="report-box-indicator-line"></div>
        </div>
        <div className="report-box-info">
          <div className="report-box-info-text">El paciente reporto que el nuevo medicamento le causo alergia en la piel.</div>
          <div className="report-box-info-options">
            <div className="report-box-info-options-text">
              <div className="report-box-info-options-text-date">02 Octubre 2021 - </div>
              <div className="report-box-info-options-text-report"> Tipo de reporte</div>
            </div>
            <div className="report-box-info-options-comments" onClick={() => setEventComments(!eventComments)}>
              <i className="report-box-info-options-comments-icon icon zaia-icono-chat-relleno"/>
              <div className="report-box-info-options-comments-quantity">1</div>
            </div>
          </div>
        </div>
      </ S.ReportBox>*/}
    </>
  );
}

export default CurrentState;
