import styled from "styled-components";

export const VitalSignTimeLineCard = styled.div`
  .weekly-bars-card {
    width: 314px;
    min-width: 314px;
    height: auto;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 0px 3px 6px #00000029;
    padding: 5px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    &__header {
      display: flex;
      height: 52px;
      align-items: center;
      &-icon {
        background-color: ${({colorVariation}) => colorVariation};
        width: 45px;
        border-top-left-radius: 10px;
        display: flex;
        color: var(--white);
        font-size: 27px;
        padding-top: 14px; 
        i {
          margin: auto;
        }
      }
      &-text {
        margin-left: 10px;
        p {
          margin-top: 5px;
          font-family: "Open Sans";
          color: var(--text-toogle-gray);
          font-size: 12px;
        }
      }
    }
    &__bars-container {
      padding: 10px;
      height: 100%;
      display: flex;
      &-listlevels {
        width: 55px;
        height: 100px;
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: ${({colorPrimary}) => colorPrimary};
        p {
          font-size: 9px;
          font-family: "Open Sans SemiBold"
        } 
      }
      &-bars {
        width: 100%;
        margin: 0 10px;
        display: flex;
        overflow: auto;
        justify-content: space-between;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;
