import React from "react";
import * as S from "./DasboardOutcomesByPatient.style";

//Components
import PatientHeader from "../../../shared/patientHeader";
// import FollowUpProcessByPatient from "./components/followUpProcessByPatient"; 
// import ProstateProcessByPatient from "./components/prostateProcessByPatient"; 
import OutcomesProcessByPatient from "./components/outcomesProcessByPatient";

function DasboardOutcomesByPatient({ patientID, processID, processToCustomFormID, processNameKey }) {

  return (
    <S.DasboardOutcomesByPatientDiv>
      <PatientHeader patientID={patientID} processID={processID} closeLink={"/outcomes"} viewName={'outcomes'} processToCustomFormID={processToCustomFormID}/>
      {/* <div className="checkboxes-header-container">
        {
          medicalConsultations.map(item => {
            return <div className="checkbox-container" key={item.id}>
              <CheckboxRound 
                checkFunction={setMedicalConsultation}
                itemCheckbox={item}
                selectedItemId={medicalConsultation.id}
              />
            </div> 
          })
        }
      </div> */}
      <div className="graphs-outcomes-container">
        {
          // processNameKey === 'follow-up' ?
          //   <FollowUpProcessByPatient patientID={patientID} processID={processID}/>
          // : null
          <OutcomesProcessByPatient patientID={patientID} processID={processID} processToCustomFormID={processToCustomFormID}/> 
          // processNameKey === 'prostate' ?
          // <ProstateProcessByPatient patientID={patientID} processID={processID}/> : null
        }
      </div>
    </S.DasboardOutcomesByPatientDiv>
  );
}

export default DasboardOutcomesByPatient;
