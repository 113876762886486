import React from "react";
import * as S from "./ZaiaToggle.style";

function ZaiaToggle({ 
  tabs, 
  activeTab, 
  setActiveTab, 
  color,
  backgroundColor,
  borderRadius,
  sizeFont
}) {

  return (
    <S.ZaiaToggle 
      color={color} 
      backgroundColor={backgroundColor} 
      borderRadius={borderRadius}
      sizeFont={sizeFont}
    >
      {tabs.map((tab) => (
        <p
          onClick={() => setActiveTab(tab.id)}
          className={activeTab === tab.id ? "activeTab" : ""}
          key={tab.id}
        >
          {tab.name}
        </p>
      ))}
    </S.ZaiaToggle>
  )
}

export default ZaiaToggle;