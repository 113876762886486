// graphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_PERSONAL_INFO, QUERY_GET_USER_BY_ID_AND_PROCESS_ID } from "../graphql/queries/User";


export default function usePatientVerification(patientId, processId) {

  const {
    data: userData,
    loading: userDataLoading
  } = useQuery(
    processId
    ? QUERY_GET_USER_BY_ID_AND_PROCESS_ID
    : QUERY_GET_USER_PERSONAL_INFO,
    { 
      variables: 
      processId
      ? {
        processId: processId,
        patientId: patientId
      }
      : {
        patientId: patientId
      }
    }
  );

  return {
    patientValid: userData?.user[0] ? true : false,
    loadingValidation: userDataLoading
  };
}