import React from "react";
import * as S from "./ZaiaButton.style.js";

function ZaiaButton({ 
  id,
  marginTop, 
  title, 
  type, 
  option, 
  marginBottom, 
  color, 
  shadow = true, 
  backgroundColor, 
  fontSize,
  fontFamily,
  borderRadius,
  icon,
  border = true,
  height
}) {

  return (
    <S.ZaiaButton
      id={id}
      type={type}
      option={option}
      marginTop={marginTop}
      marginBottom={marginBottom}
      color={color}
      shadow={shadow}
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      fontFamily={fontFamily}
      borderRadius={borderRadius}
      border={border}
      height={height}
    >
      {
        icon ? 
        <div className="icon-container">
          <i className={`button-icon icon ${icon}`}></i> 
        </div> : null
      }
      {title}
    </S.ZaiaButton>
  );
}

export default ZaiaButton;
