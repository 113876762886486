import styled from "styled-components";

export const StepOneDiv = styled.div`
  margin-top: 20px;
`;
export const StepTwoDiv = styled.div`
  margin-top: 20px;
  .register__dateTitle {
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .register__phoneTitle {
    margin: 14px 0 10px 0;
  }
  .registerForm__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 310px;
    height: 30px;
    margin: 14px 0 30px 0;
  }
  .registerForm__phone > input:focus {
    outline: none;
    border: 1px solid var(--blue-input-focus-color);
  }
  .registerForm__gender {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0 10px 0;
  }
  .registerForm__gender > input {
    height: 45px;
    width: 90px;
    border: 1px solid var(--disabled-input-color);
    color: #bcbcbc;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
  }
  .registerForm__gender > input.active {
    background-color: var(--blue-color);
    color: white;
  }
`;
export const StepThreeDiv = styled.div`
  margin-top: 10px;
  h3 {
    margin-top: 10px;
  }
  .registerForm__checkboxLabel {
    display: flex;
    align-items: center;
    margin-bottom: 120px;
  }
`;
export const StepFourDiv = styled.div`
  margin-top: 20px;
  .registerForm__radio {
    display: flex;
    align-items: center;
    width: 130px;
    justify-content: space-between;
    margin: 10px 0 30px 0;
  }
  .secondSpecialty {
    margin-top: 30px;
  }
`;
export const StepFiveDiv = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  p {
    color: var(--subtitle-color);
    font-size: smaller;
    margin: 5px 0 30px 0;
  }
  .stepFiveDiv__input {
    width: 300px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid var(--disabled-input-color);
    margin: 10px 0 10px 0;
    padding: 5px;
  }
  .stepFiveDiv__input:focus {
    border: 1px solid var(--blue-input-focus-color);
    outline: none;
  }
`;
export const LastStepDiv = styled.div`
  margin-top: 100px;
  p {
    color: var(--blue-color-100);
    margin: 10px 0 50px 0;
  }
  .paragraph {
    max-width: 258px;
  }

  button[type="submit"]:disabled {
    background-color: var(--disabled-input-color);
  }
`;
