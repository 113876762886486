import styled, {keyframes} from "styled-components";

const load = (completed) => keyframes`
  0% { width: 0; }
  100% { width: ${completed}%; }
`;

export const ProgressBar = styled.div`
  height: 15px;
  background-color: ${(props) => props.bgColor2};
  border-radius: 50px;
  margin: 5px 0;
  /* transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg); */
  .fillerStyles {
    height: 100%;
    width: ${({completed, animationFromZero}) => !animationFromZero ? completed ? completed : 0 : null}%;
    transition: 1s ease;
    background-color: ${(props) => props.bgColor};
    border-radius: inherit;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: ${({bgColor2}) => '0px 1px 6px ' + bgColor2};
    animation: ${({completed, animationFromZero}) => animationFromZero ? load(completed) : null} 3s normal forwards;
  }
  .labelStyles {
    color: var(--white);
    font-size: 0.65rem;
    padding: 0 5px;
    white-space: nowrap;
  }
`;
