import React from "react";
import * as S from "./UserDropdown.style";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Context
import { useStateValue } from "../../../../contextAPI/StateProvider";

// Utils
import { Routes } from "../../../../utils/constants";

// Assets
const img = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-bienvenido.svg`;

// GraphQl
// import { doctorVar } from '../../../../graphql/cache';

function UserDropdown({doctorEmail, modalClose}) {
  const history = useHistory();
  const [t] = useTranslation("global");
  const [, dispatch] = useStateValue();
  const { HOME } = Routes;
  // const client = useApolloClient();

  const logout = () => {
    localStorage.removeItem("accessToken");
    // doctorVar(null);
    localStorage.removeItem("apollo-cache-persist");
    dispatch({
      type: "SET_AUTH",
      isAuth: false,
    });
    history.push(HOME.route);
  };
  return (
    <S.DropdownDiv>
      <div>
        <img alt="dropdownlogo" src={img} />
        <p>{doctorEmail}</p>
        <button onClick={() => {history.push('/profile'); modalClose()}}>{t("dropdown.manage")}</button>
      </div>
      <div>
        <p>{t("dropdown.privacyPolicies")}</p>
        <p>{t("dropdown.terms")}</p>
        <button onClick={logout}>{t("dropdown.signOut")}</button>
      </div>
    </S.DropdownDiv>
  );
}

export default UserDropdown;
