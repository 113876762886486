import styled from "styled-components";

export const OutcomesProcessByPatient = styled.div`
  padding: 20px;
  /* background-color: var(--white); */
  border-radius: 10px;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  .table-title{
    font-size: 18px;
    font-family: "Nunito";
    color: ${({theme}) => theme.primaryColor };
    border-bottom: 1.6px solid var(--no-notification-background);
    padding: 13px 5px 13px 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    background-color: #E3E3F2;
    border-radius: 50px;
    align-items: center;
    height: 20px;
    .buttons-container {
      display:  flex;
    }
  }
  .view-container {
    overflow: auto;
    height: calc(100% - 50px);;
  }
`;

export const ProstateProcessRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  h5 {
    font-family: "Open Sans Bold";
    margin-bottom: 10px;
  }
  .row-left {
    width: ${({row1}) => row1 ? row1 : 'auto'}; 
    min-width: ${({row1Min}) => row1Min ? row1Min : 'auto'}; 
    margin-right: 5px;
    padding-bottom: 10px;
    flex-grow:2;
  }
  .row-right {
    width: ${({row2}) => row2 ? row2 : 'auto'};
    min-width: ${({row2Min}) => row2Min ? row2Min : 'auto'}; 
    margin-left: 5px;
    flex-grow:2;
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`

export const ButtonView = styled.button`
  border-radius: 50px;
  height: 37px;
  width: 188px;
  padding: 0 10px;
  border: none;
  outline: none;
  background-color: ${({active, theme}) => `${theme.primaryColor}${active ? '': '52'}` };
  color: var(--white);
  margin: 0 0 0 14px;
  font-family: "Nunito";
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon-container {
    margin-right: 8px;
    padding-top: 6px;
    i {
      font-size: 20px
    }
  }
`