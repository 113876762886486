import React, { useEffect, useState } from "react";
import App from "../App";
import {
  ApolloClient,
  HttpLink,
  ApolloProvider,
  InMemoryCache,
  split
} from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { StateProvider } from "../contextAPI/StateProvider";
import reducer, { initialState } from "../contextAPI/reducer";
// import { WebSocketLink } from '@apollo/client/link/ws';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useTranslation } from "react-i18next";


// import { cache } from "../graphql/cache"
// import { persistCache } from "apollo-cache-persist"

function Apollo() {
  const [accessToken, setAccessToken] = useState();
  const [client, setClient] = useState();
  const [t] = useTranslation("global");

  const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

  useEffect(() => {
    const getHeaders = () => {
      const headers = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      return headers;
    };
  
    const httpLink = new HttpLink({
      uri: `https://${GRAPHQL_ENDPOINT}`,
      headers: getHeaders(),
    });
  
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `wss://${GRAPHQL_ENDPOINT}`,
        connectionParams: () => {
          return { headers: getHeaders() };
        },
        // shouldRetry: true
      }),
    );
  
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );
  
  
    const client = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache()
    });

    setClient(client);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])


  // useEffect(() => {
  //   //   setAccessToken(sessionStorage.getItem("accessToken"));
  //   setAccessToken(initialState.isAuth);
  // }, [accessToken]);

  

  // useEffect(() => {
  //   const authLink = setContext((_, { headers }) => {
  //   const token = localStorage.getItem('accessToken');
  //     if (token) {
  //       return {
  //         headers: {
  //           ...headers,
  //           authorization: 'Bearer ' + token,
  //         },
  //       };
  //     } else {
  //       return {
  //         headers: {
  //           ...headers,
  //         },
  //       };
  //     }
  //   });

  //   apolloConfig(authLink)
    
  // }, [accessToken]);

  


  // const apolloConfig = async (authLink) => {
  //   // await persistCache({
  //   //   cache,
  //   //   storage: window.localStorage
  //   // })
    

  //   const splitLink = split(
  //     ({ query }) => {
  //       const definition = getMainDefinition(query);
  //       return (
  //         definition.kind === 'OperationDefinition' &&
  //         definition.operation === 'subscription'
  //       );
  //     },
  //     wsLink,
  //     httpLink,
  //   );
    
  //   setClient(client);
  // }

  if (!client) {
    return `${t("globally.loading")}...`;
  }

  return (
    <ApolloProvider client={client}>
      <StateProvider reducer={reducer} initialState={initialState}>
        <App setAccessToken={setAccessToken} />
      </StateProvider>
    </ApolloProvider>
  );
}

export default Apollo;
