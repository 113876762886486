import styled from "styled-components";

export const ZaiaModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  .modal-container {
    width: 350px;
    margin: auto;
    padding: 20px;
    .card-inputs {
      background-color: ${({theme}) => theme.background};
      padding: 30px;
      border-radius: 30px;
      height: 250px;
      box-shadow: 1px 1px 11px 5px ${({theme}) => theme.blackShadow}29;
      display: flex;
    }
    .card-buttons {
      padding-bottom: 20px;
      .cancel-button {
        text-align: center;
        font-family: "Open Sans";
        margin-top: 30px;
        color: ${({theme}) => theme.primaryColor};
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
`;
