import styled from "styled-components";

export const Box = styled.div`
  background-color: ${({primaryColor}) => primaryColor};
  width: 100%;
  height: ${({completeBox}) => completeBox ? '213px' : '150px'};
  position: relative;
  border-radius: 20px;
  padding: 10px;
  color: ${({theme}) => theme.onCard};
  cursor: pointer;
  box-sizing: border-box;
  .box__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box__headerProfile {
      display: flex;
      align-items: center;
      .box__headerTitle {
        font-size: 10px;
        color: ${({theme}) => theme.onCard};
        margin-left: 10px;
        font-family: 'Open Sans Semibold';
        letter-spacing: 2px;
        .box__headerDate {
          letter-spacing: 0px;
          margin-left: 0px;
          font-family: 'Open Sans';
          margin-top: 2px;
          font-size: 9.5px;
        }
      }
    }
    .box__headerIcon {
      background-color: ${({typeBoxColor}) => typeBoxColor };
      color: ${({theme}) => theme.onCard};
      border-radius: 10px;
      height: 38px;
      width: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        font-size: larger;
        margin-top: 7px;
      }
    }
    .box__headerTime {
      display: flex;
      align-items: center;
      p {
        font-size: 10px;
        font-family: 'Open Sans';
        margin: -5px 5px 0 0;
      }
    }
  }
  .box__userInfo {
    padding: ${({type}) => type === 5 || type === 4 ? '' : '10px 10px 0 10px' };
    background-color: ${({secundaryColor, type}) => type === 5 || type === 4 ? '' : secundaryColor };
    color: ${({theme}) => theme.onCard};;
    border-radius: 10px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    height: ${({completeBox}) => completeBox ? '140px' : '77px'};
    overflow: auto;
    .box__userInfoHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        p {
          font-family: "Nunito Bold";
          font-size: 0.85rem;
          margin-left: 5px;
        }
      }
      button {
        background-color: ${({theme}) => theme.onCard};;
        border-radius: 20px;
        padding: 2px 10px 2px 10px;
        font-size: 0.7rem;
        border: none;
        color: ${({primaryColor}) => primaryColor};
        font-family: "Nunito Bold";
      }
    }
    .box__userInfoSubtitle {
      font-size: 0.7rem;
      line-height: 1.4rem;
      margin-top: 3px;
      font-family: "Open Sans";
      -webkit-line-clamp: 2;
      -webkit-box-orient: ${({completeBox}) => completeBox ? '' : 'vertical'};
      overflow: ${({completeBox}) => completeBox ? '' : 'hidden'};
      text-overflow: ellipsis;
      display: -webkit-box;
    }
    .skyBlueBox__btn{
      background-color: ${({theme}) => theme.onCard};;
      padding: 8px 50px;
      color: var(--blue-box-headercolor);
      border: none;
      border-radius: 20px;
      box-shadow: 0 0 9px ${({theme}) => theme.blackShadow}29;
      cursor: pointer;
      margin: 15px auto ;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;