import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListItems.style";

function ListItems({
  option,
  selectOption,
  noEditable,
  items
}) {

  const { t }= useTranslation("global");

  return (
    <S.ListItems>
      <div className="listItems-states">
        {
          items.map((item, index) => {
            return (
              <div 
                className={`listItems-state ${option === item && 'listItems-state--selected'}`} 
                key={index}
                onClick={() => 
                  option === item 
                  ? null 
                  : noEditable
                    ? null 
                    : selectOption(item?.numberValue)
                }>{t('algorithms.chatboxOption') + ' ' + item.numberValue}</div>
            )
          })
        }
      </div>
    </S.ListItems>
  );
}

export default ListItems;
