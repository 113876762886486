import Dashboard from "../pages/private/dashboard";
import Patients from "../pages/private/patients";
import Algorithms from "../pages/private/algorithms";
import Outcomes from "../pages/private/outcomes";
import Chat from "../pages/private/chat";
import Profile from "../pages/private/profile";

const privateRoutesList = [
  {
    id: "dashboard",
    key: "dashboard",
    path: "/dashboard",
    name: "sidebar.dashboard",
    icon: "zaia-icono-home-relleno",
    component: () => <Dashboard />,
  },
  {
    id: "patients",
    key: "patients",
    path: "/patients/:patientID?",
    name: "sidebar.patients",
    icon: "zaia-icono-pacient",
    component: () => <Patients />,
  },
  {
    id: "algorithms",
    key: "algorithms",
    path: "/algorithms/:processNameKey?", 
    name: "sidebar.algorithms",
    icon: "zaia-icono-algoritmos-1",
    component: () => <Algorithms />,
  },
  {
    id: "outcomes",
    key: "outcomes",
    path: "/outcomes/:processNameKey?/:patientID?",
    name: "sidebar.outcomes",
    icon: "zaia-icono-resultados",
    component: () => <Outcomes />,
  },
  {
    id: "chat",
    key: "chat",
    path: "/chat/:chatID?",
    name: "sidebar.chat",
    icon: "zaia-icono-chat",
    component: () => <Chat />,
  },
  {
    id: "profile",
    key: "profile",
    path: "/profile",
    name: "sidebar.profile",
    icon: "zaia-icono-nombre",
    component: () => <Profile />,
  },
];

export default privateRoutesList;
