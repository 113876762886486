import React, { useRef, useState, useContext } from "react";
import * as S from "./UserHeader.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { useStateValue } from "./../../../../contextAPI/StateProvider";
import { ThemeContext } from 'styled-components';

// Components
import ZaiaRoundInput from "../../../zaiaComponents/zaiaRoundInput";
import ZaiaImage from "../../../zaiaComponents/zaiaImage";
import UserDropdown from "../../shared/userDropdown";

// GraphQL
import { useLazyQuery } from "@apollo/client";
// import { doctorVar } from '../../../../graphql/cache';

import { QUERY_GET_USERS_BY_FILTER } from "../../../../graphql/queries/User";
// import { GET_CURRENT_DOCTOR } from "../../../../graphql/localQueries/Doctor";

// pipes
import * as P from "../../../../utils/pipes"

// Hooks
import useDoctorData from "../../../../hooks/useDoctorData";

// Assets
const defaultDoctor = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-doctor.png`;


function UserHeader({ title, icon}) {

  const refPopUp = useRef();
  const {t, i18n: { language }} = useTranslation("global");
  const theme = useContext(ThemeContext);
  const openPopUp = () => refPopUp.current.open();
  const [inputText, setInputText] = useState('');
  const { doctorData } = useDoctorData();
  const [{ selectedPatient }, dispatch] = useStateValue();


  const [ getPatients, { data: patientsData }] = useLazyQuery(
    QUERY_GET_USERS_BY_FILTER, 
    {
      onCompleted: data => {
        // console.log(data.user);
      }
    }
  );


  const selectPatient = (patient) => {
    dispatch({
      type: "SET_SELECTED_PATIENT",
      selectedPatient: patient,
    });
  }

  const deletePatientSelected = () => {
    dispatch({
      type: "SET_SELECTED_PATIENT",
      selectedPatient: null,
    });
    getPatients(
      {
        variables: {
          text: ``,
        }
      }
    );
  }

  const getText = (e) => {
    setInputText(e);
    if ( e.length > 1 ) 
    getPatients(
      {
        variables: {
          text: `%${inputText}%`,
        }
      }
    );
  }

  const color = title === t("sidebar.profile") ? 
  {
    icon: theme.mainProfileColor,
    shadow: theme.mainProfileColor+'59',
    font: theme.onBackgroundProfile,
  } : null

  return (
    <S.UserHeaderDiv 
      colorIcon={color?.icon} 
      colorShadow={color?.shadow} 
      colorFont={color?.font}
    >
      <div className="header">
        <div className="header__left-content">
          {title === t("sidebar.dashboard") ? 
            <ZaiaRoundInput 
              placeholder={t("globally.search")} 
              padding="8px" 
              type={'text'} 
              getText={getText}
              tagSelectedText={selectedPatient ? `${selectedPatient?.documentNumber} - ${selectedPatient?.name} ${selectedPatient?.lastname}` : null}
              items={patientsData?.user.map((patient) => {
                return {
                  patient: patient,
                  value: patient.id,
                  image: patient.picture,
                  title: `${patient.name} ${patient.lastname}`,
                  subtitle: `${patient.enDocumentType.comment.code} ${patient.documentNumber}`
                }
              }
              )}
              selectItem={selectPatient}
              deleteSelect={deletePatientSelected}
            />
          :
          <>
          <div className={`icon ${icon} location-name`}></div>
          <h3>{title}</h3>
          </>
          }
        </div>
        <div className="header__right-content">
          <ZaiaImage img={doctorData?.picture} alt="Zaia User" size={35} defaultImg={defaultDoctor}/> 
          <h3 onClick={openPopUp}>{P.drTranslation(doctorData?.gender, language)} {doctorData?.name} {doctorData?.lastname}</h3>
          <Popup 
            ref={refPopUp}
            trigger={
              <i
              className="icon zaia-icono-dropdown"
              />
            }
          >
            {
              close => (
                <UserDropdown doctorEmail={doctorData?.email} modalClose={close}/>
              )
            }
          </Popup>
        </div>
      </div>
    </S.UserHeaderDiv>
  );
}

export default UserHeader;
