import React, { useMemo, useState } from "react";
import * as S from "./MedicinesBoxInfo.style";
import { useTranslation } from "react-i18next";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_ALL_USER_MEDICINES } from "../../../../../../../graphql/queries/User";

// import
import * as P from "../../../../../../../utils/pipes";

// components
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import ProgressBarCard from "../../../../../shared/charts/progressBarCard";

function MedicinesBoxInfo({
  time,
  icon
}) {
  const [t] = useTranslation("global");
  const { 
    data: userToMedicines, 
    loading: userToMedicinesLoading 
  } = useQuery(
    QUERY_GET_ALL_USER_MEDICINES, 
    {
      variables: { 
        startDate: new Date(time?.startDate), 
        finishDate: new Date(time?.finishDate) 
      },
    }
  );

  const [reportsQuantity, setReportsQuantity] = useState();
  const [patientsReportsQuantity, setPatientsReportsQuantity] = useState(0);
  const [takenMedicines, setTakenMedicines] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [noTakenMedicines, setNoTakenMedicines] = useState(0);
  const [totalTakenMedicines, setTotalTakenMedicines] = useState(0);
  const [takenMedicinesPercentage, setTakenMedicinesPercentage] = useState(0);

  const infoMedicines = useMemo(() => {
    const info = userToMedicines?.userToDrugHistory;
    setReportsQuantity(info ? info.length : 0);
    // const info = dataTemp;
    const reportsMedicineJoined = info?.reduce((acc, obj) => {
      let found = false;
      acc.forEach(item => {
        if (item.drugId === obj.userToDrug?.drugId) {
           found = true;
           item.count++;
        };
      });
      if (!found) {
         const newObject = {
            drugName: obj.userToDrug?.drug?.genericName,
            drugId: obj.userToDrug?.drugId,
            count: 1
         }
         if( newObject.drugName && newObject.drugId ) {
           acc.push(newObject);
         }
      }
      return acc;
   }, []);

   const reportsMedicineForCharts = [];
   if (reportsMedicineJoined?.length > 0) {
    reportsMedicineJoined.forEach(medicine => {
      let objectMedicine = {
        name: medicine.drugName,
        percentage: P.getPercentage(medicine.count, info.length)
      }
      reportsMedicineForCharts.push(objectMedicine)
    })
   }

   reportsMedicineForCharts.sort((a,b) => (Number(b.percentage) - Number(a.percentage)));

   const patientsQuantity = info?.reduce((acc, obj) => {
    let found = false;
    acc.forEach(item => {
      if (item.userId === obj.userId) {
          found = true;
          item.count++;
      };
    });
    if (!found) {
        const objCopy = JSON.parse(JSON.stringify(obj));
        objCopy.count = 1;
        acc.push(objCopy);
    }
    return acc
  }, []);

  setPatientsReportsQuantity(patientsQuantity?.length);

  const takenMedicines = info?.reduce((acc, obj) => {
    if(obj.isTaken === true) acc.taken += 1;
    if(obj.isTaken === false) acc.noTaken += 1;
    return acc
  }, { taken: 0, noTaken: 0 });

  if ((takenMedicines?.taken || takenMedicines?.taken === 0) && 
      (takenMedicines?.noTaken || takenMedicines?.noTaken === 0)) {
    setTakenMedicines(takenMedicines?.taken);
    setNoTakenMedicines(takenMedicines?.noTaken);
    setTotalTakenMedicines(takenMedicines?.taken + takenMedicines?.noTaken);
    const takenPercentage = P.getPercentage(takenMedicines?.taken, (takenMedicines?.taken + takenMedicines?.noTaken));
    setTakenMedicinesPercentage(takenPercentage);
  }

   return (reportsMedicineForCharts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToMedicines, time])

  const color = {
    primary: "var(--green-color)",
    secundary: "var(--box-shadow-green-color)",
    dark: "var(--green-color-100)",
  }

  return (
    <S.MedicinesBoxInfo
      colorPrimary={color.primary}
      colorSecundary={color.secundary}
      colorDark={color.dark}
    >
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left">
          <CircleChartCard 
            icon={icon} 
            title={t("patients.boxInfoPatient.report.medicines")}
            percentagePrincipal={reportsQuantity ? reportsQuantity : 0}
            textPrincipal={t("patients.boxInfoPatient.report.repo.wereReported")}
            subTextPrincipal={t("patients.boxInfoPatient.report.repo.reports")}
            percentageSecundary={patientsReportsQuantity ? patientsReportsQuantity : 0}
            textSecundary={t("patients.boxInfoPatient.report.repo.reported")}
            subTextSecundary={t("patients.boxInfoPatient.report.repo.patients")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div>
        <div className="boxInfoPatient__info-center">
          {/* <div className="boxInfoPatient__infoReport">
            <h5>{t("patients.boxInfoPatient.report.medicine")}</h5>
            <div className="boxInfoPatient__infoMedicineFather">
              <CircleChart />
              
            </div>
          </div> */}
          <CircleChartCard 
            icon={icon} 
            title={t("patients.boxInfoPatient.report.medicine")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
            percentagePrincipal={takenMedicinesPercentage}
          >
            <div className="boxInfoPatient__infoMedicineData">
              <h2>
                {takenMedicines}<span>/{totalTakenMedicines}</span>
              </h2>
              <p>
                <strong>
                  {t("patients.boxInfoPatient.report.repo.reports")} 
                </strong>
                <br />
                {t("patients.boxInfoPatient.report.repo.wereMarked")}
              </p>
            </div>
          </CircleChartCard>
        </div>
        <div className="boxInfoPatient__info-right">
          <ProgressBarCard 
            title={t("patients.boxInfoPatient.report.medicineMinor")}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
            colorDark={color.dark}
            infoBars={infoMedicines}
            loading={userToMedicinesLoading}
            bgActive
          />
        </div>
      </div>
    </S.MedicinesBoxInfo>
  );
}

export default MedicinesBoxInfo;
