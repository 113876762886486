import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./TimeVerticalBars.style";
import "react-alice-carousel/lib/alice-carousel.css";

// pipes
import * as P from "../../../../../utils/pipes";

const TimeVerticalBars = ({ 
  activeHeader=false, 
  colorVariation, 
  colorPrimary, 
  colorSecundary,
  listLevels, 
  listLevelsStripe = true, 
  chartInfo,
  name,
  frequency,
  frequencyType,
  time
}) => {
  const [t] = useTranslation("global");

  return (
    <S.TimeVerticalBars
      colorVariation={colorVariation}
      colorSecundary={colorSecundary}
      colorPrimary={colorPrimary}
      activeHeader={activeHeader}
    >
      <div className="weekly-bars-card">
        { activeHeader ? 
          <div className="weekly-bars-card__header">
            <div className="weekly-bars-card__header-icon">
              <i className="icon zaia-icono-glucometria"></i>
            </div>
            <div className="weekly-bars-card__header-text">
              <h4>{name}</h4>
              <p>{P.firstLetterUpper(t('patients.boxInfoPatient.report.every'))} {frequency} {frequencyType}</p>
            </div>
          </div> : ""
        }
        <div className="weekly-bars-card__bars-container">
          <div className="weekly-bars-card__bars-container-listlevels disable-select">
            { listLevels?.map(level => <p key={level}>{level}</p>) }
            { listLevelsStripe ? <p key='default'>-</p> : null}
          </div>
          <div className="weekly-bars-card__bars-container-bars">
            {/* <AliceCarousel
              disableButtonsControls
              mouseTracking
              disableDotsControls
              autoWidth
            > */}
              {
                chartInfo?.map(info => 
                  <VerticalProgressBar 
                    key={info.day}
                    colorDark={colorPrimary} 
                    percentage={info.percentage} 
                    day={info.day} 
                    dayName={info.dayName}
                    time={time}
                    colorSecundary={colorSecundary}
                    colorVariation={colorVariation}
                  />
                )              
              }
            {/* </AliceCarousel> */}
          </div>
        </div>
      </div>
    </S.TimeVerticalBars>
  );
};

const VerticalProgressBar = ({percentage, day, colorDark, dayName, time, colorSecundary, colorVariation}) => {
  const nowTime = new Date(Date.now());
  const active = (day === nowTime.getDate() && new Date(time.startDate).getMonth() === nowTime.getMonth())

  return (
    <S.VerticalProgressBarDiv
      percentage={percentage + "%"}
      colorDark={colorDark}
      active={active}
      colorSecundary={colorSecundary}
      colorVariation={colorVariation}
    >
      <div className="vertical-progress-bar">
        <div className="filler-styles"/>
      </div>
      <div className="vertical-text disable-select">
        {dayName ? 
          <p className="vertical-text__weekday">{dayName}</p>
          : null
        }
        <p className="vertical-text__number">{day}</p>
      </div>
    </S.VerticalProgressBarDiv>
  )
}

export default TimeVerticalBars;
