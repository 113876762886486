import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DashboardAllPatients.style";

// Components
import NoPatients from "../../../shared/noPatients";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Components
import BoxInfoTimeFilter from "../boxInfoTimeFilter";
import FilterPrograms from "../../../shared/filterPrograms";
import SymptomsBoxInfo from "./components/symptomsBoxInfo";
import MedicinesBoxInfo from "./components/medicinesBoxInfo";
// import VitalsignsBoxInfo from "./components/vitalsignsBoxInfo";

// GraphQL 
import { useQuery } from "@apollo/client";
import { QUERY_GET_USERS_WITH_PROCESS } from "../../../../../graphql/queries/User";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-zaia.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-alerta.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;

function DashboardAllPatients() {
  const [t] = useTranslation("global");
  const [symptomsTime, setSymptomsTime] = useState();
  const [medicinesTime, setMedicinesTime] = useState();
  // const [vitalsignsTime, setVitalsignsTime] = useState();

  const {data: usersData, error: usersError, loading: usersLoading} = useQuery(QUERY_GET_USERS_WITH_PROCESS);

  if(usersLoading) 
    return (
      <S.DashboardAllPatients>
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        />    
      </S.DashboardAllPatients>
    );

  if(usersError) 
    return (
      <S.DashboardAllPatients>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="120px"
          text={t("chat.errorLoadingChats")}
          sizeText="15px"
          widthText="240px"
        />
      </S.DashboardAllPatients>
    );

  if (usersData?.user.length === 0)
    return (
      <S.DashboardAllPatients>
        <NoPatients 
          title={t("dashboard.boxes.noAlerts")}
          img={logoZaiaEmpty}
        />
      </S.DashboardAllPatients>
    );

  return (
    <S.DashboardAllPatients>
      <h3 className="rightSidePatient__title">
        {t("patients.patientList.generalInfo")}
      </h3>
      <FilterPrograms />
      <div className="cards-container">
        <BoxInfoTimeFilter
          icon="zaia-icono-sintomas"
          title={t("globally.symptom")}
          color="var(--blue-color)"
          colorTitle="var(--blue-color-200)"
          setTime={setSymptomsTime}
          time={symptomsTime}
        >
          <SymptomsBoxInfo icon="zaia-icono-sintomas" time={symptomsTime}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-medicina"
          title={t("globally.medicine")}
          color="var(--green-color)"
          colorTitle="var(--green-color-100)"
          setTime={setMedicinesTime}
          time={medicinesTime}
        >
          <MedicinesBoxInfo icon="zaia-icono-medicina" time={medicinesTime}/>
        </BoxInfoTimeFilter>
        {/* <BoxInfoTimeFilter
          icon="zaia-icono-signos-vitales"
          title={t("globally.vitalSign")}
          color="var(--red-color)"
          colorTitle="var(--red-color-100)"
          setTime={setVitalsignsTime}
          time={vitalsignsTime}
        >
          <VitalsignsBoxInfo icon="zaia-icono-signos-vitales" time={vitalsignsTime}/>
        </BoxInfoTimeFilter> */}
      </div>
    </S.DashboardAllPatients>
  );
}

export default DashboardAllPatients;
