import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./SidebarTopPatients.style";

// components
import FilterPrograms from "../../../shared/filterPrograms";
import ZaiaToggle from "../../../../zaiaComponents/zaiaToggle";
import FilterInputText from "../../../shared/filterInputText";

function SidebarTopPatients({ 
  title, 
  quantity, 
  processIds, 
  setProcessIds, 
  setInputFilter, 
  typeFilter,
  filterText, 
  filterPlaceholder,
  activeFilterProcess=true
}) {
  const [t] = useTranslation("global");
  const [activeTab, setActiveTab] = useState(1);
  const tabs = [
    {id: 1, name: t("patients.patientList.programs")},
    {id: 2, name: t("patients.patientList.general")}
  ]

  return (
    <S.SidebarTopPatients>       
      <S.ToogleContainer>
        <ZaiaToggle
          color="var(--no-notification-color)"
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </S.ToogleContainer>
      {
        activeFilterProcess ?
        <S.FilterContainer>
          <FilterPrograms processIds={processIds} setProcessIds={setProcessIds}/> 
        </S.FilterContainer> : null
      }
      <S.InputContainer>
        <FilterInputText 
          filterBy={filterText} 
          type={typeFilter} 
          placeholder={filterPlaceholder}
          setInputFilter={setInputFilter}
        />
      </S.InputContainer>
      <S.TitleContainer>
        <h3>{title}</h3>
        <span>({quantity})</span>
      </S.TitleContainer>
    </S.SidebarTopPatients>
  );
}

export default SidebarTopPatients;
