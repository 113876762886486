import { gql } from "@apollo/client";

export const MUTATION_EDIT_DOCTOR = gql`
  mutation MUTATION_EDIT_DOCTOR(
    $changes: doctor_set_input,
    $doctorId: uuid
  ) {
    update_doctor(where: {id: {_eq: $doctorId}}, _set: $changes) {    
      affected_rows
      returning {
        id
        picture
        isEmailVerified
        email
        name
        lastname
        birthdate
        countryPhoneId
        phone
        documentType
        documentNumber
        medicalCode
      }
    }
  }
`;

// doctorData_insert_input = [{academicTitle: fpkValue , medicalField: fpkValue, universityName: text}]
export const MUTATION_INSERT_DOCTOR_DATA = gql`
  mutation MUTATION_INSERT_DOCTOR_DATA (
    $doctorData: [doctorData_insert_input!]!
  ) {
    insert_doctorData(objects: $doctorData) {
      returning {
        academicTitle
        doctorId
        id
        medicalField
        universityName
      }
    }
  }
`

// doctorData_set_input = {academicTitle: fpkValue , medicalField: fpkValue, universityName: text}
export const MUTATION_UPDATE_DOCTOR_DATA = gql`
  mutation MUTATION_UPDATE_DOCTOR_DATA(
    $idDoctorData: uuid!
    $updateDoctorData: doctorData_set_input!,
  ) {
    update_doctorData_by_pk(pk_columns: {id: $idDoctorData}, _set: $updateDoctorData) {
      academicTitle
      doctorId
      id
      universityName
      medicalField
    }
  }
`
