import styled from "styled-components";

export const Patients = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
    .patients-container {
      width: 100%;
      height: 100%;
      display: flex;
      position: relative;
      background-color: var(--white);
      filter: ${(p) => p.modal ? "blur(4px)" : "none"};
      overflow: hidden;
      &__sidebar-patients {
        width: auto;
      }
      &__info-content {
        background-color: var(--background-patient);
        width: 100%;
        padding: 20px 25px 20px ${({selectedPatient}) => selectedPatient ? "25px" : '10px'};
        overflow: hidden;
      }
    }

    .invite-modal {
      position: absolute;
      width: 100%;
      height: 100%;
    }
`;
