import styled from "styled-components";

export const CircleChart = styled.div`
  display: flex;

  .circle-chart {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .circle-chart__center-icon {
    position: absolute;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${({colorIcon}) => colorIcon};
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: larger;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .CircularProgressbar {
    width: ${({widthGraph}) => widthGraph ? widthGraph : '80px' };
    overflow: visible;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: ${({primaryColor}) => primaryColor};
    box-shadow: 0px 3px 6px #6C6BD380;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
    overflow: visible;
    filter: drop-shadow( 0px 3px 6px ${({colorSecundary}) =>  colorSecundary} );
    -webkit-filter: drop-shadow( 0px 3px 6px ${({colorSecundary}) =>  colorSecundary} );
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: ${({colorSecundary}) => colorSecundary};
    stroke-linecap: round;
    overflow: visible;
  }
`
