import styled from "styled-components";

export const CustomInputDiv = styled.div`
  display: flex;
  flex-direction: column;
  .customTextInput__input {
    width: 296px;
    height: 30px;
    border-radius: 10px;
    border: 1px solid var(--disabled-input-color);
    margin: 10px 0 10px 0;
    padding: 5px;
    display: flex;
    align-items: center;
    input {
      border: none;
      margin-left: 5px;
      width: 100%;
    }
    div {
      color: var(--input-text-color);
      margin: 2px 3px 0 5px;
    }
  }
  .customTextInput__number {
    border-radius: 10px;
    border: 1px solid var(--disabled-input-color);
    margin: 10px 0 10px 0;
    padding: 5px;
    height: 30px;
    width: 210px;
    display: flex;
    align-items: center;
    width: 100%;
    input {
      border: none;
      margin-left: 5px;
      width: 100%;
    }
    div {
      color: var(--input-text-color);
      margin: 2px 3px 0 5px;
    }
  }
  input[type="text"]:focus {
    outline: none;
  }
  input:invalid {
    border-color: red;
    outline: none;
  }
  input[type="email"]:focus {
    outline: none;
  }
  input[type="password"]:focus {
    outline: none;
  }
  input[type="number"]:focus {
    outline: none;
  }
`;
