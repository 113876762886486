import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import * as S from "./Algorithms.style";
import { useStateValue } from "../../../contextAPI/StateProvider";
// import { Link } from "react-router-dom";

// Components
import AlgorithmsView from "./components/algorithmsView";
import FormByProcess from "./components/formByProcess";
import ZaiaReaction from "../../zaiaComponents/zaiaReaction";

// pipes
import * as P from "../../../utils/pipes";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../graphql/queries/Process";

// Assets
const algorithmsImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-manos.svg`;

function Algorithms() {
  const { t, i18n: { language } }= useTranslation("global");
  const history = useHistory();
  const { processNameKey } = useParams();
  const [{ selectedPatient }] = useStateValue();
  const [programSelected, setProgramSelected] = useState();
  const [programSelectedAlgorithm] = useState();
  const { data: processData } = useQuery( 
    QUERY_GET_PROCESS_NAMES, 
    { 
      variables: { 
        whereFilter: 
        selectedPatient 
        ? {
          userToProcesses: {
            userId: {
              _in: [ selectedPatient?.id ]
            }
          }
        } 
        : null
      } 
    } 
  );

  const algorithAchoLink = {
    processName: {
      en: 'ACHO',
      es: 'ACHO'
    },
    icon: 'zaia-acho-covid',
    action: 'link',
    link: 'https://maia-survey.web.app/public'
  }

  const action = (box) => {
    if(box.action){
      window.location.assign('https://maia-survey.web.app/public');
    } else {
      setProgramSelected(box);
    }
  }

  const processId = useMemo(() => {
    if(processNameKey) return processData?.process.find((process) => process.nameKey === processNameKey)?.id
  }, [processData, processNameKey])

  useEffect(() => {
    if(processData && processNameKey && !processId) {
      history.push('/algorithms')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processId, processData])

  if(processId) return (
    <S.Algorithms>  
      <FormByProcess processId={programSelectedAlgorithm}/>
    </S.Algorithms>
  )

  // const ConditionalLink = ({ children, to, condition }) => {
  //   (!!condition && to)
  //     ? <Link to={to}>{children}</Link>
  //     : <>{children}</>
  // }

  // const selectionProcess = (process) => {
  //   setProgramSelectedAlgorithm(process.userToProcesses[0]?.process.processToCustomForms[0].customFormId)
  // } 

  if(!programSelected)
    return (
      <S.Algorithms>
        <S.ProgramBoxes>
          <div className="reaction-content">
            <ZaiaReaction 
              zaiaImg={algorithmsImg}
              text={t("algorithms.title")}
              sizeText="20px"
              textColor="var(--blue-color)"
              widthImg="110px"
            />
          </div>
          <div className="algorithms__containerBoxes" >
            {
              processData?.process.map((process, index) => {

                // const processToFormQuantity = process.userToProcesses[0]?.process.processToCustomForms.length;
                  return (
                    <div className="box-container" 
                      key={process.id} 
                      onClick={() => action(process)}
                    >
                      {/* <ConditionalLink to={`/algorithms/${process.nameKey}`} condition={processToFormQuantity > 0}> */}
                        <Box
                          icon={process.icon}
                          title={P.dataTranslation(process.processName, language)}
                          process={process}
                        />
                      {/* </ConditionalLink> */}
                    </div>
                  )                
              })
            }
            <div className="box-container" key={"achoLink"} onClick={() => action(algorithAchoLink)}>
              <Box
                icon={algorithAchoLink.icon}
                title={P.dataTranslation(algorithAchoLink.processName, language)}
              />
            </div>
          </div>
        </S.ProgramBoxes>
      </S.Algorithms>
    )

  return (
    <S.Algorithms>
      <AlgorithmsView
        algorithm={programSelected}
        setProgramSelected={setProgramSelected}
      />
    </S.Algorithms>
  );
}

const Box = ({ icon, title }) => {
  return (
    <div>
      <S.BoxDiv>
        <div className="box__iconContainer">
          <i className={`icon ${icon}`} />
        </div>
        <h5>{title}</h5>
      </S.BoxDiv>
    </div>
  );
};

export default Algorithms;
