import React from "react";
import * as S from "./PatientReviewBox.style";
import { useTranslation } from "react-i18next";

// Assets
const welcomeImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/zaia-estetoscopio.svg`;

function PatientReviewBox({selectedPatient}) {
  
  const [t] = useTranslation("global");

  return (
    <S.PatientReviewBoxDiv>
      <div className="text-container">
        <h1>
          {t("dashboard.review.reviewMsg")} {selectedPatient.name} {selectedPatient.lastname}
        </h1>
        <p><strong>{selectedPatient.enDocumentType.comment.code} </strong>{selectedPatient.documentNumber}. 
        {/* <strong>{t("dashboard.review.belongsTo")}: </strong>{'Mieloma 4.0'} */}
        </p>
      </div>
      <div className="image-container">
        <img src={welcomeImg} alt="welcomeImg" />
      </div>
    </S.PatientReviewBoxDiv>
  );
}

export default PatientReviewBox;
