import styled from "styled-components";

export const CmpVerticalOptions = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  h5 {
    font-family: "Open Sans Bold";
  }
  .boxes-container {
    /* max-height: 120px;
    overflow: auto; */
    margin-top: 8px;
  }
`;

export const Box = styled.div`
  padding: 5px 10px;
  width: 100%;
  box-sizing: border-box;
  .box {
    cursor: pointer;
    box-shadow: ${({active}) => active ? '0px 1px 7px var(--blue-color)' : ''};
    border-radius: 5px;
    border: 1px solid var(--blue-color);
    background-color: ${({active}) => active ? 'var(--blue-color)' : ''};
    justify-content: center;
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    height: 50px;
    font-family: "Open Sans Semibold";
    color: ${({active}) => active ? 'var(--white)' : 'var(--blue-dark-color)'};
    text-align: center;
    margin: 0 10px 0 10px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    &-name {
      font-size: 15px;
      margin: auto auto auto 20px;
    }
    .box-icon {
      margin: auto 10px;
    }
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`