import styled from "styled-components";

export const FiltersDiv = styled.div`
  display: flex;
  flex-direction: column;
  .filters__moreIcon {
    cursor: pointer;
    border-radius: 15px;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding: 5px 5px;
  }
  .filters__Icons {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    padding-top: 10px;
    justify-content: center;
    align-items: center;
    p {
      font-size: 0.6rem;
      margin-bottom: -5px;
    }
    div {
      margin-bottom: 10px;
    }
    .filters__icon {
      color: ${({theme}) => theme.onSection};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      padding: 10px;
      margin: 3px 3px 3px 3px;
      cursor: pointer;
      box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;

      &--one{
        background-color: ${({theme}) => theme.allSections};
      }
      &--two{
        background-color: ${({theme}) => theme.primarySectionSypmtoms};
      }
      &--three{
        background-color: ${({theme}) => theme.primarySectionMedicines};
      }
      &--four{
        background-color: ${({theme}) => theme.primarySectionVitalSigns};
      }
      &--five{
        background-color: ${({theme}) => theme.primarySectionProfile};
      }
      &--six{
        background-color: ${({theme}) => theme.primarySectionCalendar};
      }
    }
    .active {
      border: 1px solid ${({theme}) => theme.onBackground};
      border-radius: 15px;
    }
  }
`;
