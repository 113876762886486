import React from "react";
import * as S from "./CoverPage.style";
const zaiaImage = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/logo-zaia-login.svg`;

function CoverPage() {
  return (
    <S.CoverPage>
      <img className="zaia-image" src={zaiaImage} alt="ZAIA" />
    </S.CoverPage>
  );
}

export default CoverPage;
