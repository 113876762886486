import { useField } from "formik";
import React from "react";
import { CustomSelectDiv } from "./style";

function CustomSelect({ icon, ...props }) {
  const [field, meta] = useField(props);

  return (
    <CustomSelectDiv>
      <div className="customSelect">
        <div className={`icon ${icon}`}></div>
        <select required {...field} {...props}></select>
      </div>

      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </CustomSelectDiv>
  );
}

export default CustomSelect;
